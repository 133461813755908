/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  ButtonIconOutLine,
  Div,
  ModalNotification,
  Notification,
  Row,
  SelectValue,
  Table,
  Title,
} from "../../components";
import { Color, Http, Mask, Type, Utils } from "../../utilities";
import { relatorioPDF } from "../../utilities/utils/RelatorioPDF";
import moment from "moment";
import * as XLSX from 'xlsx';

export default function Relatorio(props) {
  const {
    location: { state },
  } = props;
  const [isNewTable, setIsNewTable] = useState(false);
  const [produtosPreco, setProdutosPreco] = useState([]);

  const [apuracaoRodape, setApuracaoRodape] = useState([]);
  const [concorrentesInseridos, setConcorrentesInseridos] = useState([]);
  const [columnsConcorrentes] = useState([
    { value: "Código de Barras", align: "center" },
    { value: "PLU", align: "center" },
  ]);
  const [fieldsConcorrentes] = useState([
    { value: "ean", align: "center", type: Type.DADO.STRING },
    {
      value: "codigoProduto",
      align: "center",
      type: Type.DADO.STRING,
    },
  ]);
  var newColumns = [];
  var newFieldRow = [];

  const handleOnExportXLS = (data, name) => {

    var wb = XLSX.utils.book_new()
    var ws = XLSX.utils.aoa_to_sheet(data)

    XLSX.utils.book_append_sheet(wb, ws, name);

    XLSX.writeFile(wb, name + ".xlsx")
  }

  // RELATORIO PDF
  function exportarPDF() {
    const pesquisa = state.pesquisa;

    const header = [
      {
        text: 'Apuração de Cotação de Pesquisa',
        fontSize: 16,
        bold: true,
        margin: [15, 20, 0, 15],
      },
    ]

    const dadosTotal = apuracaoRodape.map(dados => {
      return [
        {
          text: dados.concorrente ? dados.concorrente : '',
          fontSize: 8,
        },
        {
          text: dados.qtd_produtos_para_cotacao ? dados.qtd_produtos_para_cotacao : '',
          fontSize: 8,
          alignment: 'center'
        },
        {
          text: dados.qtd_produtos_cotados ? dados.qtd_produtos_cotados : '',
          fontSize: 8,
          alignment: 'center'
        },
        {
          text: dados.qtd_vitoria_preco ? dados.qtd_vitoria_preco : '',
          fontSize: 8,
          alignment: 'center'
        },
      ]
    })
    const tableTotal = {
      margin: [0, 4, 0, 15],
      table: {
        headerRows: 1,
        widths: ['*', '*', '*', '*'],
        body: [
          //colunas
          [
            { text: 'Concorrente', style: 'tableHeader', },
            { text: 'Qtd Produtos para cotação', style: 'tableHeader', alignment: 'center' },
            { text: 'Qtd Produtos cotados', style: 'tableHeader', alignment: 'center' },
            { text: 'Qtd Produtos vitoriosos', style: 'tableHeader', alignment: 'center' },
          ],
          //linhas
          ...dadosTotal
        ]
      },
      layout: {
        fillColor: function (rowIndex, node, columnIndex) {
          return (rowIndex % 2 === 0) ? '#F2F2F2' : null;
        }
      }
    }

    // Colunas e Tamanho dinamico Apuracao
    let widths = []
    let colunas = newColumns.map(coluna => {
      if (coluna.value === 'Código de Barras') {
        widths.push('auto')
        return { text: coluna.value, style: 'tableHeaderApuracao', alignment: 'center' }
      }
      if (coluna.value === 'Produto') {
        widths.push('*')
        return { text: coluna.value, style: 'tableHeaderApuracao', alignment: 'left' }
      }
      widths.push('auto')
      return { text: coluna.value, style: 'tableHeaderApuracao', alignment: 'center' }
    })

    const dadosAparacao = produtosPreco.map(produto => {
      let row = []
      newFieldRow.forEach((item, index) => {
        row.push({
          text: produto[item.value] ? produto[item.value === 'menor_preco' ? 'menor_precoMask' : item.value] : '0,00',
          style: 'apuracaoRows',
          alignment: index === 2 ? 'left' : 'center'
        })
      })
      return [
        ...row
      ]
    })

    const tableApuracao = {
      table: {
        headerRows: 1,
        widths: widths,
        body: [
          colunas,
          //linhas
          ...dadosAparacao
        ]
      },
      layout: {
        fillColor: function (rowIndex, node, columnIndex) {
          return (rowIndex % 2 === 0) ? '#F2F2F2' : null;
        }
      }
    }

    // CONTEUDO PDF
    let dataPesquisa = pesquisa.datacadastroMask ? pesquisa.datacadastroMask : '';
    let dataValidade = pesquisa.datavalidadeMask ? pesquisa.datavalidadeMask : '';

    const content = [
      {
        columns: [
          {
            text: 'Descricao: ' + pesquisa.descricao,
            fontSize: 9,
          },

          {
            text: 'Pesquisa N.: ' + pesquisa.idPesquisa,
            fontSize: 9,
          },
        ],
      },

      {
        columns: [
          {
            text: 'Data da Validade: ' + dataValidade,
            fontSize: 9,
          },
          {
            text: 'Tipo: ' + pesquisa.tipo,
            fontSize: 9,
          },
        ],
      },

      {
        columns: [
          {
            text: 'Data da Pesquisa: ' + dataPesquisa,
            fontSize: 9,
          },
          {
            text: 'Status: ' + pesquisa.status,
            fontSize: 9,
          },
        ],
      },


      {
        text: 'Total da precificação da pesquisa por concorrentes',
        margin: [0, 18, 0, 5]
      },
      tableTotal,

      {
        text: 'Apuração de precificação de pesquisa de Concorrentes',
        margin: [0, 18, 0, 5]
      },
      tableApuracao
    ]

    const styles = {
      tableHeader: {
        bold: true,
        fontSize: 9,
        color: 'black'
      },
      tableHeaderApuracao: {
        bold: true,
        fontSize: 8,
        color: 'black'
      },
      apuracaoRows: {
        bold: false,
        fontSize: 7,
        margin: [0, 2, 0, 2]
      }
    }
    let orientation = 'landscape'
    let nameArquivo = pesquisa.descricao.trim() ? pesquisa.descricao.trim() : "file.pdf"

    relatorioPDF(header, content, styles, orientation, nameArquivo);
  }



  useEffect(() => {
    getDataPrecificacao();
    getDataConcorrentesInseridos();
    getDataApuracaoRodape();
  }, []);

  useEffect(() => {
    if (concorrentesInseridos.length > 0) {
      setIsNewTable(true);
    }
  }, [concorrentesInseridos]);

  function getDataConcorrentesInseridos() {
    let idPesquisa = state.pesquisa.idPesquisa;
    if (!idPesquisa) return;

    if (idPesquisa) {
      Http.get("/pesquisa/" + idPesquisa + "/concorrente")
        .then(({ data }) => {
          if (data) {
            setConcorrentesInseridos(data);
          }
        })
        .catch((error) => console.log(error));
    }
  }

  function getDataApuracaoRodape() {
    let idPesquisa = state.pesquisa.idPesquisa;
    if (!idPesquisa) return;

    if (idPesquisa) {
      Http.get("/pesquisa/" + idPesquisa + "/apuracao/rodape")
        .then(({ data }) => {
          if (data) {
            setApuracaoRodape(data);
          }
        })
        .catch((error) => console.log(error));
    }
  }

  function getDataPrecificacao() {
    let idPesquisa = state.pesquisa.idPesquisa;
    if (!idPesquisa) return;

    //`pesquisa/${idPesquisa}/preco/lista_de_preco`
    Http.get(`pesquisa/${idPesquisa}/apuracao`)
      .then(({ status, data }) => {
        if (data) {
          newDataProdutosPreco(data);
        }
      })
      .catch((error) => console.log(error));
  }

  function newDataProdutosPreco(data) {
    let newProdutosPreco = [];

    data.forEach((item) => {
      let precosConcorrentes = JSON.parse(item.concorrente_preco.value);
      let newItem = {
        ...item,
        ...precosConcorrentes,
      };
      newProdutosPreco.push(newItem);
    });

    setProdutosPreco(newProdutosPreco);
  }

  function createColumnFieldsDinamic(table) {
    if (concorrentesInseridos.length > 0) {
      let newColumn = [];
      let newFields = [];

      concorrentesInseridos.forEach((item) => {
        newColumn.push({
          value: item.descricao,
          align: "right",
          width: "100px",
        });
        newFields.push({
          value: item.descricao,
          align: "right",
          type: Type.DADO.CURRENCY,
          width: "100px",
        });
      });

      let newColumnConcorrentes = [
        ...columnsConcorrentes,
        { value: "Produto", align: "left" },
        ...newColumn,
        { value: "Menor Preço", align: "right" },
      ];
      let newFieldsConcorrentes = [
        ...fieldsConcorrentes,
        { value: "descricao_produto", align: "left", type: Type.DADO.STRING },
        ...newFields,
        { value: "menor_preco", align: "right", type: Type.DADO.CURRENCY },
      ];

      newColumns = newColumnConcorrentes;
      newFieldRow = newFieldsConcorrentes;

      return (
        <Table
          columns={newColumnConcorrentes}
          fields={newFieldsConcorrentes}
          bordered
          search
          orientation="center"
          pagination
          data={table}
          error="Nenhuma pesquisa encontrada"
        ></Table>
      );
    }
  }

  function getTipoPesquisaSelect() {
    let selectValue = [new SelectValue("", "SELECIONE", true)];

    concorrentesInseridos.forEach((item) => {
      selectValue.push(
        new SelectValue(item.idConcorrente, item.descricao, false)
      );
    });
    return selectValue;
  }

  function finalizarPesquisa(pesquisa) {
    Http.put(`/pesquisa/situacao/concluido`, pesquisa).then(({ data }) => {
      if (data.status) {
        Notification.success(data.mensagem);
      } else {
        Notification.danger(data.mensagem);
      }
    });
  }

  function onConfirmFinalizarPesquisa(pesquisa) {
    ModalNotification.confirm(
      "Deseja Finalizar esta Pesquisa? ",
      "Esta pesquisa será finalizada no sistema de compras!",
      () => finalizarPesquisa(pesquisa),
      () => { }
    );
  }

  function exportCSV_Total_Cotacao() {
    var date = Date.now();
    var pedidos = apuracaoRodape;
   // var cvsRow = [];
    var header = [['Concorrente', 'Qtd Produtos para cotação', 'Qtd Produtos cotados', 'Qtd Produtos vitoriosos']];

    for (var pindex = 0; pindex < pedidos.length; pindex++) {
      header.push([
        pedidos[pindex].concorrente,
        pedidos[pindex].qtd_produtos_para_cotacao,
        pedidos[pindex].qtd_produtos_cotados,
        pedidos[pindex].qtd_vitoria_preco,

      ]);
    }

    handleOnExportXLS(header, "Total_da_cotacao-" + moment(date).format("yyyy-MM-DD"))

    // for (var i = 0; i < header.length; ++i) {
    //   cvsRow.push(header[i].join(","));
    // }

    // var csvString = cvsRow.join("%0A");
    // var a = document.createElement("a");
    // a.href = 'data:attachment/csv,' + csvString;
    // a.target = "_Blank";
    // a.download = "Total_da_cotacao_por_Concorrentes-" + moment(date).format("yyyy-MM-DD") + ".csv";
    // document.body.appendChild(a);
    // a.click();
  }

  function exportCSV_Apuracao_Cotacao() {
    var date = Date.now();
    var pedidos = produtosPreco;
   // var cvsRow = [];
    var header = [[], []];
    var columm = createColumnFieldsDinamic(produtosPreco);
    var fields = columm.props.fields;

    for (var pindex = 0; pindex < columm.props.columns.length; pindex++) {
      header[0].push(columm.props.columns[pindex].value);
    }

    for (var pindex = 0; pindex < pedidos.length; pindex++) {
      header.push([])

      fields.forEach((item, index) => {
        let label = Utils.isNotNull(pedidos[pindex][item.value]) ? pedidos[pindex][item.value] : pedidos[pindex][item.value + "Mask"]

        header[pindex + 1].push( item.value === "menor_preco" ? Mask.convertNumberBr(label) : label)
      })
    }

    handleOnExportXLS(header, "Apuracao_de_cotacao-" + moment(date).format("yyyy-MM-DD"))

    // for (var i = 0; i < header.length; ++i) {
    //   cvsRow.push(header[i].join(","));
    // }

    // var csvString = cvsRow.join("%0A");
    // var a = document.createElement("a");
    // a.href = 'data:attachment/csv,' + csvString;
    // a.target = "_Blank";
    // a.download = "Apuracao_de_cotacao_por_Concorrentes-" + moment(date).format("yyyy-MM-DD") + ".csv";
    // document.body.appendChild(a);
    // a.click();
  }

  return (
    <Div
      margin="3"
      shadow
      bg={Color.NODE.WHITE}
      borderLeft={Color.NODE.PRIMARY}
    >

      <Div col="12">
        <Row
          col="12"
          style={{ justifyContent: "flex-end", padding: "16px" }}
        >

          <ButtonIconOutLine
            value="Exportar Cotação XLS"
            icon={Type.ICON.CSV}
            btn={Color.NODE.DANGER}
            handlerClick={() => exportCSV_Total_Cotacao()}
          />

          <ButtonIconOutLine
            value="Exportar Apuração XLS"
            icon={Type.ICON.CSV}
            btn={Color.NODE.DANGER}
            handlerClick={() => exportCSV_Apuracao_Cotacao()}
          />


          <ButtonIconOutLine
            value="Exportar PDF"
            icon={Type.ICON.PDF}
            btn={Color.NODE.DANGER}
            handlerClick={() => exportarPDF()}
          />

          <ButtonIconOutLine
            //icon={Type.ICON.DELETE}
            value="Finalizar Pesquisa"
            btn={Color.NODE.WARNING}
            handlerClick={(e) => {
              e.preventDefault();
              onConfirmFinalizarPesquisa(state.pesquisa);
            }}
          />
        </Row>
        {apuracaoRodape && (
          <Table
            title="Total da precificação da pesquisa por concorrentes"
            columns={[
              { value: "Concorrente", align: "left" },
              {
                value: "Qtd Produtos para cotação",
                width: "100px",
                align: "center",
              },
              {
                value: "Qtd Produtos cotados",
                width: "100px",
                align: "center",
              },
              {
                value: "Qtd Produtos vitoriosos",
                align: "center",
                width: "100px",
              },
            ]}
            fields={[
              {
                value: "concorrente",
                align: "left",
                type: Type.DADO.STRING,
                width: "100px",
              },
              {
                value: "qtd_produtos_para_cotacao",
                align: "center",
                type: Type.DADO.INTEGER,
                width: "70px",
              },
              {
                value: "qtd_produtos_cotados",
                align: "center",
                type: Type.DADO.STRING,
                width: "70px",
              },
              {
                value: "qtd_vitoria_preco",
                align: "center",
                type: Type.DADO.STRING,
                width: "70px",
              },
            ]}
            bordered
            search
            orientation="center"
            data={apuracaoRodape}
            error="Nenhuma pesquisa encontrada"
          ></Table>
        )}
      </Div>

      <Title
        value="Apuração de precificação de pesquisa de Concorrentes"
        type="h4"
        paddingLeft="4"
        paddingRight="4"
        paddingTop="3"
      />

      <Div padding="2">
        {!isNewTable && (
          <Table
            columns={columnsConcorrentes}
            fields={fieldsConcorrentes}
            bordered
            orientation="center"
            pagination
            search
            data={produtosPreco}
            error="Nenhuma pesquisa encontrada"
          ></Table>
        )}
        {isNewTable && createColumnFieldsDinamic(produtosPreco)}
      </Div>
    </Div>

  );
}
