import React, { Component } from "react";
import { Link } from "react-router-dom";
import Div from "../../layout/div/Div";
import I from "../../i/I";
import Label from "../../texts/label/Label";
import Span from "../../texts/span/Span";
import { className } from "../../css";

class NavbarItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      active: false
    };

    this.toggleActive = this.toggleActive.bind(this);
  }

  toggleActive() {
    this.setState({
      active: !this.state.active
    });
  }

  getClasseContainer() {
    let classe = "collapsed ";
    classe = classe.concat(this.state.active ? "active " : "");
    classe = classe.concat(className(this.props))
    return classe;
  }

  render() {
    const { rota, target, value, sizeIcon, iconLeft, iconRight, classIconLeft, id } = this.props;

    return (
      <Link to={rota}>
        <li
          id={id}
          data-target={target}
          data-toggle="collapse"
          className={this.getClasseContainer()}
          onClick={e => this.toggleActive()}
        >
          <span>
            <Div inline="between">
              <Div inline="center">
                <I
                  className="pl-2 pr-2"
                  sizeIcon={sizeIcon}
                  icon={iconLeft}
                  padding="2"
                />
                <Span className="cursor-pointer" value={value} />
              </Div>
              <I sizeIcon={sizeIcon} icon={iconRight} paddingRight="2" />
            </Div>
          </span>
        </li>
      </Link>
    );
  }
}

export default NavbarItem;
