import React, { Component } from "react";
import { Color, Http, Type, Mask} from "../../utilities";

import {
  Row, Title, Table, Label,
  InputDefault,
  InputInteger,
  InputNumberBR,
  Form, 
  SelectValue,
  Select,
  Notification, ModalNotification,
  SectionForm,
  SectionButtonForm, NavbarTab,
  Button,
  Div, If
} from "../../components";
import { connect } from "react-redux";


class Cadastrar extends Component {

  constructor(props) {
    super(props);

    this.state = {
      nameButton: "Salvar",
      nameButtonProdFornecedor: "Adicionar",
      nameButtonCodigoBarra: "Adicionar",
      user: props.user,     
      classificações: [],  
      produtoFornecedores: [],  
      pessoas: [],  
      departamentos: [],
      grupos: [], 
      addButtonCodigoBarra: false,        
      itemActive: {
        target: "DADOS",
        value: "DADOS",
        active: true        
      },
      marcas: [],  
      ...this.props.location.state 
    };

    this.submit = this.submit.bind(this); 
    this.submitProtudoFornecedor = this.submitProtudoFornecedor.bind(this); 

    this.submitCodigoBarra = this.submitCodigoBarra.bind(this);      
    this.listaCodigoBarra = this.listaCodigoBarra.bind(this);                   
    this.deletarCodigoBarra = this.deletarCodigoBarra.bind(this);         
    this.addCodigoBarra = this.addCodigoBarra.bind(this);  
    this.cleanFormCodigoBarra = this.cleanFormCodigoBarra.bind(this);  
  }

  UNSAFE_componentWillMount() {         
    let produto = this.state.produto; 
    this.getDataDepartamentos();     
    this.getDataFornecedor();     
    this.getDataMarcas();       
    if (this.state.editar) { 
          this.listaCodigoBarra()
          this.getDataGrupo(produto.idDepartamento);          
          this.getDataProdutoFornecedor(produto.idProduto);            
          this.setState({
            nameButton: "Alterar",            
            idProduto: produto.idProduto,
            idMarca: produto.idMarca,	
            idClassificacao: produto.idClassificacao, 
            idDepartamento: produto.idDepartamento,
            idGrupo: produto.idGrupo,
            idProdutoPrincipal: produto.idProdutoPrincipal,
            codigoProduto: produto.codigoProduto,
            descricao: produto.descricao,
            descricaoCurta: produto.descricaoCurta,	
            descricaoLonga: produto.descricaoLonga,
            descricaoFabricacao: produto.descricaoFabricacao,
            ean: produto.ean,
            margemPrevista: produto.margemPrevista,     	
            ncm: produto.ncm,
            prazoval: produto.prazoval,
            icms: produto.icms,
            margemMinima: produto.margemMinima,
            custoUltimoPedido: produto.custoUltimoPedido,
            altura: produto.altura,
            largura: produto.largura,
            profundidade: produto.profundidade,
            embalagem: produto.embalagem,
            pesoLiquido: produto.pesoLiquido,
            pesoBruto: produto.pesoBruto,
            situacao: produto.situacao,
            cpfCnpjFornecedorPrincipal: produto.cpfCnpjFornecedorPrincipal,
            nomeFornecedorPrincipal: produto.nomeFornecedorPrincipal,
            principal: "NÃO"
          });
    }

  }

  getDataDepartamentos() {
    this.setState({departamentos: []});
    Http.get(`/departamento/` + this.state.user.idGestorCompras).then(({ data }) => {                  
      if (data) {      
        let depComDados = [];  
        depComDados.push(new SelectValue(0, "SELECIONE", true));      
        Array.from(data).map((dep) => {
          depComDados.push(
            new SelectValue(dep.idDepartamento,  dep.sequencia + ' - ' + dep.descricao, false)
          );
        });            
        this.setState({ departamentos: depComDados });
      }
    });
  }

  getDataGrupo(idDepartamento) {
    this.setState({grupos: []});
    Http.get(`/grupo/listargrupopordepartamento/` + idDepartamento).then(({ data }) => {                  
      if (data) {      
        let grupoComDados = [];  
        grupoComDados.push(new SelectValue(0, "SELECIONE", true));      
        Array.from(data).map((grup) => {
          grupoComDados.push(
            new SelectValue(grup.idGrupo, grup.sequencia + ' - '+ grup.descricao, false)
          );
        });            
        this.setState({ grupos: grupoComDados });
      }
    });
  }

  listaProdutos() {
    this.props.history.push({pathname: `/compras/produto`});
  }

  getDataFornecedor() {            
    Http.get(`/pessoa/listarFornecedor/` + this.state.user.idGestorCompras)
      .then(({ status, data }) => {
        if (data) {
          let pessoasComDados = [];
          pessoasComDados.push(new SelectValue(0, "SELECIONE", true));
          Array.from(data).map((pessoa) => {
            pessoasComDados.push(
              new SelectValue(pessoa.idPessoa, pessoa.nome, false)
            );
          });          
          this.setState({ pessoas: pessoasComDados });
        }
      })
      .catch((error) => console.log(error));
  }

  getDataProdutoFornecedor(idProduto) {        
    this.setState({ produtoFornecedores: [] });
    var prodFornec = {
      idGestorCompras: this.state.user.idGestorCompras,
      idProduto: idProduto
    }
    Http.post(`/produtofornecedor/listar`, prodFornec)    
    .then(({ status, data }) => {         
      if (data) {          
        this.setState({ produtoFornecedores: data });
      }
    })
    .catch((error) => console.log(error));
  } 

  getTipoSimNaoSelect() {
    return [  
      new SelectValue("NAO", "NÃO", true),
      new SelectValue("SIM", "SIM", false),
    ];
  }

  getTipoSituacaoSelect() {
    return [
      new SelectValue("", "SELECIONE", true),
      new SelectValue("LIBERADO-VENDA", "LIBERADO-VENDA", false),
      new SelectValue("BLOQUEADO-COMPRA", "BLOQUEADO-COMPRA", false),
      new SelectValue("BLOQUEADO-VENDA", "BLOQUEADO-VENDA", false),
      new SelectValue("PRODUTO-EXCLUIDO", "PRODUTO-EXCLUIDO", false)
    ];
  }

  getTipoUnidadeMedidaSelect() {
    return [
      new SelectValue("", "SELECIONE", true),
      new SelectValue("CAIXA", "CAIXA", false),
      new SelectValue("DISPLAY", "DISPLAY", false),
      new SelectValue("FARDO", "FARDO", false),
      new SelectValue("GARRAFA", "GARRAFA", false),
      new SelectValue("LITRO", "LITRO", false),
      new SelectValue("METRO", "METRO", false),
      new SelectValue("MILHEIRO", "MILHEIRO", false),
      new SelectValue("PACOTE", "PACOTE", false),
      new SelectValue("PEÇA", "PEÇA", false),
      new SelectValue("QUILO", "QUILO", false),
      new SelectValue("ROLO", "ROLO", false),
      new SelectValue("UNIDADE", "UNIDADE", false),
      new SelectValue("VIDRO", "VIDRO", false)
    ];
  }
 
  async submit(event) {
    event.preventDefault();
    var valida =  this.validaFormProd();
    if (valida) {
      if (event.target.reportValidity()) {
        if (this.state.idProduto === undefined) {        
          this.cadastrar(this.state);
        } else {
          this.update(this.state);
        }
      }
    }
  }

  cadastrar(produto) {           
    produto.idGestorCompras = this.state.user.idGestorCompras         
      Http.post("/produto", produto).then(({ data }) => {
        if (data.status) {          
          this.setState({idProduto: data.id, nameButton: "Alterar"});                                      
          Notification.success(data.mensagem);
        } else {
          Notification.danger(data.mensagem);
        }
      });    
  }

  validaFormProd () {

    var valida = true;

    if (this.state.idDepartamento === undefined) {          
      Notification.danger("Selecione um Departamento!");
      return false;
    }

    if (this.state.idGrupo === undefined) {          
      Notification.danger("Selecione um Grupo!");
      return false;
    }

    return valida;
  }

  update(produto) {        
    produto.idUsuarioAtualizacao = this.state.user.idUsuario;
      Http.put("/produto", produto).then(({ data }) => {      
        if (data.status) {          
          Notification.success(data.mensagem);
          this.listaProdutos();
        } else {
          Notification.danger(data.mensagem);
        }      
      });    
  }

  getDataMarcas() {
    this.setState({marcas: []});
    Http.get(`/marca/listar/` + this.state.user.idGestorCompras).then(({ data }) => {                  
      if (data) {      
        let marcasComDados = [];  
        marcasComDados.push(new SelectValue(0, "SELECIONE", true));      
        Array.from(data).map((marca) => {
          marcasComDados.push(
            new SelectValue(marca.idMarca, marca.descricao, false)
          );
        });            
        this.setState({ marcas: marcasComDados });
      }
    });
  }

  getItens = () => {
    let item = this.state.itemActive;
      return [
        {
          target: "DADOS",
          value: "DADOS DO PRODUTO",
          active: item.value === "DADOS",
        },
        {
          target: "EMBALAGENS",
          value: "EMBALAGENS",
          active: item.value === "EMBALAGENS",
        },
        {
          target: "FORNECEDOR",
          value: "FORNECEDOR",
          active: item.value === "FORNECEDOR",
        }
      ];  
    
  };
  
   renderProduto() {
    
    return <Div>
          <Form id="formCadastroProduto" handlerSubmit={this.submit}>
          <SectionForm borderLeft="primary">
            <Row col="12">
            <Select required 
                        responsive="6"
                        label="Departamentos"
                        valueSelected={this.state.idDepartamento}
                        handlerChange={(e) => {
                          this.setState({idDepartamento: e.target.value });
                          this.getDataGrupo(e.target.value);                             
                        }}
                        values={this.state.departamentos}
                      />
                <Select disabled={this.state.tipo === "DEPARTAMENTO" ? true : false} 
                        responsive="6"
                        label="Grupo"
                        valueSelected={this.state.idGrupo}
                        handlerChange={(e) => {
                          this.setState({idGrupo: e.target.value });                             
                        }}
                        values={this.state.grupos}
                      />
            </Row>
            <Row col="12">
            <Select required
                  responsive="6"
                  label="Situação"
                  valueSelected={this.state.situacao}
                  handlerChange={(e) => {
                    this.setState({situacao: e.target.value });                                        
                  }}
                  values={this.getTipoSituacaoSelect()}
                /> 
            <Select required 
                        responsive="6"
                        label="Marcas"
                        valueSelected={this.state.idMarca}
                        handlerChange={(e) => {
                          this.setState({idMarca: e.target.value });                             
                        }}
                        values={this.state.marcas}
                      />
            </Row>
            <Row col="12">
            <InputDefault 
                label="ID Produto Principal"
                name="idProdutoPrincipal"
                responsive="6"
                value={this.state.idProdutoPrincipal}
                handlerChange={(e) => {
                  this.setState({idProdutoPrincipal: e.target.value });                  
                }}
              />

              <InputDefault 
                label="Código Produto"
                name="codigoProduto"
                responsive="6"
                value={this.state.codigoProduto}
                handlerChange={(e) => {
                  this.setState({codigoProduto: e.target.value });                  
                }}
              />
            </Row>
            <Row col="12">
            <InputDefault 
                label="Descrição"
                name="descricao"
                responsive="12"
                value={this.state.descricao}
                handlerChange={(e) => {
                  this.setState({descricao: e.target.value });                  
                }}
              />

          </Row>
          <Row col="12">
            <InputDefault 
                label="Descrição Curta"
                name="descricaoCurta"
                responsive="12"
                value={this.state.descricaoCurta}
                handlerChange={(e) => {
                  this.setState({descricaoCurta: e.target.value });                  
                }}
              />
          </Row>
          <Row col="12">
            <InputDefault 
                label="Descrição Longa"
                name="descricaoLonga"
                responsive="12"
                value={this.state.descricaoLonga}
                handlerChange={(e) => {
                  this.setState({descricaoLonga: e.target.value });                  
                }}
              />
          </Row>
          <Row col="12">
            <InputDefault 
                label="Descrição Fabricação"
                name="descricaoFabricacao"
                responsive="12"
                value={this.state.descricaoFabricacao}
                handlerChange={(e) => {
                  this.setState({descricaoFabricacao: e.target.value });                  
                }}
              />
            </Row> 
            <Row col="12">
              <InputNumberBR 
                  label="ICMS"
                  name="icms"
                  responsive="4"
                  value={this.state.icms}
                  handlerChange={(e) => {
                    this.setState({icms: e.target.value });                  
                  }}
                />
              <InputNumberBR 
                  label="Margem Minima"
                  name="margemMinima"
                  responsive="4"
                  value={this.state.margemMinima}
                  handlerChange={(e) => {
                    this.setState({margemMinima: e.target.value });                  
                  }}
                />
              <InputNumberBR 
                  label="Custo Ultimo Pedido"
                  name="custoUltimoPedido"
                  responsive="4"
                  value={this.state.custoUltimoPedido}
                  handlerChange={(e) => {
                    this.setState({custoUltimoPedido: e.target.value });                  
                  }}
                />

            </Row>            
            <Row col="12">
              <InputInteger 
                  label="Altura"
                  name="altura"
                  responsive="4"
                  value={this.state.altura}
                  handlerChange={(e) => {
                    this.setState({altura: e.target.value });                  
                  }}
                />
              <InputInteger 
                  label="Largura"
                  name="largura"
                  responsive="4"
                  value={this.state.largura}
                  handlerChange={(e) => {
                    this.setState({largura: e.target.value });                  
                  }}
                />
               <Select required
                  responsive="4"
                  label="Unidade de Venda"
                  valueSelected={this.state.embalagem}
                  handlerChange={(e) => {
                    this.setState({embalagem: e.target.value });                                        
                  }}
                  values={this.getTipoUnidadeMedidaSelect()}
            /> 

            </Row>
            </SectionForm> 
            <SectionForm title="Informações Fiscais" borderLeft="primary">
            <Row col="12">
            <InputInteger 
                  label="Prazo de Validade"
                  name="prazoval"
                  responsive="4"
                  value={this.state.prazoval}
                  handlerChange={(e) => {
                    this.setState({prazoval: e.target.value });                  
                  }}
                />


              <InputInteger 
                  label="Profundidade;"
                  name="profundidade;"
                  responsive="4"
                  value={this.state.profundidade}
                  handlerChange={(e) => {
                    this.setState({profundidade: e.target.value });                  
                  }}
                />
            <InputDefault 
                label="Peso Bruto"
                name="pesoBruto"
                responsive="4"
                value={this.state.pesoBruto}
                handlerChange={(e) => {
                  this.setState({pesoBruto: e.target.value });                  
                }}
              />
            </Row>
            <Row col="12">
            <InputDefault 
                label="EAN"
                name="ean"
                responsive="3"
                value={this.state.ean}
                handlerChange={(e) => {
                  this.setState({ean: e.target.value });                  
                }}
              />
            <InputDefault 
                label="NCM"
                name="ncm"
                responsive="3"
                value={this.state.ncm}
                handlerChange={(e) => {
                  this.setState({ncm: e.target.value });                  
                }}
              />
            <InputDefault 
                label="Peso Líquido"
                name="pesoLiquido"
                responsive="3"
                value={this.state.pesoLiquido}
                handlerChange={(e) => {
                  this.setState({pesoLiquido: e.target.value });                  
                }}
              />

            <InputNumberBR 
                label="Margem Ideal"
                name="margemPrevista"
                responsive="3"
                value={this.state.margemPrevista}
                handlerChange={(e) => {
                  this.setState({margemPrevista: e.target.value });                  
                }}
              />
            </Row>
            </SectionForm>
            
            <SectionButtonForm>
                <Button
                  btn={Color.NODE.PRIMARY}
                  value={this.state.nameButton}
                  col="1"
                  margin="1"
                />

                <Button
                  btn={Color.NODE.DANGER}
                  value="Cancelar"
                  col="1"
                  handlerClick={(_e) => this.listaProdutos()}
                  margin="1"
                />
              </SectionButtonForm>
          
    </Form>    
    </Div>
  }


  submitProtudoFornecedor(event) {
    event.preventDefault();    
    if (event.target.reportValidity()) {
        if (this.state.idProdutoFornecedor === undefined) {
          var valida =  this.validaForm();
          if (valida) {
            this.cadastrarProdFornecedor(this.state);
          }    
        } else {
          this.updateProdFornecedor(this.state);
        }
      }    
  }

  validaForm () {

    var valida = true;

    if(this.state.idProduto === undefined){
      Notification.danger("O produto não foi salvo!"); ;
      valida = false;        
    }
    if(this.state.idPessoaFornecedor === undefined){
      Notification.danger("Selecione um Fornecedor!"); ;
      valida = false;        
    }
    
    Array.from(this.state.produtoFornecedores).map((prod) => {      
      if(this.state.idPessoaFornecedor === prod.idPessoaFornecedor){        
        Notification.danger("Este fornecedor já esta cadastrado para este produto!");
        valida = false;        
      }
    });

    return valida;
  }

  cadastrarProdFornecedor(prodfornec) {
    prodfornec.idProduto = this.state.idProduto;
    prodfornec.idGestorCompras = this.state.user.idGestorCompras;
    Http.post("/produtofornecedor", prodfornec).then(({ data }) => {
      if (data.status) {
        this.getDataProdutoFornecedor(this.state.idProduto);
        this.cleanFormProdFornec();
        Notification.success(data.mensagem);           
      } else {
        Notification.danger(data.mensagem);
      }
    });
  }

  addProdFornecedor() {
    this.cleanFormProdFornec();
    if(this.state.buttonProdFornecedor) {
      this.setState({buttonProdFornecedor: false});
    } else {
      this.setState({buttonProdFornecedor: true});
    }      
  }

  updateProdFornecedor(prodFornec) {    
      Http.put("/produtofornecedor", prodFornec).then(({ data }) => {      
        if (data.status) { 
          this.getDataProdutoFornecedor(this.state.idProduto);
          this.cleanFormProdFornec();            
          Notification.success(data.mensagem);
        } else {
          Notification.danger(data.mensagem);
        }      
      });
    
  }

  cleanFormProdFornec() {          
    this.setState({
      buttonProdFornecedor: false,
      nameButtonProdFornecedor: "Adicionar",
      idProdutoFornecedor: undefined,
      idPessoaFornecedor: "",	
      codigoFabricacao: "",
      unidade: "",
      quantidadeUnitaria: "",
      codigoBarras: "",
      unidadeMaster: "",
      quantidadeMaster: "",
      codigoBarrasMaster: "",
      fatorConversao: ""
    });      
  }

  editarProdFornec(prodFornec) {       
    this.setState({
      buttonProdFornecedor: true,
      nameButtonProdFornecedor: "Alterar", 
      idProdutoFornecedor: prodFornec.idProdutoFornecedor,      
      idPessoaFornecedor: prodFornec.idPessoaFornecedor,	
      codigoFabricacao: prodFornec.codigoFabricacao,
      unidade: prodFornec.unidade,
      quantidadeUnitaria: prodFornec.quantidadeUnitaria,
      codigoBarras: prodFornec.codigoBarras,
      unidadeMaster: prodFornec.unidadeMaster,
      quantidadeMaster: prodFornec.quantidadeMaster,
      codigoBarrasMaster: prodFornec.codigoBarrasMaster,
      fatorConversao: prodFornec.fatorConversao,
      principal: prodFornec.principal
    });
        
  }

  deletarProdutoFornecedor(prodFornec) {
    ModalNotification.confirm(
      "Deseja desativar este Fornecedor? " + prodFornec.nomeEmpresa,
      "Este Fornecedor será desativada no sistema de compras!",
      () => this.deleteProdFornec(prodFornec),
      () => this.cancelar()
    );
  }

  async deleteProdFornec(prodFornec) {    
    prodFornec.idUsuarioInativacao = this.state.user.idUsuario
    await Http.post(`/produtofornecedor/deletar`, prodFornec)
    .then(({ status, data }) => {
      if (data.status) {
        Notification.success(data.mensagem);
        this.cleanFormProdFornec();
        this.getDataProdutoFornecedor(prodFornec.idProduto);         
      } else {
        Notification.danger(data.mensagem);
      }
    })
    .catch((error) => console.log(error));
  }

  listaCodigoBarra() {      
    this.setState({ codigosbarras: [] });
    var cBarra = {
      idGestorCompras: this.state.user.idGestorCompras,
      idProduto: this.state.produto.idProduto
    }    
    Http.post(`/produtocodigobarra/listar`, cBarra)    
    .then(({ status, data }) => {      
      if (data) {          
        this.setState({ codigosbarras: data });
      }
    })
    .catch((error) => console.log(error));
  }

  submitCodigoBarra(event) {
    event.preventDefault();
    if (event.target.reportValidity()) {      
      if (this.state.idCodigoBarra === undefined) {
        this.cadastrarCodigoBarra(this.state);
      } else {
        this.updateCodigoBarra(this.state);
      }
    }
  }

  cadastrarCodigoBarra(codigobarra) {
    codigobarra.idProduto = this.state.idProduto;    
    codigobarra.idGestorCompras = this.state.user.idGestorCompras;
    Http.post("/produtocodigobarra", codigobarra).then(({ data }) => {
      if (data.status) {
        this.listaCodigoBarra();
        this.cleanFormCodigoBarra();        
        Notification.success(data.mensagem);
      } else {
        Notification.danger(data.mensagem);
      }
    });  
  }

  updateCodigoBarra(codigobarra) {
    codigobarra.idUsuarioAtualizacao = this.state.user.idUsuario;
      Http.put("/produtocodigobarra", codigobarra).then(({ data }) => {       
        if (data.status) {          
          Notification.success(data.mensagem);
          this.listaCodigoBarra();
          this.cleanFormCodigoBarra();   
        } else {
          Notification.danger(data.mensagem);
        }      
      });
  }

  addCodigoBarra() {
    this.cleanFormCodigoBarra();
    if(this.state.addButtonCodigoBarra) {
      this.setState({addButtonCodigoBarra: false});
    } else {
      this.setState({addButtonCodigoBarra: true});
    }      
  }

  cleanFormCodigoBarra() {              
    this.setState({
        nameButtonCodigoBarra: "Adicionar", 
        addButtonCodigoBarra: false, 
        codigoBarra: "", 
        fator: "", 
        unidadeVenda: "",
        idCodigoBarra: undefined
    });      
  }

  editarCodigoBarra(codigobarra) {
    this.setState({
      addButtonCodigoBarra: true,
      nameButtonCodigoBarra: "Alterar",
      idCodigoBarra: codigobarra.idCodigoBarra,
      codigoBarra: codigobarra.codigoBarra, 
      fator: codigobarra.fator, 
      unidadeVenda: codigobarra.unidadeVenda
    });      
  }

  deletarCodigoBarra(codigobarra) {
    ModalNotification.confirm(
      "Deseja desativar este Código de Barras? ",
      "Este código de barra será desativada no sistema de compras!",
      () => this.deleteCodigoBarra(codigobarra),
      () => this.cancelar()
    );
  }
     
  async deleteCodigoBarra(codigobarra) {    
    codigobarra.idUsuarioInativacao = this.state.user.idUsuario
    await Http.post(`/produtocodigobarra/deletar`, codigobarra)
    .then(({ status, data }) => {
      if (data.status) {
        Notification.success(data.mensagem);
        this.listaCodigoBarra();         
      } else {
        Notification.danger(data.mensagem);
      }
    })
    .catch((error) => console.log(error));
  }

  renderCodigoBarra() {
    return <Div>
        <Div rounded margin="3" padding="4" shadow>
        <Button
                btn={Color.NODE.PRIMARY}
                value="Novo"
                col="1"
                handlerClick={(_e) => this.addCodigoBarra()}
                margin="1"
              />

        {this.state.addButtonCodigoBarra && (
              <Form id="formCadastroCodigoBarra" handlerSubmit={this.submitCodigoBarra}>
                  <SectionForm borderLeft="primary">
                    <Row col="12">
                      <InputDefault  required
                        label="Código de Barra"
                        name="codigoBarra"
                        responsive="6"
                        maxLength="200"
                        value={this.state.codigoBarra}
                        handlerChange={(e) => {
                          this.setState({codigoBarra: e.target.value });                                        
                        }}
                      />
                    <Select required
                        responsive="3"
                        label="Unidade de Venda"
                        valueSelected={this.state.unidadeVenda}
                        handlerChange={(e) => {
                          this.setState({unidadeVenda: e.target.value });                                        
                        }}
                        values={this.getTipoUnidadeMedidaSelect()}
                    /> 
                   <InputInteger  required
                        label="Fator"
                        name="fator"
                        responsive="3"
                        maxLength="200"
                        value={this.state.fator}
                        handlerChange={(e) => {
                          this.setState({fator: e.target.value });                                        
                        }}
                      />
                    </Row>
                  </SectionForm>
                  <SectionButtonForm>
                    <Button
                      btn={Color.NODE.PRIMARY}
                      value={this.state.nameButtonCodigoBarra}
                      col="1"
                      margin="1"              
                    />
                </SectionButtonForm>              
            </Form>
            )}

            </Div>
            <Div margin="3" shadow bg={Color.NODE.WHITE} borderLeft={Color.NODE.PRIMARY}>
            <Title value="Códigos de Barras" type="h4" paddingLeft="4" paddingRight="4" paddingTop="3"/>        
            <Div padding="2">
              <Table
                columns={[              
                  { value: "Embalagem", align: "left" },                  
                  { value: "Unidade de Venda", align: "left" },                     
                  { value: "Fator", align: "left" }
                ]}
                fields={[
                  { value: "codigoBarra", align: "left", type: Type.DADO.STRING },                                        
                  { value: "unidadeVenda", align: "left", type: Type.DADO.STRING },
                  { value: "fator", align: "left", type: Type.DADO.STRING },
                ]}
                bordered
                search
                orientation="center"
                pagination            
                delete   
                edit
                handlerEdit={(codigo) => this.editarCodigoBarra(codigo)}         
                handlerDelete={(codigo) => this.deletarCodigoBarra(codigo)}
                data={this.state.codigosbarras}
                error="Nenhuma embalagens encontrada"
              ></Table>
            </Div>
      </Div>

      </Div>;
  }

  renderFornecedor() {
    
    return <Div>
    <Div rounded margin="3" padding="4" shadow>
     <Button
             btn={Color.NODE.PRIMARY}
             value="Novo"
             col="1"
             handlerClick={(_e) => this.addProdFornecedor()}
             margin="1"
           />

   {this.state.buttonProdFornecedor && (
     <Form id="formCadastroProdutoFornecedor" handlerSubmit={this.submitProtudoFornecedor}>
       <SectionForm borderLeft="primary">
         <Row col="12">
            <Select required 
                  disabled={this.state.idProdutoFornecedor !== undefined ? true : false}
                  responsive="4"
                  label="Fornecedor"
                  valueSelected={this.state.idPessoaFornecedor}
                  handlerChange={(e) => {
                    this.setState({idPessoaFornecedor: e.target.value });                             
                  }}
                  values={this.state.pessoas}
                />

              <Select required
                        responsive="4"
                        label="Fornecedor Principal"
                        valueSelected={this.state.principal}
                        handlerChange={(e) => {
                          this.setState({principal: e.target.value });                                        
                        }}
                        values={this.getTipoSimNaoSelect()}
                    /> 
         </Row>
         
         <Row col="12">
            <InputInteger required
             label="Código de Fabricação"
             name="codigoFabricacao"
             responsive="4"
             maxLength="200"
             value={this.state.codigoFabricacao}
             handlerChange={(e) => {
               this.setState({codigoFabricacao: e.target.value });                                        
             }}
           />

          <Select required
            responsive="4"
            label="Unidade"
            valueSelected={this.state.unidade}
            handlerChange={(e) => {
              this.setState({unidade: e.target.value });                                        
            }}
            values={this.getTipoUnidadeMedidaSelect()}
        /> 

         <InputInteger  
             label="Quantidade Unitária"
             name="quantidadeUnitaria"
             responsive="4"
             maxLength="200"
             value={this.state.quantidadeUnitaria}
             handlerChange={(e) => {
               this.setState({quantidadeUnitaria: e.target.value });                                        
             }}
           />
         </Row>
         <Row col="12">         
         <InputInteger 
             label="Fator Conversão"
             name="fatorConversao"
             responsive="4"
             maxLength="200"
             value={this.state.fatorConversao}
             handlerChange={(e) => {
               this.setState({fatorConversao: e.target.value });                                        
             }}
           />
           
           <Select required
                        responsive="4"
                        label="Unidade Master"
                        valueSelected={this.state.unidadeMaster}
                        handlerChange={(e) => {
                          this.setState({unidadeMaster: e.target.value });                                        
                        }}
                        values={this.getTipoUnidadeMedidaSelect()}
                    /> 

           <InputInteger  
             label="Quantidade Master"
             name="quantidadeMaster"
             responsive="4"             
             value={this.state.quantidadeMaster}
             handlerChange={(e) => {
               this.setState({quantidadeMaster: e.target.value });                                        
             }}
           />
         </Row>
         <Row col="12">
         <InputDefault 
             label="Cóodigo de Barras"
             name="codigoBarras"
             responsive="6"
             maxLength="200"
             value={this.state.codigoBarras}
             handlerChange={(e) => {
               this.setState({codigoBarras: e.target.value });                                        
             }}
           />
          <InputDefault 
             label="Código de Barra Master"
             name="codigoBarrasMaster"
             responsive="6"
             maxLength="200"
             value={this.state.codigoBarrasMaster}
             handlerChange={(e) => {
               this.setState({codigoBarrasMaster: e.target.value });                                        
             }}
           />
           </Row>

         <SectionButtonForm>
             <Button
               btn={Color.NODE.PRIMARY}
               value={this.state.nameButtonProdFornecedor}
               col="1"
               margin="1"              
             />
         </SectionButtonForm> 
       </SectionForm>
     </Form>
   )}
</Div>

<Div margin="3" shadow bg={Color.NODE.WHITE} borderLeft={Color.NODE.PRIMARY}>
 <Title value="Fornecedor" type="h4" paddingLeft="4" paddingRight="4" paddingTop="3"/>        
 <Div padding="2">
   <Table
     columns={[                     
       { value: "Empresa",          align: "left" },
       { value: "Principal",        align: "left" },       
       { value: "Unidade",          align: "left" },
       { value: "Quantidade",       align: "left" }
     ]}
     fields={[       
       { value: "nomeFornecedor",      align: "left",   type: Type.DADO.STRING },                      
       { value: "principal",      align: "left",   type: Type.DADO.STRING },
       { value: "unidade",      align: "left",   type: Type.DADO.STRING },
       { value: "quantidadeUnitaria",      align: "left",   type: Type.DADO.STRING },                      
     ]}
     bordered
     search
     orientation="center"
     pagination            
     edit
     delete
     handlerEdit={(prodFornec) => this.editarProdFornec(prodFornec)}
     handlerDelete={(prodFornec) => this.deletarProdutoFornecedor(prodFornec)}
     data={this.state.produtoFornecedores}
     error="Nenhum fornecedor encontrado"
   ></Table>
 </Div>
</Div>
</Div>;
  }


  formataNomeProduto() {        
    var descricao = this.state.descricao;
    var idproduto = this.state.codigoProduto;
    return  idproduto + ' - ' + descricao;
  }
  

  render() {
    let { target } = this.state.itemActive;
    return (
      <Div>
        <Form id="form">
          <SectionForm borderLeft="primary">
          <Row col="12">       
            <Div inline="left" responsive="6">
              <Title type="h2" value="Produto" />              
              <h3><Label value={this.formataNomeProduto()}></Label></h3>           
            </Div>            
            <Div inline="left" responsive="6">
                <Title type="h2" value="Fornecedor Principal" />                
                <h3><Label value={this.state.nomeFornecedorPrincipal}></Label></h3>          
             </Div>                 
          </Row>
          <Row col="12">
            <Div inline="left" responsive="3">
              <Title type="h2" value="EAN" />                
              <h3><Label value={this.state.ean}></Label></h3>           
            </Div>    
            <Div inline="left" responsive="3">
              <Title type="h2" value="NCM" />                
              <h3><Label value={this.state.ncm}></Label></h3>           
            </Div>   
            <Div inline="left" responsive="3">
              <Title type="h2" value="Unidade de Venda" />
              <h3><Label value={this.state.embalagem}></Label></h3>           
            </Div>  
            <Div inline="left" responsive="3">
            <Title type="h2" value="Margem Prevista" />
              <h3><Label value={this.state.margemPrevista}></Label></h3>           
            </Div>   
          </Row>
          </SectionForm>
        </Form>

        <Div className="w-100">
          <NavbarTab
            itens={this.getItens()}
            handlerClick={(itemActive) => {
              this.setState({ itemActive });
            }}
          />
        </Div>
        <If and value1={target === "DADOS"}>
          {this.renderProduto()}
        </If>
        <If and value1={target === "EMBALAGENS"}>
          {this.renderCodigoBarra()}
        </If>        
        <If and value1={target === "FORNECEDOR"}>
          {this.renderFornecedor()}
        </If>
      </Div>
    );
  }
   
}

const mapStateToProps = (store) => ({
  user: store.loginState.user,
});

export default connect(mapStateToProps)(Cadastrar);
