export default class Message {
  static EMPRESAS = {
    SUCESSO_CADASTRO: "Empresa cadastrada com sucesso!",
    FALHA_CADASTRO: "Falha ao cadastrar empresa",
    SUCESSO_EDICAO: "Empresa editada com sucesso!",
    FALHA_EDICAO: "Falha ao editar empresa",
    SUCESSO_INATIVACAO: "Empresa inativada com sucesso",
    FALHA_INATIVACAO: "Falha ao inativar empresa"
  };

  static FILIAIS = {
    SUCESSO_CADASTRO: "Filial cadastrada com sucesso!",
    FALHA_CADASTRO: "Falha ao cadastrar filial",
    SUCESSO_EDICAO: "Filial editada com sucesso!",
    FALHA_EDICAO: "Falha ao editar filial",
    SUCESSO_INATIVACAO: "Filial inativada com sucesso",
    FALHA_INATIVACAO: "Falha ao inativar filial"
  };

  static FUNCIONARIOS = {
    SUCESSO_CADASTRO: "Funcionário cadastrada com sucesso!",
    FALHA_CADASTRO: "Falha ao cadastrar funcionário",
    SUCESSO_EDICAO: "Funcionário editada com sucesso!",
    FALHA_EDICAO: "Falha ao editar funcionário",
    SUCESSO_INATIVACAO: "Funcionário inativada com sucesso",
    FALHA_INATIVACAO: "Falha ao inativar funcionário"
  };

}
