/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  ButtonIconOutLine,
  Div,
  ModalNotification,
  Notification,
  Row,
  Table,
  Title,
} from "../../components";
import { Color, Http, Type, Mask, Utils } from "../../utilities";
import { relatorioPDF } from "../../utilities/utils/RelatorioPDF";
import TableApuracao from "./TableApuracao";

export default function Relatorio(props) {

  const {
    location: { state },
  } = props;
  const [produtosPreco, setProdutosPreco] = useState([]);
  const [valorPedido, setValorPedido] = useState([]);
  const [apuracaoRodape, setApuracaoRodape] = useState([]);

  // RELATORIO PDF
  function exportarPDF() {
    const cotacao = state.cotacao;

    const header = [
      {
        text: 'Apuração de Cotação',
        fontSize: 16,
        bold: true,
        margin: [15, 20, 0, 15, 15],
      },
    ]

    const dadosValorTotal = valorPedido.map(dados => {
      return [
        {
          text: dados.fornecedor ? dados.fornecedor : '',
          fontSize: 8,
        },
        {
          text: dados.total_precoMask ? dados.total_precoMask : '',
          fontSize: 8,
          alignment: 'center'
        }
      ]
    })

    const dadosTotal = apuracaoRodape.map(dados => {
      return [
        {
          text: dados.fornecedor ? dados.fornecedor : '',
          fontSize: 8,
        },
        {
          text: dados.qtd_produtos_para_cotacao ? dados.qtd_produtos_para_cotacao : '',
          fontSize: 8,
          alignment: 'center'
        },
        {
          text: dados.qtd_produtos_cotados ? dados.qtd_produtos_cotados : '',
          fontSize: 8,
          alignment: 'center'
        },
        {
          text: dados.qtd_vitoria_preco ? dados.qtd_vitoria_preco : '',
          fontSize: 8,
          alignment: 'center'
        },
        {
          text: dados.total_precoMask ? dados.total_precoMask : '',
          fontSize: 8,
          alignment: 'center'
        },
      ]
    })

    let rowsApuracao = []

    produtosPreco.forEach(dados => {
      //LINHAS FORNECEDORES
      let fornecedores = dados.cotacoesPrecos.map(fornecedor => {
        return [
          {
            text: fornecedor.fornecedorDescricao ? fornecedor.fornecedorDescricao : '',
            fontSize: 8,
          },
          {
            text: fornecedor.quantidadeDisponivel ? fornecedor.quantidadeDisponivel : '',
            fontSize: 8,
            alignment: 'center'
          },
          {
            text: fornecedor.quatidadeMinimaPedido ? fornecedor.quatidadeMinimaPedido : '',
            fontSize: 8,
            alignment: 'center'
          },
          {
            text: fornecedor.prazoEntrega ? fornecedor.prazoEntrega : '',
            fontSize: 8,
            alignment: 'center'
          },
          {
            text: fornecedor.preco ? fornecedor.preco : '',
            fontSize: 8,
            alignment: 'center'
          },
          {
            text: fornecedor.fornecedorObs ? fornecedor.fornecedorObs : '',
            fontSize: 8,
            alignment: 'center'
          }
        ]
      })

      rowsApuracao.push(
        [
          {
            text: dados.ean ? dados.ean : '',
            fontSize: 8,
          },
          {
            text: dados.codigoproduto ? dados.codigoproduto : '',
            fontSize: 8,
            alignment: 'center'
          },
          {
            text: dados.descricao_produto ? dados.descricao_produto : '',
            fontSize: 8,
            alignment: 'center'
          },
          {
            text: dados.quantidadesolicitada ? dados.quantidadesolicitada : '',
            fontSize: 8,
            alignment: 'center'
          },
          {
            text: dados.quantidadedisponivel ? dados.quantidadedisponivel : '',
            fontSize: 8,
            alignment: 'center'
          },
          {
            text: dados.prazoentrega ? dados.prazoentrega : '',
            fontSize: 8,
            alignment: 'center'
          },
          {
            text: dados.menor_preco ? dados.menor_preco : '',
            fontSize: 8,
            alignment: 'center'
          },
          {
            text: dados.fornecedor_ganhador ? dados.fornecedor_ganhador : '',
            fontSize: 8,
            alignment: 'center'
          }
        ]
      )
      rowsApuracao.push(
        [
          {
            colSpan: 8,
            margin: [0, 15],
            table: {
              headerRows: 1,
              widths: ['*', '*', '*', '*', '*', '*'],
              body: [
                //colunas
                [
                  { text: 'Fornecedor', style: 'tableHeader', },
                  { text: 'Qtd Disponível', style: 'tableHeader', alignment: 'center' },
                  { text: 'Qtd Minima', style: 'tableHeader', alignment: 'center' },
                  { text: 'Prazo Entrega', style: 'tableHeader', alignment: 'center' },
                  { text: 'Preço', style: 'tableHeader', alignment: 'center' },
                  { text: 'Observações', style: 'tableHeader', alignment: 'center' }
                ],
                //linhas
                ...fornecedores
              ]
            },
            layout: {
              fillColor: function (rowIndex, node, columnIndex) {
                return (rowIndex % 2 === 0) ? '#FFF' : null;
              }
            }
          }
        ]
      )
    })

    const tableTotalFornecedores = {
      margin: [0, 15],
      table: {
        headerRows: 1,
        widths: ['*', '*'],
        body: [
          //colunas
          [
            { text: 'Fornecedor', style: 'tableHeader', },
            { text: 'Valor Pedido', style: 'tableHeader', alignment: 'center' }
          ],
          //linhas
          ...dadosValorTotal
        ]
      },
      layout: {
        fillColor: function (rowIndex, node, columnIndex) {
          return (rowIndex % 2 === 0) ? '#F2F2F2' : null;
        }
      }
    }

    const tableTotalMenorPreco = {
      margin: [0, 4, 0, 15, 15],
      table: {
        headerRows: 1,
        widths: ['*', '*', '*', '*', '*'],
        body: [
          //colunas
          [
            { text: 'Fornecedor', style: 'tableHeader', },
            { text: 'Qtd Produtos para cotação', style: 'tableHeader', alignment: 'center' },
            { text: 'Qtd Produtos cotados', style: 'tableHeader', alignment: 'center' },
            { text: 'Qtd Produtos vitoriosos', style: 'tableHeader', alignment: 'center' },
            { text: 'Valor Pedido', style: 'tableHeader', alignment: 'center' }
          ],
          //linhas
          ...dadosTotal
        ]
      },
      layout: {
        fillColor: function (rowIndex, node, columnIndex) {
          return (rowIndex % 2 === 0) ? '#F2F2F2' : null;
        }
      }
    }
    // TABELA DE APURACAO PDF
    const tableApuracao = {
      margin: [0, 4, 0, 15, 15, 15, 15, 15],
      table: {
        headerRows: 1,
        widths: ['*', '*', '*', '*', '*', '*', '*', '*'],
        body: [
          //colunas
          [
            { text: 'Código de Barras', style: 'tableHeader', },
            { text: 'PLU', style: 'tableHeader', alignment: 'center' },
            { text: 'Produto', style: 'tableHeader', alignment: 'center' },
            { text: 'Qtd Solicitada', style: 'tableHeader', alignment: 'center' },
            { text: 'Qtd Disponível', style: 'tableHeader', alignment: 'center' },
            { text: 'Prazo Entrega', style: 'tableHeader', alignment: 'center' },
            { text: 'Menor Preço', style: 'tableHeader', alignment: 'center' },
            { text: 'Ganhador', style: 'tableHeader', alignment: 'center' },
          ],
          //linhas
          ...rowsApuracao
        ]
      },
      layout: {
        fillColor: function (rowIndex, node, columnIndex) {
          return (rowIndex % 2 === 0) ? '#F2F2F2' : null;
        }
      }
    }
    // CONTEUDO PDF
    let dataPesquisa = Mask.maskDateBrSemHoras(cotacao.iniciovigencia);
    let dataValidade = Mask.maskDateBrSemHoras(cotacao.fimvigencia);

    const content = [
      {
        columns: [
          {
            text: 'Descricao: ' + cotacao.descricao,
            fontSize: 9,
          },

          {
            text: 'Cotação N.: ' + cotacao.idCotacao,
            fontSize: 9,
          },
        ],
      },

      {
        columns: [
          {
            text: 'Data da Validade: ' + dataValidade,
            fontSize: 9,
          },
          {
            text: 'Tipo: ' + cotacao.tipo,
            fontSize: 9,
          },
        ],
      },

      {
        columns: [
          {
            text: 'Data da Cotação: ' + dataPesquisa,
            fontSize: 9,
          },
          {
            text: 'Status: ' + cotacao.status,
            fontSize: 9,
          },
        ],
      },

      {
        text: 'Total da cotação por Fornecedores',
        margin: [0, 18]
      },
      tableTotalFornecedores,

      {
        text: 'Total da cotação por Fornecedor Menor Preço',
        margin: [0, 18, 0, 5]
      },
      tableTotalMenorPreco,

      {
        text: 'Apuração de Cotação de Fornecedor',
        margin: [0, 18, 0, 5]
      },
      tableApuracao
    ]
    const styles = {
      tableHeader: {
        bold: true,
        fontSize: 9,
        color: 'black'
      },
      tableHeaderApuracao: {
        bold: true,
        fontSize: 8,
        color: 'black'
      },
      apuracaoRows: {
        bold: false,
        fontSize: 7,
        margin: [0, 2, 0, 2]
      }
    }
    let orientation = 'landscape'
    let nameArquivo = cotacao.descricao ? cotacao.descricao.trim() : "file.pdf"

    relatorioPDF(header, content, styles, orientation, nameArquivo);
  }

  useEffect(() => {
    getDataApuracaoRodape();
    getDataApuracao()
    getDataApuracaoValorPedido();
  }, []);

  function getDataApuracaoRodape() {
    let idCotacao = state.cotacao.idCotacao;
    if (!idCotacao) return;

    if (idCotacao) {
      Http.get("/cotacao/" + idCotacao + "/apuracao/rodape")
        .then(({ data }) => {
          if (data) {
            setApuracaoRodape(data);
          }
        })
        .catch((error) => console.log(error));
    }
  }

  function getDataApuracaoValorPedido() {
    let idCotacao = state.cotacao.idCotacao;
    if (!idCotacao) return;

    Http.get(`cotacao/${idCotacao}/apuracao/valorpedido`)
      .then(({ status, data }) => {
        if (data) {
          setValorPedido(data);
        }
      })
      .catch((error) => console.log(error));
  }

  function getDataApuracao() {
    let idCotacao = state.cotacao.idCotacao;
    if (!idCotacao) return;

    //`pesquisa/${idPesquisa}/preco/lista_de_preco`
    Http.get(`cotacao/${idCotacao}/apuracao`)
      .then(({ status, data }) => {
        console.log(data)
        if (Utils.isArrayNotEmpty(data)) {
          setProdutosPreco(data)
        }
      })
      .catch((error) => console.log(error));
  }

  function finalizarPesquisa() {
    Http.put(`/cotacao/situacao/gerarpedido`, produtosPreco).then(({ data }) => {
      if (data.status) {
        Notification.success(data.mensagem);
      } else {
        Notification.danger(data.mensagem);
      }
    });
  }

  function onConfirmFinalizarPesquisa() {
    ModalNotification.confirm(
      "Deseja Gerar o Pedido para esta Cotação? ",
      "Esta cotação será concluída no sistema de compras!",
      () => finalizarPesquisa(),
      () => { }
    );
  }

  return (
    <Div
      margin="3"
      shadow
      bg={Color.NODE.WHITE}
      borderLeft={Color.NODE.PRIMARY}
    >

      <Div col="12">

        <Title
          value="Total da cotação por Fornecedores"
          type="h4"
          paddingLeft="4"
          paddingRight="4"
          paddingTop="3"
        />

        <Div padding="2">
          <Table
            columns={[
              { value: "Fornecedor", align: "left" },
              {
                value: "Valor Pedido",
                align: "center",
                width: "100px",
              },
            ]}
            fields={[
              {
                value: "fornecedor",
                align: "left",
                type: Type.DADO.STRING,
                width: "100px",
              },
              {
                value: "total_preco",
                align: "center",
                type: Type.DADO.CURRENCY,
                width: "70px",
              },
            ]}
            bordered
            orientation="center"
            data={valorPedido}
            error="Nenhuma pedido encontrado"
          ></Table>
        </Div>

        {apuracaoRodape && (
          <Table
            title="Total da cotação por Fornecedores Menor Preço"
            columns={[
              { value: "Fornecedor", align: "left" },
              {
                value: "Qtd Produtos para cotação",
                width: "100px",
                align: "center",
              },
              {
                value: "Qtd Produtos cotados",
                width: "100px",
                align: "center",
              },
              {
                value: "Qtd Produtos vitoriosos",
                align: "center",
                width: "100px",
              },
              {
                value: "Valor Pedido",
                align: "center",
                width: "100px",
              },
            ]}
            fields={[
              {
                value: "fornecedor",
                align: "left",
                type: Type.DADO.STRING,
                width: "100px",
              },
              {
                value: "qtd_produtos_para_cotacao",
                align: "center",
                type: Type.DADO.INTEGER,
                width: "70px",
              },
              {
                value: "qtd_produtos_cotados",
                align: "center",
                type: Type.DADO.STRING,
                width: "70px",
              },
              {
                value: "qtd_vitoria_preco",
                align: "center",
                type: Type.DADO.STRING,
                width: "70px",
              },
              {
                value: "total_preco",
                align: "center",
                type: Type.DADO.CURRENCY,
                width: "70px",
              },
            ]}
            bordered
            orientation="center"
            data={apuracaoRodape}
            error="Nenhuma pesquisa encontrada"
          ></Table>
        )}
      </Div>

      <Title
        value="Apuração de Cotação de Fornecedores"
        type="h4"
        paddingLeft="4"
        paddingRight="4"
        paddingTop="3"
        marginLeft="2"
      />

      <Div padding='2' col="12">
        <TableApuracao produtos={produtosPreco} setProdutosPreco={setProdutosPreco} />
      </Div>

      <Row
        col="12"
        style={{ justifyContent: "flex-end", padding: "16px" }}
      >
        <ButtonIconOutLine
          value="Exportar PDF"
          icon={Type.ICON.PDF}
          btn={Color.NODE.DANGER}
          handlerClick={() => exportarPDF()}
        />

        <ButtonIconOutLine
          //icon={Type.ICON.DELETE}
          value="Gerar Pedido"
          btn={Color.NODE.WARNING}
          handlerClick={(e) => {
            e.preventDefault();
            onConfirmFinalizarPesquisa();
          }}
        />
      </Row>

    </Div>

  );
}
