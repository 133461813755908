import React, { Component } from "react";
import "./navbartop.css";

import Label from "../../texts/label/Label";
import Button from "../../buttons/Button";
import Dropdown from "../../dropdown/Dropdown";
import I from "../../i/I";
import DropDownMenu from "../../dropdown/DropDownMenu";
import DropDownItem from "../../dropdown/DropDownItem";
import Type from "../../../utilities/enuns/Type";
import Div from "../../layout/div/Div";
import { logar } from "../../../redux/actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { DropdownHeader } from "../..";
import ButtonSandwichToggle from "../../buttons/ButtonSandwichToggle";
import Image from "../../image/Image";
import mobioh from "../../../assets/images/mobioh.jpg";
import { Utils, Color } from "../../../utilities";
import Span from "../../texts/span/Span";

class NavbarTop extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      showLogo: true,
    };
  }

  getClasseContainer = () => {
    let classe = "";
    classe = classe.concat(this.props.active ? "active " : "");
    return classe;
  };

  getClassDefault = () => {
    let classe = `navbar menu-mobioh `;
    classe = classe.concat(this.props.stylle > 0)
      ? `bg-${this.props.stylle} `
      : "";
    classe = classe.concat(this.props.light ? "navbar-light" : "");
    classe = classe.concat(this.props.dark ? "navbar-dark" : "");
    return classe;
  };

  getButtonUserDropDown() {
    return (
      <Button
        type="button"
        id="dropdownMenuButton"
        toggle="dropdown"
        btn={Color.NODE.SECONDARY}
        className="rounded-0"
      >
        <I icon={Type.ICON.USER} colorText={Color.NODE.WHITE}></I>
      </Button>
    );
  }

  getButtonNotificationDropDown() {
    return (
      <Button type="button" id="dropdownMenuButton" toggle="dropdown">
        <I icon={Type.ICON.BELL} size="fa-x"></I>
      </Button>
    );
  }

  logout() {
    this.props.logar(false, this.props.user);
    sessionStorage.removeItem("auth");
    this.props.history.replace("/login");
  }

  toggleLogo(ID_LOGO, ID_TITLE) {
    let logo = document.getElementById(ID_LOGO);
    if (Utils.isValueValid(logo)) {
      logo.classList.toggle("active");
    }

    let title = document.getElementById(ID_TITLE);
    if (Utils.isValueValid(title)) {
      title.classList.toggle("active");
    }
  }

  render() {
    const ID_LOGO = Utils.uuidString();
    const ID_TITLE = Utils.uuidString();

    const { email, username } = this.props.user;
    return (
      <Div id={this.props.id} className={this.getClasseContainer()}>
        <nav className={this.getClassDefault()}>
          {/**
            *  <Button
            id="btnmenutop"
            className="custom-toggler navbar-toggler bg-bege btn-sanduiche"
            handlerClick={this.props.handlerClick}
          >
            <i className="navbar-toggler-icon"></i>
          </Button>
            */}
          <Div inline="between">
            <Div inline="start">
              <ButtonSandwichToggle
                className="custom-toggler navbar-toggler bg-bege btn-sanduiche btnmenutop"
                handlerClick={(e) => {
                  this.toggleLogo(ID_LOGO, ID_TITLE);
                  this.props.handlerClick(e);
                }}
              />
              <Image
                src={mobioh}
                responsive
                width="59px"
                marginLeft="1"
                id={ID_LOGO}
                className="show-logo-mobioh"
              />

              <Label
                bold
                value={this.props.title}
                id={ID_TITLE}
                className="ml-3 show-title-mobioh"
              ></Label>
            </Div>

            <Div inline="end">
              <Dropdown dropdown={this.getButtonUserDropDown()}>
                <DropDownMenu>
                  <DropdownHeader
                    className="border-bottom"
                    value={`Usuário: ${
                      String(this.props.username).split(" ")[0]
                    }`}
                  />
                  <Div column marginTop="1">
                    <DropDownItem
                      className="text-primary"
                      value={`${String(this.props.user.email)}`}
                    />
                    <DropDownItem
                      className="bg-danger text-white rounded pl-3 pt-1 pb-1 pr-3 mt-2 cursor-pointer"
                      handlerClick={(e) => this.logout()}
                    >
                      <I icon={Type.ICON.SIGNOUT}>
                        <Span value="Sair" />
                      </I>
                    </DropDownItem>
                  </Div>
                </DropDownMenu>
              </Dropdown>
            </Div>
          </Div>
          <Div>{this.props.children}</Div>
        </nav>

        {/*
                <Dropdown dropdown={this.getButtonNotificationDropDown()}>
                <DropDownMenu>
                  <DropDownHeader value="HEADER"></DropDownHeader>
                  <DropDownItem value="Item 1"></DropDownItem>
                  <DropDownItem value="Item 2"></DropDownItem>
                  <DropDownDivider></DropDownDivider>
                  <DropDownItem value="Item 3"></DropDownItem>
                  <DropDownItem value="Item 4"></DropDownItem>
                </DropDownMenu>
              </Dropdown>
                */}
      </Div>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ logar }, dispatch);

const mapStateToProps = (store) => ({
  loggedIn: store.loginState.loggedIn,
  user: store.loginState.user,
});

export default connect(mapStateToProps, mapDispatchToProps)(NavbarTop);
