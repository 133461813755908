import React from "react";
import PropTypes from "prop-types";
import Div from "../layout/div/Div";
import { Utils, Id } from "../../utilities";

import { border, backGround, className } from "../css";

const getClasseTitleHeader = props => {
  let classe = "card-header rounded-0 ";
  classe = classe.concat(border(props));
  classe = classe.concat(backGround(props));
  classe = classe.concat(className(props));
  return classe;
};

const CollapseCardapioIfood = props => {
  let idHeader = Utils.uuidString();
  return (
    <Div col={props.col}>
      <Div>
        <Div className="card">
          <Div
            className={getClasseTitleHeader(props)}
            id={idHeader}
            expanded={true}
            controls={props.id}
          >
            {props.title}
          </Div>

          <Div
            id={props.id}
            collapse
            className={Id.COLLAPSE.BODY_COLLAPSE}
            labelledby={idHeader}
            parent={`#${props.idParent}`}
          >
            <Div className="card-body">{props.body || props.children}</Div>
          </Div>
        </Div>
      </Div>
    </Div>
  );
};

CollapseCardapioIfood.propTypes = {
  body: PropTypes.node,
  title: PropTypes.node,
  col: PropTypes.string,
  color: PropTypes.string
};

export default CollapseCardapioIfood;
