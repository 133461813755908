import React from "react";
import { Color, Mask, Type, Utils } from "../../utilities";
import { Div, I, If, Input } from "../../components";
import Tree from "react-animated-tree-v2";
import { Label } from "recharts";
import { close, minusIcon, plusIcon } from "./IconsTree";

const treeStyles = {
  color: "rgb(62, 65, 73)",
  width: "100%",
  marginLeft: "15px",
  paddingRight: "25px",
  fontWeight: "bolder",
};

const treeDepart = {
  padding: "10px 5px 10px 10px",
  border: "1px solid #dee2e6",
  borderRadius: "11px",
  marginBottom: "15px",
};

const treeSec = {
  //color: "rgb(158, 158, 158)",
  padding: "10px 0px 10px 10px",
  borderTop: "1px solid #dee2e6",
  fontWeight: "400",
  fontSize: "14px",
};

const styles = {
  label: {
    color: "#9e9e9e",
    fontSize: "17px",
    fontWeight: "bold",
  },
  labelVinculo: {
    color: Color.CUSTOM_COLORS.PRIMARY,
    border: `1px solid ${Color.CUSTOM_COLORS.PRIMARY}`,
    padding: "3px",
    borderRadius: "3px",
    position: "absolute",
    fontSize: "10px",
    top: "-3px",
    cursor: "pointer",
    left: "21px",
  },
  margemPrevista: {
    cursor: "pointer",
    backgroundColor: Color.RGBA.AZUL_CLARO,
    padding: "5px",
    color: Color.CUSTOM_COLORS.DANGER,
    borderRadius: "3px",
    position: "absolute",
    fontWeight: "bolder",
    top: "7px",
    right: "115px",
    fontSize: "12px",
  },
  labelAtivo: {
    backgroundColor: Color.RGBA.AZUL_CLARO,
    padding: "5px",
    fontWeight: "bolder",
    borderRadius: "3px",
    position: "absolute",
    top: "7px",
    right: "165px",
    fontSize: "12px",
  },
  labelAtivoSecao: {
    backgroundColor: Color.RGBA.AZUL_CLARO,
    padding: "5px",
    fontWeight: "bolder",
    borderRadius: "3px",
    position: "absolute",
    top: "10px",
    right: "122px",
    fontSize: "12px",
  },
  margemPrevistaSecao: {
    cursor: "pointer",
    backgroundColor: Color.RGBA.AZUL_CLARO,
    padding: "5px",
    color: Color.CUSTOM_COLORS.DANGER,
    borderRadius: "3px",
    position: "absolute",
    fontWeight: "bolder",
    top: "10px",
    right: "66px",
    fontSize: "12px",
  },
};

export default function ArvoreGrupos({
  grupos,
  deletarDepartamento,
  deletarSecao,
  setCloseModal,
  addDepartamento,
  editarDepartamento,
  editarGrupo,
  gruposFilter,
  setState,
}) {
  const [openTree] = React.useState(true);
  const [active, setActive] = React.useState(false);

  let iconAddDepartamento = (
    <>
      <span
        style={{
          position: "absolute",
          right: 0,
        }}
      >
        <I
          pointer
          icon={Type.ICON.PLUS_CIRCLE}
          title="Novo Departamento"
          style={{
            color: Color.CUSTOM_COLORS.SUCCESS,
            fontSize: 23,
            marginRight: "34px",
          }}
          handlerClick={() => addDepartamento()}
        />
      </span>
      <span
        style={{
          marginRight: "-15px",
        }}
      >
        {" "}
      </span>
    </>
  );

  let iconAddSecao = (index, departamento) => (
    <>
      <span
        style={{
          position: "absolute",
          right: 0,
        }}
      >
        {departamento.sequencia !== "1" && (
          <>
            <I
              pointer
              title="Nova Seção"
              icon={Type.ICON.PLUS_CIRCLE}
              style={{
                color: Color.CUSTOM_COLORS.SUCCESS,
                fontSize: 23,
                marginRight: "10px",
              }}
              handlerClick={() => {
                setCloseModal(true, departamento.idDepartamento);
              }}
            />
            <I
              pointer
              title="Editar Departamento"
              icon={Type.ICON.EDIT}
              style={{
                color: Color.RGBA.LARANJA,
                fontSize: 23,
                marginRight: "10px",
              }}
              handlerClick={() => {
                editarDepartamento(departamento);
              }}
            />

            <I
              title="Inativar Departamento"
              pointer
              icon={Type.ICON.DELETE}
              style={{
                color: Color.CUSTOM_COLORS.DANGER,
                fontSize: 23,
                marginRight: "10px",
              }}
              handlerClick={() => deletarDepartamento(departamento)}
            />
          </>
        )}
      </span>
      <span
        style={{
          marginRight: "-15px",
        }}
      >
        {" "}
      </span>
    </>
  );

  let iconEditarSecao = (index, secao) => (
    <>
      <span
        style={{
          position: "absolute",
          right: 0,
        }}
      >
        {secao.sequencia !== "1" && (
          <>
            <I
              title="Editar Seção"
              pointer
              icon={Type.ICON.EDIT}
              style={{
                color: Color.RGBA.LARANJA,
                fontSize: 23,
                marginRight: "10px",
              }}
              handlerClick={() => {
                editarGrupo(secao);
              }}
            />

            <I
              title="Inativar Seção"
              pointer
              icon={Type.ICON.DELETE}
              style={{
                color: Color.CUSTOM_COLORS.DANGER,
                fontSize: 23,
                marginRight: "5px",
              }}
              handlerClick={() => {
                deletarSecao(secao);
              }}
            />
          </>
        )}
      </span>
      <span
        style={{
          marginRight: "-15px",
        }}
      >
        {" "}
      </span>
    </>
  );

  function getClassSearch() {
    return active ? "active" : "";
  }

  function handleChangeSearch(e) {
    let { value } = e.target;

    let newFilter = [...gruposFilter];

    if (value.trim() !== "") {
      newFilter = grupos.filter(
        (item) =>
          item.descricao !== null &&
          item.descricao.toLowerCase().indexOf(value.toLowerCase()) > -1
      );

      setState({
        grupos: newFilter,
      });
    } else {
      setState({
        grupos: gruposFilter,
      });
    }
  }

  const searchPrincipal = () => {
    return (
      <Div className="input-group" margin="2" marginBottom="3">
        <Div
          col="12"
          style={{ paddingRight: "42px" }}
          className={`translateXmenos15 translateXmais15 input-group-prepend searchbar d-flex ${getClassSearch()}`}
        >
          <I
            icon="fa fa-search search-table"
            bg="secondary"
            colorText="white"
            handlerClick={(e) => {
              setActive(!active);
            }}
            className="inputgrouptext d-flex"
          ></I>
          <Input
            display="block"
            type="search"
            placeholder="Pesquisar Departamento"
            className="inputsearch formcontrol"
            handlerChange={(e) => {
              handleChangeSearch(e);
            }}
          ></Input>
        </Div>
      </Div>
    );
  };

  return (
    <Div col="12" shadow className="border" style={{ padding: "15px" }}>
      {searchPrincipal()}
      <Tree
        content="Departamentos Cadastrados"
        type={iconAddDepartamento}
        open={openTree}
        style={treeStyles}
        icons={{ plusIcon: plusIcon, closeIcon: close, minusIcon }}
      >
        {grupos.map((departamento, index) => {
          return (
            <Tree
              content={departamento.sequencia + " - " + departamento.descricao}
              type={
                <span>
                  {iconAddSecao(index, departamento)}
                  {departamento.margemPrevista && (
                    <h6 style={styles.margemPrevista} title="Margem Prevista">
                      {Mask.convertNumberBr(departamento.margemPrevista)}
                    </h6>
                  )}

                  {departamento.sequencia !== "1" && !departamento.ativo && (
                    <h6
                      style={{
                        ...styles.labelAtivo,
                        color: Color.CUSTOM_COLORS.DANGER,
                      }}
                    >
                      {"Inativo"}
                    </h6>
                  )}
                </span>
              }
              style={treeDepart}
              icons={{ plusIcon: plusIcon, closeIcon: close, minusIcon }}
            >
              {Utils.isNotNull(departamento.grupos) && (
                <>
                  {departamento.grupos.map((secao) => {
                    return (
                      <Tree
                        icons={{ plusIcon: plusIcon, closeIcon: close }}
                        content={
                          secao.sequenciaDepartamentoGrupo +
                          " - " +
                          secao.descricao
                        }
                        type={
                          <span>
                            {iconEditarSecao(index, secao)}
                            {secao.margemPrevista && (
                              <h6 style={styles.margemPrevistaSecao}>
                                {Mask.convertNumberBr(secao.margemPrevista)}
                              </h6>
                            )}

                            {departamento.sequencia !== "1" && !secao.ativo && (
                              <h6
                                style={{
                                  ...styles.labelAtivoSecao,
                                  color: Color.CUSTOM_COLORS.DANGER,
                                }}
                              >
                                {"Inativo"}
                              </h6>
                            )}
                          </span>
                        }
                        style={treeSec}
                      />
                    );
                  })}
                </>
              )}
            </Tree>
          );
        })}
      </Tree>

      <If and value1={grupos.length === 0}>
        <Div
          style={{
            padding: "10px 5px 10px 10px",
            border: "1px solid #dee2e6",
            borderRadius: "11px",
            margin: "15px 15px 15px 35px",
          }}
        >
          <Label value="Nenhum Departamento Cadastrado" style={styles.label} />
        </Div>
      </If>
    </Div>
  );
}
