import React, { Component } from "react";
import { Div, NavTabParam, Breadcrumb } from "../../components";
import { PathRoute } from "../../utilities";

class CenterTop extends Component {
  constructor(props) {
    super(props);

    this.state = {
      breads: PathRoute.getBreads(props.location.pathname)
    };

    this.itensTab = [
      new NavTabParam("#home", "Home"),
      new NavTabParam("#dashboard", "Dashboard"),
      new NavTabParam("#services", "Services")
    ];
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({ breads: PathRoute.getBreads(props.location.pathname) });
  }

  render() {
    return (
      <Div>
        {
          // <NavbarTab itens={this.itensTab}></NavbarTab>
        }
        <Breadcrumb itens={this.state.breads}></Breadcrumb>
      </Div>
    );
  }
}

export default CenterTop;
