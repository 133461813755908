import React, { Component } from "react";
import { ButtonIconOutLine, Div, Table, Title } from "../../components";
import { Color, Http, Type } from "../../utilities";

class ListaPrecos extends Component {
  constructor(props) {
    super(props);

    this.state = {
      buttonRenderPreco: false,
      produtosPreco: [],
      produtoPreco: "",
      idPesquisaPreco: "",
      idGestorCompras: "",
      precovenda: "",
      nameButtonPreco: "Alterar",
      concorrentePreco: [],
      concorrentesInseridos: [],
      fieldsConcorrentes: [
        { value: "ean", align: "center", type: Type.DADO.STRING },
        {
          value: "codigoProduto",
          align: "center",
          type: Type.DADO.STRING,
        },
        { value: "status", align: "left", type: Type.DADO.STRING },
      ],
      columnsConcorrentes: [
        { value: "Código de Barras", align: "center" },
        { value: "PLU", align: "center" },
        { value: "Status", align: "left" },
      ],
    };
  }

  UNSAFE_componentWillMount() {
    this.getDataPrecificacao();
    this.setState({
      idGestorCompras: this.state.idGestorCompras,
    });

    this.createColumnFieldsDinamic();
  }

  componentDidUpdate(nextProps, nextState) {
    // if (this.state.produtosPreco !== prevState.produtosPreco) {
    //   console.log("foi");
    // }
    //console.log(nextProps, nextState);
  }

  createColumnFieldsDinamic() {
    if (this.props.concorrentesInseridos) {
      let newColumn = [];
      let newFields = [];

      this.props.concorrentesInseridos.forEach((item) => {
        newColumn.push({ value: item.descricao, align: "right" });
        newFields.push({
          value: item.descricao,
          align: "right",
          type: Type.DADO.CURRENCY,
        });
      });

      let newColumnConcorrentes = [
        { value: "Precificador", align: "left" },
        { value: "Produto", align: "left" },
        ...newColumn,
        ...this.state.columnsConcorrentes,
      ];
      let newFieldsConcorrentes = [
        {
          value: "precificador",
          align: "right",
          type: Type.DADO.STRING,
        },
        { value: "produto", align: "left", type: Type.DADO.STRING },
        ...newFields,
        ...this.state.fieldsConcorrentes,
      ];

      this.setState({
        columnsConcorrentes: [...newColumnConcorrentes],
        fieldsConcorrentes: [...newFieldsConcorrentes],
      });
    }
  }

  newDataProdutosPreco(produtosPreco) {
    let newProdutosPreco = [];

    produtosPreco.forEach((item) => {
      let precosConcorrentes = JSON.parse(item.concorrente_preco.value);
      let newItem = {
        ...item,
        ...precosConcorrentes,
      };
      newProdutosPreco.push(newItem);
    });

    console.log(newProdutosPreco)

    this.setState({
      produtosPreco: newProdutosPreco,
    });
  }

  cleanFormPesquisaPreco() {
    this.setState({
      buttonPreco: false,
      idPesquisaPreco: undefined,
      nameButtonPreco: "Alterar",
      concorrentePreco: "",
      precovenda: "",
      produtoPreco: "",
    });
  }

  async getDataPrecificacao() {
    let idPesquisa = this.props.idPesquisa;
    if (!idPesquisa) return;

    ///pesquisa/{idPesquisa}/preco/lista_de_preco
    //`/pesquisa/`+ idPesquisa + `/preco/`
    await Http.get(`pesquisa/${idPesquisa}/preco/lista_de_preco`)
      .then(({ status, data }) => {
        if (data) {
          console.log(data);
          this.newDataProdutosPreco(data);
        }
      })
      .catch((error) => console.log(error));
  }

  submitPesquisaPreco(event) {
    event.preventDefault();
    if (event.target.reportValidity()) {
      if (!this.state.idPesquisaPreco === undefined) {
        this.updatePesquisaPreco(this.state);
        this.showEditPreco();
      }
    }
  }

  render() {
    console.log(this.state.fieldsConcorrentes)
    return (
      <Div>
        <Div
          margin="3"
          shadow
          bg={Color.NODE.WHITE}
          borderLeft={Color.NODE.PRIMARY}
        >
          <Title
            value="Produtos Precificados"
            type="h4"
            paddingLeft="4"
            paddingRight="4"
            paddingTop="3"
          />

          <Div padding="2">
            <Table
              columns={this.state.columnsConcorrentes}
              fields={this.state.fieldsConcorrentes}
              bordered
              search
              orientation="center"
              pagination
              data={this.state.produtosPreco}
              error="Nenhuma pesquisa encontrada"
            ></Table>
          </Div>
        </Div>
      </Div>
    );
  }
}

export default ListaPrecos;
