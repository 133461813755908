import React from "react";
import { Div, InputDate, Select } from "../../../components";
import { Color } from "../../../utilities";

const TemplateFilterPesquisa = ({
  handler,
  status,
  tipo,
  inicioData,
  fimData,
  selectStatus,
}) => {
  return (
    <Div rounded col="12" bg={Color.NODE.LIGHT} textAlign="left">
      <Div col="12" padding="2" inline="start">
        <InputDate
          required
          label="Início"
          direction="down"
          colorIcon="white"
          bgIcon="secondary"
          bg="secondary"
          responsive="3"
          handlerChangeDay={(e) => handler(e, "incio")}
          value={inicioData}
        />
        <InputDate
          required
          label="Término"
          direction="down"
          colorIcon="white"
          bgIcon="secondary"
          bg="secondary"
          responsive="3"
          handlerChangeDay={(e) => handler(e, "fim")}
          value={fimData}
        />

        <Select
          required
          responsive="3"
          label="Status da Pesquisa"
          multi={true}
          handlerChange={(e) => handler(e.target.value, "status")}
          values={status}
          valueSelected={selectStatus}
        />
        <Select
          required
          responsive="3"
          label="Tipo da Pesquisa"
          multi={true}
          handlerChange={(e) => handler(e.target.value, "tipo")}
          values={tipo}
        />
      </Div>
    </Div>
  );
};

export default TemplateFilterPesquisa;
