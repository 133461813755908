/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Div,
  If,
  InputSearchTable,
  Label,
  Notification,
  Row,
  Select,
  SelectValue,
  Table,
  Title,
} from "../../components";
import { Color, Http, Mask, Type, Utils } from "../../utilities";

export default function PrecificacaoEditar2(props) {
  const {
    location: { state },
  } = props;

  const filtroPreco = {
    SEM_PRECO: "sem preco",
    COM_PRECO: "com preco",
    TODOS: "todos",
  };

  const [selectConcorrente, setSelectConcorrente] = useState("");
  const [originalProdutosLista, setOriginalProdutosLista] = useState([]);
  const [listaProdutoConcorrente, setListaProdutoConcorrente] = useState([]);
  const [produtosInseridos, setProdutosInseridos] = useState({
    original: [],
    slice: [],
  });
  const [produtosConcorrete, setProdutosConcorrente] = useState([]);
  const [concorrentesInseridos, setConcorrentesInseridos] = useState([]);
  const [produtosModificados, setProdutosModificados] = useState([]);
  const [modificado, setModificado] = useState(false);
  const [filtroSelect, setFiltroSelect] = useState(filtroPreco.TODOS);

  useEffect(() => {
    getDataConcorrentesInseridos();
    getDataProdutosInseridos();
  }, []);

  function getDataConcorrentesInseridos() {
    let idPesquisa = state.pesquisa.idPesquisa;
    if (!idPesquisa) return;

    if (idPesquisa) {
      Http.get("/pesquisa/" + idPesquisa + "/concorrente")
        .then(({ data }) => {
          if (data) {
            setConcorrentesInseridos(data);
          }
        })
        .catch((error) => console.log(error));
    }
  }

  function getDataProdutosInseridos() {
    let idPesquisa = state.pesquisa.idPesquisa;
    if (!idPesquisa) return;

    //`/pesquisa/${idPesquisa}/preco`
    Http.get("/pesquisa/" + idPesquisa + "/produto")
      .then(({ status, data }) => {
        if (data) {
          setProdutosInseridos({
            original: [...data],
            slice: data.length > 50 ? data.slice(0, 50) : data,
          });
          setOriginalProdutosLista([...data]);
        }
      })
      .catch((error) => console.log(error));
  }

  function getProdutosConcorrentePrecificados(idConcorrente) {
    let idPesquisa = state.pesquisa.idPesquisa;
    if (!idPesquisa) return;

    Http.get(`/pesquisa/${idPesquisa}/concorrente/${idConcorrente}/preco`)
      .then(({ data }) => {
        setSelectConcorrente(idConcorrente);

        if (data) {
          handlerlistaConcorrentePreco(data);
          setListaProdutoConcorrente(data);
        }
      })
      .catch((error) => console.log(error));
  }

  function handlerlistaConcorrentePreco(listaConcorrente) {
    let newProdutosInseridos = [];

    originalProdutosLista.forEach((produto) => {
      let newItem = produto;
      produto.precovenda = 0;
      produto.selected = false;

      listaConcorrente.forEach((precificado) => {
        if (precificado.idProduto === produto.idProduto) {
          newItem = {
            ...produto,
            ...precificado,
          };
        }
      });

      newProdutosInseridos.push(newItem);
    });

    setProdutosInseridos({
      slice:
        newProdutosInseridos.length > 50
          ? newProdutosInseridos.slice(0, 50)
          : newProdutosInseridos,
      original: newProdutosInseridos,
    });
    setProdutosConcorrente(newProdutosInseridos);
  }

  function getTipoPesquisaSelect() {
    let selectValue = [new SelectValue("", "SELECIONE", true)];

    concorrentesInseridos.forEach((item) => {
      selectValue.push(
        new SelectValue(item.idConcorrente, item.descricao, false)
      );
    });
    return selectValue;
  }

  function getSelectFiltro() {
    let selectValue = [
      new SelectValue(filtroPreco.TODOS, "TODOS", false),
      new SelectValue(filtroPreco.SEM_PRECO, "SEM PRECIFICAÇÃO", false),
      new SelectValue(filtroPreco.COM_PRECO, "COM PRECIFICAÇÃO", false),
    ];

    return selectValue;
  }

  function filtrarProdutos(produtos, type) {
    let newProdutos = [];
    let arrayProdutos = [...produtos];

    if (type === filtroPreco.SEM_PRECO) {
      arrayProdutos.forEach((produto) => {
        if (
          typeof produto["precovenda"] === "undefined" ||
          produto["precovenda"] === 0
        ) {
          produto.precovenda = 0;
          newProdutos.push(produto);
        }
      });
      console.log("sem", newProdutos);
      setProdutosInseridos({
        slice: newProdutos.length > 50 ? newProdutos.slice(0, 50) : newProdutos,
        original: newProdutos,
      });
    }

    if (type === filtroPreco.COM_PRECO) {
      arrayProdutos.forEach((produto) => {
        if (
          typeof produto["precovenda"] !== "undefined" &&
          produto["precovenda"] !== 0
        ) {
          newProdutos.push(produto);
        }
      });
      setProdutosInseridos({
        slice: newProdutos.length > 50 ? newProdutos.slice(0, 50) : newProdutos,
        original: newProdutos,
      });
    }

    if (type === filtroPreco.TODOS) {
      arrayProdutos.forEach((produto) => {
        if (
          typeof produto["precovenda"] !== "undefined" &&
          produto["precovenda"] !== 0
        ) {
          newProdutos.push(produto);
        }

        if (
          typeof produto["precovenda"] === "undefined" ||
          produto["precovenda"] === 0
        ) {
          produto.precovenda = 0;
          newProdutos.push(produto);
        }
      });
      if (Utils.isArrayNotEmpty(originalProdutosLista)) {
        setProdutosInseridos({
          slice:
            newProdutos.length > 50 ? newProdutos.slice(0, 50) : newProdutos,
          original: newProdutos,
        });
      }
    }
  }

  function handleProdutosModificado(data) {
    let newProdutosSelecionados = produtosModificados;

    if (Utils.isNotNull(data.precovenda)) {
      data.selected = true;
    }
    if (data.precovenda === "0,00") {
      data.selected = true;
    }

    let produtoEncontrado = produtosModificados.filter((item) => {
      return item.idProduto === data.idProduto;
    });

    if (data.selected) {
      if (!Utils.isNotArrayEmpty(produtoEncontrado)) {
        newProdutosSelecionados.push(data);
      }
    }
    if (!data.selected) {
      if (Utils.isNotObjectEmpty(produtoEncontrado)) {
        let index = newProdutosSelecionados.indexOf(data);
        newProdutosSelecionados.splice(index, 1);
      }
    }

    setProdutosModificados([...newProdutosSelecionados]);
  }

  function postPreco(obj) {
    Http.post(`/pesquisa/preco`, obj)
      .then(({ status, data }) => {
        if (status) {
          Notification.success(data.mensagem);
        } else {
          Notification.danger("Falha ao inserir Concorrente");
        }
      })
      .catch((error) => console.log(error));
  }
  function putPreco(obj) {
    Http.put(`/pesquisa/preco`, obj)
      .then(({ status, data }) => {
        if (status) {
          Notification.success(data.mensagem);
        } else {
          Notification.danger("Falha ao inserir Concorrente");
        }
      })
      .catch((error) => console.log(error));
  }

  async function submitPreco(produto) {
    if (!selectConcorrente) {
      Notification.danger("Selecione um Concorrente!");
      return false;
    }

    // if (!produto.precovenda || produto.precovenda === "0,00") {
    //   Notification.danger("Insira um valor Maior que 0,00 para o Produto!");
    //   return false;
    // }

    let objPreco = {
      idGestorCompras: produto.idGestorCompras,
      idPesquisa: produto.idPesquisa,
      idConcorrente: selectConcorrente,
      idUsuarioPrecificador: state.user.idUsuario,
      idProduto: produto.idProduto,
      idPesquisaPreco: produto.idPesquisaPreco,
      status: "Precificado",
      precovenda: parseFloat(Mask.convertNumberDecimal(produto.precovenda)),
    };

    if (objPreco.idPesquisaPreco) {
      if (produto.selected) {
        produto.selected = false;
        setModificado(!modificado);
        console.log("put");
        putPreco(objPreco);
      }
    } else {
      if (produto.selected) {
        produto.selected = false;
        setModificado(!modificado);
        console.log("create");
        postPreco(objPreco);
      }
    }
  }

  const handleScroll = (e) => {
    const { slice, original } = produtosInseridos;

    let element = e.target;

    if (
      Math.round(element.scrollHeight - element.scrollTop) ===
      element.clientHeight
    ) {
      let newProdutos = [];
      let start = slice.length;
      let end =
        original.length > 50
          ? slice.length + 50
          : slice.length - original.length;

      if (original.length >= slice.length) {
        newProdutos = [...slice, ...original.slice(start, end)];

        setProdutosInseridos((state) => {
          return {
            slice: newProdutos,
            original: state.original,
          };
        });
      }
    }
  };

  function buscarItem(searchText) {
    let buscarLista = [...produtosInseridos.original];
    let newList = buscarLista.filter(
      (item) =>
        (item.descricao !== null &&
          item.descricao.toLowerCase().indexOf(searchText.toLowerCase()) >
            -1) ||
        (item.codigoProduto !== null &&
          item.codigoProduto.toLowerCase().indexOf(searchText.toLowerCase()) >
            -1) ||
        (item.ean !== null &&
          item.ean.toLowerCase().indexOf(searchText.toLowerCase()) > -1)
    );

    return [...newList];
  }

  function handlerChange(e) {
    let newArray = buscarItem(e.target.value);

    setProdutosInseridos((state) => {
      return {
        slice: newArray.length > 50 ? newArray.slice(0, 50) : newArray,
        original: state.original,
      };
    });
  }

  return (
    <Div
      margin="3"
      shadow
      bg={Color.NODE.WHITE}
      borderLeft={Color.NODE.PRIMARY}
    >
      <Title
        value="Produtos Inseridos na Pesquisa"
        type="h4"
        paddingLeft="4"
        paddingRight="4"
        paddingTop="3"
      />

      <Div col="12" style={{}}>
        <Div col="12">
          <Row col="12">
            <Select
              required
              responsive="4"
              label="Concorrente"
              handlerChange={(e) => {
                getProdutosConcorrentePrecificados(e.target.value);
                setFiltroSelect(filtroPreco.TODOS);
              }}
              values={getTipoPesquisaSelect()}
            />

            <If and value1={selectConcorrente === ""}>
              <Label
                value="Selecione um Concorrente para Precificar os Produtos"
                style={{
                  padding: "0px 15px",
                  marginTop: "15px",
                  fontSize: "16px",
                  fontWeight: "bolder",
                  color: "#dc3545",
                  display: "flex",
                  alignItems: "center",
                }}
              />
            </If>

            <If and value1={selectConcorrente !== ""}>
              <Select
                required
                responsive="4"
                label="Filtrar Lista"
                handlerChange={(e) => {
                  filtrarProdutos(produtosConcorrete, e.target.value);
                  setFiltroSelect(e.target.value);
                }}
                valueSelected={filtroSelect}
                values={getSelectFiltro()}
              />
            </If>
          </Row>

          {/* <If and value1={produtosModificados.length > 0 && selectConcorrente !== ""}>
            <Div responsive="12" padding="1" style={{ marginBottom: "15px", }}>
              <Row col="12">
                <ButtonIconOutLine
                  value="Atualizar Preço"
                  style={{ maxWidth: "170px", height: "40px", marginTop: "27px" }}
                  btn={Color.NODE.PRIMARY}
                  //  handlerClick={() => this.mudarSituacao()}
                  col="2"
                  className="custom-btn"
                />
                <Label
                  type="h4"
                  style={{
                    fontSize: "17px",
                    margin: "35px 30px 10px 10px",
                    fontWeight: "600"
                  }}
                  value={`${produtosModificados.length} produtos modificados`}
                />
              </Row>
            </Div>
          </If> */}

          <If and value1={selectConcorrente !== ""}>
            <Div
              col="12"
              style={{ overflow: "scroll", maxHeight: "500px" }}
              handlerScroll={(e) => handleScroll(e)}
            >
              <Div responsive="12">
                <InputSearchTable handlerChange={(e) => handlerChange(e)} />
              </Div>
              <Table
                columns={[
                  { value: "PLU", align: "right" },
                  { value: "Descricao", align: "left" },
                  { value: "Código de Barras", align: "center" },
                  { value: "Preço", align: "right" },
                ]}
                fields={[
                  {
                    value: "codigoProduto",
                    align: "right",
                    type: Type.DADO.STRING,
                  },
                  { value: "descricao", align: "left", type: Type.DADO.STRING },
                  { value: "ean", align: "center", type: Type.DADO.STRING },
                  {
                    input: true,
                    typeInput: "money",
                    value: "precovenda",
                    align: "right",
                    width: "100px",
                  },
                ]}
                bordered
                orientation="center"
                data={produtosInseridos.slice}
                edit
                confirmIcon
                handlerEdit={(rede) => submitPreco(rede)}
                handlerConfirm={(data) => submitPreco(data)}
                handlerChangeInput={(data) => {
                  handleProdutosModificado(data);
                }}
                handler
                error="Nenhum produto encontrado"
              ></Table>
            </Div>
          </If>
        </Div>
      </Div>

      {/* <Div padding="2">
          {!isNewTable && (
            <Table
              columns={columnsConcorrentes}
              fields={fieldsConcorrentes}
              bordered
              search
              orientation="center"
              pagination
              data={produtosPreco}
              error="Nenhuma pesquisa encontrada"
            ></Table>
          )}
          {isNewTable && createColumnFieldsDinamic(produtosPreco)}
        </Div> */}
    </Div>
  );
}
