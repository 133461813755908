import React from "react";
import { Div, Label } from "../../../components";
import { col } from "../../css";

const getClassDefault = props => {
  let classe = "";
  classe = classe.concat(col(props));
  return classe;
};

const TextArea = props => {
  return (
    <Div className={getClassDefault(props)} col={props.col}>
      {props.label && <Label value={props.label}></Label>}
      <textarea
        placeholder={props.placeHolder}
        maxLength={props.maxLength}
        name={props.name}
        className="form-control"
        rows={props.rows}
        onChange={props.handlerChange}
        value={props.value}
        defaultValue={props.defaultValue}
      ></textarea>
    </Div>
  );
};

export default TextArea;
