/* eslint-disable jsx-a11y/anchor-has-content */
import React, { Component } from "react";
import {
  Color,
  Http,
  Mask,
  Type,
  Pattern,
  DateUtils,
  Utils,
  PathRoute,
} from "../../utilities";

import {
  Div,
  If,
  NavbarTab,
  Row,
  Table,
  Title,
  InputDefault,
  InputInteger,
  InputCep,
  InputPhone,
  InputDate,
  InputCpfCnpj,
  InputFile,
  Form,
  SelectValue,
  Select,
  Notification,
  SectionForm,
  SectionButtonForm,
  Button,
  ModalNotification,
  ButtonIconOutLine,
} from "../../components";
import { connect } from "react-redux";
import CryptoJS from "crypto-js";

class Cadastrar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      nameButton: "Salvar",
      nameButtonEndereco: "Adicionar",
      nameButtonContato: "Adicionar",
      nameButtonAssinatura: "Adicionar",
      addButtonEndereco: false,
      addButtonAssinatura: false,
      addButtonContato: false,
      addButtonArquivo: false,
      user: props.user,
      enderecos: [],
      contatos: [],
      arquivos: [],
      filials: [],
      estados: [],
      cidades: [],
      assinaturas: [],
      grupoProdutos: [],
      tipoCadastro: "MATRIZ",
      itemActive: {
        target: "MATRIZ",
        value: "MATRIZ",
        active: true,
      },

      nomeUsuario: "",
      emailUsuario: "",
      loginUsuario: "",
      cargoUsuario: "",
      senhaUsuario: "",
      telefoneUsuario: "",
      idUsuario: "",
      userEdit: false,
      usuarios: [],

      ...this.props.location.state,
    };

    this.submit = this.submit.bind(this);
    this.listaPessoas = this.listaPessoas.bind(this);
    this.validaForm = this.validaForm.bind(this);
    this.removerMascara = this.removerMascara.bind(this);
    this.configuraPessoa = this.configuraPessoa.bind(this);
    this.configuraEndereco = this.configuraEndereco.bind(this);
    this.getEstadosSelect = this.getEstadosSelect.bind(this);
    this.getInfoReceita = this.getInfoReceita.bind(this);
    this.setFormPessoa = this.setFormPessoa.bind(this);

    this.submitEndereco = this.submitEndereco.bind(this);
    this.listaEnderecos = this.listaEnderecos.bind(this);
    this.cleanFormEndereco = this.cleanFormEndereco.bind(this);
    this.editarEndereco = this.editarEndereco.bind(this);
    this.deletarEndereco = this.deletarEndereco.bind(this);
    this.addEndereco = this.addEndereco.bind(this);

    this.submitContato = this.submitContato.bind(this);
    this.seFormContato = this.seFormContato.bind(this);
    this.validaFormContato = this.validaFormContato.bind(this);
    this.listaContatos = this.listaContatos.bind(this);
    this.cleanFormContato = this.cleanFormContato.bind(this);
    this.editarContato = this.editarContato.bind(this);
    this.deletarContato = this.deletarContato.bind(this);
    this.addContato = this.addContato.bind(this);

    this.submitArquivo = this.submitArquivo.bind(this);
    this.listaArquivos = this.listaArquivos.bind(this);
    this.cleanFormArquivo = this.cleanFormArquivo.bind(this);
    this.deletarArquivo = this.deletarArquivo.bind(this);
    this.handlerFile = this.handlerFile.bind(this);

    this.deletarFilial = this.deletarFilial.bind(this);

    this.submitAssinatura = this.submitAssinatura.bind(this);
    this.listaAssinatura = this.listaAssinatura.bind(this);
    this.cleanFormAssinatura = this.cleanFormAssinatura.bind(this);
    this.deletarAssinatura = this.deletarAssinatura.bind(this);
    this.addAssinatura = this.addAssinatura.bind(this);

    this.submitUsuario = this.submitUsuario.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.getEstadosSelect();
    let pessoa = this.state.pessoa;
    if (this.state.editar) {
      this.setState({ nameButton: "Alterar" });
      this.configuraPessoa(pessoa);
      this.listaEnderecos(pessoa.idPessoa);
      this.listaAssinatura(pessoa.idPessoa);
      this.listaContatos(pessoa.idPessoa);
      this.listaArquivos(pessoa.idPessoa);
      this.listaFilial(pessoa.idPessoa);
      this.listaGrupoProdutos();
      this.listaUsuarios(pessoa.idPessoa, pessoa.idGestorCompras);
      this.cleanFormEndereco();
      this.cleanFormContato();
      this.cleanFormArquivo();
    }
  }

  configuraPessoa(pessoa) {
    this.setState({
      idPessoa: pessoa.idPessoa,
      idGestorCompras: pessoa.idGestorCompras,
      codigoAtividadePrincipal: pessoa.codigoAtividadePrincipal,
      descricaoAtividadePrincipal: pessoa.descricaoAtividadePrincipal,
      tipoCadastro: "MATRIZ",
      nome: pessoa.nome,
      razao: pessoa.razao,
      codigoPessoa: pessoa.codigoPessoa,
      tipoPessoa: "JURIDICA",
      tipoRegime: pessoa.tipoRegime,
      cpfCnpj: Mask.maskValueCpfCnpj(pessoa.cpfCnpj),
      codigoCnae: pessoa.codigoCnae,
      inscricaoEstatual: pessoa.inscricaoEstatual,
      inscricaoMunicipal: pessoa.inscricaoMunicipal,
      rg: pessoa.rg,
      emailPrincipal: pessoa.emailPrincipal,
      emailFinanceiro: pessoa.emailFinanceiro,
      emailSecundario: pessoa.emailSecundario,
      contatoPrincipal: pessoa.contatoPrincipal,
      contatoFinanceiro: pessoa.contatoFinanceiro,
      contatoSecundario: pessoa.contatoSecundario,
      telefonePrincipal: Mask.maskValuePhone(pessoa.telefonePrincipal),
      telefoneFinanceiro: Mask.maskValuePhone(pessoa.telefoneFinanceiro),
      telefoneSecundario: Mask.maskValuePhone(pessoa.telefoneSecundario),
      tipoFrete: pessoa.tipoFrete,
      arqBalancete: pessoa.arqBalancete,
      arqFicha: pessoa.arqFicha,
      arqContrato: pessoa.arqContrato,
      idUsuarioAtualizacao: this.state.user.idUsuario,
    });
  }

  configuraEndereco(endereco) {
    var cep = "";

    if (endereco.cep) {
      cep = endereco.cep.replace(".", "");
    }

    this.setState({
      addButtonEndereco: true,
      complemento: endereco.complemento,
      bairro: endereco.bairro,
      cep: cep,
      numero: endereco.numero,
      endereco: endereco.endereco,
    });
  }

  getInfoReceita(cpfCnpj) {
    var strAux = Mask.clearMask(cpfCnpj);
    if (strAux.length > 11) {
      Http.get("/service/searchCpfCnpj/" + strAux).then(({ data }) => {
        if (data.status) {
          this.configuraPessoa(data);
          this.configuraEndereco(data.endereco);
          this.setState({
            idPessoa: undefined,
            tipoPessoa: "JURIDICA",
          });
        } else {
          Notification.info(data.mensagem);
        }
      });
    }
  }

  getEstadosSelect() {
    this.setState({ cidades: [] });
    Http.get("/cidade/listarEstados").then(({ data }) => {
      if (data) {
        let estadosComDados = [];
        estadosComDados.push(new SelectValue(0, "SELECIONE", true));
        Array.from(data).map((estado) => {
          estadosComDados.push(
            new SelectValue(estado.idestadoibge, estado.nomeestado, false)
          );
        });
        this.setState({ estados: estadosComDados });
      }
    });
  }

  getCidadeSelect(idestadoibge) {
    this.setState({ cidades: [] });
    Http.get("/cidade/listarCidades/" + idestadoibge).then(({ data }) => {
      if (data) {
        let cidadesComDados = [];
        cidadesComDados.push(new SelectValue(0, "SELECIONE", true));
        Array.from(data).map((estado) => {
          cidadesComDados.push(
            new SelectValue(estado.idcidadeibge, estado.nomecidade, false)
          );
        });
        this.setState({ cidades: cidadesComDados });
      }
    });
  }

  getItens = () => {
    let item = this.state.itemActive;
    return [
      {
        target: "MATRIZ",
        value: "MATRIZ",
        active: item.value === "MATRIZ",
      },
      {
        target: "ENDEREÇO",
        value: "ENDEREÇO",
        active: item.value === "ENDEREÇO",
      },
      {
        target: "CONTATO",
        value: "CONTATO",
        active: item.value === "CONTATO",
      },
      {
        target: "ARQUIVO",
        value: "ARQUIVO",
        active: item.value === "ARQUIVO",
      },
      {
        target: "FILIAL",
        value: "FILIAL",
        active: item.value === "FILIAL",
      },
      {
        target: "ASSINATURA",
        value: "ASSINATURA",
        active: item.value === "ASSINATURA",
      },
      {
        target: "USUÁRIO",
        value: "USUÁRIO",
        active: item.value === "USUÁRIO",
      },
    ];
  };

  getTipoCadastroSelect() {
    return [new SelectValue("MATRIZ", "MATRIZ", false)];
  }

  getTipoCadastroEnderecoSelect() {
    return [
      new SelectValue("", "SELECIONE", true),
      new SelectValue("COMERCIAL", "COMERCIAL", false),
      new SelectValue("ENTREGA", "ENTREGA", false),
      new SelectValue("COBRANCA", "COBRANÇA", false),
      new SelectValue("OUTROS", "OUTROS", false),
    ];
  }

  getTipoCadastroArquivoSelect() {
    return [
      new SelectValue("", "SELECIONE", true),
      new SelectValue("PUBLICO", "PUBLICO", false),
      new SelectValue("PRIVADO", "PRIVADO", false),
    ];
  }

  getTipoAssinaturaSelect() {
    return [
      new SelectValue("", "SELECIONE", true),
      new SelectValue("COMPRA", "COMPRA", false),
      new SelectValue("PESQUISA", "PESQUISA", false),
    ];
  }

  getTipoArquivoSelect() {
    return [
      new SelectValue("", "SELECIONE", true),
      new SelectValue("BALANCETE", "BALANCETE", false),
      new SelectValue("FICHA", "FICHA", false),
      new SelectValue("CONTRATO", "CONTRATO", false),
      new SelectValue("OUTROS", "OUTROS", false),
    ];
  }

  getTipoCadastroContatoSelect() {
    return [
      new SelectValue("", "SELECIONE", true),
      new SelectValue("VENDEDOR", "VENDEDOR", false),
      new SelectValue("GERENTE", "GERENTE", false),
      new SelectValue("SOCIO", "SOCIO", false),
      new SelectValue("FUNCIONARIO", "FUNCIONARIO", false),
      new SelectValue("OUTROS", "OUTROS", false),
    ];
  }

  getTipoPessoaSelect() {
    return [
      new SelectValue("", "SELECIONE", true),
      new SelectValue("FISICA", "FISICA", false),
      new SelectValue("JURIDICA", "JURÍDICA", false),
    ];
  }

  getRegimeTributario() {
    return [
      new SelectValue("", "SELECIONE", true),
      new SelectValue("ME", "MICRO EMPRESA", false),
      new SelectValue("SP", "SIMPLES", false),
      new SelectValue("LR", "LUCRO REAL", false),
      new SelectValue("LP", "LUCRO PRESUMIDO", false),
      new SelectValue("IS", "ISENTO", false),
    ];
  }

  getTipoFrete() {
    return [
      new SelectValue("", "SELECIONE", true),
      new SelectValue("CIF", "CIF", false),
      new SelectValue("FOB", "FOB", false),
      new SelectValue("PCIF", "PCIF", false),
      new SelectValue("PFOB", "PFOB", false),
      new SelectValue("TERC", "TERC", false),
      new SelectValue("GRAT", "GRAT", false),
    ];
  }

  listaPessoas() {
    this.props.history.push({ pathname: `/compras/empresa` });
  }

  setFormPessoa() {
    return {
      idPessoa: this.state.idPessoa,
      idGestorCompras: this.state.user.idGestorCompras,
      codigoAtividadePrincipal: this.state.codigoAtividadePrincipal,
      descricaoAtividadePrincipal: this.state.descricaoAtividadePrincipal,
      tipoCadastro: "EMPRESA",
      nome: this.state.nome,
      razao: this.state.razao,
      codigoPessoa: this.state.codigoPessoa,
      tipoPessoa: "JURIDICA",
      tipoRegime: this.state.tipoRegime,
      cpfCnpj: this.state.cpfCnpj,
      codigoCnae: this.state.codigoCnae,
      inscricaoEstatual: this.state.inscricaoEstatual,
      inscricaoMunicipal: this.state.inscricaoMunicipal,
      emailPrincipal: this.state.emailPrincipal,
      emailFinanceiro: this.state.emailFinanceiro,
      emailSecundario: this.state.emailSecundario,
      contatoPrincipal: this.state.contatoPrincipal,
      contatoFinanceiro: this.state.contatoFinanceiro,
      contatoSecundario: this.state.contatoSecundario,
      telefonePrincipal: this.state.telefonePrincipal,
      telefoneFinanceiro: this.state.telefoneFinanceiro,
      telefoneSecundario: this.state.telefoneSecundario,
      tipoFrete: this.state.tipoFrete,
      arqBalancete: this.state.arqBalancete,
      arqFicha: this.state.arqFicha,
      arqContrato: this.state.arqContrato,
    };
  }

  async submit(event) {
    event.preventDefault();
    if (event.target.reportValidity()) {
      var pessoa = this.setFormPessoa();
      if (this.state.idPessoa === undefined) {
        this.cadastrar(pessoa);
      } else {
        this.update(pessoa);
      }
    }
  }

  cadastrar(pessoa) {
    let validate = this.validaForm(pessoa);
    if (validate) {
      Http.post("/pessoa", pessoa).then(({ data }) => {
        if (data.status) {
          Notification.success("Empresa criada com sucesso!");
          Http.get(
            "/pessoa/pessoaCpfCnpj/" + this.removerMascara(pessoa.cpfCnpj)
          ).then(({ data }) => {
            if (data) {
              this.setState({
                idPessoa: data.idPessoa,
                nameButton: "Alterar",
              });
              this.getItens();
            }
          });
        } else {
          Notification.danger("Erro ao criar a Empresa!");
        }
      });
    }
  }

  validaForm(pessoa) {
    var status = true;
    if (!Mask.validaCpfCnpj(pessoa.cpfCnpj)) {
      Notification.danger("CPF ou CNPJ inválido!");
      status = false;
    }

    if (pessoa.emailPrincipal) {
      if (!Mask.validarEmail(pessoa.emailPrincipal)) {
        Notification.danger("E-mail Principal inválido!");
        status = false;
      }
    }

    if (pessoa.emailFinanceiro) {
      if (!Mask.validarEmail(pessoa.emailFinanceiro)) {
        Notification.danger("E-mail Financeiro inválido!");
        status = false;
      }
    }

    if (pessoa.emailSecundario) {
      if (!Mask.validarEmail(pessoa.emailSecundario)) {
        Notification.danger("E-mail Secundário inválido!");
        status = false;
      }
    }

    return status;
  }

  update(pessoa) {
    let validate = this.validaForm(pessoa);
    if (validate) {
      pessoa.idUsuarioAtualizacao = this.state.user.idUsuario;
      Http.put("/pessoa", pessoa).then(({ data }) => {
        if (data.status) {
          Notification.success("Empresa alterada com sucesso!");
        } else {
          Notification.danger("Erro ao alterar a Empresa!");
        }
      });
    }
  }

  removerMascara(valor) {
    return valor
      .replace(".", "")
      .replace("-", "")
      .replace("/", "");
  }

  cancelar() {}

  renderPessoa() {
    return (
      <Form id="formCadastroPessoa" handlerSubmit={this.submit}>
        <SectionForm borderLeft="primary">
          <Row col="12">
            <InputCpfCnpj
              required
              disabled={this.state.idPessoa !== undefined ? true : false}
              pattern={Pattern.INPUT.CPF_CNPJ.pattern}
              label="CNPJ / CPF"
              name="cpfCnpj"
              responsive="4"
              value={this.state.cpfCnpj}
              handlerChange={(e) => {
                this.setState({ cpfCnpj: e.target.value });
              }}
              handlerBlur={(e) => {
                this.getInfoReceita(e.target.value);
              }}
            />

            <InputDefault
              label="Código Empresa"
              name="codigoPessoa"
              responsive="4"
              maxLength="20"
              value={this.state.codigoPessoa}
              handlerChange={(e) => {
                this.setState({ codigoPessoa: e.target.value });
              }}
            />

            <Select
              required
              responsive="4"
              label="Regime Tributário"
              valueSelected={this.state.tipoRegime}
              handlerChange={(e) => {
                this.setState({ tipoRegime: e.target.value });
              }}
              values={this.getRegimeTributario()}
            />
          </Row>

          <Row col="12">
            <InputDefault
              required
              label="Nome"
              name="nome"
              maxLength="200"
              responsive="4"
              value={this.state.nome}
              handlerChange={(e) => {
                this.setState({ nome: e.target.value });
              }}
            />
            <InputDefault
              required
              label="Razão"
              name="razao"
              maxLength="200"
              responsive="4"
              value={this.state.razao}
              handlerChange={(e) => {
                this.setState({ razao: e.target.value });
              }}
            />
            <InputDefault
              label="Descrição da Atividade"
              name="descricaoAtividadePrincipal"
              responsive="4"
              maxLength="200"
              value={this.state.descricaoAtividadePrincipal}
              handlerChange={(e) => {
                this.setState({ descricaoAtividadePrincipal: e.target.value });
              }}
            />
          </Row>

          <Row col="12">
            <InputDefault
              label="Código Atividade"
              name="codigoAtividadePrincipal"
              responsive="3"
              maxLength="20"
              value={this.state.codigoAtividadePrincipal}
              handlerChange={(e) => {
                this.setState({ codigoAtividadePrincipal: e.target.value });
              }}
            />

            <InputDefault
              label="CNAE"
              maxLength="20"
              name="codigoCnae"
              responsive="3"
              value={this.state.codigoCnae}
              handlerChange={(e) => {
                this.setState({ codigoCnae: e.target.value });
              }}
            />
            <InputInteger
              required
              label="Inscrição Estadual"
              name="inscricaoEstatual"
              responsive="3"
              maxLength="20"
              value={this.state.inscricaoEstatual}
              handlerChange={(e) => {
                this.setState({ inscricaoEstatual: e.target.value });
              }}
            />
            <InputInteger
              label="Inscrição Municipal"
              name="inscricaoMunicipal"
              responsive="3"
              maxLength="20"
              value={this.state.inscricaoMunicipal}
              handlerChange={(e) => {
                this.setState({ inscricaoMunicipal: e.target.value });
              }}
            />
          </Row>
          <Row col="12">
            <InputDefault
              email
              required
              label="E-mail Principal"
              name="emailPrincipal"
              responsive="4"
              maxLength="200"
              value={this.state.emailPrincipal}
              handlerChange={(e) => {
                this.setState({ emailPrincipal: e.target.value });
              }}
            />

            <InputDefault
              email
              label="E-mail Financeiro"
              name="emailFinanceiro"
              responsive="4"
              maxLength="200"
              value={this.state.emailFinanceiro}
              handlerChange={(e) => {
                this.setState({ emailFinanceiro: e.target.value });
              }}
            />

            <InputDefault
              email
              label="E-mail Secundário"
              name="emailSecundario"
              responsive="4"
              maxLength="200"
              value={this.state.emailSecundario}
              handlerChange={(e) => {
                this.setState({ emailSecundario: e.target.value });
              }}
            />
          </Row>

          <Row col="12">
            <InputDefault
              label="Contato Principal"
              name="contatoPrincipal"
              responsive="4"
              maxLength="200"
              value={this.state.contatoPrincipal}
              handlerChange={(e) => {
                this.setState({ contatoPrincipal: e.target.value });
              }}
            />

            <InputDefault
              label="Contato Financeiro"
              name="contatoFinanceiro"
              responsive="4"
              maxLength="200"
              value={this.state.contatoFinanceiro}
              handlerChange={(e) => {
                this.setState({ contatoFinanceiro: e.target.value });
              }}
            />

            <InputDefault
              label="Contato Secundário"
              name="contatoSecundario"
              responsive="4"
              maxLength="200"
              value={this.state.contatoSecundario}
              handlerChange={(e) => {
                this.setState({ contatoSecundario: e.target.value });
              }}
            />
          </Row>

          <Row col="12">
            <InputPhone
              label="Telefone Principal"
              name="telefonePrincipal"
              responsive="4"
              value={this.state.telefonePrincipal}
              handlerChange={(e) => {
                this.setState({ telefonePrincipal: e.target.value });
              }}
            />

            <InputPhone
              label="Telefone Secundário"
              name="telefoneSecundario"
              responsive="4"
              value={this.state.telefoneSecundario}
              handlerChange={(e) => {
                this.setState({ telefoneSecundario: e.target.value });
              }}
            />

            <InputPhone
              label="Telefone Financeiro"
              name="telefoneFinanceiro"
              responsive="4"
              value={this.state.telefoneFinanceiro}
              handlerChange={(e) => {
                this.setState({ telefoneFinanceiro: e.target.value });
              }}
            />
          </Row>

          <SectionButtonForm>
            <Row col="12" style={{ justifyContent: "flex-end" }}>
              <Div margin="1">
                <ButtonIconOutLine
                  icon={Type.ICON.ARROW_ANGLE_LEFT}
                  value="Voltar"
                  btn={Color.NODE.SECONDARY}
                  handlerClick={(e) => {
                    e.preventDefault();
                    this.listaPessoas();
                  }}
                />
              </Div>

              <Button
                btn={Color.NODE.PRIMARY}
                value={this.state.nameButton}
                col="1"
                margin="1"
              />
            </Row>
          </SectionButtonForm>
        </SectionForm>
      </Form>
    );
  }

  //Endereco

  submitEndereco(event) {
    event.preventDefault();
    if (event.target.reportValidity()) {
      if (this.state.idEndereco === undefined) {
        if (this.state.idPessoa === undefined) {
          Notification.danger("A Empresa ainda não foi salvo!");
          return;
        }
        this.cadastrarEndereco(this.state);
      } else {
        this.updateEndereco(this.state);
      }
    }
  }

  listaEnderecos(idPessoa) {
    this.setState({ enderecos: [] });
    Http.get("/endereco/listar/" + idPessoa).then(({ data }) => {
      if (data) {
        this.setState({ enderecos: data });
      }
    });
  }

  cadastrarEndereco(endereco) {
    endereco.idPessoa = this.state.idPessoa;
    endereco.tipoCadastro = this.state.tipoCadastroEndereco;
    Http.post("/endereco", endereco).then(({ data }) => {
      if (data.status) {
        this.listaEnderecos(endereco.idPessoa);
        this.cleanFormEndereco();
        this.setState({ addButtonEndereco: false });
        Notification.success(data.mensagem);
      } else {
        Notification.danger(data.mensagem);
      }
    });
  }

  updateEndereco(endereco) {
    endereco.tipoCadastro = this.state.tipoCadastroEndereco;
    Http.put("/endereco", endereco).then(({ data }) => {
      if (data.status) {
        this.listaEnderecos(endereco.idPessoa);
        this.cleanFormEndereco();
        this.setState({ addButtonEndereco: false });
        Notification.success(data.mensagem);
      } else {
        Notification.danger(data.mensagem);
      }
    });
  }

  addEndereco() {
    this.cleanFormEndereco();
    if (this.state.addButtonEndereco) {
      this.setState({ addButtonEndereco: false });
    } else {
      this.setState({ addButtonEndereco: true });
    }
  }

  cleanFormEndereco() {
    this.setState({
      nameButtonEndereco: "Adicionar",
      idEndereco: undefined,
      tipoCadastroEndereco: "",
      endereco: "",
      numero: "",
      complemento: "",
      bairro: "",
      cep: "",
      idestadoibge: 0,
      idcidadeibge: 0,
    });
  }

  editarEndereco(endereco) {
    this.setState({ addButtonEndereco: true });
    this.setState({
      nameButtonEndereco: "Alterar",
      idEndereco: endereco.idEndereco,
      tipoCadastroEndereco: endereco.tipoCadastro,
      endereco: endereco.endereco,
      numero: endereco.numero,
      complemento: endereco.complemento,
      bairro: endereco.bairro,
      cep: Mask.getValueMaskCep(endereco.cep),
      idestadoibge: endereco.idestadoibge,
      idcidadeibge: endereco.idcidadeibge,
    });
    this.getCidadeSelect(endereco.idestadoibge);
  }

  deletarEndereco(endereco) {
    ModalNotification.confirm(
      "Deseja desativar este Endereço? " + endereco.endereco,
      "Este endereço será desativada no sistema de compras!",
      () => this.deleteEndereco(endereco),
      () => this.cancelar()
    );
  }

  async deleteEndereco(endereco) {
    endereco.idUsuarioInativacao = this.state.user.idUsuario;
    await Http.post(`/endereco/deletar`, endereco)
      .then(({ status, data }) => {
        if (data.status) {
          Notification.success(data.mensagem);
          this.listaEnderecos(endereco.idPessoa);
        } else {
          Notification.danger(data.mensagem);
        }
      })
      .catch((error) => console.log(error));
  }

  renderEndereco() {
    return (
      <Div>
        {this.state.addButtonEndereco && (
          <Div rounded margin="3" padding="4" shadow>
            <Form id="formCadastroEndereco" handlerSubmit={this.submitEndereco}>
              <SectionForm borderLeft="primary">
                <Row col="12">
                  <Select
                    required
                    responsive="4"
                    label="Tipo Cadastro"
                    valueSelected={this.state.tipoCadastroEndereco}
                    handlerChange={(e) => {
                      this.setState({ tipoCadastroEndereco: e.target.value });
                    }}
                    values={this.getTipoCadastroEnderecoSelect()}
                  />
                  <Select
                    required
                    responsive="4"
                    label="Estado"
                    valueSelected={this.state.idestadoibge}
                    handlerChange={(e) => {
                      this.setState({ idestadoibge: e.target.value });
                      this.getCidadeSelect(e.target.value);
                    }}
                    values={this.state.estados}
                  />
                  <Select
                    required
                    responsive="4"
                    label="Cidade"
                    valueSelected={this.state.idcidadeibge}
                    handlerChange={(e) => {
                      this.setState({ idcidadeibge: e.target.value });
                    }}
                    values={this.state.cidades}
                  />
                </Row>
                <Row col="12">
                  <InputDefault
                    required
                    label="Endereço"
                    name="endereco"
                    responsive="6"
                    maxLength="200"
                    value={this.state.endereco}
                    handlerChange={(e) => {
                      this.setState({ endereco: e.target.value });
                    }}
                  />
                  <InputDefault
                    label="Complemento"
                    name="complemento"
                    responsive="6"
                    maxLength="200"
                    value={this.state.complemento}
                    handlerChange={(e) => {
                      this.setState({ complemento: e.target.value });
                    }}
                  />
                </Row>
                <Row col="12">
                  <InputDefault
                    label="Número"
                    name="numero"
                    responsive="4"
                    maxLength="20"
                    value={this.state.numero}
                    handlerChange={(e) => {
                      this.setState({ numero: e.target.value });
                    }}
                  />

                  <InputDefault
                    required
                    label="Bairro"
                    name="bairro"
                    responsive="4"
                    maxLength="100"
                    value={this.state.bairro}
                    handlerChange={(e) => {
                      this.setState({ bairro: e.target.value });
                    }}
                  />

                  <InputCep
                    required
                    label="Cep"
                    name="cep"
                    responsive="4"
                    value={this.state.cep}
                    handlerChange={(e) => {
                      this.setState({ cep: e.target.value });
                    }}
                  />
                </Row>

                <Row col="12"></Row>
              </SectionForm>

              <SectionButtonForm>
                <Row col="12" style={{ justifyContent: "flex-end" }}>
                  <Div margin="1">
                    <ButtonIconOutLine
                      //icon={Type.ICON.ARROW_ANGLE_LEFT}
                      value="Limpar"
                      btn={Color.NODE.SECONDARY}
                      handlerClick={(_e) => this.cleanFormEndereco()}
                    />
                  </Div>

                  <Div margin="1">
                    <ButtonIconOutLine
                      //icon={Type.ICON.DELETE}
                      value="Cancelar"
                      btn={Color.NODE.DANGER}
                      handlerClick={(_e) => this.addEndereco()}
                    />
                  </Div>

                  <Button
                    btn={Color.NODE.PRIMARY}
                    value={this.state.nameButtonEndereco}
                    col="1"
                    margin="1"
                  />
                </Row>
              </SectionButtonForm>
            </Form>
          </Div>
        )}

        {!this.state.addButtonEndereco && (
          <Div
            margin="3"
            shadow
            bg={Color.NODE.WHITE}
            borderLeft={Color.NODE.PRIMARY}
          >
            <Title
              value="Endereços"
              type="h4"
              paddingLeft="4"
              paddingRight="4"
              paddingTop="3"
            />

            <Row col="12" style={{ justifyContent: "flex-end" }}>
              <Button
                btn={Color.NODE.PRIMARY}
                value="Novo"
                col="1"
                handlerClick={(_e) => this.addEndereco()}
                margin="1"
              />
            </Row>

            <Div padding="2">
              <Table
                columns={[
                  { value: "Tipo Cadastro", align: "center" },
                  { value: "Cidade", align: "center" },
                  { value: "Estado", align: "center" },
                  { value: "Endereco", align: "center" },
                  { value: "Bairro", align: "center" },
                  { value: "Cep", align: "center" },
                ]}
                fields={[
                  { value: "tipoCadastro", type: Type.DADO.STRING },
                  { value: "nomeCidade", type: Type.DADO.STRING },
                  { value: "nomeEstado", type: Type.DADO.STRING },
                  { value: "endereco", type: Type.DADO.STRING },
                  { value: "bairro", type: Type.DADO.STRING },
                  { value: "cep", type: Type.DADO.CEP },
                ]}
                bordered
                search
                orientation="center"
                pagination
                edit
                delete
                handlerEdit={(enderco) => this.editarEndereco(enderco)}
                handlerDelete={(enderco) => this.deletarEndereco(enderco)}
                data={this.state.enderecos}
                error="Nenhum endereço encontrada"
              ></Table>
            </Div>
          </Div>
        )}
      </Div>
    );
  }

  //Contato

  submitContato(event) {
    event.preventDefault();
    if (event.target.reportValidity()) {
      if (this.state.idContato === undefined) {
        if (this.state.idPessoa === undefined) {
          Notification.danger("A Empresa ainda não foi salvo!");
          return;
        }
        this.cadastrarContato(this.state);
      } else {
        this.updateContato(this.state);
      }
    }
  }

  listaContatos(idPessoa) {
    this.setState({ contatos: [] });
    Http.get("/contato/listar/" + idPessoa).then(({ data }) => {
      if (data) {
        this.setState({ contatos: data });
      }
    });
  }

  cadastrarContato(contato) {
    contato.idPessoa = this.state.idPessoa;
    this.seFormContato(contato);
    let validate = this.validaFormContato(contato);
    if (validate) {
      Http.post("/contato", contato).then(({ data }) => {
        if (data.status) {
          this.listaContatos(contato.idPessoa);
          this.cleanFormContato();
          this.setState({ addButtonContato: false });
          Notification.success(data.mensagem);
        } else {
          Notification.danger(data.mensagem);
        }
      });
    }
  }

  addContato() {
    this.cleanFormContato();
    if (this.state.addButtonContato) {
      this.setState({ addButtonContato: false });
    } else {
      this.setState({ addButtonContato: true });
    }
  }

  updateContato(contato) {
    this.seFormContato(contato);
    let validate = this.validaFormContato(contato);
    if (validate) {
      Http.put("/contato", contato).then(({ data }) => {
        if (data.status) {
          this.listaContatos(contato.idPessoa);
          this.cleanFormContato();
          this.setState({ addButtonContato: false });
          Notification.success(data.mensagem);
        } else {
          Notification.danger(data.mensagem);
        }
      });
    }
  }

  validaFormContato(contato) {
    var status = true;
    if (!Mask.validaCpfCnpj(contato.cpfCnpj)) {
      Notification.danger("CPF ou CNPJ inválido!");
      status = false;
    }

    if (contato.email) {
      if (!Mask.validarEmail(contato.email)) {
        Notification.danger("E-mail Principal inválido!");
        status = false;
      }
    }

    return status;
  }

  seFormContato(contato) {
    contato.tipoCadastro = this.state.tipoCadastroContato;
    contato.nome = this.state.nomeContato;
    contato.rg = this.state.rgContato;
    contato.cpfCnpj = this.state.cpfCnpjContato;
    contato.endereco = this.state.enderecoContato;
    contato.endereco = this.state.enderecoContato;
    contato.numero = this.state.numeroContato;
    contato.complemento = this.state.complementoContato;
    contato.bairro = this.state.bairroContato;
    contato.cep = this.state.cepContato;

    contato.dataNascimento = DateUtils.getDateFormat(
      this.state.dataNascimentoAux,
      Pattern.DATE.YYYY_MM_DD_HH_MM
    );

    return contato;
  }

  cleanFormContato() {
    this.setState({
      nameButtonContato: "Adicionar",
      idContato: undefined,
      tipoCadastroContato: "",
      nomeContato: "",
      cargo: "",
      cpfCnpjContato: "",
      rgContato: "",
      telefone: "",
      celular: "",
      outroTelefone: "",
      email: "",
      dtNacimento: "",
      enderecoContato: "",
      numeroContato: "",
      complementoContato: "",
      bairroContato: "",
      cepContato: "",
      idestadoibge: 0,
      idcidadeibge: 0,
    });
  }

  editarContato(contato) {
    this.setState({ addButtonContato: true });
    this.setState({
      nameButtonContato: "Alterar",
      idContato: contato.idContato,
      tipoCadastroContato: contato.tipoCadastro,
      nomeContato: contato.nome,
      cargo: contato.cargo,
      cpfCnpjContato: Mask.getValueMaskCpfCnpj(contato.cpfCnpj),
      rgContato: contato.rg,
      telefone: Mask.getValueMaskPhone(contato.telefone),
      celular: Mask.getValueMaskPhone(contato.celular),
      outroTelefone: Mask.getValueMaskPhone(contato.outroTelefone),
      email: contato.email,
      dataNascimento: DateUtils.getDateFormat(
        contato.dataNascimento,
        Pattern.DATE.DD_MM_YYYY
      ),
      enderecoContato: contato.endereco,
      numeroContato: contato.numero,
      complementoContato: contato.complemento,
      bairroContato: contato.bairro,
      cepContato: Mask.getValueMaskCep(contato.cep),
      idestadoibge: contato.idestadoibge,
      idcidadeibge: contato.idcidadeibge,
    });
    this.getCidadeSelect(contato.idestadoibge);
  }

  deletarContato(contato) {
    ModalNotification.confirm(
      "Deseja desativar este Contato? " + contato.nome,
      "Este contato será desativada no sistema de compras!",
      () => this.deleteContato(contato),
      () => this.cancelar()
    );
  }

  async deleteContato(contato) {
    contato.idUsuarioInativacao = this.state.user.idUsuario;
    await Http.post(`/contato/deletar`, contato)
      .then(({ status, data }) => {
        if (data.status) {
          Notification.success(data.mensagem);
          this.listaContatos(contato.idPessoa);
        } else {
          Notification.danger(data.mensagem);
        }
      })
      .catch((error) => console.log(error));
  }

  mudarDataNascimento(data) {
    this.setState({ dataNascimentoAux: data });
  }

  renderContato() {
    return (
      <Div>
        {this.state.addButtonContato && (
          <Div rounded margin="3" padding="4" shadow>
            <Form id="formCadastroContato" handlerSubmit={this.submitContato}>
              <SectionForm borderLeft="primary">
                <Row col="12">
                  <Select
                    required
                    responsive="4"
                    label="Tipo Cadastro"
                    valueSelected={this.state.tipoCadastroContato}
                    handlerChange={(e) => {
                      this.setState({ tipoCadastroContato: e.target.value });
                    }}
                    values={this.getTipoCadastroContatoSelect()}
                  />
                </Row>
                <Row col="12">
                  <InputDefault
                    required
                    label="Nome"
                    name="nomeContato"
                    responsive="8"
                    maxLength="200"
                    value={this.state.nomeContato}
                    handlerChange={(e) => {
                      this.setState({ nomeContato: e.target.value });
                    }}
                  />

                  <InputDefault
                    required
                    label="Cargo"
                    name="cargo"
                    responsive="4"
                    maxLength="200"
                    value={this.state.cargo}
                    handlerChange={(e) => {
                      this.setState({ cargo: e.target.value });
                    }}
                  />
                </Row>
                <Row col="12">
                  <InputCpfCnpj
                    required
                    label="CPF / CNPJ"
                    name="cpfCnpjContato"
                    responsive="4"
                    value={this.state.cpfCnpjContato}
                    handlerChange={(e) => {
                      this.setState({ cpfCnpjContato: e.target.value });
                    }}
                  />

                  <InputDefault
                    label="RG"
                    name="rgContato"
                    responsive="4"
                    maxLength="20"
                    value={this.state.rgContato}
                    handlerChange={(e) => {
                      this.setState({ rgContato: e.target.value });
                    }}
                  />

                  <InputDate
                    required
                    label="Data Nascimento"
                    direction="up"
                    colorIcon="white"
                    bgIcon="secondary"
                    bg="secondary"
                    responsive="4"
                    handlerChangeDay={(data) => this.mudarDataNascimento(data)}
                    value={this.state.dataNascimentoAux}
                  />
                </Row>
                <Row col="12">
                  <InputDefault
                    label="E-mail"
                    name="email"
                    responsive="6"
                    maxLength="200"
                    value={this.state.email}
                    handlerChange={(e) => {
                      this.setState({ email: e.target.value });
                    }}
                  />
                  <InputDefault
                    label="Endereço"
                    name="enderecoContato"
                    responsive="6"
                    maxLength="200"
                    value={this.state.enderecoContato}
                    handlerChange={(e) => {
                      this.setState({ enderecoContato: e.target.value });
                    }}
                  />
                </Row>
                <Row col="12">
                  <Select
                    required
                    responsive="4"
                    label="Estado"
                    valueSelected={this.state.idestadoibge}
                    handlerChange={(e) => {
                      this.setState({ idestadoibge: e.target.value });
                      this.getCidadeSelect(e.target.value);
                    }}
                    values={this.state.estados}
                  />
                  <Select
                    required
                    responsive="4"
                    label="Cidade"
                    valueSelected={this.state.idcidadeibge}
                    handlerChange={(e) => {
                      this.setState({ idcidadeibge: e.target.value });
                    }}
                    values={this.state.cidades}
                  />
                  <InputCep
                    label="Cep"
                    name="cepContato"
                    responsive="4"
                    value={this.state.cepContato}
                    handlerChange={(e) => {
                      this.setState({ cepContato: e.target.value });
                    }}
                  />
                </Row>
                <Row col="12">
                  <InputDefault
                    label="Bairro"
                    name="bairroContato"
                    responsive="4"
                    maxLength="100"
                    value={this.state.bairroContato}
                    handlerChange={(e) => {
                      this.setState({ bairroContato: e.target.value });
                    }}
                  />
                  <InputDefault
                    label="Complemento"
                    name="complementoContato"
                    responsive="4"
                    maxLength="200"
                    value={this.state.complementoContato}
                    handlerChange={(e) => {
                      this.setState({ complementoContato: e.target.value });
                    }}
                  />
                  <InputDefault
                    label="Número"
                    name="numeroContato"
                    responsive="4"
                    maxLength="20"
                    value={this.state.numeroContato}
                    handlerChange={(e) => {
                      this.setState({ numeroContato: e.target.value });
                    }}
                  />
                </Row>
                <Row col="12">
                  <InputPhone
                    label="Telefone"
                    name="telefone"
                    responsive="4"
                    value={this.state.telefone}
                    handlerChange={(e) => {
                      this.setState({ telefone: e.target.value });
                    }}
                  />
                  <InputPhone
                    label="Telefone 2"
                    name="outroTelefone"
                    responsive="4"
                    value={this.state.outroTelefone}
                    handlerChange={(e) => {
                      this.setState({ outroTelefone: e.target.value });
                    }}
                  />
                  <InputPhone
                    label="Celular"
                    name="celular"
                    responsive="4"
                    value={this.state.celular}
                    handlerChange={(e) => {
                      this.setState({ celular: e.target.value });
                    }}
                  />
                </Row>

                <SectionButtonForm>
                  <Row col="12" style={{ justifyContent: "flex-end" }}>
                    <Div margin="1">
                      <ButtonIconOutLine
                        //icon={Type.ICON.ARROW_ANGLE_LEFT}
                        value="Limpar"
                        btn={Color.NODE.SECONDARY}
                        handlerClick={(_e) => this.cleanFormContato()}
                      />
                    </Div>

                    <Div margin="1">
                      <ButtonIconOutLine
                        //icon={Type.ICON.DELETE}
                        value="Cancelar"
                        btn={Color.NODE.DANGER}
                        handlerClick={(_e) => this.addContato()}
                      />
                    </Div>

                    <Button
                      btn={Color.NODE.PRIMARY}
                      value={this.state.nameButtonContato}
                      col="1"
                      margin="1"
                    />
                  </Row>
                </SectionButtonForm>
              </SectionForm>
            </Form>
          </Div>
        )}

        {!this.state.addButtonContato && (
          <Div
            margin="3"
            shadow
            bg={Color.NODE.WHITE}
            borderLeft={Color.NODE.PRIMARY}
          >
            <Title
              value="Contatos"
              type="h4"
              paddingLeft="4"
              paddingRight="4"
              paddingTop="3"
            />

            <Row col="12" style={{ justifyContent: "flex-end" }}>
              <Button
                btn={Color.NODE.PRIMARY}
                value="Novo"
                col="1"
                handlerClick={(_e) => this.addContato()}
                margin="1"
              />
            </Row>

            <Div padding="2">
              <Table
                columns={[
                  { value: "Tipo Cadastro", align: "center" },
                  { value: "Nome", align: "center" },
                  { value: "Cargo", align: "center" },
                  { value: "Cidade", align: "center" },
                  { value: "Estado", align: "center" },
                  { value: "Cep", align: "center" },
                ]}
                fields={[
                  { value: "tipoCadastro", type: Type.DADO.STRING },
                  { value: "nome", type: Type.DADO.STRING },
                  { value: "cargo", type: Type.DADO.STRING },
                  { value: "nomeCidade", type: Type.DADO.STRING },
                  { value: "nomeEstado", type: Type.DADO.STRING },
                  { value: "cep", type: Type.DADO.CEP },
                ]}
                bordered
                search
                orientation="center"
                pagination
                edit
                delete
                handlerEdit={(contato) => this.editarContato(contato)}
                handlerDelete={(contato) => this.deletarContato(contato)}
                data={this.state.contatos}
                error="Nenhum contato encontrada"
              ></Table>
            </Div>
          </Div>
        )}
      </Div>
    );
  }

  //Assinatura

  submitAssinatura(event) {
    event.preventDefault();
    if (event.target.reportValidity()) {
      if (this.state.idPessoa === undefined) {
        Notification.danger("A Empresa ainda não foi salvo!");
        return;
      }
      this.cadastrarAssinatura(this.state);
    }
  }

  listaAssinatura(idPessoa) {
    this.setState({ assinaturas: [] });
    Http.get("/assinatura/listar/" + idPessoa).then(({ data }) => {
      if (data) {
        this.setState({ assinaturas: data });
      }
    });
  }

  listaUsuarios(idPessoa, idGestorCompras) {
    this.setState({ usuarios: [] });
    Http.get(
      "/usuario/gestorcompras/" + idGestorCompras + "/pessoa/" + idPessoa
    ).then(({ data }) => {
      console.log(data);
      if (data) {
        this.setState({ usuarios: data,  addButtonUsuario: false, });
      }
    });
  }

  listaGrupoProdutos() {
    Http.get(`/grupoprodutos/listar/` + this.state.user.idGestorCompras)
      .then(({ status, data }) => {
        if (data) {
          let gruposComDados = [];
          gruposComDados.push(new SelectValue(0, "SELECIONE", true));
          Array.from(data).map((grupo) => {
            gruposComDados.push(
              new SelectValue(grupo.idGrupoProdutos, grupo.descricao, false)
            );
          });
          this.setState({ grupoProdutos: gruposComDados });
        }
      })
      .catch((error) => console.log(error));
  }

  cadastrarAssinatura(assinatura) {
    var assinaturaNovo = this.setFormAssinatura(assinatura);
    let validate = this.validaFormAssinatura(assinaturaNovo);
    if (validate) {
      Http.post("/assinatura", assinaturaNovo).then(({ data }) => {
        if (data) {
          this.listaAssinatura(assinatura.idPessoa);
          this.cleanFormAssinatura();
          this.setState({ addButtonAssinatura: false });
          Notification.success(data.mensagem);
        } else {
          Notification.danger("Erro ao tentar Salvar Assinatura");
        }
      });
    }
  }

  addAssinatura() {
    this.cleanFormAssinatura();
    if (this.state.addButtonAssinatura) {
      this.setState({ addButtonAssinatura: false });
    } else {
      this.setState({ addButtonAssinatura: true });
    }
  }

  validaFormAssinatura(assinatura) {
    var status = true;

    return status;
  }

  setFormAssinatura(assinatura) {
    var assinaturaNovo = {};
    assinaturaNovo.tipo = assinatura.tipoAssinatura;
    assinaturaNovo.dias = assinatura.dias;
    assinaturaNovo.idEmpresa = assinatura.idPessoa;
    assinaturaNovo.idGrupoProdutos = assinatura.idGrupoProdutos;
    assinaturaNovo.idGestorCompras = this.state.user.idGestorCompras;
    return assinaturaNovo;
  }

  cleanFormAssinatura() {
    this.setState({
      tipoAssinatura: "",
      dias: "",
      idGrupoProdutos: "",
    });
  }

  deletarAssinatura(assinatura) {
    ModalNotification.confirm(
      "Deseja desativar esta Assinatura? " + assinatura.descricao,
      "Esta assinatura será desativada no sistema de compras!",
      () => this.deleteAssinatura(assinatura),
      () => this.cancelar()
    );
  }

  async deleteAssinatura(assinatura) {
    assinatura.idUsuarioInativacao = this.state.user.idUsuario;
    await Http.delete(`/assinatura/inativar`, assinatura)
      .then(({ status, data }) => {
        if (data.status) {
          Notification.success(data.mensagem);
          this.listaAssinatura(assinatura.idPessoa);
        } else {
          Notification.danger(data.mensagem);
        }
      })
      .catch((error) => console.log(error));
  }

  renderAssinatura() {
    return (
      <Div>
        {this.state.addButtonAssinatura && (
          <Div rounded margin="3" padding="4" shadow>
            <Form
              id="formCadastroAssinatura"
              handlerSubmit={this.submitAssinatura}
            >
              <SectionForm borderLeft="primary">
                <Row col="12">
                  <Select
                    required
                    responsive="4"
                    label="Grupo Produtos"
                    valueSelected={this.state.idGrupoProdutos}
                    handlerChange={(e) => {
                      this.setState({ idGrupoProdutos: e.target.value });
                    }}
                    values={this.state.grupoProdutos}
                  />

                  <Select
                    required
                    responsive="4"
                    label="Tipo Assinatura"
                    valueSelected={this.state.tipoAssinatura}
                    handlerChange={(e) => {
                      this.setState({ tipoAssinatura: e.target.value });
                    }}
                    values={this.getTipoAssinaturaSelect()}
                  />

                  <InputDefault
                    required
                    label="Dias"
                    name="dias"
                    responsive="4"
                    value={this.state.dias}
                    handlerChange={(e) => {
                      this.setState({ dias: Mask.maskNumberInteger(e) });
                    }}
                  />
                </Row>

                <SectionButtonForm>
                  <Row col="12" style={{ justifyContent: "flex-end" }}>
                    <Div margin="1">
                      <ButtonIconOutLine
                        //icon={Type.ICON.DELETE}
                        value="Cancelar"
                        btn={Color.NODE.DANGER}
                        handlerClick={(_e) => this.addAssinatura()}
                      />
                    </Div>

                    <Button
                      btn={Color.NODE.PRIMARY}
                      value={this.state.nameButtonAssinatura}
                      col="1"
                      margin="1"
                    />
                  </Row>
                </SectionButtonForm>
              </SectionForm>
            </Form>
          </Div>
        )}

        {!this.state.addButtonAssinatura && (
          <Div
            margin="3"
            shadow
            bg={Color.NODE.WHITE}
            borderLeft={Color.NODE.PRIMARY}
          >
            <Title
              value="Assinaturas"
              type="h4"
              paddingLeft="4"
              paddingRight="4"
              paddingTop="3"
            />

            <Row col="12" style={{ justifyContent: "flex-end" }}>
              <Button
                btn={Color.NODE.PRIMARY}
                value="Novo"
                col="1"
                handlerClick={(_e) => this.addAssinatura()}
                margin="1"
              />{" "}
            </Row>
            <Div padding="2">
              <Table
                columns={[
                  { value: "Tipo Assinatura", align: "center" },
                  { value: "descricao", align: "center" },
                  { value: "dias", align: "center" },
                ]}
                fields={[
                  { value: "tipo", type: Type.DADO.STRING },
                  { value: "descricao", type: Type.DADO.STRING },
                  { value: "dias", type: Type.DADO.STRING },
                ]}
                bordered
                search
                orientation="center"
                pagination
                delete
                handlerDelete={(assinatura) =>
                  this.deletarAssinatura(assinatura)
                }
                data={this.state.assinaturas}
                error="Nenhuma assinatura encontrada"
              ></Table>
            </Div>
          </Div>
        )}
      </Div>
    );
  }

  //Arquivo

  submitArquivo(event) {
    event.preventDefault();
    if (event.target.reportValidity()) {
      if (this.state.idArquivo === undefined) {
        if (this.state.idPessoa === undefined) {
          Notification.danger("A Empresa ainda não foi salvo!");
          return;
        }
        this.cadastrarArquivo(this.state);
      } else {
        this.updateArquivo(this.state);
      }
    }
  }

  listaArquivos(idPessoa) {
    this.setState({ arquivos: [] });
    Http.get("/arquivo/listar/" + idPessoa).then(({ data }) => {
      if (data) {
        this.setState({ arquivos: data });
      }
    });
  }

  cadastrarArquivo(arquivo) {
    let arquivoNovo = {
      idPessoa: this.state.idPessoa,
      tipoCadastro: arquivo.tipoCadastroArquivo,
      tipoArquivo: arquivo.tipoArquivo,
      descricao: arquivo.descricao,
    };

    const formData = new FormData();
    formData.append("data", JSON.stringify(arquivoNovo));
    formData.append("files", this.state.imagensNormalizacao[0]);
    Http.postFormData("/arquivo/adicionar", formData)
      .then(({ status, data }) => {
        if (data.status) {
          this.listaArquivos(arquivoNovo.idPessoa);
          this.cleanFormArquivo();
          this.setState({ addButtonArquivo: false });
          Notification.success(data.mensagem);
        } else {
          Notification.danger(data.mensagem);
        }
      })
      .catch((error) => console.log(error));
  }

  addArquivo() {
    this.cleanFormArquivo();
    if (this.state.addButtonArquivo) {
      this.setState({ addButtonArquivo: false });
    } else {
      this.setState({ addButtonArquivo: true });
    }
  }

  cleanFormArquivo() {
    this.setState({
      idArquivo: undefined,
      tipoCadastroArquivo: "",
      tipoCadastro: "",
      tipoArquivo: "",
      nomeArquivo: "",
      descricao: "",
      caminho: "",
    });
  }

  deletarArquivo(arquivo) {
    ModalNotification.confirm(
      "Deseja desativar este Arquivo? " + arquivo.nomeArquivo,
      "Este arquivo será desativada no sistema de compras!",
      () => this.deleteArquivo(arquivo),
      () => this.cancelar()
    );
  }

  async deleteArquivo(arquivo) {
    arquivo.idUsuarioInativacao = this.state.user.idUsuario;
    await Http.post(`/arquivo/deletar`, arquivo)
      .then(({ status, data }) => {
        if (data.status) {
          Notification.success(data.mensagem);
          this.listaArquivos(arquivo.idPessoa);
        } else {
          Notification.danger(data.mensagem);
        }
      })
      .catch((error) => console.log(error));
  }

  dowloadsArquivo(arquivo) {
    var link = document.createElement("a");
    link.setAttribute("download", arquivo.nomeArquivo);
    var arqPath = arquivo.caminho + "/" + arquivo.nomeArquivo;
    link.href = arqPath;
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  handlerFile(e, tiposFile) {
    let imgs = Array.from(e.target.files).filter((file) =>
      tiposFile.includes(file.name.split(".").pop())
    );

    let sizeImgs = imgs
      .map((img) => img.size)
      .reduce((acc, size) => acc + size, 0);

    const CEM_MEGA_BYTE = 300400000;

    if (Utils.isValueValid(imgs)) {
      let isSizeValid = sizeImgs <= CEM_MEGA_BYTE;
      if (isSizeValid) {
        this.setState({ imagensNormalizacao: imgs });
      } else {
        Notification.danger(
          "Tamanho do arquivo não suportado, tamanho máximo é 100MB"
        );
      }
    } else {
      Notification.danger(
        "Nenhum arquivo válido foi encontrado, extensoes permitidas "
      );
    }
  }

  renderArquivo() {
    return (
      <Div>
        {this.state.addButtonArquivo && (
          <Div rounded margin="3" padding="4" shadow>
            <Form id="formCadastroArquivo" handlerSubmit={this.submitArquivo}>
              <SectionForm borderLeft="primary">
                <Row col="12">
                  <Select
                    required
                    responsive="3"
                    label="Tipo Cadastro"
                    valueSelected={this.state.tipoCadastroArquivo}
                    handlerChange={(e) => {
                      this.setState({ tipoCadastroArquivo: e.target.value });
                    }}
                    values={this.getTipoCadastroArquivoSelect()}
                  />

                  <Select
                    required
                    responsive="3"
                    label="Tipo Arquivo"
                    valueSelected={this.state.tipoArquivo}
                    handlerChange={(e) => {
                      this.setState({ tipoArquivo: e.target.value });
                    }}
                    values={this.getTipoArquivoSelect()}
                  />
                  <InputDefault
                    required
                    label="Descrição"
                    name="descricao"
                    responsive="5"
                    maxLength="200"
                    value={this.state.descricao}
                    handlerChange={(e) => {
                      this.setState({ descricao: e.target.value });
                    }}
                  />
                  <InputFile
                    required
                    responsive="1"
                    id="inputFile"
                    inline="center"
                    icon={Type.ICON.UPLOAD}
                    accept=".pdf, .xlsx, .doc, .xdoc, .xls, .cvs"
                    handlerChange={(e) =>
                      this.handlerFile(e, [
                        "pdf",
                        "xlsx",
                        "doc",
                        "xls",
                        "cvs",
                        "xdoc",
                      ])
                    }
                    className="btn btn-info btn-file rounded"
                  />
                </Row>

                <SectionButtonForm>
                  <Row
                    col="12"
                    marginTop="3"
                    style={{ justifyContent: "flex-end" }}
                  >
                    <Div margin="1">
                      <ButtonIconOutLine
                        //icon={Type.ICON.DELETE}
                        value="Cancelar"
                        btn={Color.NODE.DANGER}
                        handlerClick={(_e) => this.addArquivo()}
                      />
                    </Div>

                    <Button
                      btn={Color.NODE.PRIMARY}
                      value="Adicionar"
                      col="1"
                      margin="1"
                    />
                  </Row>
                </SectionButtonForm>
              </SectionForm>
            </Form>
          </Div>
        )}

        {!this.state.addButtonArquivo && (
          <Div
            margin="3"
            shadow
            bg={Color.NODE.WHITE}
            borderLeft={Color.NODE.PRIMARY}
          >
            <Title
              value="Arquivos"
              type="h4"
              paddingLeft="4"
              paddingRight="4"
              paddingTop="3"
            />

            <Row col="12" style={{ justifyContent: "flex-end" }}>
              <Button
                btn={Color.NODE.PRIMARY}
                value="Novo"
                col="1"
                handlerClick={(_e) => this.addArquivo()}
                margin="1"
              />
            </Row>
            <Div padding="2">
              <Table
                columns={[
                  { value: "Tipo Cadastro", align: "center" },
                  { value: "Tipo Arquivo", align: "center" },
                  { value: "Descricao", align: "center" },
                  { value: "Data Criação", align: "center" },
                ]}
                fields={[
                  { value: "tipoCadastro", type: Type.DADO.STRING },
                  { value: "tipoArquivo", type: Type.DADO.STRING },
                  { value: "descricao", type: Type.DADO.STRING },
                  { value: "datacadastro", type: Type.DADO.DATE },
                ]}
                bordered
                search
                orientation="center"
                pagination
                edit
                iconEdit={Type.ICON.DONWLOAD}
                delete
                handlerEdit={(arquivo) => this.dowloadsArquivo(arquivo)}
                handlerDelete={(arquivo) => this.deletarArquivo(arquivo)}
                data={this.state.arquivos}
                error="Nenhum arquivo encontrada"
              ></Table>
            </Div>
          </Div>
        )}
      </Div>
    );
  }

  listaFilial(idPessoa) {
    this.setState({ filials: [] });
    Http.get("/pessoa/listarFilialEmpresa/" + idPessoa).then(({ data }) => {
      if (data) {
        this.setState({ filials: data });
      }
    });
  }

  editarFilial(filial) {
    this.props.history.push({
      pathname: PathRoute.PATH.FILIAL_EDITAR,
      state: { pessoa: filial, editar: true },
    });
  }

  deletarFilial(filial) {
    ModalNotification.confirm(
      "Deseja desativar esta Filial? " + filial.nome,
      "Esta Filial será desativada no sistema de compras!",
      () => this.deleteFilial(filial),
      () => this.cancelar()
    );
  }

  async deleteFilial(filial) {
    filial.idUsuarioInativacao = this.state.user.idUsuario;
    await Http.post(`/pessoa/deletar`, filial)
      .then(({ status, data }) => {
        if (data.status) {
          Notification.success(data.mensagem);
          this.listaFilial(filial.idEmpresa);
        } else {
          Notification.danger(data.mensagem);
        }
      })
      .catch((error) => console.log(error));
  }

  renderFilial() {
    return (
      <Div>
        <Div
          margin="3"
          shadow
          bg={Color.NODE.WHITE}
          borderLeft={Color.NODE.PRIMARY}
        >
          <Title
            value="Filial"
            type="h4"
            paddingLeft="4"
            paddingRight="4"
            paddingTop="3"
          />

          <Div padding="2">
            <Table
              columns={[
                { value: "Nome", align: "center" },
                { value: "Razão", align: "center" },
                { value: "Tipo Cadastro", align: "center" },
                { value: "Tipo Pessoa", align: "center" },
                { value: "CPF/CNPJ", align: "center" },
                { value: "E-mail", align: "center" },
                { value: "Contato", align: "center" },
                { value: "Telefone Principal", align: "center" },
                { value: "Celular", align: "center" },
              ]}
              fields={[
                { value: "nome", type: Type.DADO.STRING },
                { value: "razao", type: Type.DADO.STRING },
                { value: "tipoCadastro", type: Type.DADO.STRING },
                { value: "tipoPessoa", type: Type.DADO.STRING },
                { value: "cpfCnpj", type: Type.DADO.CPF_CNPJ },
                { value: "emailPrincipal", type: Type.DADO.STRING },
                { value: "contatoPrincipal", type: Type.DADO.STRING },
                { value: "telefonePrincipal", type: Type.DADO.PHONE },
                { value: "telefoneFinanceiro", type: Type.DADO.PHONE },
              ]}
              bordered
              search
              orientation="center"
              pagination
              rota={PathRoute.PATH.FILIAL_CADASTRAR}
              edit
              delete
              handlerEdit={(filial) => this.editarFilial(filial)}
              handlerDelete={(filial) => this.deletarFilial(filial)}
              data={this.state.filials}
              error="Nenhum Filial encontrado"
            ></Table>
          </Div>
        </Div>
      </Div>
    );
  }

  // USUARIO

  addUsuario() {
    this.cleanFormUsuario();
    this.setState({ addButtonUsuario: !this.state.addButtonUsuario });
  }

  editarUsuario(usuario) {
    console.log(usuario)
    this.setState({
      addButtonUsuario: true,
      idUsuario: usuario.idUsuario,
      nomeUsuario: usuario.nome.toLowerCase() ,
      emailUsuario: usuario.email.toLowerCase(),
      loginUsuario: usuario.login.toLowerCase(),
      cargoUsuario: usuario.cargo,
      senhaUsuario: "",
      telefoneUsuario: usuario.telefone,
      userEdit: true,
    });
  }

  deletarUsuario(usuario) {
    ModalNotification.confirm(
      "Deseja desativar este Usuário? " + usuario.nome,
      "Esta pessoa será desativada no sistema de compras!",
      () => this.deleteU(usuario),
      () => {}
    );
  }

  deleteU(usuario) {
    usuario.idUsuarioInativacao = this.state.user.idUsuario;
    Http.delete(`/usuario`, usuario)
      .then(({ status, data }) => {
        if (data.status) {
          Notification.success(data.mensagem);
          this.listaUsuarios(usuario.idPessoa, usuario.idGestorCompras);
        } else {
          Notification.danger(data.mensagem);
        }
      })
      .catch((error) => console.log(error));
  }

  submitUsuario(event) {
    event.preventDefault();
    if (event.target.reportValidity()) {
      let usuario = this.state;

      let pessoa = this.state.pessoa;
      let idUsuario = this.props.user.idUsuario
      let senha = CryptoJS.MD5(usuario.senhaUsuario).toString();

      let newUsuario = {
        idGestorCompras: pessoa.idGestorCompras,
        nome: usuario.nomeUsuario,
        email: usuario.emailUsuario,
        login: usuario.loginUsuario,
        tipo: "EMPRESA",
        cargo: usuario.cargoUsuario,
        idPessoa: pessoa.idPessoa,
        senha,
        telefone: usuario.telefoneUsuario,
        idUsuarioAtualizacao: idUsuario,
      };

      if (!usuario.userEdit) {
        this.cadastrarUsuario(newUsuario);
      } else {
        newUsuario.idUsuario = this.state.idUsuario
        this.updateUsuario(newUsuario);
      }
    }
  }

  cadastrarUsuario(usuario) {
    Http.post("/usuario", usuario).then(({ data }) => {
      if (data.status) {
        Notification.success(data.mensagem);
        this.listaUsuarios(usuario.idPessoa, usuario.idGestorCompras);
      } else {
        Notification.danger(data.mensagem);
      }
    });
  }

  updateUsuario(usuario) {
    Http.put("/usuario", usuario).then(({ data }) => {
      if (data.status) {
        Notification.success(data.mensagem);
        this.listaUsuarios(usuario.idPessoa, usuario.idGestorCompras);
      } else {
        Notification.danger(data.mensagem);
      }
    });
  }

  cleanFormUsuario() {
    this.setState({
      nomeUsuario: "",
      emailUsuario: "",
      loginUsuario: "",
      cargoUsuario: "",
      senhaUsuario: "",
      telefoneUsuario: "",
      userEdit: false,
    });
  }

  renderUsuario() {
    return (
      <Div>
        {this.state.addButtonUsuario && (
          <Div rounded margin="3" padding="4" shadow>
            <Form id="formCadastroArquivo" handlerSubmit={this.submitUsuario}>
              <SectionForm borderLeft="primary">
                <Div responsive="12">
                  <Row col="12">
                    <InputDefault
                      required
                      label="Nome"
                      name="nomeUsuario"
                      responsive="6"
                      maxLength="200"
                      value={this.state.nomeUsuario}
                      handlerChange={(e) => {
                        this.setState({
                          nomeUsuario: e.target.value,
                        });
                      }}
                    />

                    <InputDefault
                      required
                      label="Email"
                      name="emailUsuario"
                      responsive="6"
                      maxLength="200"
                      value={this.state.emailUsuario}
                      handlerChange={(e) => {
                        this.setState({ emailUsuario: e.target.value });
                      }}
                    />
                  </Row>

                  <Row col="12">
                    <InputDefault
                      required
                      label="Login"
                      name="loginUsuario"
                      responsive="6"
                      maxLength="200"
                      value={this.state.loginUsuario}
                      handlerChange={(e) => {
                        this.setState({ loginUsuario: e.target.value });
                      }}
                    />

                    <InputDefault
                      required
                      label="Senha"
                      password
                      name="senhaUsuario"
                      responsive="6"
                      maxLength="200"
                      value={this.state.senhaUsuario}
                      handlerChange={(e) => {
                        this.setState({ senhaUsuario: e.target.value });
                      }}
                    />
                  </Row>

                  <Row col="12">
                    <InputDefault
                      required
                      label="Cargo"
                      name="cargoUsuario"
                      responsive="6"
                      maxLength="200"
                      value={this.state.cargoUsuario}
                      handlerChange={(e) => {
                        this.setState({ cargoUsuario: e.target.value });
                      }}
                    />

                    <InputPhone
                      required
                      label="Telefone"
                      name="telefoneUsuario"
                      responsive="6"
                      maxLength="200"
                      value={this.state.telefoneUsuario}
                      handlerChange={(e) => {
                        this.setState({ telefoneUsuario: e.target.value });
                      }}
                    />
                  </Row>
                </Div>

                <SectionButtonForm>
                  <Row col="12" style={{ justifyContent: "flex-end" }}>
                    <Div margin="1">
                      <ButtonIconOutLine
                        //icon={Type.ICON.DELETE}
                        value="Cancelar"
                        btn={Color.NODE.DANGER}
                        handlerClick={(_e) => this.addUsuario()}
                      />
                    </Div>

                    <Button
                      btn={Color.NODE.PRIMARY}
                      value="Adicionar"
                      col="1"
                      margin="1"
                    />
                  </Row>
                </SectionButtonForm>
              </SectionForm>
            </Form>
          </Div>
        )}

        {!this.state.addButtonUsuario && (
          <Div
            margin="3"
            shadow
            bg={Color.NODE.WHITE}
            borderLeft={Color.NODE.PRIMARY}
          >
            <Title
              value="Usuarios"
              type="h4"
              paddingLeft="4"
              paddingRight="4"
              paddingTop="3"
            />

            <Row col="12" style={{ justifyContent: "flex-end" }}>
              <Button
                btn={Color.NODE.PRIMARY}
                value="Novo"
                col="1"
                handlerClick={(_e) => this.addUsuario()}
                margin="1"
              />
            </Row>
            <Div padding="2">
              <Table
                columns={[
                  { value: "Nome", align: "left" },
                  { value: "Login", align: "left" },
                  { value: "E-mail", align: "left" },
                  { value: "Gestor", align: "left" },
                  { value: "Tipo", align: "left" },
                  { value: "Cargo", align: "left" },
                  { value: "Telefone", align: "left" },
                  { value: "Status", align: "left" },
                ]}
                fields={[
                  { value: "nome", align: "left", type: Type.DADO.STRING },
                  { value: "login", align: "left", type: Type.DADO.STRING },
                  { value: "email", align: "left", type: Type.DADO.STRING },
                  {
                    value: "descricaoGestor",
                    align: "left",
                    type: Type.DADO.STRING,
                  },
                  { value: "tipo", type: Type.DADO.STRING },
                  { value: "cargo", type: Type.DADO.STRING },
                  { value: "telefone", type: Type.DADO.PHONE },
                  {
                    value: "ativo",
                    type: Type.DADO.LABEL,
                    valueTrue: "Ativo",
                    valueFalse: "Desativado",
                    align: "center",
                    color: "#FFF",
                  },
                ]}
                bordered
                search
                pagination
                edit
                delete
                handlerEdit={(usuario) => this.editarUsuario(usuario)}
                handlerDelete={(usuario) => this.deletarUsuario(usuario)}
                // rota={PathRoute.PATH.USUARIOS_CADASTRAR}
                data={this.state.usuarios}
                error="Nenhum usuário encontrado"
              ></Table>
            </Div>
          </Div>
        )}
      </Div>
    );
  }

  render() {
    let { target } = this.state.itemActive;
    return (
      <Div>
        <Div className="w-100">
          <NavbarTab
            itens={this.getItens()}
            handlerClick={(itemActive) => {
              this.setState({ itemActive });
            }}
          />
        </Div>
        <If and value1={target === "MATRIZ"}>
          {this.renderPessoa()}
        </If>
        <If and value1={target === "ENDEREÇO"}>
          {this.renderEndereco()}
        </If>
        <If and value1={target === "CONTATO"}>
          {this.renderContato()}
        </If>
        <If and value1={target === "ARQUIVO"}>
          {this.renderArquivo()}
        </If>
        <If and value1={target === "FILIAL"}>
          {this.renderFilial()}
        </If>
        <If and value1={target === "ASSINATURA"}>
          {this.renderAssinatura()}
        </If>

        <If and value1={target === "USUÁRIO"}>
          {this.renderUsuario()}
        </If>
      </Div>
    );
  }
}

const mapStateToProps = (store) => ({
  user: store.loginState.user,
});

export default connect(mapStateToProps)(Cadastrar);
