import React, { useState } from "react";
import {
  CheckboxToggle,
  Div,
  I,
  If,
  Label,
  Row,
  Select,
  SelectValue,
  Title,
} from "../../components";
import { Color, Mask, Type, Utils } from "../../utilities";
import "./tableApuracao.css";

const styles = {
  tableHead: {
    border: "1px solid #dee2e6",
  },
  tableRows: {
    border: "1px solid #dee2e6",
    borderTop: "none",
  },
  tableDrops: {
    border: "1px solid #dee2e6",
    backgroundColor: Color.RGBA.CINZA_CLARO,
    paddingRight: "0",
  },
  head: {
    display: "flex",
    alignItems: "center",
    padding: "5px 10px",
    fontSize: "14px",
    height: "70px",
    borderRight: "1px solid #dee2e6",
    fontWeight: "bolder",
  },
  rows: {
    display: "flex",
    alignItems: "center",
    padding: "5px 10px",
    height: "70px",
    borderRight: "1px solid #dee2e6",
    fontSize: "13px",
  },
  drops: {
    display: "flex",
    alignItems: "center",
    padding: "5px 10px",
    fontSize: "14px",
    height: "70px",
    borderRight: "1px solid #dee2e6",
    fontWeight: "bolder",
  },
  dropsLabel: {
    fontSize: "12px",
    margin: "0px",
  },
  alignItens: {
    justifyContent: "end",
    textAlign: "end",
  },
  alignItensCenter: {
    justifyContent: "center",
    textAlign: "center",
  },
  alignItemsInitial: {
    alignItems: "flex-start",
    textAlign: "initial",
  },
};

function TableApuracao(props) {
  const { produtos, setProdutosPreco } = props;
  const [ganhador, setGanhador] = useState("");
  const [indexSelect, setIndex] = useState(999);
  const [edit, setEdit] = useState(999);


  function TableHead() {
    return (
      <Row col="12" style={styles.tableHead}>
        <Div style={{ ...styles.head, width: "50px" }}> </Div>
        <Div style={{ ...styles.head, width: "10%" }}>Código de Barras</Div>
        <Div style={{ ...styles.head, width: "5%" }}>PLU</Div>
        <Div style={{ ...styles.head, width: "20%" }}>Produto</Div>
        <Div style={{ ...styles.head, ...styles.alignItens, width: "9%" }}>
          Quantidade Solicitada
        </Div>
        <Div style={{ ...styles.head, ...styles.alignItens, width: "9%" }}>
          Quantidade Disponível
        </Div>
        <Div style={{ ...styles.head, ...styles.alignItens, width: "9%" }}>
          Prazo Entrega
        </Div>
        <Div style={{ ...styles.head, ...styles.alignItens, width: "9%" }}>
          Menor Preço
        </Div>
        <Div
          style={{
            ...styles.head,
            ...styles.alignItens,
            width: "24%",
            border: "none",
          }}
        >
          Ganhador
        </Div>
      </Row>
    );
  }

  function TableDrop(props) {
    const { cotacoesPrecos, i, idGanhador, produto } = props;
    let cotacoesPrecosVerif = Utils.isArrayNotEmpty(cotacoesPrecos) ? cotacoesPrecos : []

    return (
      <Row
        col="12"
        style={{
          backgroundColor: "#FFF",
          minHeight: "100px",
          border: "1px solid #dee2e6",
          borderTop: "none",
          padding: "10px 0 30px",
          display: i === indexSelect ? "block" : "none",
        }}
        // className={open && i === indexSelect ? "drop-empresa-active" : "drop-empresa"}
      >
        <Title
          value="Detalhes Fornecedores"
          type="h5"
          paddingLeft="4"
          paddingRight="4"
          paddingTop="3"
        />

        {/* HEADER FORNECEDOR DROPS */}
        <Div col="12" style={{ padding: "7px 23px 0" }}>
          <Row col="12" style={styles.tableDrops}>
            <Div style={{ ...styles.drops, width: "28%" }}>Fornecedor</Div>
            <Div
              style={{ ...styles.drops, ...styles.alignItens, width: "10%" }}
            >
              Quantidade Disponível
            </Div>
            <Div
              style={{ ...styles.drops, ...styles.alignItens, width: "10%" }}
            >
              Quantidade Minima
            </Div>
            <Div
              style={{ ...styles.drops, ...styles.alignItens, width: "10%" }}
            >
              Prazo Entrega
            </Div>
            <Div
              style={{ ...styles.drops, ...styles.alignItens, width: "10%" }}
            >
              Menor Preço
            </Div>
            <Div
              style={{
                ...styles.drops,
                ...styles.alignItensCenter,
                width: "25%",
              }}
            >
              Observações
            </Div>
            <Div style={{ ...styles.drops, border: "none", width: "80px" }}>
              {" "}
            </Div>
          </Row>
        </Div>

        {/* ROWS FORNECEDOR DROPS */}
        
        {cotacoesPrecosVerif.map((fornecedor, index) => {
          return (
            <TableRowsDrops
              i={i}
              idGanhador={idGanhador}
              produto={produto}
              background={index % 2 === 0 ? "rgba(0,0,0,.05)" : "#FFF"}
              fornecedor={fornecedor}
            />
          );
        })}
      </Row>
    );
  }

  function TableRowsDrops(props) {
    let { fornecedor, idGanhador, produto, i } = props;
    fornecedor.fornecedorObs = fornecedor.fornecedorObs
      ? fornecedor.fornecedorObs
      : "";
    
    fornecedor.checked = idGanhador === fornecedor.idPessoaFornecedor 

    return (
      <Div col="12" style={{ padding: "7px 23px 0" }}>
        <Row col="12" paddingRight="0" style={styles.tableRows}>
          <Div style={{ ...styles.rows, width: "28%" }}>
            <Label
              style={styles.dropsLabel}
              title={fornecedor.fornecedorDescricao}
            >
              {fornecedor.fornecedorDescricao.length > 35
                ? fornecedor.fornecedorDescricao.substring(0, 35) + "..."
                : fornecedor.fornecedorDescricao}
            </Label>
          </Div>
          <Div style={{ ...styles.rows, ...styles.alignItens, width: "10%" }}>
            {fornecedor.quantidadeDisponivel}
          </Div>
          <Div style={{ ...styles.rows, ...styles.alignItens, width: "10%" }}>
            {fornecedor.quantidadeMinimaPedido}
          </Div>
          <Div style={{ ...styles.rows, ...styles.alignItens, width: "10%" }}>
            {fornecedor.prazoEntrega}
          </Div>
          <Div
            className={fornecedor.ganhador ? "menor-valor" : ""}
            style={{ ...styles.rows, ...styles.alignItens, width: "10%" }}
          >
            {Mask.formatValueBr(fornecedor.preco)}
          </Div>
          <Div
            style={{ ...styles.rows, ...styles.alignItensCenter, width: "25%" }}
          >
            <Label style={styles.dropsLabel} title={fornecedor.fornecedorObs}>
              {fornecedor.fornecedorObs.length > 45
                ? fornecedor.fornecedorObs.substring(0, 45) + "..."
                : fornecedor.fornecedorObs}
            </Label>
          </Div>
          <Div style={{ ...styles.rows, border: "none", width: "80px" }}>
            <CheckboxToggle
              checked={fornecedor.checked}
              handlerChange={(e) => {

                if(!fornecedor.checked) {
                  produto.cotacoesPrecos.forEach((f) => {
                    if (f.idPessoaFornecedor === fornecedor.idPessoaFornecedor) {
                      produto.quantidadedisponivel = f.quantidadeDisponivel;
                      produto.quantidadesolicitada = f.quantidadeSolicitada;
                      produto.prazoentrega = f.prazoEntrega;
                      produto.menor_preco = f.preco;
                      produto.fornecedor_ganhador = f.fornecedorDescricao;
                      produto.fornecedor_idpessoa = f.idPessoaFornecedor
                    }
                  });
                }
                setProdutosPreco(produtos)
                setGanhador(produto.fornecedor_ganhador)
              }}
            />
          </Div>
        </Row>
      </Div>
    );
  }

  function TableRows(props) {
    const { background, index, produto } = props;
    let i = index;

    let tipo = [new SelectValue("", "SELECIONE", true)];

    if (Utils.isNotNull(produto.cotacoesPrecos)) {
      produto.cotacoesPrecos.forEach((f) => {
        tipo.push(
          new SelectValue(f.idPessoaFornecedor, f.fornecedorDescricao, true)
        );
      });
    }

    return (
      <>
        {/* ROWS PRODUTOS */}
        <Row
          col="12"
          style={{ ...styles.tableRows, backgroundColor: background }}
        >
          <Div style={{ ...styles.head, width: "50px" }}>
            <I
              className="icon-open-drop"
              icon={
                i === indexSelect
                  ? Type.ICON.ARROW_ANGLE_DOWN
                  : Type.ICON.ARROW_ANGLE_RIGHT
              }
              pointer
              sizeIcon="2"
              handlerClick={() => {
                setIndex((state) => (state === i ? 999 : i));
                setGanhador(produto.fornecedor_idpessoa)
              }}
            ></I>
          </Div>
          <Div style={{ ...styles.rows, width: "10%" }}>{produto.ean}</Div>
          <Div style={{ ...styles.rows, width: "5%" }}>
            {produto.codigoproduto}
          </Div>
          <Div style={{ ...styles.rows, width: "20%" }}>
            {produto.descricao_produto}
          </Div>
          <Div style={{ ...styles.rows, ...styles.alignItens, width: "9%" }}>
            {produto.quantidadesolicitada}
          </Div>
          <Div style={{ ...styles.rows, ...styles.alignItens, width: "9%" }}>
            {produto.quantidadedisponivel}
          </Div>
          <Div style={{ ...styles.rows, ...styles.alignItens, width: "9%" }}>
            {produto.prazoentrega}
          </Div>
          <Div style={{ ...styles.rows, ...styles.alignItens, width: "9%" }}>
            {Mask.formatValueBr(produto.menor_preco)}
          </Div>
          <Div
            style={{
              ...styles.rows,
              ...styles.alignItens,
              width: "24%",
              border: "none",
            }}
          >
            <If and value1={edit !== i}>
              <Label
                style={styles.dropsLabel}
                title={produto.fornecedor_ganhador}
              >
                {produto.fornecedor_ganhador.length > 25
                  ? produto.fornecedor_ganhador.substring(0, 25) + "..."
                  : produto.fornecedor_ganhador}
              </Label>

              <I
                className="icon-open-drop"
                icon={Type.ICON.EDIT}
                style={{
                  margin: "5px 15px",
                }}
                title="Editar Fornecedor"
                pointer
                sizeIcon="2"
                handlerClick={() => {
                  setEdit(i);
                }}
              ></I>
            </If>
            <If and value1={edit === i}>
              <Select
                required
                margin="0"
                responsive="12"
                handlerChange={(e) => {
                  
                  produto.cotacoesPrecos.forEach((f) => {
                    if (f.idPessoaFornecedor === e.target.value) {
                      produto.quantidadedisponivel = f.quantidadeDisponivel;
                      produto.quantidadesolicitada = f.quantidadeSolicitada;
                      produto.prazoentrega = f.prazoEntrega;
                      produto.menor_preco = f.preco;
                      produto.fornecedor_ganhador = f.fornecedorDescricao;
                      produto.fornecedor_idpessoa = f.idPessoaFornecedor;
                      f.checked = true
                    } else {
                      f.checked = false
                    }
                  });
                  setProdutosPreco(produtos)
                  setGanhador(e.target.value);
                  setEdit(false);
                }}
                values={tipo}
              />
            </If>
          </Div>
        </Row>

        {/* Tabela Empresa */}
        <TableDrop
          i={i}
          background={background}
          cotacoesPrecos={produto.cotacoesPrecos}
          idGanhador={produto.fornecedor_idpessoa}
          produto={produto}
        />
      </>
    );
  }

  return (
    <Div col="12" padding="4">
      <If and value1={Utils.isArrayNotEmpty(produtos)}>
        <TableHead />
        {produtos.map((produto, index) => {
          return (
            <TableRows
              background={index % 2 === 0 ? "rgba(0,0,0,.05)" : "#FFF"}
              index={index}
              produto={produto}
            />
          );
        })}
      </If>
    </Div>
  );
}

export default TableApuracao;
