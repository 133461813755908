import React from "react";
import { Div, I } from "..";
import { Type } from "../../utilities";
import "./image.css";
import { float, className, col, sizeIcon, margin, padding, shadow } from "../css";

const getClasseDeafult = props => {
  let classe = "";
  classe = classe.concat(props.responsive ? "img-fluid " : "");
  classe = classe.concat(props.border ? "img-thumbnail " : "");
  classe = classe.concat(col(props));
  classe = classe.concat(props.rounded ? "rounded-circle " : "");
  classe = classe.concat(float(props));
  classe = classe.concat(margin(props));
  classe = classe.concat(padding(props));
  classe = classe.concat(shadow(props));
  classe = classe.concat(className(props));
  return classe;
};

const getClassContainer = props => {
  let classe = "";
  classe = classe.concat(props.center ? "text-center " : "");
  return classe;
};

const Image = props => {
  return (
    <Div className={getClassContainer(props)}>
      <img
        className={getClasseDeafult(props)}
        src={props.src}
        width={props.width}
        heigth={props.height}
        alt={props.alt}
        id={props.id}
      />
      {props.close && (
        <I
          icon={Type.ICON.CLOSE}
          size={sizeIcon(props)}
          className={`${props.classNameIconClose} position-absolute translatex10 pointer`}
          handlerClick={e => props.handlerClose(props.data)}
        ></I>
      )}
    </Div>
  );
};

export default Image;
