export default class Type {
  static INPUT = {
    SUBMIT: "submit",
    TEXT: "text",
    RADIO: "radio",
    DATE: "date",
    COLOR: "color",
    EMAIL: "email",
    FILE: "file",
    CHECKBOX: "checkbox",
    HIDDEN: "hidden",
    SELECT: "select",
    TIME: "time",
    PASSWORD: "password",
    RANGE: "range",
    PHONE: "tel",
    NUMBER: "number",
    DATE_TIME: "datetime-local",
  };

  static POPOVER = {
    BUTTON: "button",
    LABEL: "label",
  };

  static ICON = {
    X: "fa-x",
    XX: "fa-2x",
    XXX: "fa-3x",
    EDIT: "fa fa-pencil-square",
    DELETE: "fa fa-trash",
    USER: "fa fa-user-circle",
    USER_CIRCLE_O: "fa fa-user-circle-o",
    BELL: "fa fa-bell-o",
    KEY: "fa fa-key",
    CALENDAR: "fa fa-calendar",
    CLOSE: "fa fa-times-circle-o",
    CLOSEX: "fa fa-times",
    INFO: "fa fa-info-circle",
    WARNING: "fa fa-warning",
    SUCCESS: "fa fa-check",
    PLUS: "fa fa-plus",
    PLUS_SQUARE: "fa fa-plus-square",
    PLUS_CIRCLE: "fa fa-plus-circle",
    TASK: "fa fa-tasks",
    UPLOAD: "fa fa-upload",
    REFRESH: "fa fa-refresh",
    ARROW_CIRCLE_DOWN: "fa fa-arrow-circle-o-down",
    PAUSE: "fa fa-pause-circle-o",
    PLAY: "fa fa-play-circle-o",
    ARROW_CHEV_RIGHT: "fa fa-chevron-right",
    ARROW_CHEV_LEFT: "fa fa-chevron-left",
    ARROW_CHEV_DOWN: "fa fa-chevron-down",
    ARROW_CHEV_UP: "fa fa-chevron-up",
    ARROW_ANGLE_RIGHT: "fa fa-angle-right",
    ARROW_ANGLE_LEFT: "fa fa-angle-left",
    ARROW_ANGLE_DOWN: "fa fa-angle-down",
    ADDRESS_BOOK: "fa fa-address-book-o",
    LIST_UL: "fa fa-list-ul",
    ELLIPSIS_V: "fa fa-ellipsis-v",
    SEARCH: "fa fa-search",
    HAND_RIGHT: "fa fa-hand-o-right",
    TICKET: "fa fa-ticket",
    LIST_ALT: "fa fa-list-alt",
    SALE_BASKET: "fa fa-shopping-basket",
    SHOPPING_CART: "fa fa-shopping-cart",
    SHOPPING_BAG: "fa fa-shopping-bag",
    SALE_CART: "fa fa-shopping-cart",
    SMILE: "fa fa-smile-o",
    SMILE_SAD: "fa fa-frown-o",
    STAR: "fa fa-star-o",
    STAR_FULL: "fa fa-star",
    MONEY: "fa fa-money",
    FOOD: "fa fa-cutlery",
    REORDER: "fa fa-reorder",
    FILTER: "fa fa-filter",
    THUMBS_UP: "fa fa-thumbs-o-up",
    THUMBS_DOWN: "fa fa-thumbs-o-down",
    PAPER_PLANE_O: "fa fa-paper-plane-o",
    MAIL_FORWARD: "fa fa-share",
    CHART_LINE: "fa fa-line-chart",
    HEART_BEATH: "fa fa-heartbeat",
    USD: "fa fa-usd",
    PDF: "fa fa-file-pdf-o",
    CSV: "fa fa-file-excel-o",
    MOTORCYCLE: "fa fa-motorcycle",
    PERCENT: "fa fa-percent",
    TRUCK: "fa fa-truck",
    CLOCK: "fa fa-clock-o",
    EXCLAMATION: "fa fa-exclamation-circle",
    PHONE_SQUARE: "fa fa-phone-square",
    UNIVERSITY: "fa fa-university",
    PENCIL: "fa fa-pencil",
    BUILDING: "fa fa-building",
    BUILDING_O: "fa fa-building-o",
    USERS: "fa fa-users",
    MALE: "fa fa-male",
    COMPANY: "fa fa-building",
    CARD_ADRESS: "fa fa-address-card-o",
    SERVER: "fa fa-server",
    PRODUCT: "fa fa-product-hunt",
    FLOPPY: "fa fa-floppy-o",
    SIGNOUT: "fa fa-sign-out",
    DONWLOAD: "fa fa-download",
    CLOUD: "fa fa-cloud",
    MAP_SIGNS: "fa fa-map-signs",
    GLOBE: "fa fa-globe",
    SEND: "fa fa-send",
    MAGIC: "fa fa-magic",
    SITEMAP: "fa fa-sitemap",
    TAG: "fa fa-tag",
    BRAND: "fa fa-bandcamp",
    SLIDE: "fa fa-sliders",
    TAGS: "fa fa-tags",
    CAMERA : "fa fa-camera",
    CAMERA_RETRO : "fa fa-camera-retro",
    DROPBOX: "fa fa-dropbox",
    CLONE: "fa fa-clone",
    EXPAND: "fa fa-expand",
    BALANCE_SCALE: "fa fa-balance-scale",
    SHARE: "fa fa-share-alt",
    DATABASE: "fa fa-database",
    ERASER: "fa fa-eraser",
    EYE: 'fa fa-eye',
    EYE_SLASH: 'fa fa-eye-slash',
  };

  static DADO = {
    LABEL: "LABEL",
    IMG: "IMAGEM",
    DATE: "DATE",
    TIME: "TIME",
    DATE_TIME: "DATE_TIME",
    STRING: "STRING",
    INTEGER: "INTEGER",
    CURRENCY: "CURRENCY",
    MARGEM: "MARGEM",
    DECIMAL: "DECIMAL",
    BOOLEAN: "BOOLEAN",
    CEP: "CEP",
    CPF_CNPJ: "CPF_CNPJ",
    ICON: "ICON",
    PHONE: "PHONE",
  };

  static APP = {
    IFOOD: "IFOOD",
    SITEMERCADO: "SITEMERCADO",
    ECOMMERCE_SACCARIA: "ECOMMERCE_SACCARIA",
    WOOCOMMERCE: "WOOCOMMERCE",
  };

  static PEDIDO_IFOOD = {
    CANCELADO: "CANCELADO",
    DESPACHADO: "DESPACHADO",
    CONFIRMADO: "CONFIRMADO",
    COLETADO: "COLETADO",
    INTEGRADO: "INTEGRADO",
    CONCLUIDO: "CONCLUÍDO",
  };
}
