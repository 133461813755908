import React, { Component } from "react";
import { Color, Http, Mask, Type, Pattern, DateUtils, Utils} from "../../utilities";

import {
  Row,
  InputDefault, InputInteger,  
  Form, Checkbox,
  SelectValue,
  Select,
  Notification,
  SectionForm,
  SectionButtonForm,
  Button,
  Div,
  Table, 
  Title, ModalNotification
} from "../../components";
import { connect } from "react-redux";


class Cadastrar extends Component {

  constructor(props) {
    super(props);

    this.state = {
      nameButton: "Salvar",
      user: props.user,     
      empresas: [],  
      filials: [],  
      filialsSelecionados: [],  
      empresaRedes: [],
      addButtonRede: false,  
      ...this.props.location.state 
    };

    this.submit = this.submit.bind(this);    
    this.submitRedes = this.submitRedes.bind(this);    
    this.addArrayFilial = this.addArrayFilial.bind(this);    

  }

  UNSAFE_componentWillMount() {        
    let rede = this.state.redeEmpresa; 
    this.getDataEmpresa();       
    if (this.state.editar) {            
          this.listaEmpresaRedes(rede.idRede);
          this.setState({
            nameButton: "Alterar",
            idRede: rede.idRede,
            descricao: rede.descricao,
            codigoRede: rede.codigoRede
          });
    }
  }


  listaRedes() {
    this.props.history.push({pathname: `/compras/redeempresa`});
  }

 
  async submit(event) {
    event.preventDefault();
    if (event.target.reportValidity()) {
      if (this.state.idRede === undefined) {
        this.cadastrar(this.state);
      } else {
        this.update(this.state);
      }
    }
  }

  async submitRedes(event) {
    event.preventDefault();
    if (event.target.reportValidity()) {      
        this.cadastrarGrupo(this.state);      
    }
  }

  cadastrar(rede) {           
    rede.idGestorCompras = this.state.user.idGestorCompras         
      Http.post("/rede", rede).then(({ data }) => {
        if (data.status) {
          this.setState({idRede: data.id, nameButton: "Alterar"});                                      
          Notification.success(data.mensagem);          
        } else {
          Notification.danger(data.mensagem);
        }
      });    
  }

  cadastrarGrupo(empresaRede) {       
    if (empresaRede.idRede === undefined) {          
      Notification.danger("Obrigatório a criação da Rede!");
      return false;
    }    

    if (!Utils.isArrayNotEmpty(this.state.filialsSelecionados)) {          
      Notification.danger("Obrigatório selecionar uma Filial!");
      return false;
    }  

    var valida = this.validaForm();
    
    if (valida) {
      Array.from(this.state.filialsSelecionados).map((filial) => {
        empresaRede.idRedeEmpresa = this.state.idRede; 
        empresaRede.idFilial = filial; 
        Http.post("/empresarede", empresaRede).then(({ data }) => {
          if (data.status) {
            Notification.success(data.mensagem);
            this.listaEmpresaRedes(this.state.idRede);
          } else {
            Notification.danger(data.mensagem);
          }
        }); 
      });  
    } 
  }

  validaForm () {
    var valida = true;
    Array.from(this.state.empresaRedes).map((empreRede) => {    
      Array.from(this.state.filialsSelecionados).map((filial) => {
        if(empreRede.idFilial === filial){        
          Notification.danger("Esta Filial já esta cadastrada!");
          valida = false;        
        }
      });    
    });
    return valida;
  }
  
  update(rede) {        
    rede.idUsuarioAtualizacao = this.state.user.idUsuario;
      Http.put("/rede", rede).then(({ data }) => {      
        if (data.status) {          
          Notification.success(data.mensagem);
          this.listaRedes();
        } else {
          Notification.danger(data.mensagem);
        }      
      });    
  }

  addRede() {
    if(this.state.addButtonRede) {
      this.setState({addButtonRede: false});
    } else {
      this.setState({addButtonRede: true});
    }      
  }

  async getDataEmpresa() { 
    this.setState({empresas: []});           
    await Http.get(`/pessoa/listarEmpresa/` + this.state.user.idGestorCompras)
      .then(({ status, data }) => {
        if (data) {
          let empresaComDados = [];
          empresaComDados.push(new SelectValue(0, "SELECIONE", true));
          Array.from(data).map((empresa) => {
            empresaComDados.push(
              new SelectValue(empresa.idPessoa, empresa.nome, false)
            );
          });
          this.setState({ empresas: empresaComDados });
        }
      })
      .catch((error) => console.log(error));
  }

  listaFilial(idPessoa) {
    this.setState({filials: []});
    Http.get("/pessoa/listarFilialEmpresa/" + idPessoa).then(({ data }) => {                  
      if (data) {      
        let filialComDados = [];        
        Array.from(data).map((empresa) => {
          filialComDados.push(
            new SelectValue(empresa.idPessoa, empresa.nome, false)
          );
        });            
        this.setState({ filials: filialComDados });
      }
    });
  }

  deletarGrupo(empresaRede) {    
    ModalNotification.confirm(
      "Deseja desativar esta Empresa do grupo? " + empresaRede.nomeEmpresa,
      "Esta Empresa/Filial será desativada no sistema de compras!",
      () => this.delete(empresaRede),
      () => this.cancelar()
    );
  }

  async delete(empresaRede) {    
    empresaRede.idUsuarioInativacao = this.state.user.idUsuario
    await Http.post(`/empresarede/deletar`, empresaRede)
    .then(({ status, data }) => {
      if (data.status) {
        Notification.success(data.mensagem);
        this.listaEmpresaRedes(this.state.idRede);     
      } else {
        Notification.danger(data.mensagem);
      }
    })
    .catch((error) => console.log(error));
  }

  listaEmpresaRedes(idRedeEmpresa) {
    this.setState({ empresaRedes: []});
    Http.get(`/empresarede/listar/` + idRedeEmpresa)
    .then(({ status, data }) => {
      if (data) {
        this.setState({ empresaRedes: data });
      }
    })
    .catch((error) => console.log(error));
  
  }

  addArrayFilial (checked, idFilial) {
    var array = this.state.filialsSelecionados;
      var index = array.indexOf(idFilial);
      if (index !== -1) {
        this.state.filialsSelecionados.splice(index, 1);        
      } else {
        this.state.filialsSelecionados.push(idFilial);  
      }      
  }
  
   render() {
    
    return (<Div>
          <Form id="formCadastroRede" handlerSubmit={this.submit}>
          <SectionForm borderLeft="primary">
            <Row col="12">
            <InputInteger 
                label="Código da Rede"
                name="descricao"
                responsive="2"
                value={this.state.codigoRede}
                handlerChange={(e) => {
                  this.setState({codigoRede: e.target.value });                  
                }}
              />

              <InputDefault  required
                label="Descrição"
                name="descricao"
                responsive="10"
                value={this.state.descricao}
                handlerChange={(e) => {
                  this.setState({descricao: e.target.value });                  
                }}
              />
            </Row>

            <SectionButtonForm>
                <Button
                  btn={Color.NODE.PRIMARY}
                  value={this.state.nameButton}
                  col="1"
                  margin="1"
                />

                <Button
                  btn={Color.NODE.DANGER}
                  value="Cancelar"
                  col="1"
                  handlerClick={(_e) => this.listaRedes()}
                  margin="5"
                />
              </SectionButtonForm>
          </SectionForm> 
    </Form>

    <Div>            
        <Div>
              <Button
                btn={Color.NODE.PRIMARY}
                value="Adicionar Rede de Empresas e Filiais"
                col="2"
                handlerClick={(_e) => this.addRede()}
                margin="1"
              />  

              {this.state.addButtonRede && (
              <Form id="formCadastroRedes" handlerSubmit={this.submitRedes}>
                <SectionForm borderLeft="primary">
                  <Row col="12">
                    <Select required 
                        responsive="3"
                        label="Empresa"
                        valueSelected={this.state.idEmpresa}
                        handlerChange={(e) => {
                          this.setState({idEmpresa: e.target.value });   
                          this.listaFilial(e.target.value)                                                               
                        }}
                        values={this.state.empresas}
                      />
                      </Row>
                    <Row col="12">                    
                      {Array.from(this.state.filials).map((cabecalho, index) => {
                        return (                          
                          <Checkbox                          
                          marginTop="4"
                          marginLeft="5"
                          label={cabecalho.value} 
                          value={cabecalho.id}
                          handlerChange={(e) =>
                            this.addArrayFilial(e.checked, e.value)
                          }
                          />
                        );
                      })}
                    </Row>
                    <SectionButtonForm>
                    <Button
                      btn={Color.NODE.PRIMARY}
                      value="Adicionar"
                      col="1"
                      margin="1"
                    />
                  </SectionButtonForm>
                </SectionForm>
              </Form>
            )}       
        </Div>

        <Title value="Grupos de Empresas" type="h4" paddingLeft="4" paddingRight="4" paddingTop="3"/>
        
        <Div padding="2">
          <Table
            columns={[              
              { value: "Empresa", align: "center" },
              { value: "Filial", align: "center" }
            ]}
            fields={[
              { value: "nomeEmpresa",    type: Type.DADO.STRING },
              { value: "nomeFilial",   type: Type.DADO.STRING }
            ]}
            bordered
            search
            orientation="center"
            pagination                        
            delete                        
            handlerDelete={(empresaRede) => this.deletarGrupo(empresaRede)}            
            data={this.state.empresaRedes}
            error="Nenhum grupo encontrada"
          ></Table>
        </Div>

    </Div>
    </Div>

    
    
    )
  }
   
}

const mapStateToProps = (store) => ({
  user: store.loginState.user,
});

export default connect(mapStateToProps)(Cadastrar);
