import React, { PureComponent } from "react";

import { Div } from "../../components";
import Chart from "chart.js";
import { Utils } from "../../utilities";

const DD = "2d";

export default class ChartLine extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      dataSets: props.dataSets || [],
      labels: []
    };
  }

  loadChart() {
    const { id } = this.props;

    let config = this.getConfigCharLine();

    Array.from(Object.values(Chart.instances)).forEach(instance => {
      if (instance.canvas.id === id) {
        instance.destroy();
      }
    });

    let chartCanvas = document.getElementById(id);
    if (chartCanvas) {
      chartCanvas = chartCanvas.getContext(DD);
      new Chart(chartCanvas, config);
    }
  }

  componentWillReceiveProps(props) {
    let newDataSets = props.dataSets;
    let datas = [];
    if (Utils.isValueValid(newDataSets)) {
      Array.from(newDataSets).forEach(dataSet => {
        let newDataSet = {
          ...dataSet,
          label: dataSet.labelHeader,
          borderWidth: 1,
          data: []
        };
        let data = Array.from(dataSet.data);
        if (Utils.isValueValid(data)) {
          data.forEach(dt => {
            let newData = {
              ...dt,
              x: dt[dataSet.keyValueX],
              y: dt[dataSet.keyValueY]
            };
            newDataSet.data.push(newData);
          });
        }
        datas.push(newDataSet);
      });

      this.setState(
        {
          dataSets: datas
        },
        () => this.loadChart()
      );
    }
  }

  getLabels() {
    let labels = [];
    let dataSets = this.state.dataSets;

    if (Utils.isValueValid(dataSets)) {
      Array.from(dataSets).forEach(dataSet => {
        labels = labels.concat(dataSet.data.map(dt => dt[dataSet.keyValueX]));
      });
    }

    return [...new Set(labels)].sort();
  }

  getTitleChart(title) {
    return title
      ? {
          text: title,
          display: true
        }
      : null;
  }

  getConfigCharLine() {
    const { title, type } = this.props;

    return {
      type: type,
      data: {
        labels: this.getLabels(),
        datasets: this.state.dataSets
      },
      options: {
        tooltips: {
          callbacks: {
            label: (tooltipItems, data) => {
              let label = this.props.handlerTooltip(tooltipItems, data);
              return label;
            },
            title: function(tooltipItems, data) {
              let dataChart =
                data.datasets[tooltipItems[0].datasetIndex].data[
                  tooltipItems[0].index
                ];
              return dataChart.x;
            }
          }
        },
        title: this.getTitleChart(title),
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                stepSize: this.props.stepSize
              }
            }
          ]
        }
      }
    };
  }

  render() {
    const { styles, id } = this.props;
    return (
      <Div {...styles}>
        <canvas id={id}></canvas>
      </Div>
    );
  }
}

ChartLine.defaultProps = {
  type: "line"
};
