import React, { Component } from "react";
import { Color, Http, Mask } from "../../utilities";

import {
  Div,
  NavbarTab,
  If,
  Row,
  Title,
  InputDefault,
  InputPhone,
  InputCpfCnpj,
  Form,
  TextAreaCustom,
  Notification,
  SectionButtonForm,
  Button,
} from "../../components";
import { connect } from "react-redux";
import CryptoJS from "crypto-js";

class Detalhe extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: props.user,
      informacao: {},
      nameButton: "Salvar",
      senha: "",
      repetirSenha: "",
      idTemplate: undefined,
      itemActive: {
        target: "SOBRE",
        value: "SOBRE",
        active: true,
      },
      ...this.props.location.state,
    };

    this.configuraUsuario = this.configuraUsuario.bind(this);
    this.submitSobre = this.submitSobre.bind(this);
    this.submitEmail = this.submitEmail.bind(this);
    this.submitPerson = this.submitPerson.bind(this);
    this.submitConfig = this.submitConfig.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.getMeusDados();
  }

  getItens = () => {
    let item = this.state.itemActive;
    return [
      {
        target: "SOBRE",
        value: "SOBRE",
        active: item.value === "SOBRE",
      },
      {
        target: "E-MAIL",
        value: "E-MAIL",
        active: item.value === "E-MAIL",
      },
      {
        target: "PERSONALIZACAO",
        value: "PERSONALIZAÇÃO",
        active: item.value === "PERSONALIZACAO",
      },
      {
        target: "CONFIGURAÇÃO",
        value: "CONFIGURAÇÃO",
        active: item.value === "CONFIGURAÇÃO",
      },
    ];
  };

  getMeusDados() {
    this.setState({ informacao: {} });
    Http.get("/usuario/meusdados/" + this.state.user.idUsuario).then(
      ({ data }) => {
        if (data) {
          this.configuraUsuario(data);
        }
      }
    );
  }

  configuraUsuario(usuario) {
    this.setState({
      idUsuario: usuario.idUsuario,
      nome: usuario.nome,
      email: usuario.email,
      login: usuario.login,
      tipo: usuario.tipo,
      cargo: usuario.cargo,
      idTemplates: usuario.idTemplates,
      sobre: usuario.sobre,
      emailCotacaoEnviar: usuario.emailCotacaoEnviar,
      emailCotacaoResposta: usuario.emailCotacaoResposta,
      telefone: Mask.maskValuePhone(usuario.telefone),
      tipocadastro: usuario.tipocadastro,
      gestor: usuario.gestor,
      nomePessoa: usuario.nomePessoa,
      cpfcnpj: Mask.maskValueCpfCnpj(usuario.cpfcnpj),
      emailprincipal: usuario.emailprincipal,
      telefoneprincipal: Mask.maskValuePhone(usuario.telefoneprincipal),
      corPrimaria: usuario.corPrimaria,
      urlLogo: usuario.urlLogo,
    });

    if (this.state.idTemplates != null) {
      this.setState({ nameButton: "Alterar" });
    }
  }

  async submitSobre(event) {
    event.preventDefault();
    if (event.target.reportValidity()) {
      if (this.state.idTemplates == null) {
        this.cadastrar();
      } else {
        this.update();
      }
    }
  }

  async submitEmail(event) {
    event.preventDefault();
    if (event.target.reportValidity()) {
      if (this.state.idTemplates == null) {
        this.cadastrar();
      } else {
        this.update();
      }
    }
  }

  async submitPerson(event) {
    event.preventDefault();
    if (event.target.reportValidity()) {
      if (this.state.idTemplates == null) {
        this.cadastrar();
      } else {
        this.update();
      }
    }
  }

  submitConfig(event) {
    event.preventDefault();
    let usuario = {
      idGestorCompras: this.props.user.idGestorCompras,
      idUsuario: this.props.user.idUsuario,
      idUsuarioAtualizacao: this.props.user.idUsuario,
      senha: this.state.senha,
    };

    let senha = CryptoJS.MD5(usuario.senha).toString();
    usuario.senha = senha;

    if(this.state.senha !== this.state.repetirSenha) {
      Notification.danger("As senhas estão diferentes! 'senha' e 'repetir senha' devem ser iguais.");
      return
    }

    Http.put("/usuario/alterar/senha", usuario).then(({ data }) => {
      if (data.status) {
        Notification.success(data.mensagem);
      } else {
        Notification.danger(data.mensagem);
      }
    });
  }

  cadastrar() {
    var template = {
      idGestorCompras: this.state.user.idGestorCompras,
      sobre: this.state.sobre,
      emailCotacaoEnviar: this.state.emailCotacaoEnviar,
      emailCotacaoResposta: this.state.emailCotacaoResposta,
      urlLogo: this.state.urlLogo,
      corPrimaria: this.state.corPrimaria,
    };

    Http.post("/template", template).then(({ data }) => {
      if (data.status) {
        Notification.success(data.mensagem);
      } else {
        Notification.danger(data.mensagem);
      }
    });
  }

  update() {
    var template = {
      idTemplates: this.state.idTemplates,
      sobre: this.state.sobre,
      idGestorCompras: this.state.user.idGestorCompras,
      emailCotacaoEnviar: this.state.emailCotacaoEnviar,
      emailCotacaoResposta: this.state.emailCotacaoResposta,
      idUsuarioAtualizacao: this.state.user.idUsuario,
      urlLogo: this.state.urlLogo,
      corPrimaria: this.state.corPrimaria,
    };

    Http.put("/template", template).then(({ data }) => {
      if (data.status) {
        Notification.success(data.mensagem);
      } else {
        Notification.danger(data.mensagem);
      }
    });
  }

  renderSobre() {
    return (
      <Form id="formCadastroSobre" handlerSubmit={this.submitSobre}>
        <Div col="12">
          <br />
        </Div>

        <Div col="12">
          <Title
            value="Sobre Empresa"
            type="h6"
            paddingLeft="2"
            paddingRight="2"
            paddingTop="3"
          />
          <br />
        </Div>

        <Div col="12">
          <TextAreaCustom
            col="12"
            value={this.state.sobre}
            handleChance={(e) => {
              this.setState({ sobre: e });
            }}
          />
        </Div>

        <Div col="12">
          <br />
        </Div>

        <SectionButtonForm>
          <Button
            btn={Color.NODE.PRIMARY}
            value={this.state.nameButton}
            col="1"
            margin="1"
          />
        </SectionButtonForm>
      </Form>
    );
  }

  renderEmail() {
    return (
      <Form id="formCadastroEmail" handlerSubmit={this.submitEmail}>
        <Div col="12">
          <Title
            value="E-mail Envio Cotação"
            type="h6"
            paddingLeft="2"
            paddingRight="2"
            paddingTop="3"
          />
          <br />
        </Div>

        <Row col="12">
          <InputDefault
            disabled
            label="TAG: Inicío Vigência"
            name="tag"
            responsive="4"
            value="{{iniciovigencia}}"
          />
          <InputDefault
            disabled
            label="TAG: Fim Vigência"
            name="tag"
            responsive="4"
            value="{{fimvigencia}}"
          />
        </Row>

        <Div col="12">
          <TextAreaCustom
            col="12"
            label="E-email Cotação Enviar"
            value={this.state.emailCotacaoEnviar}
            handleChance={(e) => {
              this.setState({ emailCotacaoEnviar: e });
            }}
          />
        </Div>

        <Div col="12">
          <br />
        </Div>

        <Div col="12">
          <Title
            value="E-mail de Pedido"
            type="h6"
            paddingLeft="2"
            paddingRight="2"
            paddingTop="3"
          />
          <br />
          <Row col="12">
            <InputDefault
              disabled
              label="TAG: Inicío Vigência"
              name="tag"
              responsive="4"
              value="{{iniciovigencia}}"
            />
            <InputDefault
              disabled
              label="TAG: Fim Vigência"
              name="tag"
              responsive="4"
              value="{{fimvigencia}}"
            />
          </Row>
        </Div>

        <Div col="12">
          <TextAreaCustom
            col="12"
            label="E-email Pedido"
            value={this.state.emailCotacaoResposta}
            handleChance={(e) => {
              this.setState({ emailCotacaoResposta: e });
            }}
          />
        </Div>

        <Div col="12">
          <br />
        </Div>

        <SectionButtonForm>
          <Button
            btn={Color.NODE.PRIMARY}
            value={this.state.nameButton}
            col="1"
            margin="1"
          />
        </SectionButtonForm>
      </Form>
    );
  }

  renderPersonalizacao() {
    return (
      <Form id="formCadastroPerson" handlerSubmit={this.submitPerson}>
        <Div col="12">
          <br />
        </Div>

        <Div col="12">
          <Title
            value="Personalização"
            type="h6"
            paddingLeft="2"
            paddingRight="2"
            paddingTop="3"
          />
          <br />
        </Div>

        <Row col="12">
          <InputDefault
            label="Url Logo"
            name="urlLogo"
            responsive="4"
            value={this.state.urlLogo}
            handlerChange={(e) => {
              this.setState({ urlLogo: e.target.value });
            }}
          />
          <InputDefault
            label="Cor Primaría"
            name="corPrimaria"
            responsive="4"
            value={this.state.corPrimaria}
            handlerChange={(e) => {
              this.setState({ corPrimaria: e.target.value });
            }}
          />
        </Row>

        <SectionButtonForm>
          <Button
            btn={Color.NODE.PRIMARY}
            value={this.state.nameButton}
            col="1"
            margin="1"
          />
        </SectionButtonForm>
      </Form>
    );
  }

  renderConfig() {
    return (
      <Form id="formConfig" handlerSubmit={this.submitConfig}>
        <Div col="12">
          <br />
        </Div>

        <Div col="12" paddingLeft="4">
          <Title
            value="Redefinir Senha"
            type="h6"
            paddingLeft="2"
            paddingRight="2"
            paddingTop="3"
          />
          <br />
        </Div>

        <Row col="12" paddingBottom="5">
          <InputDefault
            label="Nova Senha"
            name="senha"
            password
            responsive="4"
            value={this.state.senha}
            handlerChange={(e) => {
              this.setState({ senha: e.target.value });
            }}
          />
          <InputDefault
            label="Repetir Senha"
            password
            name="repetirSenha"
            responsive="4"
            value={this.state.repetirSenha}
            handlerChange={(e) => {
              this.setState({ repetirSenha: e.target.value });
            }}
          />

          <SectionButtonForm col="4">
            <Button
              btn={Color.NODE.PRIMARY}
              value={this.state.nameButton}
              style={{ width: "120px", marginTop: "28px" }}
            />
          </SectionButtonForm>
        </Row>
      </Form>
    );
  }

  render() {
    let { target } = this.state.itemActive;

    return (
      <Div
        margin="3"
        shadow
        bg={Color.NODE.WHITE}
        borderLeft={Color.NODE.PRIMARY}
      >
        <Row col="12">
          <InputDefault
            disabled
            label="Nome"
            name="nome"
            responsive="4"
            value={this.state.nome}
          />
          <InputDefault
            disabled
            label="Login"
            name="login"
            responsive="4"
            value={this.state.login}
          />
          <InputDefault
            disabled
            label="E-mail"
            name="email"
            responsive="4"
            value={this.state.email}
          />
        </Row>

        <Row col="12">
          <InputDefault
            disabled
            label="Tipo"
            name="tipo"
            responsive="4"
            value={this.state.tipo}
          />
          <InputDefault
            disabled
            label="Cargo"
            name="cargo"
            responsive="4"
            value={this.state.cargo}
          />
          <InputPhone
            disabled
            label="Telefone"
            name="telefone"
            responsive="4"
            value={this.state.telefone}
          />
        </Row>

        <Title
          value="Dados do Gestor"
          type="h4"
          paddingLeft="4"
          paddingRight="4"
          paddingTop="4"
        />

        <Row col="12">
          <InputDefault
            disabled
            label="Descrição"
            name="gestor"
            responsive="4"
            value={this.state.gestor}
          />
          <InputDefault
            disabled
            label="Nome Gestor"
            name="nomePessoa"
            responsive="4"
            value={this.state.nomePessoa}
          />
          <InputCpfCnpj
            disabled
            label="CNPJ"
            name="cpfcnpj"
            responsive="4"
            value={this.state.cpfcnpj}
          />
        </Row>
        <Row col="12">
          <InputDefault
            disabled
            label="Tipo Cadastro"
            name="tipocadastro"
            responsive="4"
            value={this.state.tipocadastro}
          />
          <InputDefault
            disabled
            label="E-mail Gestor"
            name="emailprincipal"
            responsive="4"
            value={this.state.emailprincipal}
          />
          <InputPhone
            disabled
            label="Telefone Gestor"
            name="telefoneprincipal"
            responsive="4"
            value={this.state.telefoneprincipal}
          />
        </Row>

        <Title
          value="Templates"
          type="h4"
          paddingLeft="4"
          paddingRight="4"
          paddingTop="3"
        />

        <Div>
          <Div col="12">
            <br />
          </Div>

          <Div className="w-100">
            <NavbarTab
              itens={this.getItens()}
              handlerClick={(itemActive) => {
                this.setState({ itemActive });
              }}
            />
          </Div>
          <If and value1={target === "SOBRE"}>
            {this.renderSobre()}
          </If>
          <If and value1={target === "E-MAIL"}>
            {this.renderEmail()}
          </If>
          <If and value1={target === "PERSONALIZACAO"}>
            {this.renderPersonalizacao()}
          </If>

          <If and value1={target === "CONFIGURAÇÃO"}>
            {this.renderConfig()}
          </If>
        </Div>
      </Div>
    );
  }
}

const mapStateToProps = (store) => ({
  user: store.loginState.user,
});

export default connect(mapStateToProps)(Detalhe);
