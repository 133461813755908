export default class EndPoints {
  static IFOOD = {
    CATEGORIA_LISTAR: "/cardapioifood/categorias",
    CATEGORIA_CADASTRAR: "cardapioifood/categoria/cadastrar",
    CATEGORIA_ATUALIZAR: "cardapioifood/categoria/atualizar",
    CATEGORIAS_ATUALIZAR: "cardapioifood/categorias/atualizar",
    CATEGORIA_DELETAR: "/cardapioifood/categoria/deletar",
    CATEGORIA_COMPLEMENTO_DELETAR:
      "/complementocardapioifood/categoria/deletar",
    ITEM_ALTERAR_PRECO: "/cardapioifood/item/alterarPreco",
    ITEM_PAUSAR: "/cardapioifood/item/pausar",
    ITEM_LISTAR: "/cardapioifood/categoria/itens",
    ITEM_DELETAR: "/cardapioifood/item/deletar",
    ITEM_ALTERAR_ORDEM: "/cardapioifood/itens/alterarOrdem",
    COMPLEMENTO_ALTERAR_PRECO: "/cardapioifood/complemento/alterarPreco",
    COMPLEMENTO_DELETAR: "/complementocardapioifood/complemento/deletar",
    DASHBOARD_TOTALIZADORES: "/pedidoappdashboard/totalizadores",
    DASHBOARD_CANCELAMENTOS: "/pedidoappdashboard/cancelamentos",
    DASHBOARD_PRODUTO_MAIS_VENDIDO: "/pedidoappdashboard/produtomaisvendido",
    DASHBOARD_PEDIDOS: "/pedidoappdashboard/pedidos",
    DASHBOARD_CLIENTES_TOP5: "/pedidoappdashboard/clientes/top5",
    DASHBOARD_CLIENTES_NOVOS: "/pedidoappdashboard/clientes/novos",
    DASHBOARD_RECEBIMENTOS_TOP5: "/pedidoappdashboard/recebimentos/top5",
    DASHBOARD_PRODUTOS_TOP5: "/pedidoappdashboard/produtos/top5",
    DIAGNOSTICO_SINCRONIZAR_PRODUTOS: "/pedidoapp/diagnostico/produto/sincronizar",
    PEDIDO_AVALIACAO: "/pedidoifood/avaliacao/responder",
    AVALIACAO_LISTAR: "/pedidoapp/avaliacoes/",
    LISTAR_PEDIDOS_FINANCEIRO: "/pedidoappfinanceiro/pedidos",
    RESTAURANTE_LISTAR_PAUSAS_ATIVAS : "/restauranteapp/pausas/ativas",
    RESTAURANTE_LISTAR_PAUSAS_TODAS : "/restauranteapp/pausas",
    LISTAR_PEDIDOS_APP: "/pedidoapp/pedidos"
  };

  static PRODUTO = {};
}
