import React from "react";

const close = (props) => (
  <svg {...props} x="0px" y="0px" viewBox="0 0 24 24" fill="#000000">
    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      {" "}
      <title></title>{" "}
      <g id="Complete">
        {" "}
        <g id="minus">
          {" "}
          <line
            fill="none"
            stroke="#000000"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            x1="4"
            x2="20"
            y1="12"
            y2="12"
          ></line>{" "}
        </g>{" "}
      </g>{" "}
    </g>
  </svg>
);

const plusIcon = (props) => (
  <svg
    {...props}
    x="0px"
    y="0px"
    fill="#000000"
    height="200px"
    width="200px"
    version="1.1"
    id="Layer_1"
    viewBox="0 0 512.005 512.005"
  >
    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      {" "}
      <g>
        {" "}
        <g>
          {" "}
          <path d="M388.418,240.923L153.751,6.256c-8.341-8.341-21.824-8.341-30.165,0s-8.341,21.824,0,30.165L343.17,256.005 L123.586,475.589c-8.341,8.341-8.341,21.824,0,30.165c4.16,4.16,9.621,6.251,15.083,6.251c5.461,0,10.923-2.091,15.083-6.251 l234.667-234.667C396.759,262.747,396.759,249.264,388.418,240.923z"></path>{" "}
        </g>{" "}
      </g>{" "}
    </g>
  </svg>
);

const minusIcon = (props) => (
  <svg
    {...props}
    x="0px"
    y="0px"
    fill="#000000"
    height="200px"
    width="200px"
    version="1.1"
    viewBox="0 0 512.011 512.011"
  >
    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      {" "}
      <g>
        {" "}
        <g>
          {" "}
          <path d="M505.755,123.592c-8.341-8.341-21.824-8.341-30.165,0L256.005,343.176L36.421,123.592c-8.341-8.341-21.824-8.341-30.165,0 s-8.341,21.824,0,30.165l234.667,234.667c4.16,4.16,9.621,6.251,15.083,6.251c5.462,0,10.923-2.091,15.083-6.251l234.667-234.667 C514.096,145.416,514.096,131.933,505.755,123.592z"></path>{" "}
        </g>{" "}
      </g>{" "}
    </g>
  </svg>
);

export { close, plusIcon, minusIcon };
