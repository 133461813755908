import React, { Component } from 'react'
import {NavbarTop} from '../../components'
import Id from '../../utilities/enuns/Id'

class Footer extends Component {

    render() {
        return (
            <NavbarTop title="Título da página" id={Id.HTML.NAVBARTOP} idnavbar={Id.HTML.NAVBAR} stylle="light" light />
        )
    }

}

export default Footer