import React, { Component } from "react";
import { Http } from "../../utilities";

import {
  Row,
  InputDefault,
  InputNumberBR,
  Form,
  InputSearch,
  Title,
  Notification,
  ModalNotification,
  Select,
  SelectValue,
  Div,
  ModalCustom,
} from "../../components";
import { connect } from "react-redux";
import ArvoreGrupos from "./ArvoreGrupos";

class Cadastrar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      nameButton: "Salvar",
      departamentos: [],
      user: props.user,
      dialoggrupo: false,
      closeModal: false,
      labelModal: "Adicionar Nova Seção",
      departamentosGrupos: [],
      grupos: [],
      gruposFilter: [],
      grupo: {},
      selectedgrupo: {},
      globalFilter: null,
      idDepartamento: undefined,

      editarDepart: false,

      ...this.props.location.state,
    };

    this.submit = this.submit.bind(this);
    this.submitDepartamento = this.submitDepartamento.bind(this);
    this.setStateTree = this.setStateTree.bind(this);
    this.setCloseModal = this.setCloseModal.bind(this);
    this.editarGrupo = this.editarGrupo.bind(this);
    this.addDepartamento = this.addDepartamento.bind(this);
    this.editarDepartamento = this.editarDepartamento.bind(this);
    this.deletarDepartamento = this.deletarDepartamento.bind(this); 
    this.modalDeleteDepart = this.modalDeleteDepart.bind(this);
    this.deletarGrupo = this.deletarGrupo.bind(this);
    this.delete = this.delete.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.getDataListaGrupos();
    this.getDataDepartamentos();
  }

  setStateTree(props) {
    this.setState(props);
  }

  async getDataListaGrupos() {
    this.setState({ grupos: [] });
    await Http.get(
      `/departamento/listarDepartamentoGrupos/` +
        this.state.user.idGestorCompras
    )
      .then(({ status, data }) => {
        if (data) {
          this.setState({ grupos: data, gruposFilter: data });
        }
      })
      .catch((error) => console.log(error));
  }

  getDataDepartamentos() {
    this.setState({ departamentos: [] });
    Http.get(`/departamento/` + this.state.user.idGestorCompras).then(
      ({ data }) => {
        if (data) {
          let depComDados = [];
          depComDados.push(new SelectValue(0, "SELECIONE", true));
          Array.from(data).map((dep) => {
            depComDados.push(
              new SelectValue(dep.idDepartamento, dep.descricao, false)
            );
          });
          this.setState({ departamentos: depComDados });
        }
      }
    );
  }

  submit(e) {
    // e.preventDefault();

    if (document.forms["formCadastroGrupo"].reportValidity()) {
      if (this.state.editarDepart) {
        this.submitDepartamento();
      }

      if (!this.state.editarDepart) {
        let objGrupo = this.objGrupo()

        if (this.state.idGrupo === undefined) {
          this.cadastrar(objGrupo);
        } else {
          this.update(objGrupo);
        }
      }

      return true;
    }

    return false;
  }

  cadastrar(grupo) {
    grupo.idGestorCompras = this.state.user.idGestorCompras;
    Http.post("/grupo", grupo).then(({ data }) => {
      if (data.status) {
        Notification.success(data.mensagem);
        this.cancelar();
        this.getDataListaGrupos();
      } else {
        Notification.danger(data.mensagem);
      }
    });
  }

  update(grupo) {
    grupo.idUsuarioAtualizacao = this.state.user.idUsuario;
    Http.put("/grupo", grupo).then(({ data }) => {
      if (data.status) {
        Notification.success(data.mensagem);
        this.cancelar();
        this.getDataListaGrupos();
      } else {
        Notification.danger(data.mensagem);
      }
    });
  }

  cadastrarDeparmento(departamento) {
    departamento.idGestorCompras = this.state.user.idGestorCompras;
    Http.post("/departamento", departamento).then(({ data }) => {
      if (data.status) {
        this.cancelar();
        Notification.success(data.mensagem);
        this.getDataDepartamentos();
        this.getDataListaGrupos();
      } else {
        Notification.danger(data.mensagem);
      }
    });
  }

  updateDeparmento(departamento) {
    departamento.idUsuarioAtualizacao = this.state.user.idUsuario;
    Http.put("/departamento", departamento).then(({ data }) => {
      if (data.status) {
        this.cancelar();
        Notification.success(data.mensagem);
        this.getDataDepartamentos();
        this.getDataListaGrupos();
      } else {
        Notification.danger(data.mensagem);
      }
    });
  }

  objDepartamento(ativo) {
    const { idDepartamento, descricao, margemPrevista } = this.state;
    return {
      idDepartamento,
      descricao,
      margemPrevista,
      idGestorCompras: this.state.user.idGestorCompras,
      ativo
    };
  }

  objGrupo() {
    const { idDepartamento, idGrupo, descricao, margemPrevista } = this.state;
    return {
      idDepartamento,
      idGrupo,
      descricao,
      margemPrevista,
      idGestorCompras: this.state.user.idGestorCompras,
    };
  }

  submitDepartamento() {
    let objDepartamento = this.objDepartamento(true);

    if (this.state.idDepartamento === undefined) {
      this.cadastrarDeparmento(objDepartamento);
    } else {
      this.updateDeparmento(objDepartamento);
    }
  }

  leftToolbarTemplate = () => {
    return (
      <InputSearch
        label="Procurar na Grid"
        name="globalFilter"
        value={this.state.globalFilter}
        handlerChange={(e) => this.setState({ globalFilter: e.target.value })}
        placeholder="Procurar..."
      />
    );
  };

  rightToolbarTemplate = () => {
    return <span className="p-input-icon-right"></span>;
  };

  exportCSV() {}

  editarGrupo(grupo) {
    this.setState({
      nameButton: "Alterar",
      idGrupo: grupo.idGrupo,
      idDepartamento: grupo.idDepartamento,
      descricao: grupo.descricao,
      margemPrevista: grupo.margemPrevista,
      closeModal: true,
      editarDepart: false,
      labelModal: "Editar Seção " + grupo.descricao,
    });
  }

  deletarGrupo(grupo) {
    if (grupo.sequencia === "1") {
      Notification.danger("Essa Seção não Pode Ser Inativada!");
      return;
    }

    ModalNotification.confirm(
      "Deseja desativar esta Seção? " + grupo.descricao,
      "Esta Seção será desativada no sistema de compras!",
      () => this.delete(grupo),
      () => this.cancelar()
    );
  }

  async delete(grupo) {
    grupo.idUsuarioInativacao = this.state.user.idUsuario;
    await Http.delete(`/grupo`, grupo)
      .then(({ status, data }) => {
        if (data.status) {
          Notification.success(data.mensagem);
          this.getDataListaGrupos();
        } else {
          Notification.danger(data.mensagem);
        }
      })
      .catch((error) => console.log(error));
  }

  cancelar() {
    this.setState({
      nameButton: "Salvar",
      idGrupo: undefined,
      idDepartamento: undefined,
      descricao: "",
      margemPrevista: "",
    });
  }

  setCloseModal(boolean, idDepartamento = undefined) {
    this.setState({
      closeModal: boolean,
      labelModal: "Adicionar Nova Seção",
      idDepartamento: idDepartamento,
      editarDepart: false,
    });
  }

  addDepartamento() {
    this.setState({
      closeModal: true,
      editarDepart: true,
      labelModal: "Adicionar Novo Departamento",
      idDepartamento: undefined,
    });
  }

  editarDepartamento(departamento) {
    this.setState({
      closeModal: true,
      editarDepart: true,
      labelModal: "Editar Departamento " + departamento.descricao,
      idDepartamento: departamento.idDepartamento,
      descricao: departamento.descricao,
      margemPrevista: departamento.margemPrevista
    });
  }

  deletarDepartamento(departamento) {
    if (departamento.sequencia === "1") {
      Notification.danger("Essa Seção não Pode Ser Inativado!");  
      return 
    }
    ModalNotification.confirm(
      "Deseja desativar este Departamento? " + departamento.descricao,
      "Este Departamento será desativada no sistema de compras!",
      () => this.modalDeleteDepart(departamento),
      () => this.cancelar()
    );
  }

  modalDeleteDepart(departamento) {
    departamento.idUsuarioInativacao = this.state.user.idUsuario;
    Http.delete(`/departamento`, departamento)
      .then(({ status, data }) => {
        if (data.status) {
          Notification.success(data.mensagem);
          this.getDataDepartamentos();
        } else {
          Notification.danger(data.mensagem);
        }
      })
      .catch((error) => console.log(error));
  }

  render() {
    return (
      <>
        <ModalCustom
          title={this.state.labelModal}
          showModal={this.state.closeModal}
          setShowModal={this.setCloseModal}
          confirmSubmit={(e) => this.submit(e)}
        >
          <Div
            col="12"
            style={{ overflow: "auto", maxHeight: "75vh", padding: 0 }}
          >
            <Form id="formCadastroGrupo">
              <Row col="12">
                {!this.state.editarDepart && (
                  <Select
                    required
                    disabled
                    responsive="3"
                    label="Departamentos"
                    valueSelected={this.state.idDepartamento}
                    // handlerChange={(e) => {
                    //   this.setState({ idDepartamento: e.target.value });
                    // }}
                    values={this.state.departamentos}
                  />
                )}

                <InputDefault
                  required
                  label="Descrição"
                  name="descricao"
                  maxLength="200"
                  responsive="7"
                  value={this.state.descricao}
                  handlerChange={(e) => {
                    this.setState({ descricao: e.target.value });
                  }}
                />
                <InputNumberBR
                  required
                  label="Margem Prevista"
                  name="margemPrevista"
                  maxLength="200"
                  responsive="2"
                  value={this.state.margemPrevista}
                  handlerChange={(e) => {
                    this.setState({ margemPrevista: e.target.value });
                  }}
                />
              </Row>
            </Form>
          </Div>
        </ModalCustom>
        <Div rounded margin="3" padding="4" shadow>
          <Div>
            <Title
              value="Departamento e Seção"
              type="h4"
              paddingLeft="4"
              paddingRight="4"
              paddingTop="3"
            />

            <Div padding="2">
              <ArvoreGrupos
                deletarDepartamento={this.deletarDepartamento}
                deletarSecao={this.deletarGrupo}
                grupos={this.state.grupos}
                gruposFilter={this.state.gruposFilter}
                setCloseModal={this.setCloseModal}
                editarGrupo={this.editarGrupo}
                addDepartamento={this.addDepartamento}
                editarDepartamento={this.editarDepartamento}
                setState={this.setStateTree}
              />
            </Div>
          </Div>
        </Div>
      </>
    );
  }
}

const mapStateToProps = (store) => ({
  user: store.loginState.user,
});

export default connect(mapStateToProps)(Cadastrar);
