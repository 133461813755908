import React, { Component } from "react";
import {
  Div,
  Table,
  InputSearch,
  Title,
  Notification,
  ModalNotification,
  SelectValue,
} from "../../components";
import { connect } from "react-redux";
import { Color, PathRoute, Http, Type, Utils } from "../../utilities";
import TemplateFilterProdutos from "./template/TemplateFilterProdutos";

class Listar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: props.user,
      dialogRede: false,
      produtos: [],
      produto: {},
      selectedProdutos: {},
      globalFilter: null,
      ...this.props.location.state,

      produtosSlice: [],
      filtros: {
        departamentos: [new SelectValue(0, "SELECIONE", true)],
        grupos: [new SelectValue(0, "SELECIONE", true)],
      },
      departamentos: [],
      departamentosSelecionados: [],
      grupos: [],
      idDepartamento: "",
      idGrupo: "",
      tipoPesquisas: [],
      tipoPesquisa:"DESCRICAO",
      idGestorCompras: "",
      paginacao: 0,
    };
  }

  UNSAFE_componentWillMount() {
    this.getDataProdutos(0);
    this.getDataDepartamentos();
    this.getTipoPesquisaSelect();
  }

  getTipoPesquisaSelect() {
    var tp =  [
      new SelectValue("DESCRICAO", "DESCRIÇÃO", true),      
      new SelectValue("EAN", "CÓDIGO DE BARRAS", false),
      new SelectValue("PLU", "PLU", false),
    ];

    this.setState({tipoPesquisas: tp});
  }

  getDataDepartamentos() {
    Http.get(`/departamento/` + this.props.idGestorCompras).then(
      ({ data }) => {
        if (data) {
          let depComDados = [];
          depComDados.push(new SelectValue(0, "SELECIONE", true));
          depComDados.push(new SelectValue("6597f716-7beb-4166-a41a-66ee5fe1b9ea", "TODOS", false));
          Array.from(data).map((dep) => {
            depComDados.push(
              new SelectValue(
                dep.idDepartamento,
                dep.sequencia + " - " + dep.descricao,
                false
              )
            );
          });
          this.setState({
            filtros: {
              ...this.state.filtros,
              departamentos: depComDados,
            },
          });
        }
      }
    );
  }

  getDataGrupos(idDepartamento) {
    Http.get(`/grupo/listargrupopordepartamento/` + idDepartamento).then(
      ({ data }) => {
        if (data) {
          let grupoComDados = [];
          grupoComDados.push(new SelectValue(0, "SELECIONE", true));
          grupoComDados.push(new SelectValue("6597f716-7beb-4166-a41a-66ee5fe1b9ea", "TODOS", false));
          Array.from(data).map((dep) => {
            grupoComDados.push(
              new SelectValue(
                dep.idGrupo,
                dep.sequenciaDepartamentoGrupo + " - " + dep.descricao,
                false
              )
            );
          });
          this.setState({
            filtros: {
              ...this.state.filtros,
              grupos: grupoComDados,
            },
          });
        }
      }
    );
  }

  async getDataProdutos(paginacao) {
    await Http.get(`/produto/listar/` + this.state.user.idGestorCompras + '/paginacao/' + paginacao)
      .then(({ status, data }) => {
        if (data) {
          this.setState({ produtos: data });
        }
      })
      .catch((error) => console.log(error));
  }

  leftToolbarTemplate = () => {
    return (
      <InputSearch
        label="Procurar na Grid"
        name="globalFilter"
        value={this.state.globalFilter}
        handlerChange={(e) => this.setState({ globalFilter: e.target.value })}
        placeholder="Procurar..."
      />
    );
  };

  rightToolbarTemplate = () => {
    return <span className="p-input-icon-right"></span>;
  };

  exportCSV() {}

  editarProduto(produto) {
    this.props.history.push({
      pathname: PathRoute.PATH.PRODUTO_EDITAR,
      state: { produto: produto, editar: true },
    });
  }

  detalharProduto(produto) {
    alert(produto.descricao);
  }

  deletarProduto(produto) {
    ModalNotification.confirm(
      "Deseja desativar este Produto? " + produto.descricao,
      "Este produto será desativada no sistema de compras!",
      () => this.delete(produto),
      () => this.cancelar()
    );
  }

  async delete(produto) {
    produto.idUsuarioInativacao = this.state.user.idUsuario;
    await Http.post(`/produto/deletar`, produto)
      .then(({ status, data }) => {
        if (data.status) {
          Notification.success(data.mensagem);
          this.getDataProdutos(0);
        } else {
          Notification.danger(data.mensagem);
        }
      })
      .catch((error) => console.log(error));
  }

  cancelar() {}

  handleScroll = (e) => {
    const { produtosSlice, produtos } = this.state;
    let element = e.target;
    if (element.scrollHeight - element.scrollTop === element.clientHeight) {
      let newProdutos = [];
      let start = produtosSlice.length;
      let end =
        produtos.length > 100
          ? produtosSlice.length + 100
          : produtosSlice.length - produtos.length;

      if (produtos.length >= produtosSlice.length) {
        newProdutos = [...produtosSlice, ...produtos.slice(start, end)];

        this.setState({
          produtosSlice: newProdutos,
        });
      }
    }
  };

  async getProdutosFiltros(idDepartamento, idGrupo) {
    this.setState({produtos: [], produtosSlice: []});
    var idgestor = this.state.user.idGestorCompras;
    var paginacao = 0;    

    await Http.get(
      idGrupo
        ? `/produto/filtro/${idDepartamento}/departamento/${idGrupo}/grupo/${idgestor}/paginacao/${paginacao}`
        : `/produto/filtro/${idDepartamento}/departamento/${idgestor}/paginacao/${paginacao}`
    )
      .then(({ status, data }) => {
        if (data) {
          this.setState({
            produtos: data,
            produtosSlice: data.length > 100 ? data.slice(0, 100) : data,
          });
        }
      })
      .catch((error) => console.log(error));
  }


  mudarFiltros(value, type) {
    if (type === "departamento") {
      if (value != 0) {
        this.getDataGrupos(value);
      }
      this.setState(
        {
          idDepartamento: value,
          idGrupo: "",
          produtos: [],
          produtosSlice: [],
        },
        () =>
          value != 0
            ? this.getProdutosFiltros(value, null)
            : {}
      );
    }

    if (type === "grupo") {
      this.setState(
        {
          idGrupo: value,
          paginacao: 0,
          produtos: [],
          produtosSlice: [],
        },
        () => this.listarPedidos()
      );
    }

    if (type === "tpPesquisa") {
      this.setState({tipoPesquisa: value});
    }
  }

  listarPedidos() {
    let { idDepartamento, idGrupo } = this.state;

    if (idDepartamento !== "" && idGrupo !== "") {
      this.getProdutosFiltros(idDepartamento, idGrupo);
    }
  }

  renderFiltros() {
    let { departamentos, grupos } = this.state.filtros;

    let { tipoPesquisas } = this.state;
    return (
      <TemplateFilterProdutos
        departamentos={departamentos}
        grupos={grupos}
        tipoPesquisas={tipoPesquisas}
        handler={(value, type) => this.mudarFiltros(value, type)}
        handlerSearch={(e) => this.getProdutosSearch(e)}
        handlerChange={(e) => this.handlerChangeProduto(e)}
      />
    );
  }

  handlerChangeProduto(e) {
    if (e.target.value === "") {
      this.getDataProdutos(0);
    }
  }

  getProdutosSearch(search) {
    this.setState({produtos: [], produtosSlice: []});
    let busca = { descricao: search, idPesquisa: this.props.idPesquisa, tipoPesquisa: this.state.tipoPesquisa };
    
    if (search.trim() !== "") {
      Http.post(`/pesquisa/produto/listar/resumido`, busca)
        .then(({ data }) => {
          if (data) {
            this.setState({
              produtos: data,
              produtosSlice: data.length > 100 ? data.slice(0, 100) : data,
            });
          }
        })
        .catch((error) => console.log(error));
    }
  }

  render() {
    const {
      produtosSlice,
    } = this.state;

    return (
      <Div
        margin="3"
        shadow
        bg={Color.NODE.WHITE}
        borderLeft={Color.NODE.PRIMARY}
      >
        <Title
          value="Produtos"
          type="h4"
          paddingLeft="4"
          paddingRight="4"
          paddingTop="3"
        />

        {/* <Div padding="2">
          <Table
            columns={[
              { value: "PLU", align: "right" },
              { value: "Descricao", align: "left" },
              { value: "Ean", align: "left" },
              { value: "Departamento", align: "left" },
              { value: "Grupo", align: "left" },
              { value: "Embalagem", align: "left" },
              { value: "Fornecedor Principal", align: "left" },
            ]}
            fields={[
              {
                value: "codigoProduto",
                align: "right",
                type: Type.DADO.STRING,
              },
              { value: "descricao", align: "left", type: Type.DADO.STRING },
              { value: "ean", align: "left", type: Type.DADO.STRING },
              {
                value: "nomeDepartamento",
                align: "left",
                type: Type.DADO.STRING,
              },
              { value: "nomeGrupo", align: "left", type: Type.DADO.STRING },
              { value: "embalagem", align: "left", type: Type.DADO.STRING },
              {
                value: "nomeFornecedorPrincipal",
                align: "left",
                type: Type.DADO.STRING,
              },
            ]}
            bordered
            search
            orientation="center"
            pagination
            rota={PathRoute.PATH.PRODUTO_CADASTRAR}
            edit
            delete
            handlerEdit={(rede) => this.editarProduto(rede)}
            handlerDelete={(rede) => this.deletarProduto(rede)}
            data={this.state.produtos}
            error="Nenhum produto encontrado"
          ></Table>
        </Div> */}

        <Div
          col="12"
          style={{ overflow: "scroll", maxHeight: "75vh" }}
          handlerScroll={(e) => this.handleScroll(e)}
        >
          <Div>
            <Table
              columns={[
                { value: "PLU", align: "right" },
                { value: "Descricao", align: "left" },
                { value: "Código de Barras", align: "left" },
                { value: "Departamento", align: "left" },
                { value: "Grupo", align: "left" },
                { value: "Marca", align: "left" },
                { value: "Embalagem", align: "left" },
                { value: "Fornecedor Principal", align: "left" },
              ]}
              fields={[
                {
                  value: "codigoProduto",
                  align: "right",
                  type: Type.DADO.STRING,
                },
                {
                  value: "descricao",
                  align: "left",
                  type: Type.DADO.STRING,
                },
                { value: "ean", align: "left", type: Type.DADO.STRING },
                {
                  value: "nomeDepartamento",
                  align: "left",
                  type: Type.DADO.STRING,
                },
                {
                  value: "nomeGrupo",
                  align: "left",
                  type: Type.DADO.STRING,
                },
                {
                  value: "nomeMarca",
                  align: "left",
                  type: Type.DADO.STRING,
                },
                {
                  value: "embalagem",
                  align: "left",
                  type: Type.DADO.STRING,
                },
                {
                  value: "nomeFornecedorPrincipal",
                  align: "left",
                  type: Type.DADO.STRING,
                },
              ]}
              bordered
              orientation="center"
              //pagination
              filter={this.renderFiltros()}
              //filterShow={true}
              data={produtosSlice}
              error="Nenhum produto encontrado"
              search
              rota={PathRoute.PATH.PRODUTO_CADASTRAR}
              edit
              delete
              handlerEdit={(rede) => this.editarProduto(rede)}
              handlerDelete={(rede) => this.deletarProduto(rede)}
            ></Table>
          </Div>
        </Div>
      </Div>
    );
  }
}

const mapStateToProps = (store) => ({
  idGestorCompras: store.loginState.user.idGestorCompras,
  idUsuario: store.loginState.user.idUsuario,
});

export default connect(mapStateToProps)(Listar);
