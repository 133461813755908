import React from 'react'

const DropDownMenu = (props) => {

    return (
        <div className="dropdown-menu dropdown-menu-right">
            {props.children}
        </div>
    )

}

export default DropDownMenu 