import moment from "moment";
import { Pattern, DateEnum } from "..";

export default class DateUtils {
  static getDiaDaSemanaAtual(date) {
    switch (date) {
      case 0:
        return "Domingo";
      case 1:
        return "Segunda";
      case 2:
        return "Terça";
      case 3:
        return "Quarta";
      case 4:
        return "Quinta";
      case 5:
        return "Sexta";
      case 6:
        return "Sábado";
      default:
        return "Segunda";
    }
  }

  static getMesAtualLabel = mes => {
    switch (mes) {
      case 1:
        return "Janeiro";
      case 2:
        return "Fevereiro";
      case 3:
        return "Março";
      case 4:
        return "Abril";
      case 5:
        return "Maio";
      case 6:
        return "Junho";
      case 7:
        return "Julho";
      case 8:
        return "Agosto";
      case 9:
        return "Setembro";
      case 10:
        return "Outubro";
      case 11:
        return "Novembro";
      case 12:
        return "Dezembro";
      default:
        return "Janeiro";
    }
  };

  static getMesAtual() {
    return new Date().getMonth() + 1;
  }

  static getDiaDaSemanaPrimeiroDiaDoMes() {
    let mesAtual = new Date().getMonth();
    let anoAtual = new Date().getFullYear();
    return new Date(anoAtual, mesAtual, 1).getDay();
  }

  static getDataFormatada = value => {
    let data = new Date(null);
    let hora = String(value).split(":")[0];
    let minuto = String(value).split(":")[1];
    data.setHours(hora, minuto, "0", "0");

    return moment(data)
      .add(1, "days")
      .format("YYYY-MM-DDTHH:mm:ss.SSSZ");
  };

  static getHoraFormatadaUTC = (date, utc = "-300") => {
    return moment(date).utcOffset(utc);
  };

  static getDataHojeBR() {
    return moment(new Date()).format(Pattern.DATE.DD_MM_YYYY);
  }

  static getDateFormat(date, format) {
    return moment(date).format(format);
  }

  static getDataUltimoDiaMes(
    ano = new Date().getFullYear(),
    mes = new Date().getMonth()
  ) {
    let date = new Date(ano, mes),
      y = date.getFullYear(),
      m = date.getMonth();
    return moment(new Date(y, m + 1, 0)).format(Pattern.DATE.YYYY_MM_DD_HH_MM);
  }

  static getDataPrimeiroDiaMes(
    ano = new Date().getFullYear(),
    mes = new Date().getMonth()
  ) {
    let date = new Date(ano, mes),
      y = date.getFullYear(),
      m = date.getMonth();
    return moment(new Date(y, m, 1)).format(Pattern.DATE.YYYY_MM_DD_HH_MM);
  }

  static getDataDiaMes(dia, mes = new Date().getMonth()) {
    let date = new Date(),
      y = date.getFullYear();
    return moment(new Date(y, mes, dia)).format(Pattern.DATE.YYYY_MM_DD_HH_MM);
  }

  static getDatePlusDays(data, plus) {
    let date = new Date(data);
    date.setDate(date.getDate() + plus);
    return moment(date).format(Pattern.DATE.YYYY_MM_DD);
  }

  static getDateMinusDays(data, minus) {
    let date = new Date(data);
    date.setDate(date.getDate() - minus);
    return moment(date).format(Pattern.DATE.YYYY_MM_DD);
  }

  static getDiaSemana(date) {
    let dia = moment(date).get("DAY");
    switch (dia) {
      case 0:
        return DateEnum.DIAS_SEMANA.DOMINGO;
      case 1:
        return DateEnum.DIAS_SEMANA.SEGUNDA;
      case 2:
        return DateEnum.DIAS_SEMANA.TERCA;
      case 3:
        return DateEnum.DIAS_SEMANA.QUARTA;
      case 4:
        return DateEnum.DIAS_SEMANA.QUINTA;
      case 5:
        return DateEnum.DIAS_SEMANA.SEXTA;
      case 6:
        return DateEnum.DIAS_SEMANA.SABADO;
      default:
        return null;
    }
  }

  static getAnosInterval(interval = 2) {
    let ano = new Date().getFullYear();
    let anos = [ano];

    for (let index = 1; index <= interval; index++) {
      anos.push(ano - index);
      anos.push(ano + index);
    }

    return anos.sort();
  }

  static getAnoAtual() {
    return new Date().getFullYear();
  }

  static getDiaAtual() {
    return moment(new Date()).format('DD')
  }

}
