import React, { Component } from "react";
import { Div, Table, Button, Title, Checkbox, ButtonIconOutLine, Notification } from "../../components";
import { connect } from "react-redux";
import { Color, PathRoute, Http, Type, Mask, Utils } from "../../utilities";

const ENTER = 13;

class Listar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: props.user,
      produtos: [],
      produtosSlice: [],
      pessoa: {},
      produtosSelecionados: [],
      produtosInseridosSelecionados: [],
      selecionarTodos: false,
      ...this.props.location.state,
    };

  }

  UNSAFE_componentWillMount() {
    var user = this.props.user;

    if (user.idPessoa != null) {
      this.getDataCotacao(user.idPessoa);
    }
  }


  async getDataCotacao(idPessoa) {
    await Http.get(`/cotacao/produtos/idpessoa/` + idPessoa)
      .then(({ status, data }) => {
        if (data) {
          Array.from(data).map((prod) => {
            prod.selected = false
          });
          this.setState({
            produtos: data,
            produtosSlice: data.length > 100 ? data.slice(0, 100) : data,
            produtosSelecionados: [],
            produtosInseridosSelecionados: [],
          });
        }
      })
      .catch((error) => console.log(error));
  }

  inserirProdutosPesquisa() {

    let newProdutos = [];
    let valido = true

    this.state.produtosSelecionados.map(item => {

      if (!parseFloat(Mask.convertNumberDecimal(item.valorCotacao))) {
        Notification.danger("Informe todos os preços dos produtos!");
        valido = false
        return
      }

      if (!Utils.isNotNull(item.prazoentrega) || item.prazoentrega === "0") {
        Notification.danger("Informe um prazo de entrega em dias!");
        valido = false
        return
      }

      if (!Utils.isNotNull(item.quantidademinimapedido) || item.quantidademinimapedido === "0") {
        Notification.danger("Informe a quantidade minima do pedido!");
        valido = false
        return
      }

      if (!Utils.isNotNull(item.quantidadedisponivel) || item.quantidadedisponivel === "0") {
        Notification.danger("Informe a quantidade disponivel do pedido!");
        valido = false
        return
      }
    })

    this.state.produtosSelecionados.forEach((item, index) => {

      let obj = {
        idPessoa: this.props.user.idPessoa,
        idGestorCompras: this.props.user.idGestorCompras,
        idProduto: item.idProduto,
        preco: parseFloat(Mask.convertNumberDecimal(item.valorCotacao)),
        quantidadepedido: item.quantidadesolicitada,
        quantidadesolicitada: item.quantidadesolicitada,
        quantidadedisponivel: item.quantidadedisponivel,
        quantidademinimapedido: item.quantidademinimapedido,
        prazoentrega: item.prazoentrega,
        idUsuarioAtualizacaoFornecedor: this.props.user.idUsuario,
        obsfornecedor: item.obsfornecedor
      };

      newProdutos.push(obj);

    });

    if (valido) {
      if (Utils.isArrayNotEmpty(newProdutos)) {
        Http.put("/cotacao/produtos/valores/", newProdutos)
          .then(({ status, data }) => {
            if (data.status) {
              this.getDataCotacao(this.props.user.idPessoa)
              Notification.success(data.mensagem);
            } else {
              Notification.danger(data.mensagem);
            }
          })
          .catch((error) => console.log(error));

        this.setState({
          selecionarTodos: false,
        });
      }
    }


  }

  handleScroll = (e) => {
    const { produtosSlice, produtos } = this.state;
    let element = e.target;
    if (element.scrollHeight - element.scrollTop === element.clientHeight) {
      let newProdutos = [];
      let start = produtosSlice.length;
      let end =
        produtos.length > 100
          ? produtosSlice.length + 100
          : produtosSlice.length - produtos.length;

      if (produtos.length >= produtosSlice.length) {
        newProdutos = [...produtosSlice, ...produtos.slice(start, end)];

        this.setState({
          produtosSlice: newProdutos,
        });
      }
    }
  };

  handlerInputProdutoPreco(data) {
    this.setState({
      InputProdutoPreco: data
    })
  }


  render() {

    const { user, produtosSlice, produtosSelecionados } = this.state;

    return (
      <Div margin="3" shadow bg={Color.NODE.WHITE} borderLeft={Color.NODE.PRIMARY}>

        <Div padding="2">
          <Title value={user.nome} type="h4" paddingLeft="4" paddingRight="4" paddingTop="3" />
        </Div>

        <Div
          col="12"
          style={{ overflow: "scroll", maxHeight: "calc(100vh - 249px)" }}
          handlerScroll={(e) => this.handleScroll(e)}
        >
          <Table
            columns={[
              { value: "PLU", align: "center" },
              { value: "Descricao", align: "left" },
              { value: "Código de Barras", align: "center" },
              { value: "Embalagem", align: "center" },
              { value: "Qtd Solicitada", align: "center" },
              { value: "Preço Unitário", align: "center" },
              { value: "Prazo de Entrega (dias)", align: "center" },
              { value: "Qtd Mínima", align: "center" },
              { value: "Qtd Disponível", align: "center" },
              { value: "Observação", align: "center" }
            ]}
            fields={[
              {
                value: "codigoproduto",
                align: "right",
                type: Type.DADO.STRING,
              },
              {
                value: "descricao",
                align: "left",
                type: Type.DADO.STRING,
              },
              { value: "ean", align: "left", type: Type.DADO.STRING },
              {
                value: "embalagem",
                align: "center",
                type: Type.DADO.STRING,
                width: "100px",
              },
              {
                value: "quantidadesolicitada",
                align: "left",
                type: Type.DADO.INTEGER,
                width: "80px",
              },
              {
                input: true,
                typeInput: "money",
                value: "valorCotacao",
                align: "right",
                width: "90px",
              },
              {
                input: true,
                typeInput: "integer",
                value: "prazoentrega",
                align: "right",
                width: "80px",
              },
              {
                input: true,
                typeInput: "integer",
                value: "quantidademinimapedido",
                align: "right",
                width: "80px",
              },
              {
                input: true,
                typeInput: "integer",
                value: "quantidadedisponivel",
                align: "right",
                width: "80px",
              },
              {
                input: true,
                typeInput: "text",
                value: "obsfornecedor",
                align: "right",
                width: "280px",
              }
            ]}
            detail
            iconDeDetail={Type.ICON.ERASER}
            detailTitle={"Limpar Campos"}
            colorDetail={"#000"}
            handlerDetail={(data) => {
              this.handlerInputProdutoPreco(data)
              data.quantidadedisponivel = ""
              data.quantidademinimapedido = ""
              data.valorCotacao = ""
              data.prazoentrega = ""
              data.obsfornecedor = ""
              data.selected = false;
            }}
            bordered
            orientation="center"
            data={produtosSlice}
            error="Nenhuma cotação para precificar!"
            handlerChangeInput={(data) => {
              this.handlerInputProdutoPreco(data)
              let newProdutosSelecionados = produtosSelecionados;

              if (!data.quantidademinimapedido) {
                data.quantidademinimapedido = data.quantidadesolicitada
              }

              if(!data.quantidadedisponivel) {
                data.quantidadedisponivel = data.quantidadesolicitada
              }

              if (
                data.quantidadedisponivel > 0
                || data.quantidademinimapedido > 0
                || (data.valorCotacao !== "0,00" && data.valorCotacao)
                || data.prazoentrega > 0
                || data.obsfornecedor
              ) {
                data.selected = true;
              } else {
                data.selected = false;
              }
              let produtoEncontrado = produtosSelecionados.filter(
                (item) => {
                  return item.idProduto === data.idProduto
                }
              );

              if (data.selected) {
                if (!Utils.isNotArrayEmpty(produtoEncontrado)) {
                  newProdutosSelecionados.push(data);
                }
              }
              if (!data.selected) {
                if (Utils.isNotObjectEmpty(produtoEncontrado)) {
                  let index = newProdutosSelecionados.indexOf(data)
                  newProdutosSelecionados.splice(index, 1)
                }
              }

              this.setState({
                produtosSelecionados: [...newProdutosSelecionados],
              });

            }}
          ></Table>
        </Div>
        <Div col="12" inline="end">
          <ButtonIconOutLine
            value="Salvar"
            btn={Color.NODE.PRIMARY}
            handlerClick={() => this.inserirProdutosPesquisa()}
          />
        </Div>

        <Div>
          <br />
        </Div>
      </Div>
    );
  }
}

const mapStateToProps = (store) => ({
  idPessoa: store.loginState.user.idPessoa,
});

export default connect(mapStateToProps)(Listar);
