import React, { Component } from "react";
import { Div, Button } from "../../components";
import "./modal.css";

class Modal extends Component {
  DBLOCK = "d-block";
  ID_MODAL = "modal-mobioh";

  constructor(props) {
    super(props);

    this.state = {
      ...props,
    };

    this.closed = props.closed || false;
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({
      ...props,
    });
  }

  getClassModal = () => {
    let classe = "modal ";
    classe = classe.concat(this.props.show ? this.DBLOCK : "d-none");
    return classe;
  };

  onDismiss() {
    document.getElementById(this.ID_MODAL).classList.toggle(this.DBLOCK);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.closed === false;
  }

  componentDidUpdate(props, state) {
    if (!this.closed) {
      this.onDismiss();
    }
  }

  cancel(event) {
    this.onDismiss();
    if (this.props.handlerCancel) {
      this.props.handlerCancel(event);
    }
  }

  confirm(event) {
    this.onDismiss();
    this.props.handlerConfirm(event);
  }

  render() {
    return (
      <Div className={this.getClassModal()} id={this.ID_MODAL}>
        <Div
          inline="center"
          className="vertical-center table-wrapper-scroll-y my-custom-scrollbar mx-auto"
          responsive="6"
        >
          <Div className="modal-content">
            <Div className="modal-header">
              <h5 className="modal-title">{this.props.title}</h5>
              <Button className="close" handlerClick={(e) => this.cancel(e)}>
                <span aria-hidden="true">&times;</span>
              </Button>
            </Div>
            <Div className="modal-body">
              {this.state.body || this.state.children}
            </Div>
            <Div className="modal-footer ">
              <Button
                handlerClick={(e) => this.cancel(e)}
                value="Cancelar"
                btn="secondary"
              />
              <Button
                handlerClick={(e) => this.confirm(e)}
                value="Confirmar"
                btn="primary"
              />
            </Div>
          </Div>
        </Div>
      </Div>
    );
  }
}

export default Modal;
