import React, { Component } from "react";
import { Div, Table, InputSearch, Button, Title, Checkbox, Notification} from "../../components";
import { connect } from "react-redux";
import { Color,  Http, Type, Utils} from "../../utilities";

const ENTER = 13;

class Listar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: props.user,
      dialogPessoa: false,
      fornecedores: [],
      pessoa: {},
      selectedPessoa: {},
      cotacoes: [],
      produtosInseridos: [],
      cotacaoSelecionados: [],
      fornecedorSelecionados: [],
      globalFilter: null,
      selecionarTodos: false,
      selecionarTodosFornec: false,
      divCotacao: false,
      divFornecedor: false,
      divProduto: false,
      ...this.props.location.state,
    };
    
  }

  UNSAFE_componentWillMount() {
    this.getDataCotacao();
  }


  leftToolbarTemplate = () => {
    return (
      <InputSearch                           
      label="Procurar na Grid"
      name="globalFilter"              
      value={this.state.globalFilter}
      handlerChange={(e) => this.setState({ globalFilter: e.target.value })}
      placeholder="Procurar..."
    />
    )
  }

  rightToolbarTemplate = () => {
    return (      
        <span className="p-input-icon-right">           


        </span>      
    )
  }

  exportCSV () {

  }


  cancelar() {
    
  }

  async getDataCotacao() {
    this.setState({cotacoes: []});
    await Http.get(`/cotacao/gestorAberta/` + this.state.user.idGestorCompras)
    .then(({ status, data }) => {
      if (data) {
        Array.from(data).map((pre) => {
          pre.nameStatus = this.enumStatusPesquisa(pre.status);
        });
        this.setState({divCotacao: true, cotacoes: data });
      }
    })
    .catch((error) => console.log(error));
  }

  enumStatusPesquisa(status) {
    switch (status) {
      case "ABERTA":
        return "ABERTA";

      case "CONCLUIDO":
        return "CONCLUÍDO";

      case "CANCELADO":
        return "CANCELADO";
        
      case "ENVIADO_FORNECEDOR":
        return "ENVIADO P/ FORNECEDOR";

      case "AGUARDANDO_APURACAO":
        return "AGUARDANDO APURAÇÃO";

      default:
        return null;
    }
  }

  getDataFornecedorCotacao(cotacaoSelecionados) {
    this.setState({ divFornecedor: false, fornecedores: [] });
    
    var idsCotacao = "";
    Array.from(cotacaoSelecionados).map((cota) => {      
      if (cota.selected) {
        if (idsCotacao === "" ){ 
          idsCotacao = cota.idCotacao;
        } else {
          idsCotacao = idsCotacao + ", " + cota.idCotacao;
        } 
      }  
    });

    Http.get("/cotacao/fornecedores/cotacoes/" + idsCotacao)
    .then(({ status, data }) => {         
      if (data.length > 0) { 
        this.setState({ divFornecedor: true, fornecedores: data}); 
      }
    }).catch((error) => console.log(error));

  }
  
  getDataProdutosCotacao(fornecedorSelecionados) {
    this.setState({ divProduto: false, produtosInseridos: [] });

    var strCotacaoSelecionados = "";
    var strFornecedorSelecionados = "";

    Array.from(this.state.cotacaoSelecionados).map((cotacao) => {
      if (cotacao.selected) {
        if (strCotacaoSelecionados === "" ){ 
          strCotacaoSelecionados = cotacao.idCotacao;
        } else {
          strCotacaoSelecionados = strCotacaoSelecionados + "," + cotacao.idCotacao;
        } 
      }     
    });
    
    
    Array.from(fornecedorSelecionados).map((fornecedor) => {
      if (fornecedor.selected) {
        if (strFornecedorSelecionados === "" ){ 
          strFornecedorSelecionados = fornecedor.idPessoa;
        } else {
          strFornecedorSelecionados = strFornecedorSelecionados + "," + fornecedor.idPessoa;
        }  
      }    
    });

    Http.get("/cotacao/" + strCotacaoSelecionados + "/produtos/" + strFornecedorSelecionados)
    .then(({ status, data }) => {          
      if (data) {                                    
        this.setState({ divProduto: true, produtosInseridos: data});              
      }
    })
    .catch((error) => console.log(error));  
  }

  enviarCotacao() {

    var strCotacaoSelecionados = "";
    var strFornecedorSelecionados = "";

    Array.from(this.state.cotacaoSelecionados).map((cotacao) => {
      if (cotacao.selected) {
        if (strCotacaoSelecionados === "" ){ 
          strCotacaoSelecionados = cotacao.idCotacao;
        } else {
          strCotacaoSelecionados = strCotacaoSelecionados + "," + cotacao.idCotacao;
        } 
      }     
    });
    
    
    Array.from(this.state.fornecedorSelecionados).map((fornecedor) => {
      if (fornecedor.selected) {
        if (strFornecedorSelecionados === "" ){ 
          strFornecedorSelecionados = fornecedor.idPessoa;
        } else {
          strFornecedorSelecionados = strFornecedorSelecionados + "," + fornecedor.idPessoa;
        }  
      }    
    });

    Http.get("/cotacao/" + strCotacaoSelecionados + "/enviarCotacao/" + strFornecedorSelecionados)
    .then(({ status, data }) => {          
      if (data.status) {          
        Notification.success(data.mensagem);
      } else {
        Notification.danger(data.mensagem);
      }
      this.setState({ 
        divProduto: false, 
        divFornecedor: false, 
        produtosInseridos: [],
        fornecedorSelecionados: []
      });  
      this.getDataCotacao();
    })
    .catch((error) => console.log(error));    

  }

  render() {
    const {
      fornecedores,
      cotacoes,
      selecionarTodos,     
      selecionarTodosFornec,
      cotacaoSelecionados,
      fornecedorSelecionados,
      produtosInseridos
    } = this.state;

    return (
      <Div margin="3" shadow bg={Color.NODE.WHITE} borderLeft={Color.NODE.PRIMARY}>
        {this.state.divCotacao && (
          <Div padding="2">

          <Div padding="2">
            <Title value="Cotação" type="h4" paddingLeft="4" paddingRight="4" paddingTop="3"/>
          </Div>  

          <Table
            columns={[              
              { value: "Descrição", align: "center" },
              { value: "Tipo", align: "center" },
              { value: "Status", align: "center" },
              { value: "Início da Vigência", align: "center" },
              { value: "Fim da Vigência", align: "center" }
            ]}
            fields={[
              { value: "descricao",    type: Type.DADO.STRING },
              { value: "tipo",    type: Type.DADO.STRING },
              { value: "nameStatus",    type: Type.DADO.STRING },
              { value: "iniciovigencia",    type: Type.DADO.DATE },
              { value: "fimvigencia",    type: Type.DADO.DATE }
            ]}
            bordered
            search
            orientation="center"       
            data={cotacoes}
            error="Nenhuma cotação encontrada"
            checkbox={{ nameChecked: "selected", valueChecked: true }}
            handlerCheckbox={(cotacao) => {
              cotacao.selected = !cotacao.selected;
              let cotacaoSelecionado = cotacaoSelecionados.filter(
                (p) => p.idCotacao === cotacao.idCotacao
              )[0];

              if (Utils.isValueValid(cotacaoSelecionado)) {
                const index = cotacaoSelecionados.indexOf(cotacao);
                cotacaoSelecionados.splice(index);
              } else {
                cotacaoSelecionados.push(cotacao);
              }

              this.setState({ cotacaoSelecionados });
              this.getDataFornecedorCotacao(cotacaoSelecionados);
            }}
            labelCheckBox={
              <Checkbox
                width="50px"
                label="Selecionar Todos"
                checked={selecionarTodos}
                handlerChange={({ checked }) => {
                  let cotacaoList = cotacoes;
                  
                  if (Utils.isArrayNotEmpty(cotacaoList)) {
                    cotacaoList.forEach((c) => {
                      c.selected = checked;
                      this.setState({ cotacaoSelecionados: checked ? cotacaoList: [], selecionarTodos: checked});
                    });
                  } else {
                    Notification.info(
                      "Nenhuma Cotação selecionado, verifique se as cotações estão selecionado para serem inseridos na cotação."
                    );
                  }

                  if (!checked) {
                    this.setState({ 
                      produtosInseridos: [],
                      cotacaoSelecionados: [],
                      fornecedorSelecionados: [],
                      selecionarTodos: false,
                      selecionarTodosFornec: false,
                      divFornecedor: false,
                      divProduto: false,
                    });
                  } else {                    
                    this.getDataFornecedorCotacao(cotacaoList);
                  }
                }}
              ></Checkbox>
            }
          ></Table>
        </Div>
        )}  

        {this.state.divFornecedor && (
          <Div padding="2">

          <Div padding="2">
            <Title value="Fornecedor" type="h4" paddingLeft="4" paddingRight="4" paddingTop="3"/>
          </Div>  

          <Table
            columns={[              
              { value: "Nome", align: "center" },
              { value: "E-mail", align: "center" },
              { value: "CNPJ", align: "center" }
            ]}
            fields={[
              { value: "fornecedor",    type: Type.DADO.STRING },
              { value: "emailPrincipal",    type: Type.DADO.STRING },
              { value: "cpfCnpj",    type: Type.DADO.CPF_CNPJ },
            ]}
            bordered
            search
            orientation="center"       
            data={fornecedores}
            error="Nenhum fornecedor encontrado"
            checkbox={{ nameChecked: "selected", valueChecked: true }}
            handlerCheckbox={(fornecedor) => {
              fornecedor.selected = !fornecedor.selected;
              let fornecedorSelecionado = fornecedorSelecionados.filter(
                (p) => p.idPessoa === fornecedor.idPessoa
              )[0];

              if (Utils.isValueValid(fornecedorSelecionado)) {
                const index = fornecedorSelecionados.indexOf(fornecedor);
                fornecedorSelecionados.splice(index);
              } else {
                fornecedorSelecionados.push(fornecedor);
              }

              this.setState({ fornecedorSelecionados });
              this.getDataProdutosCotacao(fornecedorSelecionados);
            }}
            labelCheckBox={
              <Checkbox
                width="50px"
                label="Selecionar Todos"
                checked={selecionarTodosFornec}
                handlerChange={({ checked }) => {
                  let fornecedorList = fornecedores;

                  if (Utils.isArrayNotEmpty(fornecedorList)) {
                    fornecedorList.forEach((c) => {
                      c.selected = checked;
                      this.setState({ fornecedorSelecionados: checked ? fornecedorList: [], selecionarTodosFornec: checked});
                    });
                  } else {
                    Notification.info(
                      "Nenhum Fornecedor selecionado, verifique se as cotações estão selecionado para serem inseridos."
                    );
                  }

                  if (!checked) {
                    this.setState({ 
                      produtosInseridos: [],
                      divProduto: false,
                    });
                  } else {                    
                    this.getDataProdutosCotacao(fornecedorList);
                  }
                }}
              ></Checkbox>
            }
          ></Table>
        </Div>
        )}  

        {this.state.divProduto && (       

          <Div padding="2">

            <Div padding="2">
              <Title value="Produtos" type="h4" paddingLeft="4" paddingRight="4" paddingTop="3"/>
            </Div>  

            <Table
                columns={[
                  { value: "PLU", align: "center" },
                  { value: "Descricao", align: "left" },
                  { value: "Código de Barras", align: "center" },
                  { value: "Qdt Solicitada", align: "center" }
                ]}
                fields={[
                  { value: "codigoproduto",  align: "center", type: Type.DADO.STRING },
                  { value: "descricao", align: "left", type: Type.DADO.STRING },
                  { value: "ean", align: "left", type: Type.DADO.STRING },
                  { value: "quantidadesolicitada", align: "left", type: Type.DADO.STRING }
                ]}
              bordered
              orientation="center"       
              data={produtosInseridos}
              error="Nenhum produto encontrado"            
            ></Table>
        </Div>

        )} 
        
        <Div padding="2">
          <Button disabled={this.state.produtosInseridos.length == 0}
            btn={Color.NODE.PRIMARY} value="Enviar Cotação" col="2" margin="1" 
            handlerClick={(_e) => this.enviarCotacao()}
            />
        </Div>  
             
      </Div>
    );
  }
}

const mapStateToProps = (store) => ({
  idEmpresa: store.loginState.user.idEmpresa,
  idFilial: store.loginState.user.idFilial,
});

export default connect(mapStateToProps)(Listar);
