import React, { Component } from "react";
import "./dragdrop.css";
import { Div } from "..";
import PropTypes from "prop-types";

class DragDropOrder extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dragSrcEl: null,
      close: true
    };
  }

  setStyle(e, style, add) {
    e.target.classList.toggle(style, add);
  }

  handleDragStart(e, data) {
    this.setStyle(e, "opacity-4", true);

    this.setState({
      itemStart: data,
      dragSrcEl: e.target.innerHTML
    });

    e.dataTransfer.effectAllowed = "move";
    e.dataTransfer.setData("text/html", e.target.innerHTML);
  }

  handleDragOver(e) {
    if (e.preventDefault) {
      e.preventDefault(); // Necessary. Allows us to drop.
    }

    return false;
  }

  handleDragEnter(e) {
    this.setStyle(e, "over", true);
  }

  handleDragLeave(e) {
    this.setStyle(e, "over", false);
  }

  handleDrop(e, data) {
    if (e.stopPropagation) {
      e.stopPropagation();
    }

    this.setStyle(e, "over", false);

    this.state.dragSrcEl = e.target.innerHTML;
    e.target.innerHTML = e.dataTransfer.getData("text/html");

    this.setState({
      itemTarget: data
    });

    return false;
  }

  handleDragEnd(e) {
    let target = e.target;
    const { keyOrder } = this.props;

    if (target.innerHTML !== this.state.dragSrcEl) {
      target.innerHTML = this.state.dragSrcEl;
    }
    this.setStyle(e, "opacity-4", false);

    let { itemStart, itemTarget } = this.state;

    let orderStart = itemStart[keyOrder];

    try {
      itemStart[keyOrder] = itemTarget[keyOrder];
      itemTarget[keyOrder] = orderStart;

      let imagens = Array.from(this.props.data).sort(
        (a, b) => a[keyOrder] - b[keyOrder]
      );
      this.props.handlerChangeOrderDragDrop(imagens);
    } catch (error) {
      console.log(error);
    }
  }

  getTemplateDragDrop() {
    return (
      <Div className={this.props.className}>
        {Array.from(this.props.children).map((child, key) => {
          return (
            <Div
              key={key}
              handleMouseEnter={e => this.setState({ close: false })}
              handleMouseLeave={e => this.setState({ close: true })}
            >
              <Div
                className="cursor-move"
                draggable={true}
                onDragLeave={e => this.handleDragLeave(e)}
                onDragStart={e => this.handleDragStart(e, child.props.data)}
                onDragEnter={e => this.handleDragEnter(e)}
                onDragOver={e => this.handleDragOver(e)}
                onDragEnd={e => this.handleDragEnd(e)}
                onDrop={e => this.handleDrop(e, child.props.data)}
              >
                <Div>
                  <Div className="pointer-event-none ">{child}</Div>
                </Div>
              </Div>
            </Div>
          );
        })}
      </Div>
    );
  }

  render() {
    return this.getTemplateDragDrop();
  }
}

DragDropOrder.propTypes = {
  handlerChangeOrderDragDrop: PropTypes.func.isRequired,
  handlerRemoveDragDrop: PropTypes.func,
  children: PropTypes.any.isRequired
};

export default DragDropOrder;
