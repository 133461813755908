import React, { Component } from "react";
import { Color, Http } from "../../utilities";

import {
  Row,
  InputDefault,
  Form,
  Notification,
  SectionForm,
  SectionButtonForm,
  Button,
  Div,
} from "../../components";
import { connect } from "react-redux";

class Cadastrar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      nameButton: "Salvar",
      user: props.user,
      ...this.props.location.state,
    };

    this.submit = this.submit.bind(this);
  }

  UNSAFE_componentWillMount() {
    let concorrente = this.state.concorrente;
    if (this.state.editar) {
      this.setState({
        nameButton: "Alterar",
        idConcorrente: concorrente.idConcorrente,
        idGestorCompras: concorrente.idGestorCompras,
        descricao: concorrente.descricao,
        localizacao: concorrente.localizacao,
        telefone: concorrente.telefone,
        website: concorrente.website,
      });
    }
  }

  listaConcorrente() {
    this.props.history.push({ pathname: `/compras/concorrente` });
  }

  async submit(event) {
    event.preventDefault();
    if (event.target.reportValidity()) {
      if (this.state.idConcorrente === undefined) {
        this.cadastrar(this.state);
      } else {
        this.update(this.state);
      }
    }
  }

  cadastrar(concorrente) {
    concorrente.idGestorCompras = this.state.user.idGestorCompras;
    Http.post("/concorrente", concorrente).then(({ data }) => {
      if (data.status) {
        Notification.success(data.mensagem);
        this.listaConcorrente();
      } else {
        Notification.danger(data.mensagem);
      }
    });
  }

  update(concorrente) {
    concorrente.idUsuarioAtualizacao = this.state.user.idUsuario;
    Http.put("/concorrente", concorrente).then(({ data }) => {
      if (data.status) {
        Notification.success(data.mensagem);
        this.listaConcorrente();
      } else {
        Notification.danger(data.mensagem);
      }
    });
  }

  render() {
    return (
      <Div>
        <Form id="formCadastroConcorrente" handlerSubmit={this.submit}>
          <SectionForm borderLeft="primary">
            <Row col="12">
              <InputDefault
                required
                label="Descrição"
                name="descricao"
                responsive="12"
                maxLength="200"
                value={this.state.descricao}
                handlerChange={(e) => {
                  this.setState({ descricao: e.target.value });
                }}
              />
            </Row>

            <Row col="12">
              <InputDefault
                label="Localização"
                name="localizacao"
                responsive="6"
                maxLength="200"
                value={this.state.localizacao}
                handlerChange={(e) => {
                  this.setState({ localizacao: e.target.value });
                }}
              />

              <InputDefault
                label="Website"
                name="website"
                responsive="6"
                maxLength="200"
                value={this.state.website}
                handlerChange={(e) => {
                  this.setState({ website: e.target.value });
                }}
              />
            </Row>

            <SectionButtonForm>
              <Row col="12" style={{ justifyContent: "flex-end" }}>
                <Button
                  btn={Color.NODE.DANGER}
                  value="Cancelar"
                  col="1"
                  handlerClick={(_e) => this.listaConcorrente()}
                  margin="1"
                />

                <Button
                  btn={Color.NODE.PRIMARY}
                  value={this.state.nameButton}
                  col="1"
                  margin="1"
                />
              </Row>
            </SectionButtonForm>
          </SectionForm>
        </Form>
      </Div>
    );
  }
}

const mapStateToProps = (store) => ({
  user: store.loginState.user,
});

export default connect(mapStateToProps)(Cadastrar);
