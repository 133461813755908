import React, { createContext, useEffect, useState } from "react";

export const ThemesContext = createContext({});

const ThemeContextProvider = ({ children }) => {
  const [state, setState] = useState({});
  const hostname = window.location.hostname;

  const setColor = (empresa) => {
    const {
      corPrimaria,
      corSecundaria,
      corTerciaria,
      bgLogin,
      bgLogo,
      textMenuLeft,
      textLogin,
    } = empresa;

    // SET CORES GLOBAIS PARA CSS
    document.documentElement.style.setProperty("--cor-primaria", corPrimaria);
    document.documentElement.style.setProperty(
      "--cor-secundaria",
      corSecundaria
    );
    document.documentElement.style.setProperty("--cor-terciaria", corTerciaria);
    document.documentElement.style.setProperty("--bg-logo", bgLogo);
    document.documentElement.style.setProperty("--bg-login", bgLogin);
    document.documentElement.style.setProperty("--text-menu-left", textMenuLeft);
    document.documentElement.style.setProperty("--text-login", textLogin);

    setState(empresa);
  };

  useEffect(() => {
    // setColor();
  }, []);

  return (
    <ThemesContext.Provider value={state}>{children}</ThemesContext.Provider>
  );
};

export default ThemeContextProvider;
