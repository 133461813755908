import React from "react";
import { className } from "../css";
import { Div } from "..";

const SectionButtonForm = props => {
  return (
    <Div col={props.col ? props.col : "12"} style={props.style} className={className(props)}>
      {props.children}
    </Div>
  );
};

export default SectionButtonForm;
