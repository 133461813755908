import React from "react";
import Div from "../../layout/div/Div";
import { InputGroup } from "../..";
import { Type } from "../../../utilities";

const InputSearch = props => {
  return (
    <Div className={props.className}>
      <InputGroup
        type="search"
        name="search"
        prepend
        icon={Type.ICON.SEARCH}
        bgIcon={props.bgIcon}
        colorIcon={props.colorText}
        placeholder={props.placeholder}
        value={props.value}
        responsive={props.responsive}
        label={props.label}
        col="12"
        handlerChange={props.handlerChange}
        handlerIcon={props.handlerIcon}
        handlerKeyUp={props.handlerKeyUp}
        handlerBlur={props.handlerBlur}
      />
    </Div>
  );
};

export default InputSearch;
