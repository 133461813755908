import React, { Component } from "react";
import "./App.css";
import { Parent, Header, CenterTop, Center, Div, MenuLeft } from "./components";
import Id from "./utilities/enuns/Id";
import MenuSideBar from "./components/menu/lateral/MenuSideBar";

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeMenu: false,
    };

    this.toggleNavbar = this.toggleNavbar.bind(this);
  }

  toggleNavbar() {
    this.setState({
      activeMenu: !this.state.activeMenu,
    });
  }

  render() {
    return (
      <Parent className="bg-container-mobioh">
        <Header
          history={this.props.history}
          handlerClick={(e) => this.toggleNavbar()}
          active={this.state.activeMenu}
          title={this.props.title}
        ></Header>
        {/** <MenuSideBar/>
         *
         */}
        <MenuLeft active={this.state.activeMenu}></MenuLeft>
        <Div active={this.state.activeMenu} className={Id.HTML.MAIN}>
          <CenterTop {...this.props}></CenterTop>
          <Center>{this.props.children}</Center>
        </Div>
      </Parent>
    );
  }
}

export default App;
