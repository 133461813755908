import { Modal } from "../../components";
import React from "react";
import ReactDOM from "react-dom";

export default class ModalNotification {
  static ID = "modal-root";

  static removeModal() {
    ReactDOM.render(null, document.getElementById(ModalNotification.ID));
  }

  static confirmarComHandlerCancel(
      title,
      body,
      handlerConfirm,
      handlerCancel,
      state
  ) {
    ReactDOM.render(
        ModalNotification.getModal(title, body, handlerConfirm, handlerCancel),
        document.getElementById(ModalNotification.ID)
    );
  }

  static render(title, body) {
    ReactDOM.render(
        ModalNotification.getModal(title, body),
        document.getElementById(ModalNotification.ID)
    );
  }

  static confirm(title, body, handlerConfirm) {
    ReactDOM.render(
        ModalNotification.getModal(title, body, handlerConfirm),
        document.getElementById(ModalNotification.ID)
    );
  }

  static getModal(title, body, handlerConfirm, handlerCancel) {
    return (
        <Modal
            title={title}
            body={body}
            handlerConfirm={handlerConfirm}
            handlerCancel={handlerCancel}
            show
        />
    );
  }
}
