import React from "react";
import { Mask, Utils } from "../../../utilities";
import { Component } from "react";
import InputGroup from "./InputGroup";
import Div from "../../layout/div/Div";

const STRING_VAZIA = "";
const PONTO = ".";
const VIRGULA = ",";

class InputCurrency extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.value || STRING_VAZIA,
      valueMask: props.value || STRING_VAZIA
    };
  }

  UNSAFE_componentWillMount() {
    this.forceUpdate();
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({
      value: props.value || STRING_VAZIA,
      valueMask: Mask.formatValueBr(props.value)
    });
  }

  render() {
    let value = Utils.replaceAll(String(this.state.value), PONTO, STRING_VAZIA);
    let valueMask = String(this.state.valueMask);
    const {
      marginBottom,
      label,
      handlerChange,
      placeholder,
      responsive,
      disabled
    } = this.props;
    return (
      <Div marginBottom={marginBottom} responsive={responsive}>
        <InputGroup
          label={label}
          placeholder={placeholder}
          valueGroupLeft="R$"
          disabled={disabled}
          value={Mask.formatBR(valueMask)}
          handlerChange={e => e}
          formcontrol
          handlerKeyUp={e => {
            console.log(value);
            this.setState({ value, valueMask: value });
            //  handlerChange(e);
          }}
        />
      </Div>
    );
  }
}

export default InputCurrency;
