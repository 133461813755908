import React, { Component } from "react";
import {
  ButtonIconOutLine,
  Checkbox,
  Div,
  ModalCustom,
  ModalNotification,
  Notification,
  Row,
  Table,
  Title,
  Label,
} from "../../components";
import { Color, Http, Type, Utils } from "../../utilities";

class InserirEditarConcorrentes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      concorrentes: [],
      concorrentesSelecionados: [],
      concorrentesInseridos: [],
      concorrentesInseridosSelecionados: [],
      closeModal: false,
      idGestorCompras: "",
      selecionarTodos: false,
      selecionarTodosInseridos: false,
      user: {},
    };
  }

  UNSAFE_componentWillMount() {
    this.getDataConcorrentes();
    this.getDataConcorrentesInseridos();
    this.setState({
      idGestorCompras: this.props.idGestorCompras,
    });
  }

  getDataConcorrentes() {
    if (this.props.idGestorCompras) {
      Http.get(
        `/pesquisa/${this.props.idPesquisa}/gestor/${this.props.idGestorCompras}/concorrente/listar/`
      )
        .then(({ status, data }) => {
          if (data) {
            this.setState({ concorrentes: data });
          }
        })
        .catch((error) => console.log(error));
    }
  }

  getDataConcorrentesInseridos() {
    let idPesquisa = this.props.idPesquisa;
    if (this.props.idPesquisa) {
      Http.get("/pesquisa/" + idPesquisa + "/concorrente/")
        .then(({ status, data }) => {
          if (data) {
            this.setState({ concorrentesInseridos: data });
          }
        })
        .catch((error) => console.log(error));
    }
  }

  async deleteConPesquisa(concorrentePesquisa) {
    concorrentePesquisa.idUsuarioInativacao = this.state.user.idUsuario;
    await Http.delete(`/pesquisa/concorrente/`, concorrentePesquisa)
      .then(({ status, data }) => {
        if (data.status) {
          Notification.success(data.mensagem);
          this.getDataConcorrentes();
          this.getDataConcorrentesInseridos();
        } else {
          Notification.danger(data.mensagem);
        }
      })
      .catch((error) => console.log(error));
  }

  deletarConcorrentePesquisa(concorrentePesquisa) {
    ModalNotification.confirm(
      "Deseja deletar este Concorrente? ",
      "Este concorrente será deletado da pesquisa!",
      () => this.deleteConPesquisa(concorrentePesquisa)
    );
  }

  inserirConcorrentePesquisa() {
    if (!this.props.idPesquisa) {
      Notification.danger("Confirme Dados da pesquisa antes!");
      return;
    }
    let idPesquisa = this.props.idPesquisa;

    this.state.concorrentesSelecionados.forEach((item, index) => {
      let obj = {
        idPesquisa,
        idGestorCompras: item.idGestorCompras,
        idConcorrente: item.idConcorrente,
      };

      Http.post("/pesquisa/concorrente/", [obj])
        .then(({ status, data }) => {
          if (status) {
            Notification.success(data.message);
            this.getDataConcorrentes();
            this.getDataConcorrentesInseridos();
            this.setState({
              concorrentesSelecionados: [],
              selecionarTodos: false,
            });
          } else {
            Notification.danger("Falha ao inserir Concorrente");
          }
        })
        .catch((error) => console.log(error));
    });
  }

  setCloseModal = (boolean) => {
    this.setState({
      closeModal: boolean,
      concorrenteSelecionado: [],
      selecionarTodos: false,
    });
  };

  deletarTodosConcorrentesPesquisa(concorrentesSelecionados) {
    ModalNotification.confirm(
      "Deseja remover todos os Concorrentes selecionados? ",
      "Estes Concorrentes serão removidos da pesquisa!",
      () => {
        this.deletarConcorrentesSelecionados(concorrentesSelecionados);
      }
    );

    this.setState({
      selecionarTodosInseridos: false,
      concorrentesInseridosSelecionados: [],
    });
  }

  deletarConcorrentesSelecionados(concorrentes) {
    concorrentes.forEach((concorrente, index) => {
      concorrente.idUsuarioInativacao = this.state.user.idUsuario;

      Http.delete(`/pesquisa/concorrente/`, concorrente)
        .then(({ status, data }) => {
          if (data.status) {
            Notification.success(data.mensagem);

            if(index === concorrentes.length - 1) {
              this.getDataConcorrentes();
              this.getDataConcorrentesInseridos();
            }
            
          } else {
            Notification.danger(data.mensagem);
          }
        })
        .catch((error) => console.log(error));
    });
  }

  render() {
    const {
      concorrentes,
      selecionarTodos,
      selecionarTodosInseridos,
      concorrentesSelecionados,
      concorrentesInseridos,
      concorrentesInseridosSelecionados,
    } = this.state;

    return (
      <>
        <ModalCustom
          title="Concorrentes Disponíveis "
          showModal={this.state.closeModal}
          setShowModal={this.setCloseModal}
          confirm={() => this.inserirConcorrentePesquisa()}
        >
          <Div col="12" style={{ overflow: "auto", maxHeight: "75vh" }}>
            <Table
              columns={[
                { value: "Descrição", align: "center" },
                { value: "Localização", align: "center" },
                { value: "Website", align: "center" },
              ]}
              fields={[
                { value: "descricao", type: Type.DADO.STRING },
                { value: "localizacao", type: Type.DADO.STRING },
                { value: "website", type: Type.DADO.STRING },
              ]}
              bordered
              orientation="center"
              data={concorrentes}
              search
              error="Nenhum concorrente encontrado"
              checkbox={{ nameChecked: "selected", valueChecked: true }}
              handlerCheckbox={(concorrente) => {
                concorrente.selected = !concorrente.selected;
                let concorrenteSelecionado = concorrentesSelecionados.filter(
                  (p) => p.idConcorrente === concorrente.idConcorrente
                )[0];

                if (Utils.isValueValid(concorrenteSelecionado)) {
                  const index = concorrentesSelecionados.indexOf(concorrente);
                  concorrentesSelecionados.splice(index);
                } else {
                  concorrentesSelecionados.push(concorrente);
                }

                this.setState({
                  concorrentesSelecionados,
                });
              }}
              labelCheckBox={
                <Checkbox
                  width="50px"
                  label="Selecionar Todos"
                  checked={selecionarTodos}
                  handlerChange={({ checked }) => {
                    let concorrentesList = concorrentes;

                    if (Utils.isArrayNotEmpty(concorrentesList)) {
                      concorrentesList.forEach((c) => {
                        c.selected = checked;
                        this.setState({
                          concorrentesSelecionados: checked
                            ? concorrentesList
                            : [],
                          selecionarTodos: checked,
                        });
                      });
                    } else {
                      Notification.info(
                        "Nenhum Concorrete selecionado, verifique se os concorrentes estão selecionado para serem inseridos na pesquisa."
                      );
                    }
                  }}
                ></Checkbox>
              }
            ></Table>
          </Div>
        </ModalCustom>
        <Div>
          <Div col="12" padding="2">
            <Row col="12" padding="3">
              <Title
                value="Concorrentes inseridos na pesquisa"
                type="h4"
                paddingLeft="4"
                paddingRight="4"
                paddingTop="3"
                col="10"
              />
              <Div
                col="12"
                padding="0 1rem"
                style={{
                  textAlign: "right",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "flex-end ",
                  marginBottom: "-20px",
                }}
              >
                {concorrentesInseridosSelecionados.length >= 1 && (
                  <>
                    <Label
                      value={
                        concorrentesInseridosSelecionados.length +
                        " Concorrentes Selecionados"
                      }
                      style={{ marginLeft: "10px", fontWeight: "bold" }}
                      className="text-bege"
                    />

                    <Div margin="1">
                      <ButtonIconOutLine
                        icon={Type.ICON.DELETE}
                        value="Excluir"
                        btn={Color.NODE.DANGER}
                        handlerClick={() =>
                          this.deletarTodosConcorrentesPesquisa(
                            this.state.concorrentesInseridosSelecionados
                          )
                        }
                      />
                    </Div>
                  </>
                )}
                <ButtonIconOutLine
                  value="Adicionar Concorrentes"
                  icon={Type.ICON.PLUS}
                  btn={Color.NODE.PRIMARY}
                  handlerClick={() => this.setCloseModal(true)}
                />
              </Div>
            </Row>
            <Table
              columns={[
                { value: "Descrição", align: "center" },
                { value: "Localização", align: "center" },
                { value: "Website", align: "center" },
              ]}
              fields={[
                { value: "descricao", type: Type.DADO.STRING },
                { value: "localizacao", type: Type.DADO.STRING },
                { value: "website", type: Type.DADO.STRING },
              ]}
              bordered
              orientation="center"
              data={concorrentesInseridos}
              error="Nenhum concorrente encontrado"
              delete
              handlerDelete={(rede) => this.deletarConcorrentePesquisa(rede)}
              search
              checkbox={{ nameChecked: "selected", valueChecked: true }}
              handlerCheckbox={(concorrente) => {
                concorrente.selected = !concorrente.selected;
                concorrente.idUsuarioInativacao = this.props.idUsuario;
                concorrente.idUsuarioAtualizacao = this.props.idUsuario;

                if (!concorrente.selected) {
                  let index = concorrentesInseridosSelecionados.indexOf(
                    concorrente
                  );
                  concorrentesInseridosSelecionados.splice(index, 1);
                } else {
                  concorrentesInseridosSelecionados.push(concorrente);
                }

                this.setState({
                  concorrentesInseridosSelecionados,
                  selecionarTodosInseridos: false,
                });
              }}
              TableColumnCheckBox={
                <Checkbox
                  width="40px"
                  label="Selecionar Todos os Concorrentes"
                ></Checkbox>
              }
              labelCheckBox={
                <Checkbox
                  width="40px"
                  //  label="Selecionar Todos"
                  checked={selecionarTodosInseridos}
                  handlerChange={({ checked }) => {
                    let concorrenteList = [...concorrentesInseridos];

                    if (Utils.isArrayNotEmpty(concorrenteList)) {
                      concorrenteList.forEach((c) => {
                        c.selected = checked;
                        this.setState({
                          concorrentesInseridosSelecionados: checked
                            ? concorrenteList
                            : [],
                          selecionarTodosInseridos: checked,
                        });
                      });
                    } else {
                      Notification.info(
                        "Nenhum Concorrente selecionado, verifique se os Concorrentes estão selecionados."
                      );
                    }
                  }}
                ></Checkbox>
              }
              stylesCheckbox={{ width: "150px" }}
            ></Table>
          </Div>
        </Div>
      </>
    );
  }
}

export default InserirEditarConcorrentes;
