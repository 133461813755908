import React from "react";

import {
  col,
  collapse,
  colorText,
  float,
  offset,
  border,
  backGround,
  display,
  show,
  active,
  pointer,
  responsive,
  column,
  rounded,
  margin,
  padding,
  underline,
  className,
  textAlign,
  inline
} from "../css";

const getClassDefault = props => {
  let classe = "row-mobioh ";
  classe = classe.concat(col(props));
  classe = classe.concat(textAlign(props));
  classe = classe.concat(colorText(props));
  classe = classe.concat(float(props));
  classe = classe.concat(offset(props));
  classe = classe.concat(border(props));
  classe = classe.concat(backGround(props));
  classe = classe.concat(display(props));
  classe = classe.concat(pointer(props));
  classe = classe.concat(show(props));
  classe = classe.concat(active(props));
  classe = classe.concat(inline(props));
  classe = classe.concat(collapse(props));
  classe = classe.concat(responsive(props));
  classe = classe.concat(column(props));
  classe = classe.concat(rounded(props));
  classe = classe.concat(margin(props));
  classe = classe.concat(padding(props));
  classe = classe.concat(underline(props));
  classe = classe.concat(className(props));

  return classe;
};

const Row = props => {
  return (
    <div className={getClassDefault(props)} id={props.id} style={props.style} onClick={props.handlerClick}>
      {props.children}
    </div>
  );
};

export default Row;
