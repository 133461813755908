import React from "react";
import PropTypes from "prop-types";
import { className, col, float, display, invisible, margin, padding, backGround } from "../../css";

const getClassDefault = props => {
  let classe = "";
  classe = classe.concat(col(props));
  classe = classe.concat(float(props));
  classe = classe.concat(display(props));
  classe = classe.concat(invisible(props));
  classe = classe.concat(backGround(props));
  classe = classe.concat(padding(props));
  classe = classe.concat(margin(props));
  classe = classe.concat(props.formcontrol ? "form-control " : "");
  classe = classe.concat(className(props));

  return classe;
};

const validateKeyPress = e => {
  if (e.key === "Enter" || e.key === "ENTER") {
    e.preventDefault();
  }
};

const Input = props => {
  return (
    <input
      className={getClassDefault(props)}
      width={props.width}
      type={props.type}
      disabled={props.disabled}
      placeholder={props.placeholder}
      onChange={props.handlerChange}
      onFocus={props.handlerFocus}
      onBlur={props.handlerBlur}
      onKeyDown={e => {
        validateKeyPress(e)
        if (e.key === "Enter" || e.key === "ENTER") {
          if(props.handlerConfirm) {
             props.handlerConfirm(e)
          }
        }
      }}
      onKeyUp={props.handlerKeyUp}
      onClick={props.handlerClick}
      name={props.name}
      multiple={props.multiple}
      checked={props.checked}
      id={props.id}
      required={props.required}
      pattern={props.pattern}
      title={props.title}
      value={props.value}
      max={props.max}
      accept={props.accept}
      maxLength={props.maxLength}
      style={props.style}
    />
  );
};

Input.propTypes = {
  col: PropTypes.string,
  type: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  handlerChange: PropTypes.func,
  handlerBlur: PropTypes.func,
  name: PropTypes.string,
  placeholder: PropTypes.string
};

Input.defaultProps = {
  col: "12",
  type: "text",
  disabled: false
};

export default Input;
