import { data } from "jquery";
import React, { Component } from "react";
import {
  ButtonIconOutLine,
  Checkbox,
  Div,
  I,
  ModalCustom,
  ModalNotification,
  Notification,
  Row,
  SelectValue,
  Table,
  Title,
} from "../../components";
import { Color, DateUtils, Http, Type, Utils } from "../../utilities";
import TemplateFilterProdutos from "./template/TemplateFilterProdutos";

class InserirEditarProdutos extends Component {
  constructor(props) {
    super(props);

    this.state = {
      produtos: [],
      produtosSlice: [],
      filtros: {
        departamentos: [new SelectValue(0, "SELECIONE", true)],
        grupos: [new SelectValue(0, "SELECIONE", true)],
      },
      produtosSelecionados: [],
      produtosInseridosSelecionados: [],
      produtosInseridos: [],
      departamentos: [],
      departamentosSelecionados: [],
      grupos: [],
      idDepartamento: "",
      idGrupo: "",
      closeModal: false,
      idGestorCompras: "",
      tipoPesquisas: [],
      tipoPesquisa:"DESCRICAO",
      selecionarTodos: false,
      user: {},
      paginacao: 0,
      quantidadesolicitada: 1,
    };
  }

  UNSAFE_componentWillMount() {
    this.getDataProdutosInseridos();
    this.getProdutos(0);
    this.getDataDepartamentos();
    this.getTipoPesquisaSelect();
    this.setState({
      idGestorCompras: this.props.idGestorCompras,
    });
  }

  getTipoPesquisaSelect() {
    var tp =  [
      new SelectValue("DESCRICAO", "DESCRIÇÃO", true),      
      new SelectValue("EAN", "CÓDIGO DE BARRAS", false),
      new SelectValue("PLU", "PLU", false),
    ];

    this.setState({tipoPesquisas: tp});
  }

  getDataDepartamentos() {
    Http.get(`/departamento/` + this.props.idGestorCompras).then(
      ({ data }) => {
        if (data) {
          let depComDados = [];
          depComDados.push(new SelectValue(0, "SELECIONE", true));
          depComDados.push(new SelectValue("6597f716-7beb-4166-a41a-66ee5fe1b9ea", "TODOS", false));
          Array.from(data).map((dep) => {
            depComDados.push(
              new SelectValue(
                dep.idDepartamento,
                dep.sequencia + " - " + dep.descricao,
                false
              )
            );
          });
          this.setState({
            filtros: {
              ...this.state.filtros,
              departamentos: depComDados,
            },
          });
        }
      }
    );
  }

  getDataGrupos(idDepartamento) {
    Http.get(`/grupo/listargrupopordepartamento/` + idDepartamento).then(
      ({ data }) => {
        if (data) {
          let grupoComDados = [];
          grupoComDados.push(new SelectValue(0, "SELECIONE", true));
          grupoComDados.push(new SelectValue("6597f716-7beb-4166-a41a-66ee5fe1b9ea", "TODOS", false));
          Array.from(data).map((dep) => {
            grupoComDados.push(
              new SelectValue(
                dep.idGrupo,
                dep.sequenciaDepartamentoGrupo + " - " + dep.descricao,
                false
              )
            );
          });
          this.setState({
            filtros: {
              ...this.state.filtros,
              grupos: grupoComDados,
            },
          });
        }
      }
    );
  }
  
  getDataProdutosInseridos() {
    let idCotacao = this.props.idCotacao;
    Http.get("/cotacao/" + idCotacao + "/produtos/")
      .then(({ status, data }) => {
        console.log(data)
        if (data) {
          this.setState({ produtosInseridos: data });
        }
      })
      .catch((error) => console.log(error)); 
  }

  async getProdutos(paginacao) {    
    await Http.get(`/produto/cotacao/${this.props.idCotacao}/listar/${this.props.idGestorCompras}/paginacao/${paginacao}`,)
      .then(({ status, data }) => {        
        if (data) {
          this.setState({ produtos: data });
        }
      })
      .catch((error) => console.log(error));
  }

  async getProdutosFiltros(idDepartamento, idGrupo, idCotacao) { 
    this.setState({produtos: [], produtosSlice: []});
    var idgestor = this.state.idGestorCompras;
    var paginacao = 0;
    
    await Http.get(
      idGrupo
        ? `/produto/filtro/${idDepartamento}/departamento/${idGrupo}/grupo/${idgestor}/paginacao/${paginacao}`
        : `/produto/filtro/${idDepartamento}/departamento/${idgestor}/paginacao/${paginacao}`
    )
      .then(({ status, data }) => {
        if (data) {
          this.setState({
            produtos: data,
            produtosSlice: data.length > 100 ? data.slice(0, 100) : data,
          });
        }
      })
      .catch((error) => console.log(error));
  }

  async consultarProdutos() {
    let filtros = { ...this.state.filtros };

    let idDepartamento = this.state.idDepartamento;
    let departamentos = this.state.departamentos;
    let idGrupo = this.state.idGrupo;
    var prodDepart = idDepartamento;
    var grupDepart = idGrupo;

    departamentos.map((depto) => {
      depto.idDepartamento = filtros.idDepartamento;
    });

    let url = Utils.isValueValid(departamentos)
      ? "/produtos/filtro/" +
      prodDepart +
      "/departamento/" +
      grupDepart +
      "/grupo/"
      : "/produtos/listar/" + this.props.idGestorCompras;

    await Http.get(
      url,
      Utils.isValueValid(departamentos) ? departamentos : filtros
    )
      .then(({ status, data }) => {
        if (status) {
          Array.from(data).map((produto) => {
            produto.isProdutoDisponivel = produto.situacao === "LIBERADO-VENDA";
          });
        }
        this.setState({ produtos: status ? data : [] });
      })
      .catch((error) => console.log(error));
  }

  inserirProdutosPesquisa() {
    if (!this.props.idCotacao) {
      Notification.danger("Confirme Dados da pesquisa antes!");
      return;
    }

    let idCotacao = this.props.idCotacao;
    let newProdutos = [];

    this.state.produtosSelecionados.forEach((item, index) => {
      let isProdutoInserido = false;
      let obj = {
        idCotacao,
        idGestorCompras: item.idGestorCompras,
        idProduto: item.idProduto,
        quantidadesolicitada: item.quantidadesolicitada >= 1 ? item.quantidadesolicitada : 1   
      };
      this.state.produtosInseridos.forEach((itemInserido) => {
        if (item.idProduto === itemInserido.idProduto) {
          isProdutoInserido = true;
        }
      });
      if (!isProdutoInserido) {
        newProdutos.push(obj);
      }
    });

    Http.post("/cotacao/produtos/", newProdutos)
      .then(({ status, data }) => {
        if (data.status) {
          Notification.success(data.mensagem);
          this.getDataProdutosInseridos();
          this.setState({
            produtosSelecionados: [],
            produtos: [],
            produtosSlice: [],
            selecionarTodos: false,
          });
        } else {
          Notification.danger(data.mensagem);
        }
      })
      .catch((error) => console.log(error));

    this.setState({
      selecionarTodos: false,
    });
  }

  setCloseModal = (boolean) => {
    this.setState({
      closeModal: boolean,
      produtos: [],
      produtosSlice: [],
      produtosSelecionados: [],
      selecionarTodos: false,
    });
  };

  listarPedidos() {
    let { idDepartamento, idGrupo } = this.state;

    if (idDepartamento !== "" && idGrupo !== "") {
      this.getProdutosFiltros(idDepartamento, idGrupo, this.props.idCotacao);
    }
  }

  mudarFiltros(value, type) {
    if (type === "departamento") {
      if (value != 0) {
        this.getDataGrupos(value);
      }
      this.setState(
        {
          idDepartamento: value,
          idGrupo: "",
          produtos: [],
          produtosSlice: [],
        },
        () =>
          value != 0
            ? this.getProdutosFiltros(value, null, this.props.idCotacao)
            : {}
      );
    }

    if (type === "grupo") {
      this.setState(
        {
          idGrupo: value,
          paginacao: 0,
          produtos: [],
          produtosSlice: [],
        },
        () => this.listarPedidos()
      );
    }

    if (type === "tpPesquisa") {
      this.setState({tipoPesquisa: value});
    }
  }

  async deletarProduto(produtoPesquisa) {
    produtoPesquisa.idUsuarioInativacao = this.props.idUsuario;
    await Http.delete(`/cotacao/produtos`, produtoPesquisa[0])
      .then(({ status, data }) => {
        if (data.status) {
          Notification.success(data.mensagem);
          this.getDataProdutosInseridos();
        } else {
          Notification.danger(data.mensagem);
        }
      })
      .catch((error) => console.log(error));
  }

  deletarProdutoPesquisa(produtoPesquisa) {
    let deleteProduto = [];

    if (!!produtoPesquisa.length) {
      deleteProduto = [...produtoPesquisa];
    } else {
      deleteProduto = [produtoPesquisa];
    }

    ModalNotification.confirm(
      "Deseja remover este Produto? ",
      "Este Produto será removido da cotação!",
      () => this.deletarProduto(deleteProduto)
    );
  }

  deletarTodosProdutosPesquisa(produtosSelecionados) {
    ModalNotification.confirm(
      "Deseja remover todos os Produtos selecionados? ",
      "Estes Produtos serão removidos da cotação!",
      () => {
        this.deletarProduto(produtosSelecionados);
      }
    );

    this.setState({
      selecionarTodos: false,
    });
  }

  handlerChangeProduto(e) {
    if (e.target.value === "") {
      this.getProdutos(0);
    }
  }

  renderFiltros() {
    let { departamentos, grupos } = this.state.filtros;
    let { tipoPesquisas } = this.state;
    return (
      <TemplateFilterProdutos
        departamentos={departamentos}
        grupos={grupos}
        tipoPesquisas={tipoPesquisas}
        handler={(value, type) => this.mudarFiltros(value, type)}
        handlerSearch={(e) => this.getProdutosSearch(e)}
        handlerChange={(e) => this.handlerChangeProduto(e)}
        produtosSelecionados={this.state.produtosSelecionados}
      />
    );
  }

  getProdutosSearch(search) {
    this.setState({produtos: [], produtosSlice: []});
    let busca = { 
        descricao: search, 
        idCotacao: this.props.idCotacao,
        idGestorCompras: this.props.idGestorCompras,
        tipoPesquisa: this.state.tipoPesquisa
    };

    if (search.trim() !== "") {
      Http.post(`/cotacao/produtos/listar/resumido`, busca)
        .then(({ data }) => {
          if (data) {
            this.setState({
              produtos: data,
              produtosSlice: data.length > 100 ? data.slice(0, 100) : data,
            });
          }
        })
        .catch((error) => console.log(error));
    }
  }

  handleScroll = (e) => {
    const { produtosSlice, produtos } = this.state;
    let element = e.target;
    if (element.scrollHeight - element.scrollTop === element.clientHeight) {
      let newProdutos = [];
      let start = produtosSlice.length;
      let end =
        produtos.length > 100
          ? produtosSlice.length + 100
          : produtosSlice.length - produtos.length;

      if (produtos.length >= produtosSlice.length) {
        newProdutos = [...produtosSlice, ...produtos.slice(start, end)];

        console.log(newProdutos);
        this.setState({
          produtosSlice: newProdutos,
        });
      }
    }
  };

  render() {
    const {
      produtosInseridos,
      produtos,
      produtosSelecionados,
      selecionarTodos,
      produtosInseridosSelecionados,
      produtosSlice,
    } = this.state;

    return (
      <>
        <ModalCustom
          title="Produtos Disponíveis "
          showModal={this.state.closeModal}
          setShowModal={this.setCloseModal}
          confirm={() => this.inserirProdutosPesquisa()}
        >
          <Div
            col="12"
            style={{ overflow: "scroll", maxHeight: "75vh" }}
            handlerScroll={(e) => this.handleScroll(e)}
          >
            <Div>
              <Table
                columns={[
                  { value: "PLU", align: "center" },
                  { value: "Descricao", align: "left" },
                  { value: "Código de Barras", align: "center" },
                  { value: "Departamento", align: "left" },
                  { value: "Grupo", align: "center" },
                  { value: "Marca", align: "center" },
                  { value: "Embalagem", align: "left" },
                  { value: "Fornecedor Principal", align: "left" },
                  { value: "Qdt Solicitada", align: "center" }
                ]}
                fields={[
                  {
                    value: "codigoProduto",
                    align: "right",
                    type: Type.DADO.STRING,
                  },
                  {
                    value: "descricao",
                    align: "left",
                    type: Type.DADO.STRING,
                  },
                  { value: "ean", align: "left", type: Type.DADO.STRING },
                  {
                    value: "nomeDepartamento",
                    align: "left",
                    type: Type.DADO.STRING,
                  },
                  {
                    value: "nomeGrupo",
                    align: "left",
                    type: Type.DADO.STRING,
                  },
                  {
                    value: "nomeMarca",
                    align: "left",
                    type: Type.DADO.STRING,
                  },
                  {
                    value: "embalagem",
                    align: "left",
                    type: Type.DADO.STRING,
                  },
                  {
                    value: "nomeFornecedorPrincipal",
                    align: "left",
                    type: Type.DADO.STRING,
                  },
                  {
                    input: true,
                    typeInput: "integer",
                    value: "quantidadesolicitada",
                    align: "right",
                    width: "50px",
                  },
                ]}
                bordered
                orientation="center"
                //pagination
                filter={this.renderFiltros()}
                filterShow={true}
                data={produtosSlice}
                error="Nenhum produto encontrado"
                handlerChangeInput={(produto) => {
                  
                    if (produto.quantidadesolicitada > 0 ) {
                      produto.selected = true;
                    } else {
                      produto.selected = false;
                    }                  
                    
                    produto.idUsuarioAtualizacao = this.props.idUsuario;                  
  
                    let newProdutosSelecionados = produtosSelecionados;
  
                    if (produto.selected) {
                      var valido = true;
                      this.state.produtosSelecionados.forEach((item, index) => {      
                        if (item.codigoProduto === produto.codigoProduto) {
                          valido = false;
                        }      
                      });
  
                      if (valido) {
                        newProdutosSelecionados.push(produto);
                      }
                      
                    } else {
                      newProdutosSelecionados = produtosSelecionados.filter(
                        (item) => {
                          return item.codigoProduto === produto.codigoProduto
                            ? false
                            : true;
                        }
                      );
                    }
  
                    this.setState({
                      produtosSelecionados: newProdutosSelecionados,
                    });
             }} 
                TableColumnCheckBox={
                  <Checkbox
                    width="50px"
                    label="Selecionar Todos os Produtos"
                  ></Checkbox>
                }
                labelCheckBox={
                  <Checkbox
                    width="50px"
                    label="Selecionar Todos"
                    checked={selecionarTodos}
                    handlerChange={({ checked }) => {
                      let produtosList = produtos;

                      if (Utils.isArrayNotEmpty(produtosList)) {
                        produtosList.forEach((c) => {
                          c.selected = checked;
                          this.setState({
                            produtosSelecionados: checked ? produtosList : [],
                            selecionarTodos: checked,
                          });
                        });
                      } else {
                        Notification.info(
                          "Nenhum Produto selecionado, verifique se os produtos estão selecionado para serem inseridos na pesquisa."
                        );
                      }
                    }}
                  ></Checkbox>
                }
              ></Table>
            </Div>
          </Div>
        </ModalCustom>
        <Div>
          <Div col="12" padding="2">
            <Row col="12" padding="3">
              <Title
                value="Produtos inseridos na cotação"
                type="h4"
                paddingLeft="4"
                paddingRight="4"
                paddingTop="3"
                col="10"
              />
              <Div
                col="2"
                padding="0 1rem"
                style={{
                  textAlign: "right",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "flex-end ",
                  marginBottom: "-20px",
                }}
              >
                <ButtonIconOutLine
                  value="Adicionar Produtos"
                  icon={Type.ICON.PLUS}
                  btn={Color.NODE.PRIMARY}
                  handlerClick={() => this.setCloseModal(true)}
                />
              </Div>
            </Row>
            <Table
              columns={[
                { value: "PLU", align: "center" },
                { value: "Descricao", align: "left" },
                { value: "Código de Barras", align: "center" },
                { value: "Qdt Solicitada", align: "center" },
              ]}
              fields={[
                {
                  value: "codigoproduto",
                  align: "center",
                  type: Type.DADO.STRING,
                },
                { value: "descricao", align: "left", type: Type.DADO.STRING },
                { value: "ean", align: "left", type: Type.DADO.STRING },
                { value: "quantidadesolicitada", align: "left", type: Type.DADO.STRING },
              ]}
              bordered
              orientation="center"
              pagination
              search
              data={produtosInseridos}
              error="Nenhum produto encontrado"
              checkbox={{ nameChecked: "selected", valueChecked: true }}
              handlerCheckbox={(produto) => {
                produto.selected = !produto.selected;
                produto.idUsuarioInativacao = this.props.idUsuario;
                produto.idUsuarioAtualizacao = this.props.idUsuario;
                let produtoEncontrado = produtosInseridosSelecionados.filter(
                  (p) =>
                    !p.produto &&
                    Utils.isValueValid(p.situacao) &&
                    String(p.ativo) === "true"
                )[0];

                if (Utils.isValueValid(produtoEncontrado)) {
                  produtoEncontrado = produto;
                } else {
                  produtosInseridosSelecionados.push(produto);
                }

                this.setState({
                  produtosInseridosSelecionados,
                });
              }}
              TableColumnCheckBox={
                <Checkbox
                  width="40px"
                  label="Selecionar Todos os Produtos"
                ></Checkbox>
              }
              labelCheckBox={
                <Div
                  col="12"
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <Div
                    col="6"
                    style={{
                      display: "flex",
                      marginTop: "12px",
                    }}
                  >
                    <Checkbox
                      width="40px"
                      label="Selecionar Todos"
                      checked={selecionarTodos}
                      handlerChange={({ checked }) => {
                        let produtosList = produtosInseridos;

                        if (Utils.isArrayNotEmpty(produtosList)) {
                          produtosList.forEach((c) => {
                            c.selected = checked;
                            this.setState({
                              produtosInseridosSelecionados: checked
                                ? produtosList
                                : [],
                              selecionarTodos: checked,
                            });
                          });
                        } else {
                          Notification.info(
                            "Nenhum Produto selecionado, verifique se os produtos estão selecionado para serem inseridos na cotação."
                          );
                        }
                      }}
                    ></Checkbox>
                  </Div>

                  <I
                    icon={Type.ICON.DELETE}
                    sizeIcon="2"
                    colorText={Color.NODE.DANGER}
                    style={{ cursor: "pointer" }}
                    handlerClick={() =>
                      this.deletarTodosProdutosPesquisa(
                        this.state.produtosInseridosSelecionados
                      )
                    }
                  ></I>
                </Div>
              }
              delete
              //filter={this.renderFiltros()}
              handlerDelete={(rede) => this.deletarProdutoPesquisa(rede)}
            ></Table>
          </Div>
        </Div>
      </>
    );
  }
}

export default InserirEditarProdutos;
