import React, { Component } from "react";
import {
  ButtonIconOutLine,
  Checkbox,
  Div,
  ModalCustom,
  ModalNotification,
  Notification,
  Row,
  Table,
  Title,
} from "../../components";
import { Color, Http, Type, Utils } from "../../utilities";

class InserirEditarEmpresas extends Component {
  constructor(props) {
    super(props);

    this.state = {
      empresas: [],
      empresasSelecionadas: [],
      empresasInseridas: [],
      closeModal: false,
      idGestorCompras: "",
      selecionarTodos: false,
      user: {},
    };
  }

  UNSAFE_componentWillMount() {
    this.getDataEmpresas();
    this.getDataEmpresasInseridos();
    this.setState({
      idGestorCompras: this.props.idGestorCompras,
    });
  }

  getDataEmpresas() {
    if (this.props.idGestorCompras) {
      Http.get(
        `/pesquisa/${this.props.idPesquisa}/gestor/${this.props.idGestorCompras}/empresas/listar/`
      )
        .then(({ status, data }) => {
          if (data) {
            this.setState({ empresas: data });
          }
        })
        .catch((error) => console.log(error));
    }
  }

  getDataEmpresasInseridos() {
    let idPesquisa = this.props.idPesquisa;
    if (this.props.idPesquisa) {
      Http.get("/pesquisa/" + idPesquisa + "/empresas/")
        .then(({ status, data }) => {
          console.log(data)
          if (data) {
            this.setState({ empresasInseridas: data });
          }
        })
        .catch((error) => console.log(error));
    }
  }

  async deleteEmPesquisa(empresaPesquisa) {
    empresaPesquisa.idUsuarioInativacao = this.state.user.idUsuario;
    await Http.delete(`/pesquisa/empresas/`, empresaPesquisa)
      .then(({ status, data }) => {
        if (data.status) {
          Notification.success(data.mensagem);
          this.getDataEmpresas();
          this.getDataEmpresasInseridos();
        } else {
          Notification.danger(data.mensagem);
        }
      })
      .catch((error) => console.log(error));
  }

  deletarEmpresaPesquisa(empresaPesquisa) {
    ModalNotification.confirm(
      "Deseja remover essa Empresa da pesquisa? ",
      "Esta empresa será removida da pesquisa!",
      () => this.deleteEmPesquisa(empresaPesquisa)
    );
  }

  inserirEmpresaPesquisa() {
    if (!this.props.idPesquisa) {
      Notification.danger("Confirme Dados da pesquisa antes!");
      return;
    }
    let idPesquisa = this.props.idPesquisa;

    this.state.empresasSelecionadas.forEach((item, index) => {
      let obj = {
        idPesquisa,
        idGestorCompras: item.idGestorCompras,
        idPessoa: item.idPessoa,
      };

      Http.post("/pesquisa/empresas/", obj)
        .then(({ status, data }) => {
          console.log(data)
          if (data.status) {
            Notification.success(data.message);
            this.getDataEmpresas();
            this.getDataEmpresasInseridos();
            this.setState({ empresasSelecionadas: [], selecionarTodos: false });
          } else {
            Notification.danger("Falha ao inserir a Empresa");
          }
        })
        .catch((error) => console.log(error));
    });
  }

  setCloseModal = (boolean) => {
    this.setState({
      closeModal: boolean,
      empresasSelecionadas: [],
      selecionarTodos: false
    });
  };

  render() {

    const {
      empresas,
      selecionarTodos,
      empresasSelecionadas,
      empresasInseridas,
    } = this.state;

    return (
      <>
        <ModalCustom
          title="Empresas Disponíveis "
          showModal={this.state.closeModal}
          setShowModal={this.setCloseModal}
          confirm={() => this.inserirEmpresaPesquisa()}
        >
          <Div col="12" style={{ overflow: "auto", maxHeight: "75vh" }}>
            <Table
              columns={[
                { value: "Razão Social", align: "center" },
                { value: "Cnpj", align: "center" },

              ]}
              fields={[
                { value: "razao", type: Type.DADO.STRING },
                { value: "cpfCnpj", type: Type.DADO.STRING },
              ]}
              bordered
              orientation="center"
              pagination
              data={empresas}
              error="Nenhuma empresa encontrada"
              checkbox={{ nameChecked: "selected", valueChecked: true }}
              handlerCheckbox={(empresa) => {
                empresa.selected = !empresa.selected;
                let empresaSelecionada = empresasSelecionadas.filter(
                  (p) => p.idEmpresa === empresa.idEmpresa
                )[0];

                if (Utils.isValueValid(empresaSelecionada)) {
                  const index = empresasSelecionadas.indexOf(empresa);
                  empresasSelecionadas.splice(index);
                } else {
                  empresasSelecionadas.push(empresa);
                }

                this.setState({
                  empresasSelecionadas,
                });
              }}
              labelCheckBox={
                <Checkbox
                  width="50px"
                  label="Selecionar Todos"
                  checked={selecionarTodos}
                  handlerChange={({ checked }) => {
                    let empresasList = empresas;

                    if (Utils.isArrayNotEmpty(empresasList)) {
                      empresasList.forEach((c) => {
                        c.selected = checked;
                        this.setState({
                          empresasSelecionadas: checked
                            ? empresasList
                            : [],
                          selecionarTodos: checked,
                        });
                      });
                    } else {
                      Notification.info(
                        "Nenhuma Empresa selecionada, verifique se as empresas estão selecionadas para serem inseridas na pesquisa."
                      );
                    }
                  }}
                ></Checkbox>
              }
            ></Table>
          </Div>
        </ModalCustom>
        <Div>
          <Div col="12" padding="2">
            <Row col="12" padding="3">
              <Title
                value="Empresas inseridas na pesquisa"
                type="h4"
                paddingLeft="4"
                paddingRight="4"
                paddingTop="3"
                col="10"
              />
              <Div
                col="2"
                padding="0 1rem"
                style={{
                  textAlign: "right",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "flex-end ",
                  marginBottom: "-20px",
                }}
              >
                <ButtonIconOutLine
                  value="Adicionar Empresas"
                  icon={Type.ICON.PLUS}
                  btn={Color.NODE.PRIMARY}
                  handlerClick={() => this.setCloseModal(true)}
                />
              </Div>
            </Row>
            <Table
              columns={[
                { value: "Razão Social", align: "center" },
                { value: "Cnpj", align: "center" },
                { value: "Email", align: "center" },
              ]}
              fields={[
                { value: "razao", type: Type.DADO.STRING },
                { value: "cpfCnpj", type: Type.DADO.STRING },
                { value: "emailPrincipal", type: Type.DADO.STRING },
              ]}
              bordered
              orientation="center"
              pagination
              data={empresasInseridas}
              error="Nenhuma empresa Inserida ou Encontrada na Pesquisa"
              delete
              handlerDelete={(rede) => this.deletarEmpresaPesquisa(rede)}
            ></Table>
          </Div>
        </Div>
      </>
    );
  }
}

export default InserirEditarEmpresas;
