import React, { Component } from "react";

import { Navbar, NavbarItemMenu, If } from "../../components";
import { Type, Utils } from "../../utilities";
import NavbarSubMenu from "../navbar/menu-left/NavbarSubMenu";
import NavbarItem from "../navbar/menu-left/NavbarItem";
import { connect } from "react-redux";
import Div from "../layout/div/Div";

const ID_NAVBAR = Utils.uuidString();

const ID_EMPRESAS = Utils.uuidString();
const ID_MEUSDADOS = Utils.uuidString();
const ID_PRODUTOS = Utils.uuidString();
const ID_PESQ_PRECO = Utils.uuidString();
const ID_COTACAO_PRECO = Utils.uuidString();

class MenuLeft extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ifood: false,
      sitemercado: false,
      woocommerce: false,
      compras: true,
    };
  }

  UNSAFE_componentWillMount() {}

  isUserMaster() {
    return false;
  }

  renderNavBarFornecedor() {
    return (
      <Navbar title="Mobioh" id={ID_NAVBAR} active={this.props.active}>
        <NavbarItemMenu
          iconLeft={Type.ICON.ADDRESS_BOOK}
          iconRight={Type.ICON.ARROW_ANGLE_DOWN}
          value="Fornecedor"
          target={`#${ID_MEUSDADOS}`}
          rota="#"
        />
        <NavbarSubMenu id={ID_MEUSDADOS} parent={ID_NAVBAR}>
          <NavbarItem
            iconLeft={Type.ICON.ADDRESS_BOOK}
            value="Cotações"
            rota="/compras/respondercotacao"
          />
          <NavbarItem
            iconLeft={Type.ICON.INFO}
            value="Apuração"
            rota="/compras/apuracaocotacao"
          />
        </NavbarSubMenu>
      </Navbar>
    );
  }

  renderNavBarUser() {
    return (
      <Navbar title="Mobioh" id={ID_NAVBAR} active={this.props.active}>
        <NavbarItemMenu
          iconLeft={Type.ICON.DATABASE}
          iconRight={Type.ICON.ARROW_ANGLE_DOWN}
          value="Pesquisa de Preço"
          target={`#${ID_PESQ_PRECO}`}
          rota="#"
        />
        <NavbarSubMenu id={ID_PESQ_PRECO} parent={ID_PESQ_PRECO}>
          <NavbarItem
            iconLeft={Type.ICON.SHARE}
            value="Concorrentes"
            rota="/compras/concorrente"
          />

          <NavbarItem
            iconLeft={Type.ICON.BRAND}
            value="Pesquisa"
            rota="/compras/pesquisa"
          />

          {/* <NavbarItem 
                                iconLeft={Type.ICON.SLIDE} 
                                value="Apurar Pesquisa"
                                rota="#"                        
                            />   */}
        </NavbarSubMenu>

        <NavbarItemMenu
          iconLeft={Type.ICON.REORDER}
          iconRight={Type.ICON.ARROW_ANGLE_DOWN}
          value="Cotação de Compra"
          target={`#${ID_COTACAO_PRECO}`}
          rota="#"
        />
        <NavbarSubMenu id={ID_COTACAO_PRECO} parent={ID_COTACAO_PRECO}>
          <NavbarItem
            iconLeft={Type.ICON.BRAND}
            value="Cotacao"
            rota="/compras/cotacao"
          />
          <NavbarItem
            iconLeft={Type.ICON.BRAND}
            value="Envio Cotação"
            rota="/compras/enviocotacao"
          />
        </NavbarSubMenu>

        <NavbarItemMenu
          iconLeft={Type.ICON.PRODUCT}
          value="Assinaturas"
          rota="/compras/grupoprodutos"
        />

        <NavbarItemMenu
          iconLeft={Type.ICON.PRODUCT}
          iconRight={Type.ICON.ARROW_ANGLE_DOWN}
          value="Produtos"
          target={`#${ID_PRODUTOS}`}
          rota="#"
        />

        <NavbarSubMenu id={ID_PRODUTOS} parent={ID_NAVBAR}>
          <NavbarItem
            iconLeft={Type.ICON.CLONE}
            value="Marcas"
            rota="/compras/marca"
          />

          <NavbarItem
            iconLeft={Type.ICON.SLIDE}
            value="Departamentos e Seção"
            rota="/compras/departamentos"
          />

          <NavbarItem
            iconLeft={Type.ICON.PRODUCT}
            value="Produtos"
            rota="/compras/produto"
          />
        </NavbarSubMenu>

        <NavbarItemMenu
          iconLeft={Type.ICON.COMPANY}
          iconRight={Type.ICON.ARROW_ANGLE_DOWN}
          value="Empresas"
          target={`#${ID_EMPRESAS}`}
          rota="#"
        />

        <NavbarSubMenu id={ID_EMPRESAS} parent={ID_NAVBAR}>
          <NavbarItem
            iconLeft={Type.ICON.COMPANY}
            value="Rede Empresas"
            rota="/compras/redeempresa"
          />
          <NavbarItem
            iconLeft={Type.ICON.UNIVERSITY}
            value="Empresas"
            rota="/compras/empresa"
          />
        </NavbarSubMenu>

        <NavbarItemMenu
          iconLeft={Type.ICON.SERVER}
          value="Fornecedores"
          rota="/compras/fornecedor"
        />

        <NavbarItemMenu
          iconLeft={Type.ICON.USERS}
          value="Usuários"
          rota="/compras/usuario"
        />

        <NavbarItemMenu
          iconLeft={Type.ICON.ADDRESS_BOOK}
          // iconRight={Type.ICON.ARROW_ANGLE_DOWN}
          value="Minha Conta"
          target={`#${ID_MEUSDADOS}`}
          rota="/compras/meusdados"
        />
      </Navbar>
    );
  }

  render() {
    const user = this.props.user;

    return (
      <Div>
        <If and value1={user.tipo === "USUARIO" || user.tipo === "GESTOR" || user.tipo === "EMPRESA"}>
          {this.renderNavBarUser()}
        </If>
        <If and value1={user.tipo === "FORNECEDOR"}>
          {this.renderNavBarFornecedor()}
        </If>
      </Div>
    );
  }
}

const mapStateToProps = (store) => ({
  user: store.loginState.user,
});

export default connect(mapStateToProps)(MenuLeft);
