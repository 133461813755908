import axios from "axios";
import Loading from "../../components/spinner/Loading";
import Utils from "../utils/Utils";

const URL_TOKEN = "oauth/token";


const BASE_URL = `https://${window.location.hostname}:443/compras/v1/`;
const BASE_URL_SOCKET = `https://${window.location.hostname}:443/compras/v1/`;

//const BASE_URL = `https://${window.location.hostname}/compras/v1/`;
//const BASE_URL_SOCKET = `https://${window.location.hostname}/compras/v1/`; 

//const BASE_URL = `http://localhost:8587/compras/v1/`;
//const BASE_URL_SOCKET = `http://localhost:8587/compras/v1/`;

//const BASE_URL = `https://otimizze.mobioh.com.br:8587/compras/v1/`;
//const BASE_URL = `https://compras-teste.mobioh.com.br/compras/v1/`;


const BASIC_AUTHORIZATION = {
  username: "compras",
  password: "compras",
};

const authorization = "compras:compras"

const getToken = async () => {
  try {
    const response = await axios.get(BASE_URL.concat(URL_TOKEN), {
      params: { grant_type: "client_credentials" },
      auth: BASIC_AUTHORIZATION,
    });

    sessionStorage.setItem("token", response.data.access_token);

    let token = `Bearer ${response.data.access_token}`;
    let expires_in = response.data.expires_in;

    Utils.refreshPage(Number(expires_in) * 1000);

    return token;
  } catch (erro) {
    let message = String(erro.message)
      .trim()
      .toLocaleLowerCase()
      .replace(" ", "_");

    console.log(message);
    if (message.includes("network_error")) {
      console.log("Falha ao consultar token");
    }
  }

  return null;
};


const getAxiosCreate = async () => {
  let basicAuthorization = "Basic " + btoa(authorization);

  let CONFIG = {
    baseURL: BASE_URL,
    headers: {
      "Content-Type": "application/json",
      Authorization: basicAuthorization,
    },
  };

  return axios.create(CONFIG);
};

const getAxiosInstance = async () => {
  let axiosCreate = await getAxiosCreate();

  axiosCreate.interceptors.request.use(
    (config) => {
      Loading.show();
      return config;
    },
    (error) => {
      Loading.hide();
      Promise.reject(error);
    }
  );
  axiosCreate.interceptors.response.use(
    (response) => {
      Loading.hideTime();
      return response;
    },
    (error) => {
      let response = error.response;
      if (Utils.isValueValid(response)) {
        if (response.status !== Number(401)) {
          Loading.hide();
          return Promise.reject(error);
        }
      } else {
        Loading.hide();
        return Promise.reject(error);
      }

    //  Utils.refreshPage();
    }
  );
  return axiosCreate;
};

export const URL_TOKEN_SOCKET = () => {
  let basicAuthorization = btoa(authorization);

  return `${BASE_URL_SOCKET}ws?Basic=${basicAuthorization}`;
};

export const URL_TOKEN_SOCKET_NOVO = () => {
  let basicAuthorization = btoa("Basic " + authorization);

  return `${BASE_URL_SOCKET}ws`;
};

export const HEADER_SOCKET = () => {
  let basicAuthorization = "Basic " + btoa("Basic " + authorization);

  return {
    Authorization: basicAuthorization
  };
};

export default getAxiosInstance();
