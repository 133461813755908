import React from "react";
import { Mask, Type } from "../../../utilities";
import InputDefault from "./InputDefault";
import Pattern from "../../../utilities/enuns/Pattern";

const InputPhone = props => {
  return (
    <InputDefault
      {...props}
      formcontrol
      pattern={Pattern.INPUT.PHONE.pattern}
      title={Pattern.INPUT.PHONE.message}
      placeholder={Pattern.INPUT.PHONE.example}
      type={Type.INPUT.PHONE}
      handlerChange={e => {
        Mask.maskPhone(e);
        return props.handlerChange(e);
      }}
    />
  );
};

export default InputPhone;
