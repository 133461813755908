import React, { Component } from "react";
import { Color, Http } from "../../utilities";

import {
  Row,
  InputDefault,
  Form,
  SelectValue,
  Select,
  Notification,
  SectionForm,
  SectionButtonForm,
  Button  
} from "../../components";
import { connect } from "react-redux";


class Bloqueiar extends Component {

  constructor(props) {
    super(props);

    this.state = {
      nameButton: "Salvar",
      user: props.user,   
      pessoas: [],         
      ...this.props.location.state 
    };

    this.submit = this.submit.bind(this);
    this.listaGestores = this.listaGestores.bind(this);    
    this.configuraGestor = this.configuraGestor.bind(this);    
    this.getDataPessoasSelect = this.getDataPessoasSelect.bind(this);    
    this.bloqueirar = this.bloqueirar.bind(this);    
     

  }

  UNSAFE_componentWillMount() {    
    this.getDataPessoasSelect();   
    let gestor = this.state.gestor;    
    if (this.state.editar) {
      this.configuraGestor(gestor);  
    }
  }

  configuraGestor(gestor) {
    this.setState({
        nameButton: "Alterar",        
        idPessoa: gestor.idPessoa,
        idUsuario: gestor.idUsuario,
        idGestorCompras: gestor.idGestorCompras,
        idUsuarioAtualizacao: this.state.user.idUsuario,
        codigoGestorCompras: gestor.codigoGestorCompras,	
        descricao: gestor.descricao,
        motivoBloqueio : gestor.motivoBloqueio
    })    
  }


  listaGestores() {
    this.props.history.push({pathname: `/compras/gestor`});
  }


  async getDataPessoasSelect() {           
    await Http.get(`/pessoa/listarEmpresas/` + this.state.user.idGestorCompras)
      .then(({ status, data }) => {        
        if (data) {
          let pessoaComDados = [];
          pessoaComDados.push(new SelectValue("", "SELECIONE A PESSOA", true));
          Array.from(data).map((pessoa) => {
            pessoaComDados.push(
              new SelectValue(pessoa.idPessoa, pessoa.nome, false)
            );
          });
          this.setState({ pessoas: pessoaComDados });
        }
      })
      .catch((error) => console.log(error));
  }
  
  async submit(event) {
    event.preventDefault();
    if (event.target.reportValidity()) {
        this.bloqueirar(this.state);
    }
  }

  bloqueirar() {       
    this.setState({idUsuarioInativacao: this.state.user.idUsuario}); 
     Http.put(`/gestor/bloqueiar`, this.state)
    .then(({ status, data }) => {
      if (data.status) {
        Notification.success(data.mensagem);
        this.listaGestores();        
      } else {
        Notification.danger(data.mensagem);
      }
    })
    .catch((error) => console.log(error));    
  }

   render() {
    return (
      <Form id="formBloqueiarGestor" handlerSubmit={this.submit}>
          <SectionForm borderLeft="primary">
            <Row col="12">
            <Select disabled
                responsive="4"
                label="Pessoa"
                valueSelected={this.state.idPessoa}
                handlerChange={(e) => {
                  this.setState({idPessoa: e.target.value });                                                          
                }}
                values={this.state.pessoas}
              />

              <InputDefault  required
                  label="Motivo Bloqueio"
                  name="motivoBloqueio"
                  responsive="8"
                  value={this.state.motivoBloqueio}
                  handlerChange={(e) => {
                    this.setState({motivoBloqueio: e.target.value });                  
                  }}
                />
            </Row>

            <SectionButtonForm>
                <Button
                  btn={Color.NODE.PRIMARY}
                  value="Bloqueiar"
                  col="1"
                  margin="1"
                />

                <Button
                  btn={Color.NODE.DANGER}
                  value="Cancelar"
                  col="1"
                  handlerClick={(_e) => this.listaGestores()}
                  margin="5"
                />
              </SectionButtonForm>
          </SectionForm> 
    </Form>)
  }
   
}

const mapStateToProps = (store) => ({
  user: store.loginState.user,
});

export default connect(mapStateToProps)(Bloqueiar);
