import React from "react";
import { Div } from "..";
import I from "../i/I";
import { Color, Type } from "../../utilities";

const renderStars = (score, color) => {
  return [1, 2, 3, 4, 5].map((i, index) => {
    let icon = i <= score ? Type.ICON.STAR_FULL : Type.ICON.STAR;
    return <I key={index} icon={icon} colorText={color} />;
  });
};

const Stars = props => {
  return <Div>{renderStars(props.score, props.color)}</Div>;
};

Stars.defaultProps = {
    score: 0
}

export default Stars;
