import React, { Component } from "react";
import { Div, Table, InputSearch, Title  , Notification, ModalNotification} from "../../components";
import { connect } from "react-redux";
import { Color, PathRoute, Http, Type } from "../../utilities";

const ENTER = 13;

class Listar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: props.user,
      dialoggrupo: false,
      grupos: [],
      grupo: {},
      selectedgrupo: {},
      globalFilter: null,
      ...this.props.location.state,
    };    
    
  }

  UNSAFE_componentWillMount() {
    this.getDataGrupos();
  }

  async getDataGrupos() {        
    await Http.get(`/grupo/listar/` + this.state.user.idGestorCompras)
      .then(({ status, data }) => {        
        if (data) {
          this.setState({ grupos: data });
        }
      })
      .catch((error) => console.log(error));
  }

  leftToolbarTemplate = () => {
    return (
      <InputSearch                           
      label="Procurar na Grid"
      name="globalFilter"              
      value={this.state.globalFilter}
      handlerChange={(e) => this.setState({ globalFilter: e.target.value })}
      placeholder="Procurar..."
    />
    )
  }

  rightToolbarTemplate = () => {
    return (      
        <span className="p-input-icon-right">           

        </span>      
    )
  }

  exportCSV () {

  }

  editarGrupo(grupo) {
    this.props.history.push({
      pathname: PathRoute.PATH.GRUPO_EDITAR,
      state: { grupo: grupo, editar: true },
    });
  }


  deletarGrupo(grupo) {    
    ModalNotification.confirm(
      "Deseja desativar este Grupo? " + grupo.descricao,
      "Este Grupo será desativada no sistema de compras!",
      () => this.delete(grupo),
      () => this.cancelar()
    );
  }

  async delete(grupo) {    
    grupo.idUsuarioInativacao = this.state.user.idUsuario
    await Http.post(`/grupo/deletar`, grupo)
    .then(({ status, data }) => {
      if (data.status) {
        Notification.success(data.mensagem);
        this.getDataGrupos();        
      } else {
        Notification.danger(data.mensagem);
      }
    })
    .catch((error) => console.log(error));
  }

  cancelar() {
    
  }

  render() {
    
    return (
      <Div margin="3" shadow bg={Color.NODE.WHITE} borderLeft={Color.NODE.PRIMARY}>

        <Title value="Grupos" type="h4" paddingLeft="4" paddingRight="4" paddingTop="3"/>
        
        <Div padding="2">
          <Table
            columns={[              
              { value: "Departamento", align: "center" },
              { value: "Descrição", align: "center" },
              { value: "Sequência", align: "center" },
            ]}
            fields={[
              { value: "nomeDepartamneto",    type: Type.DADO.STRING },
              { value: "descricao",    type: Type.DADO.STRING },
              { value: "sequencia",    type: Type.DADO.STRING }
            ]}
            bordered
            search
            orientation="center"
            pagination
            rota={PathRoute.PATH.GRUPO_CADASTRAR}
            edit
            delete            
            handlerEdit={(grupo) => this.editarGrupo(grupo)}
            handlerDelete={(grupo) => this.deletarGrupo(grupo)}            
            data={this.state.grupos}
            error="Nenhum Grupo encontrada"
          ></Table>
        </Div>
      </Div>
    );
  }
}

const mapStateToProps = (store) => ({
  idGestorCompras: store.loginState.user.idGestorCompras,
  idUsuario: store.loginState.user.idUsuario,
});

export default connect(mapStateToProps)(Listar);
