import React, { Component } from "react";
import { Div, Row, Table, ButtonIconOutLine, Title, } from "../../components";
import { connect } from "react-redux";
import { Color, Http, Type} from "../../utilities";
import { relatorioPDF } from "../../utilities/utils/RelatorioPDF";

class Apuracao extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: props.user,
      dialogPessoa: false,
      pessoas: [],
      pessoa: {},
      selectedPessoa: {},
      cotacoes: [],
      produtosInseridos: [],
      cotacaoSelecionados: [],
      globalFilter: null,
      selecionarTodos: false,
      divCotacao: false,
      divProduto: false,
      ...this.props.location.state,
    };
    
  }

  UNSAFE_componentWillMount() {
    var user = this.props.user;
  
    if(user.idPessoa != null) {
        this.getDataCotacao(user.idPessoa);        
    } 
  }



  getDadosCotacao(cotacao) { 
    this.setState({cotacao: cotacao });   
    this.getDataEmpresaCotacao(cotacao);
    this.getDataProdutosCotacao(cotacao);
    this.getDataPedidoCotacao(cotacao);    
  }


  getDataCotacao(idPessoa) {
    this.setState({cotacoes: []});
    Http.get(`/cotacao/fornecedor/status/` + idPessoa).then(({ data }) => {                        
      if (data) {  
        Array.from(data).map((pre) => {
          pre.nameStatus = this.enumStatusPesquisa(pre.status);
        });           
        this.setState({divCotacao: true, cotacoes: data });
      }
    });
  }

  getDataEmpresaCotacao(cotacao) {
    this.setState({ divProduto: false, empresas: [] });

    Http.get("/cotacao/empresa/" + cotacao.idCotacao)
    .then(({ status, data }) => {           
      if (data) {                                    
        this.setState({ divProduto: true, empresas: data});              
      }
    })
    .catch((error) => console.log(error));      
  }
  
  getDataProdutosCotacao(cotacao) {
    this.setState({ produtosInseridos: [] });

    var strCotacaoSelecionados = cotacao.idCotacao;
    var strFornecedorSelecionados = this.props.user.idPessoa;

    Http.get("/cotacao/" + strCotacaoSelecionados + "/produtos/enviados/" + strFornecedorSelecionados)
    .then(({ status, data }) => {           
      if (data) {                                    
        this.setState({ produtosInseridos: data});              
      }
    })
    .catch((error) => console.log(error));  
    
  }

  getDataPedidoCotacao(cotacao) {
    this.setState({ pedidos: [] });

    Http.get("/cotacao/" + cotacao.idCotacao + "/pedido/" + this.props.user.idPessoa)
    .then(({ status, data }) => {           
      if (data) {                                    
        this.setState({ pedidos: data});              
      }
    })
    .catch((error) => console.log(error));      
  }

  enumStatusPesquisa(status) {
    switch (status) {
      case "ABERTA":
        return "ABERTA";

      case "CONCLUIDO":
        return "CONCLUÍDO";

      case "CANCELADO":
        return "CANCELADO";
        
      case "ENVIADO_FORNECEDOR" ||"EMVIADO_FORNECEDOR":
        return "ENVIADO P/ FORNECEDOR";

      case "AGUARDANDO_APURACAO":
        return "AGUARDANDO APURAÇÃO";

      default:
        return null;
    }
  }

  exportarPDF() {
    
    const header = [
      {
        text: 'Pedido',
        fontSize: 16,
        bold: true,
        margin: [15, 20, 0, 15, 15],
      },
    ]

    const dadosEmpresa = this.state.empresas.map(dados => {
      return [
        {
          text: dados.nomeEmpresa ? dados.nomeEmpresa : '',
          fontSize: 8,
        },
        {
          text: dados.cpfCnpjEmpresaMask ? dados.cpfCnpjEmpresaMask : '',
          fontSize: 8,
          alignment: 'center'
        }
      ]
    })

    const tableEmpresa = {
      margin: [0, 15],
      table: {
        headerRows: 1,
        widths: ['*', '*'],
        body: [
          //colunas
          [
            { text: 'Cliente', style: 'tableHeader', },
            { text: 'CNPJ', style: 'tableHeader', alignment: 'center' }            
          ],
          //linhas
          ...dadosEmpresa
        ]
      },
      layout: {
        fillColor: function (rowIndex, node, columnIndex) {
          return (rowIndex % 2 === 0) ? '#F2F2F2' : null;
        }
      }
    }


    const dadosproduto = this.state.produtosInseridos.map(dados => {
      return [
        {
          text: dados.codigoproduto ? dados.codigoproduto : '',
          fontSize: 8,
        },
        {
          text: dados.descricao ? dados.descricao : '',
          fontSize: 8,
          alignment: 'center'
        },
        {
          text: dados.ean ? dados.ean : '',
          fontSize: 8,
          alignment: 'center'
        },
        {
          text: dados.quantidadesolicitada ? dados.quantidadesolicitada : '',
          fontSize: 8,
          alignment: 'center'
        },
        {
          text: dados.quantidadedisponivel ? dados.quantidadedisponivel : '',
          fontSize: 8,
          alignment: 'center'
        },
        {
          text: dados.quantidademinimapedido ? dados.quantidademinimapedido : '',
          fontSize: 8,
          alignment: 'center'
        },
        {
          text: dados.preco ? dados.precoMask : '',
          fontSize: 8,
          alignment: 'center'
        }
      ]
    })


    const tableProduto = {
      margin: [0, 15],
      table: {
        headerRows: 1,
        widths: ['*', '*', '*', '*', '*', '*', '*'],
        body: [
          //colunas
          [
            { text: 'PLU', style: 'tableHeader', },
            { text: 'Descricao', style: 'tableHeader', alignment: 'center' },
            { text: 'Código de Barras', style: 'tableHeader', alignment: 'center' },
            { text: 'Qdt Solicitada', style: 'tableHeader', alignment: 'center' },
            { text: 'Qdt Disponível', style: 'tableHeader', alignment: 'center' },
            { text: 'Qdt Miníma', style: 'tableHeader', alignment: 'center' },
            { text: 'Preço', style: 'tableHeader', alignment: 'center' }
          ],
          //linhas
          ...dadosproduto
        ]
      },
      layout: {
        fillColor: function (rowIndex, node, columnIndex) {
          return (rowIndex % 2 === 0) ? '#F2F2F2' : null;
        }
      }
    }

    const dadosPedido = this.state.pedidos.map(dados => {
      return [
        {
          text: dados.valortotal ? dados.valortotalMask : '',
          fontSize: 8,
        },
        {
          text: dados.tipopagamento ? dados.tipopagamento : '',
          fontSize: 8,
          alignment: 'center'
        },
        {
          text: dados.planopagamento ? dados.planopagamento : '',
          fontSize: 8,
          alignment: 'center'
        },
        {
          text: dados.prazoentrega ? dados.prazoentrega : '',
          fontSize: 8,
          alignment: 'center'
        }
      ]
    }) 

    const tablePedido = {
      margin: [0, 15, 15],
      table: {
        headerRows: 1,
        widths: ['*', '*', '*', '*'],
        body: [
          //colunas
          [
            { text: 'Valor', style: 'tableHeader', },
            { text: 'Tipo Pagamento', style: 'tableHeader', alignment: 'center' },
            { text: 'Plano Pagamento', style: 'tableHeader', alignment: 'center' },
            { text: 'Prazo Entrega', style: 'tableHeader', alignment: 'center' } 
          ],
          //linhas
          ...dadosPedido
        ]
      },
      layout: {
        fillColor: function (rowIndex, node, columnIndex) {
          return (rowIndex % 2 === 0) ? '#F2F2F2' : null;
        }
      }
    }

    const content = [             

      {
        text: 'Cliente',
        margin: [0, 18]
      },
      tableEmpresa,

      {
        text: 'Produtos',
        margin: [0, 15]
      },
      tableProduto,

      {
        text: 'Pedido',
        margin: [0, 15]
      },
      tablePedido,
    ]


    const styles = {
      tableHeader: {
        bold: true,
        fontSize: 9,
        color: 'black'
      },
      tableHeaderApuracao: {
        bold: true,
        fontSize: 8,
        color: 'black'
      },
      apuracaoRows: {
        bold: false,
        fontSize: 7,
        margin: [0, 2, 0, 2]
      }
    }

    let orientation = 'landscape'


    relatorioPDF(header, content, styles, orientation);
  }

  render() {
    const {
      user,
      cotacoes,
      empresas,            
      pedidos,
      produtosInseridos
    } = this.state;

    return (
      <Div margin="3" shadow bg={Color.NODE.WHITE} borderLeft={Color.NODE.PRIMARY}>

        <Div padding="2">
          <Div padding="2">
            <Title value={user.nome} type="h4" paddingLeft="2" paddingRight="2" paddingTop="2"/>
          </Div>  
        </Div>   
 
        {this.state.divCotacao && (
          <Div padding="2">

          <Div padding="2">
            <Title value="Cotação" type="h4" paddingLeft="2" paddingRight="2" paddingTop="2"/>
          </Div>  

          <Table
            columns={[              
              { value: "Descrição", align: "center" },
              { value: "Tipo", align: "center" },
              { value: "Status", align: "center" },
              { value: "Início da Vigência", align: "center" },
              { value: "Fim da Vigência", align: "center" }              
            ]}
            fields={[
              { value: "descricao",    type: Type.DADO.STRING },
              { value: "tipo",    type: Type.DADO.STRING },
              { value: "nameStatus",    type: Type.DADO.STRING },
              { value: "iniciovigencia",    type: Type.DADO.DATE },
              { value: "fimvigencia",    type: Type.DADO.DATE },              
            ]}
            bordered            
            orientation="center"       
            data={cotacoes}
            edit
            handlerEdit={(cotacao) => this.getDadosCotacao(cotacao)}
            error="Nenhuma cotação encontrada"          
          ></Table>
        </Div>
        )}  

        {this.state.divProduto && (       
          <Div>

<Div padding="2">

      <Div padding="2">
        <Title value="Cliente" type="h4" paddingLeft="2" paddingRight="2" paddingTop="2"/>
      </Div>  

        <Table
            columns={[
              { value: "Nome", align: "center" },
              { value: "CNPJ", align: "left" },
            ]}
            fields={[
              { value: "nomeEmpresa",  align: "center", type: Type.DADO.STRING },
              { value: "cpfCnpjEmpresa", align: "left", type: Type.DADO.CPF_CNPJ }
            ]}
          bordered              
          orientation="center"       
          data={empresas}
          error="Nenhum empresa encontrado"            
        ></Table>
        </Div>

          <Div padding="2">

            <Div padding="2">
              <Title value="Produtos" type="h4" paddingLeft="2" paddingRight="2" paddingTop="2"/>
            </Div>  

            <Table
                columns={[
                  { value: "PLU", align: "center" },
                  { value: "Descricao", align: "left" },
                  { value: "Código de Barras", align: "center" },
                  { value: "Qdt Solicitada", align: "center" },
                  { value: "Qdt Disponível", align: "center" },
                  { value: "Qdt Miníma", align: "center" },                  
                  { value: "Preço", align: "center" }
                ]}
                fields={[
                  { value: "codigoproduto",  align: "center", type: Type.DADO.STRING },
                  { value: "descricao", align: "left", type: Type.DADO.STRING },
                  { value: "ean", align: "left", type: Type.DADO.STRING },
                  { value: "quantidadesolicitada", align: "left", type: Type.DADO.INTEGER },
                  { value: "quantidadedisponivel", align: "left", type: Type.DADO.INTEGER },
                  { value: "quantidademinimapedido", align: "left", type: Type.DADO.INTEGER },
                   { value: "preco", align: "left", type: Type.DADO.CURRENCY},
                ]}
              bordered              
              orientation="center"       
              data={produtosInseridos}
              error="Nenhum produto encontrado"            
            ></Table>
        </Div>

        <Div padding="2">

        <Div padding="2">
          <Title value="Pedido Ganhador" type="h4" paddingLeft="2" paddingRight="2" paddingTop="2"/>
        </Div>  

        <Table
            columns={[
              { value: "Valor", align: "center" },
              { value: "Tipo Pagamento", align: "left" },
              { value: "Plano Pagamento", align: "center" },
              { value: "Prazo Entrega", align: "center" }
            ]}
            fields={[
              { value: "valortotal",  align: "center", type: Type.DADO.CURRENCY },
              { value: "tipopagamento", align: "left", type: Type.DADO.STRING },
              { value: "planopagamento", align: "left", type: Type.DADO.STRING },
              { value: "prazoentrega", align: "left", type: Type.DADO.INTEGER }
            ]}
          bordered              
          orientation="center"       
          data={pedidos}
          error="Nenhum produto encontrado"            
        ></Table>
        </Div>

        <Row
          col="12"
          style={{ justifyContent: "flex-end", padding: "16px" }}
        >
          <ButtonIconOutLine
            value="Exportar PDF"
            icon={Type.ICON.PDF}
            btn={Color.NODE.DANGER}
            handlerClick={() => this.exportarPDF()}
          />
        </Row>

      </Div>
        )}        
      </Div>
    );
  }
}

const mapStateToProps = (store) => ({
  idEmpresa: store.loginState.user.idEmpresa,
  idFilial: store.loginState.user.idFilial,
});

export default connect(mapStateToProps)(Apuracao);
