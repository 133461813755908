import React, { PureComponent } from "react";

import { Div } from "../../components";
import Chart from "chart.js";

import plugin from "chartjs-plugin-labels";

const DD = "2d";

export default class ChartPie extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      data: props.data || [],
      labels: []
    };
  }

  loadChart() {
    const { id } = this.props;
    let config = this.getConfigCharLine();
    Array.from(Object.values(Chart.instances)).forEach(instance => {
      if (instance.canvas.id === id) {
        instance.destroy();
      }
    });

    let chartCanvas = document.getElementById(id);
    if (chartCanvas) {
      chartCanvas = chartCanvas.getContext(DD);
      new Chart(chartCanvas, config);
    }
  }

  componentWillReceiveProps(props) {
    this.setState(
      {
        data: props.data
      },
      () => {
        this.loadChart();
      }
    );
  }

  getConfigCharLine() {
    const {
      title,
      type,
      widthBar,
      semiCircle,
      arcLabel,
      displayLegend,
      displayLabel
    } = this.props;
    const { labels, datasets } = this.state.data;
    return {
      type: type,
      data: {
        labels: labels,
        datasets: datasets
      },
      options: {
        cutoutPercentage: widthBar || 0,
        rotation: semiCircle ? Math.PI : -0.5 * Math.PI,
        circumference: semiCircle ? Math.PI : 2 * Math.PI,
        title: {
          display: true,
          text: title
        },
        legend: {
          display: displayLegend
        },
        tooltips: {
          callbacks: {
            label: (tooltipItems, data) => {
              let label = this.props.handlerTooltip(tooltipItems, data);
              return label;
            }
          }
        },
        plugins: {
          labels: displayLabel
            ? {
                render: "label",
                arc: arcLabel
              }
            : false
        }
      }
    };
  }

  render() {
    const { styles, id } = this.props;
    return (
      <Div {...styles}>
        <canvas id={id}></canvas>
      </Div>
    );
  }
}

ChartPie.defaultProps = {
  type: "pie"
};
