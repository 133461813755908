import React, { Component } from "react";
import Div from "../layout/div/Div";
import I from "../i/I";
import Type from "../../utilities/enuns/Type";
import Label from "../texts/label/Label";
import './alert.css'

class Alert extends Component {
  constructor(props) {
    super(props);

    this.state = {
      timer: props.timer | 0
    };

    this.closed = false;

    this.closeAlert = this.closeAlert.bind(this);
    this.onDismiss = this.onDismiss.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.closeAlert();
  }

  onDismiss() {
    document
      .getElementById("alert-mobioh")
      .classList.toggle("alert-mobioh-active");
  }

  closeAlert = () => {
    this.timeout = setTimeout(() => {
      if (!this.closed) {
        this.onDismiss();
        this.closed = true
      } else {
        this.closed = false;
      }
    } , this.state.timer * 1000)
  }

  getClassAlert() {
    let classe = "alert-mobioh alert-mobioh-active text-white fixed-top ";
    classe = classe.concat(this.props.danger ? "bg-danger" : "");
    classe = classe.concat(this.props.info ? "bg-info" : "");
    classe = classe.concat(this.props.warning ? "bg-warning" : "");
    classe = classe.concat(this.props.success ? "bg-success" : "");
    //  classe = classe.concat(this.props.show ? "alert-mobioh-active " : "");
    return classe;
  }


  componentDidUpdate(props, state) {
    if (this.closed) {
      this.closed = false
      this.onDismiss();
      this.closeAlert();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeout)
  }

  close() {
    this.onDismiss();
    clearTimeout(this.timeout)
    this.closed = true;
  }

  render() {
    return (
      <Div className={this.getClassAlert()} id="alert-mobioh" style={{ zIndex: 99999  }}>
        <Div className="d-flex justify-content-center flex-wrap">
          {this.props.info && (
            <Div float="left" paddingLeft="2" paddingTop="2">
              <I icon={Type.ICON.INFO} sizeIcon="2" ></I>
            </Div>
          )}
          {(this.props.danger || this.props.warning) && (
            <Div float="left" paddingLeft="2" paddingTop="2">
              <I icon={Type.ICON.EXCLAMATION} sizeIcon="2"></I>
            </Div>
          )}
          {this.props.success && (
            <Div float="left" paddingLeft="2" paddingTop="2">
              <I icon={Type.ICON.SUCCESS} sizeIcon="2"></I>
            </Div>
          )}
          <Div float="left" paddingLeft="2" className="mg-top-alert">
            <Label>{this.props.message}</Label>
          </Div>
        </Div>
        <Div className="float-right icon-close-navbar-top">
          <I
            sizeIcon="2"
            pointer
            marginRight="2"
            icon={Type.ICON.CLOSE}
            handlerClick={e => this.close()}
          ></I>
        </Div>
      </Div>
    );
  }
}

export default Alert;
