/* eslint-disable jsx-a11y/anchor-has-content */
import React, { Component } from "react";
import { Color, Http, Mask, Type, Pattern, DateUtils, Utils } from "../../utilities";

import {
  Div, Label, If, NavbarTab,
  Row,
  Table,
  Title,
  InputDefault,
  InputInteger,
  InputCep,
  InputPhone, 
  InputDate, 
  InputCpfCnpj,  
  InputFile,
  Form,
  SelectValue,
  Select,
  Notification,
  SectionForm,
  SectionButtonForm,
  Button,
  ModalNotification
} from "../../components";
import { connect } from "react-redux";


class Cadastrar extends Component {

  constructor(props) {
    super(props);

    this.state = {
      nameButton: "Salvar",
      nameButtonEndereco: "Adicionar",   
      nameButtonContato: "Adicionar",   
      user: props.user,            
      enderecos: [],
      contatos: [],      
      arquivos: [],
      estados: [],
      cidades: [],       
      itemActive: {
        target: "PESSOA",
        value: "PESSOA",
        active: true        
      },
      ...this.props.location.state 
    };

    this.submit = this.submit.bind(this);
    this.listaPessoas = this.listaPessoas.bind(this);    
    this.validaForm = this.validaForm.bind(this);    
    this.removerMascara = this.removerMascara.bind(this);  
    this.configuraPessoa = this.configuraPessoa.bind(this);        
    this.configuraEndereco = this.configuraEndereco.bind(this);        
    this.getEstadosSelect = this.getEstadosSelect.bind(this);        
    this.getInfoReceita = this.getInfoReceita.bind(this);        
    this.setFormPessoa = this.setFormPessoa.bind(this);        

    this.submitEndereco = this.submitEndereco.bind(this);      
    this.listaEnderecos = this.listaEnderecos.bind(this);      
    this.cleanFormEndereco = this.cleanFormEndereco.bind(this);       
    this.editarEndereco = this.editarEndereco.bind(this);       
    this.deletarEndereco = this.deletarEndereco.bind(this);         

    this.submitContato = this.submitContato.bind(this);         
    this.seFormContato = this.seFormContato.bind(this);  
    this.validaFormContato = this.validaFormContato.bind(this);           
    this.listaContatos = this.listaContatos.bind(this);      
    this.cleanFormContato = this.cleanFormContato.bind(this);       
    this.editarContato = this.editarContato.bind(this);       
    this.deletarContato = this.deletarContato.bind(this); 
    
    this.submitArquivo = this.submitArquivo.bind(this);         
    this.listaArquivos = this.listaArquivos.bind(this);      
    this.cleanFormArquivo = this.cleanFormArquivo.bind(this);           
    this.deletarArquivo = this.deletarArquivo.bind(this); 
    this.handlerFile = this.handlerFile.bind(this); 

  }

  UNSAFE_componentWillMount() {   
    this.getEstadosSelect();       
    let pessoa = this.state.pessoa;    
    if (this.state.editar) {
      this.setState({nameButton: "Alterar"});
      this.configuraPessoa(pessoa);
      this.listaEnderecos(pessoa.idPessoa);  
      this.listaContatos(pessoa.idPessoa);
      this.listaArquivos(pessoa.idPessoa);   
      this.cleanFormEndereco();
      this.cleanFormContato();
      this.cleanFormArquivo();
    }
  }

  configuraPessoa(pessoa) {
    this.setState({        
        idPessoa: pessoa.idPessoa,        
        idGestorCompras: pessoa.idGestorCompras,
        codigoAtividadePrincipal: pessoa.codigoAtividadePrincipal,
        descricaoAtividadePrincipal : pessoa.descricaoAtividadePrincipal,
        tipoCadastro: "PESSOA", 
        nome : pessoa.nome,
        razao : pessoa.razao,
        codigoPessoa : pessoa.codigoPessoa,
        tipoPessoa : pessoa.tipoPessoa,
        tipoRegime : pessoa.tipoRegime,
        cpfCnpj : Mask.maskValueCpfCnpj(pessoa.cpfCnpj),
        codigoCnae : pessoa.codigoCnae,
        inscricaoEstatual : pessoa.inscricaoEstatual,
        inscricaoMunicipal : pessoa.inscricaoMunicipal,
        rg : pessoa.rg,
        emailPrincipal : pessoa.emailPrincipal,
        emailFinanceiro : pessoa.emailFinanceiro,
        emailSecundario : pessoa.emailSecundario,
        contatoPrincipal : pessoa.contatoPrincipal,
        contatoFinanceiro : pessoa.contatoFinanceiro,
        contatoSecundario : pessoa.contatoSecundario,
        telefonePrincipal :  Mask.maskValuePhone(pessoa.telefonePrincipal),
        telefoneFinanceiro : Mask.maskValuePhone(pessoa.telefoneFinanceiro),
        telefoneSecundario : Mask.maskValuePhone(pessoa.telefoneSecundario),
        tipoFrete : pessoa.tipoFrete,
        arqBalancete : pessoa.arqBalancete,
        arqFicha : pessoa.arqFicha,
        arqContrato : pessoa.arqContrato,
        idUsuarioAtualizacao: this.state.user.idUsuario
    });
    
  }

  configuraEndereco(endereco) {    
    this.setState({
      complemento: endereco.complemento,
			bairro: endereco.bairro,
      cep: endereco.cep,
			numero: endereco.numero,
			endereco: endereco.endereco
    });
  }

  getInfoReceita(cpfCnpj){
    var strAux = Mask.clearMask(cpfCnpj);    
    if (strAux.length > 11) {
        Http.get("/service/searchCpfCnpj/" + strAux).then(({ data }) => {           
          if (data.status) {
            this.configuraPessoa(data)
            this.configuraEndereco(data.endereco);
            this.setState({
              idPessoa: undefined,
              tipoPessoa: "JURIDICA"
            });
          } else {
            Notification.info(data.mensagem);
          }
        });
    }
  }

  getEstadosSelect() {
    this.setState({cidades: []});
    Http.get("/cidade/listarEstados").then(({ data }) => {            
      if (data) {              
        let estadosComDados = [];
        estadosComDados.push(new SelectValue(0, "SELECIONE", true));
        Array.from(data).map((estado) => {
          estadosComDados.push(
            new SelectValue(estado.idestadoibge, estado.nomeestado, false)
          );
        });
        this.setState({ estados: estadosComDados });
      }
    });
  }

  getCidadeSelect(idestadoibge) {
    this.setState({cidades: []});
    Http.get("/cidade/listarCidades/" + idestadoibge).then(({ data }) => {            
      if (data) {              
        let cidadesComDados = [];
        cidadesComDados.push(new SelectValue(0, "SELECIONE", true));
        Array.from(data).map((estado) => {
          cidadesComDados.push(
            new SelectValue(estado.idcidadeibge, estado.nomecidade, false)
          );
        });
        this.setState({ cidades: cidadesComDados });
      }
    });
  }

  getItens = () => {
    let item = this.state.itemActive;
    if (this.state.idPessoa === undefined) {
      return [
        {
          target: "PESSOA",
          value: "PESSOA",
          active: item.value === "PESSOA",
        }
      ];
    } else {
      return [
        {
          target: "PESSOA",
          value: "PESSOA",
          active: item.value === "PESSOA",
        },
        {
          target: "ENDEREÇO",
          value: "ENDEREÇO",
          active: item.value === "ENDEREÇO",
        },
        {
          target: "CONTATO",
          value: "CONTATO",
          active: item.value === "CONTATO",
        },
        {
          target: "ARQUIVO",
          value: "ARQUIVO",
          active: item.value === "ARQUIVO",
        },
      ];
    }
    
  };

  getTipoCadastroSelect() {
    return [
      new SelectValue("", "SELECIONE", true),
      new SelectValue("EMPRESA", "EMPRESA", false),
      new SelectValue("FORNECEDOR", "FORNECEDOR", false),
      new SelectValue("FILIAL", "FILIAL", false),
      new SelectValue("REPRESENTANTE", "REPRESENTANTE", false),    
      new SelectValue("TRANSPORTADORA", "TRANSPORTADORA", false)
    ];
  }

  getTipoCadastroEnderecoSelect() {
    return [
      new SelectValue("", "SELECIONE", true),
      new SelectValue("COMERCIAL", "COMERCIAL", false),
      new SelectValue("ENTREGA", "ENTREGA", false),
      new SelectValue("COBRANCA", "COBRANÇA", false),      
      new SelectValue("OUTROS", "OUTROS", false)      
    ];
  }  

  getTipoCadastroArquivoSelect() {
    return [
      new SelectValue("", "SELECIONE", true),
      new SelectValue("PUBLICO", "PUBLICO", false),
      new SelectValue("PRIVADO", "PRIVADO", false)    
    ];
  }

  getTipoArquivoSelect() {
    return [
      new SelectValue("", "SELECIONE", true),
      new SelectValue("BALANCETE", "BALANCETE", false),
      new SelectValue("FICHA", "FICHA", false),
      new SelectValue("CONTRATO", "CONTRATO", false),
      new SelectValue("OUTROS", "OUTROS", false)            
    ];
  }

  getTipoCadastroContatoSelect() {
    return [
      new SelectValue("", "SELECIONE", true),
      new SelectValue("VENDEDOR", "VENDEDOR", false),
      new SelectValue("GERENTE", "GERENTE", false),
      new SelectValue("SOCIO", "SOCIO", false),      
      new SelectValue("FUNCIONARIO", "FUNCIONARIO", false),      
      new SelectValue("OUTROS", "OUTROS", false)      
    ];
  }   

  getTipoPessoaSelect() {
    return [
      new SelectValue("", "SELECIONE", true),
      new SelectValue("FISICA", "FISICA", false),
      new SelectValue("JURIDICA", "JURÍDICA", false)
    ];
  }

  getRegimeTributario() {
    return [
      new SelectValue("", "SELECIONE", true),
      new SelectValue("ME", "MICRO EMPRESA", false),
      new SelectValue("SP", "SIMPLES", false),
      new SelectValue("LR", "LUCRO REAL", false),
      new SelectValue("LP", "LUCRO PRESUMIDO", false),
      new SelectValue("IS", "ISENTO", false)
    ];
  }
  

  getTipoFrete() {
    return [
      new SelectValue("", "SELECIONE", true),
      new SelectValue("CIF", "CIF", false),
      new SelectValue("FOB", "FOB", false),
      new SelectValue("PCIF", "PCIF", false),
      new SelectValue("PFOB", "PFOB", false),
      new SelectValue("TERC", "TERC", false),
      new SelectValue("GRAT", "GRAT", false)
    ];
  }
  

  listaPessoas() {
    this.props.history.push({pathname: `/pessoa`});
  }

  setFormPessoa() {
    return {
      idPessoa: this.state.idPessoa,        
      idGestorCompras: this.state.user.idGestorCompras,
      codigoAtividadePrincipal: this.state.codigoAtividadePrincipal,
      descricaoAtividadePrincipal : this.state.descricaoAtividadePrincipal,      
      nome : this.state.nome,
      razao : this.state.razao,
      codigoPessoa : this.state.codigoPessoa,
      tipoPessoa : this.state.tipoPessoa,
      tipoRegime : this.state.tipoRegime,
      cpfCnpj : this.state.cpfCnpj,
      codigoCnae : this.state.codigoCnae,
      inscricaoEstatual : this.state.inscricaoEstatual,
      inscricaoMunicipal : this.state.inscricaoMunicipal,
      rg : this.state.rg,
      emailPrincipal : this.state.emailPrincipal,
      emailFinanceiro : this.state.emailFinanceiro,
      emailSecundario : this.state.emailSecundario,
      contatoPrincipal : this.state.contatoPrincipal,
      contatoFinanceiro : this.state.contatoFinanceiro,
      contatoSecundario : this.state.contatoSecundario,
      telefonePrincipal :  this.state.telefonePrincipal,
      telefoneFinanceiro : this.state.telefoneFinanceiro,
      telefoneSecundario : this.state.telefoneSecundario,
      tipoFrete : this.state.tipoFrete,
      arqBalancete : this.state.arqBalancete,
      arqFicha : this.state.arqFicha,
      arqContrato : this.state.arqContrato      
    }
  }
  
  async submit(event) {
    event.preventDefault();
    if (event.target.reportValidity()) {
      var pessoa = this.setFormPessoa();
      if (this.state.idPessoa === undefined) {
        this.cadastrar(pessoa);
      } else {
        this.update(pessoa);
      }
    }
  }

  cadastrar(pessoa) {            
    let validate = this.validaForm(pessoa);
    if (validate) {      
      Http.post("/pessoa", pessoa).then(({ data }) => {
        if (data.status) {
          Notification.success(data.mensagem);
          Http.get("/pessoa/pessoaCpfCnpj/" + this.removerMascara(pessoa.cpfCnpj)).then(({ data }) => {            
            if (data) {   
              this.setState({
                idPessoa: data.idPessoa,
                nameButton: "Alterar"
              });                            
              this.getItens();
            }
          });
        } else {
          Notification.danger(data.mensagem);
        }
      });
    } 
  }

  validaForm(pessoa) {
    var status = true;
    if(!Mask.validaCpfCnpj(pessoa.cpfCnpj)){
      Notification.danger("CPF ou CNPJ inválido!");
      status = false;
    }

    if (pessoa.emailPrincipal) {
      if(!Mask.validarEmail(pessoa.emailPrincipal)){
        Notification.danger("E-mail Principal inválido!");
        status = false;
      }
    }

    if (pessoa.emailFinanceiro) {
      if(!Mask.validarEmail(pessoa.emailFinanceiro)){
        Notification.danger("E-mail Financeiro inválido!");
        status = false;
      }
    }    

    if (pessoa.emailSecundario) {
      if(!Mask.validarEmail(pessoa.emailSecundario)){
        Notification.danger("E-mail Secundário inválido!");
        status = false;
      }
    }

    return status;
  }

  update(pessoa) {    
    let validate = this.validaForm(pessoa);
    if (validate) {
      pessoa.idUsuarioAtualizacao = this.state.user.idUsuario;
      Http.put("/pessoa", pessoa).then(({ data }) => {      
        if (data.status) {          
          Notification.success(data.mensagem);
        } else {
          Notification.danger(data.mensagem);
        }      
      });
    }
  }

  removerMascara(valor) {
    return valor.replace('.','').replace('-','').replace('/','');
  }

  cancelar() { 
    
  }

  renderPessoa() {
    return <Form id="formCadastroPessoa" handlerSubmit={this.submit}>
          <SectionForm borderLeft="primary">
          <Row col="12">
            <InputDefault  disabled
                label="Código"
                name="idPessoa"
                responsive="4"
                value={this.state.idPessoa}
              />

            <InputCpfCnpj  required
                disabled={this.state.idPessoa !== undefined ? true : false}
                pattern={Pattern.INPUT.CPF_CNPJ.pattern}
                label="CNPJ / CPF"
                name="cpfCnpj"
                responsive="4"
                value={this.state.cpfCnpj}
                handlerChange={(e) => {
                  this.setState({cpfCnpj: e.target.value });                  
                }}
                handlerBlur={(e) => {
                  this.getInfoReceita(e.target.value)
                }}
              />
          </Row>


            <Row col="12">
              <Select required
                responsive="4"
                label="Tipo Cadastro"
                valueSelected={this.state.tipoCadastro}
                handlerChange={(e) => {
                  this.setState({tipoCadastro: e.target.value });
                }}
                values={this.getTipoCadastroSelect()}
              />
              <Select required
                responsive="4"
                label="Tipo Pessoa"
                valueSelected={this.state.tipoPessoa}
                handlerChange={(e) => {
                  this.setState({tipoPessoa: e.target.value });                  
                }}
                values={this.getTipoPessoaSelect()}
              />
              <Select required
                responsive="4"
                label="Regime Tributário"
                valueSelected={this.state.tipoRegime}
                handlerChange={(e) => {
                  this.setState({tipoRegime: e.target.value });                  
                }}
                values={this.getRegimeTributario()}
              />
            </Row>

            <Row col="12">
              <InputDefault  required
                label="Nome"
                name="nome"
                maxLength="200"
                responsive="6"
                value={this.state.nome}
                handlerChange={(e) => {
                  this.setState({nome: e.target.value });                  
                }}
              />
              <InputDefault  required
                label="Razão"
                name="razao"
                maxLength="200"
                responsive="6"
                value={this.state.razao}
                handlerChange={(e) => {
                  this.setState({razao: e.target.value });                  
                }}
              />
            </Row>

            <Row col="12">

              <InputDefault 
                label="CNAE"
                maxLength="20"
                name="codigoCnae"
                responsive="2"
                value={this.state.codigoCnae}
                handlerChange={(e) => {
                  this.setState({codigoCnae: e.target.value });                  
                }}
              />
            
              <InputInteger  required
                label="Inscrição Estadual"
                name="inscricaoEstatual"
                responsive="2"
                maxLength="20"
                value={this.state.inscricaoEstatual}
                handlerChange={(e) => {
                  this.setState({inscricaoEstatual: e.target.value });                  
                }}
              />
            
              <InputInteger 
                label="Inscrição Municipal"
                name="inscricaoMunicipal"
                responsive="2"
                maxLength="20"
                value={this.state.inscricaoMunicipal}
                handlerChange={(e) => {
                  this.setState({inscricaoMunicipal: e.target.value });                  
                }}
              />
            
              <InputInteger 
                label="RG"
                name="rg"
                responsive="2"
                maxLength="20"
                value={this.state.rg}
                handlerChange={(e) => {
                  this.setState({rg: e.target.value });                  
                }}
              />     

              <InputDefault  
                label="Código Pessoa"
                name="codigoPessoa"
                responsive="4"
                maxLength="20"
                value={this.state.codigoPessoa}
                handlerChange={(e) => {
                  this.setState({codigoPessoa: e.target.value });                  
                }}
              />         
            </Row>

            <Row col="12">
                <InputDefault email
                  label="E-mail Principal"
                  name="emailPrincipal"
                  responsive="4"
                  maxLength="200"
                  value={this.state.emailPrincipal}
                  handlerChange={(e) => {
                    this.setState({emailPrincipal: e.target.value });                    
                  }}
                />

                <InputDefault email
                  label="E-mail Financeiro"
                  name="emailFinanceiro"
                  responsive="4"
                  maxLength="200"
                  value={this.state.emailFinanceiro}
                  handlerChange={(e) => {
                    this.setState({emailFinanceiro: e.target.value });                    
                  }}
                />

                <InputDefault email
                  label="E-mail Secundário"
                  name="emailSecundario"
                  responsive="4"
                  maxLength="200"
                  value={this.state.emailSecundario}
                  handlerChange={(e) => {
                    this.setState({emailSecundario: e.target.value });                    
                  }}
                />
              </Row>

              <Row col="12">
                <InputDefault 
                  label="Contato Principal"
                  name="contatoPrincipal"
                  responsive="4"
                  maxLength="200"
                  value={this.state.contatoPrincipal}
                  handlerChange={(e) => {
                    this.setState({contatoPrincipal: e.target.value });                    
                  }}
                />

                <InputDefault 
                  label="Contato Financeiro"
                  name="contatoFinanceiro"
                  responsive="4"
                  maxLength="200"
                  value={this.state.contatoFinanceiro}
                  handlerChange={(e) => {
                    this.setState({contatoFinanceiro: e.target.value });                    
                  }}
                />

                <InputDefault 
                  label="Contato Secundário"
                  name="contatoSecundario"
                  responsive="4"
                  maxLength="200"
                  value={this.state.contatoSecundario}
                  handlerChange={(e) => {
                    this.setState({contatoSecundario: e.target.value });                    
                  }}
                />
              </Row>

              <Row col="12">
                  <InputPhone 
                    label="Telefone Principal"
                    name="telefonePrincipal"
                    responsive="4"
                    value={this.state.telefonePrincipal}
                    handlerChange={(e) => {
                      this.setState({telefonePrincipal: e.target.value });                      
                    }}
                  />

                  <InputPhone 
                    label="Telefone Secundário"
                    name="telefoneSecundario"
                    responsive="4"
                    value={this.state.telefoneSecundario}
                    handlerChange={(e) => {
                      this.setState({telefoneSecundario: e.target.value });                      
                    }}
                  />

                  <InputPhone 
                    label="Telefone Financeiro"
                    name="telefoneFinanceiro"
                    responsive="4"
                    value={this.state.telefoneFinanceiro}
                    handlerChange={(e) => {
                      this.setState({telefoneFinanceiro: e.target.value });                      
                    }}
                  />

              </Row>

              <Row col="12">
                  <Select 
                    responsive="4"
                    label="Tipo Frete"
                    valueSelected={this.state.tipoFrete}
                    handlerChange={(e) => {
                      this.setState({tipoFrete: e.target.value });                      
                    }}
                    values={this.getTipoFrete()}
                  />

                <InputDefault 
                    label="Código Atividade"
                    name="codigoAtividadePrincipal"
                    responsive="4"
                    maxLength="20"
                    value={this.state.codigoAtividadePrincipal}
                    handlerChange={(e) => {
                      this.setState({codigoAtividadePrincipal: e.target.value });                      
                    }}
                  />
                  
                  <InputDefault 
                    label="Descrição da Atividade"
                    name="descricaoAtividadePrincipal"
                    responsive="4"
                    maxLength="200"
                    value={this.state.descricaoAtividadePrincipal}
                    handlerChange={(e) => {
                      this.setState({descricaoAtividadePrincipal: e.target.value });                      
                    }}
                  />
              </Row>            	

              <SectionButtonForm>
                  <Button
                    btn={Color.NODE.PRIMARY}
                    value={this.state.nameButton}
                    col="1"
                    margin="1"
                  />
                  <Button
                    btn={Color.NODE.DANGER}
                    value="Cancelar"
                    col="1"
                    handlerClick={(_e) => this.listaPessoas()}
                    margin="5"
                  />
                </SectionButtonForm>
          </SectionForm> 
    </Form>;
  }

    //Endereco

    submitEndereco(event) {
      event.preventDefault();
      if (event.target.reportValidity()) {
        if (this.state.idEndereco === undefined) {
          this.cadastrarEndereco(this.state);
        } else {
          this.updateEndereco(this.state);
        }
      }
    }
    
    listaEnderecos(idPessoa) {      
      this.setState({enderecos: []});
      Http.get("/endereco/listar/" + idPessoa).then(({ data }) => {                   
        if (data) {                                  
          this.setState({ enderecos: data });
        }
      });
    }

    cadastrarEndereco(endereco) {
      endereco.idPessoa = this.state.idPessoa;
      endereco.tipoCadastro = this.state.tipoCadastroEndereco;
      Http.post("/endereco", endereco).then(({ data }) => {
        if (data.status) {
          this.listaEnderecos(endereco.idPessoa);
          this.cleanFormEndereco();
          Notification.success(data.mensagem);           
        } else {
          Notification.danger(data.mensagem);
        }
      });
    }

    updateEndereco(endereco) {
      endereco.tipoCadastro = this.state.tipoCadastroEndereco;
      Http.put("/endereco", endereco).then(({ data }) => {      
        if (data.status) { 
          this.listaEnderecos(endereco.idPessoa);
          this.cleanFormEndereco();            
          Notification.success(data.mensagem);
        } else {
          Notification.danger(data.mensagem);
        }      
      });
    }

    cleanFormEndereco() {          
      this.setState({
        nameButtonEndereco: "Adicionar",
        idEndereco: undefined,
        tipoCadastroEndereco: "",        
        endereco: "",
        numero: "",
        complemento: "",
        bairro: "",
        cep: "",
        idestadoibge: 0,
        idcidadeibge: 0
      });      
    }

    editarEndereco(endereco) {       
      this.setState({
        nameButtonEndereco: "Alterar", 
        idEndereco: endereco.idEndereco,
        tipoCadastroEndereco: endereco.tipoCadastro,        
        endereco: endereco.endereco,
        numero: endereco.numero,
        complemento: endereco.complemento,
        bairro: endereco.bairro,
        cep: Mask.getValueMaskCep(endereco.cep),
        idestadoibge: endereco.idestadoibge,
        idcidadeibge: endereco.idcidadeibge});
      this.getCidadeSelect(endereco.idestadoibge);         
    }

    deletarEndereco(endereco) {
      ModalNotification.confirm(
        "Deseja desativar este Endereço? " + endereco.endereco,
        "Este endereço será desativada no sistema de compras!",
        () => this.deleteEndereco(endereco),
        () => this.cancelar()
      );
    }
       
    async deleteEndereco(endereco) {    
      endereco.idUsuarioInativacao = this.state.user.idUsuario
      await Http.post(`/endereco/deletar`, endereco)
      .then(({ status, data }) => {
        if (data.status) {
          Notification.success(data.mensagem);
          this.listaEnderecos(endereco.idPessoa);         
        } else {
          Notification.danger(data.mensagem);
        }
      })
      .catch((error) => console.log(error));
    }
    
  renderEndereco() {
    return <Div><Form id="formCadastroEndereco" handlerSubmit={this.submitEndereco}>
          <SectionForm borderLeft="primary">
            <Row col="12">
            <Select required
                responsive="4"
                label="Tipo Cadastro"
                valueSelected={this.state.tipoCadastroEndereco}
                handlerChange={(e) => {
                  this.setState({tipoCadastroEndereco: e.target.value });                                        
                }}
                values={this.getTipoCadastroEnderecoSelect()}
              />            
              <Select required
                responsive="4"
                label="Estado"
                valueSelected={this.state.idestadoibge}
                handlerChange={(e) => {
                  this.setState({idestadoibge: e.target.value });                                        
                  this.getCidadeSelect(e.target.value);
                }}
                values={this.state.estados}
              />
              <Select required
                responsive="4"
                label="Cidade"
                valueSelected={this.state.idcidadeibge}
                handlerChange={(e) => {
                  this.setState({idcidadeibge: e.target.value });                                        
                }}
                values={this.state.cidades}                
              />
            
            </Row>
            <Row col="12">
              <InputDefault  required
                label="Endereço"
                name="endereco"
                responsive="6"
                maxLength="200"
                value={this.state.endereco}
                handlerChange={(e) => {
                  this.setState({endereco: e.target.value });                                        
                }}
              />
              <InputDefault  
                label="Complemento"
                name="complemento"
                responsive="6"
                maxLength="200"
                value={this.state.complemento}
                handlerChange={(e) => {
                  this.setState({complemento: e.target.value });                                        
                }}
              />
            </Row>
            <Row col="12">
              <InputDefault  
                label="Numéro"
                name="numero"
                responsive="4"
                maxLength="20"
                value={this.state.numero}
                handlerChange={(e) => {
                  this.setState({numero: e.target.value });                                        
                }}
              />

            <InputDefault  required
                label="Bairro"
                name="bairro"
                responsive="4"
                maxLength="100"
                value={this.state.bairro}
                handlerChange={(e) => {
                  this.setState({bairro: e.target.value });                                        
                }}
              />

              <InputCep  required
                label="Cep"
                name="cep"
                responsive="4"
                value={this.state.cep}
                handlerChange={(e) => {
                  this.setState({cep: e.target.value });                                        
                }}
              />
            </Row>

            <Row col="12">
            </Row>
          </SectionForm>
          <SectionButtonForm>
            <Button
              btn={Color.NODE.PRIMARY}
              value={this.state.nameButtonEndereco}
              col="1"
              margin="1"              
            />

            <Button
              btn={Color.NODE.SECONDARY}
              value="Limpar"
              col="1"
              handlerClick={(_e) => this.cleanFormEndereco()}
              margin="1"
            />

            <Button
              btn={Color.NODE.DANGER}
              value="Cancelar"
              col="1"
              handlerClick={(_e) => this.listaPessoas()}
              margin="5"
            />
         </SectionButtonForm>              
    </Form>
    
      <Div margin="3" shadow bg={Color.NODE.WHITE} borderLeft={Color.NODE.PRIMARY}>
        <Title value="Endereços" type="h4" paddingLeft="4" paddingRight="4" paddingTop="3"/>        
        <Div padding="2">
          <Table
            columns={[              
              { value: "Tipo Cadastro", align: "center" },                      
              { value: "Cidade",        align: "center" },
              { value: "Estado",        align: "center" },             
              { value: "Endereco",      align: "center" }, 
              { value: "Bairro",        align: "center" },
              { value: "Cep",           align: "center" }
            ]}
            fields={[
              { value: "tipoCadastro", type: Type.DADO.STRING },
              { value: "nomeCidade",   type: Type.DADO.STRING },    
              { value: "nomeEstado",   type: Type.DADO.STRING }, 
              { value: "endereco",     type: Type.DADO.STRING },                      
              { value: "bairro",       type: Type.DADO.STRING },    
              { value: "cep",          type: Type.DADO.CEP }                         
            ]}
            bordered
            search
            orientation="center"
            pagination            
            edit
            delete
            handlerEdit={(enderco) => this.editarEndereco(enderco)}
            handlerDelete={(enderco) => this.deletarEndereco(enderco)}
            data={this.state.enderecos}
            error="Nenhum endereço encontrada"
          ></Table>
        </Div>
      </Div>
  </Div>;
  }

  //Contato

  submitContato(event) {
    event.preventDefault();
    if (event.target.reportValidity()) {
      if (this.state.idContato === undefined) {
        this.cadastrarContato(this.state);
      } else {
        this.updateContato(this.state);
      }
    }
  }

  listaContatos(idPessoa) {
    this.setState({contatos: []});
    Http.get("/contato/listar/" + idPessoa).then(({ data }) => {            
      if (data) {                        
        this.setState({ contatos: data });
      }
    });
  }

  cadastrarContato(contato) {
    contato.idPessoa = this.state.idPessoa;
    this.seFormContato(contato);
    let validate = this.validaFormContato(contato);
    if (validate) {
      Http.post("/contato", contato).then(({ data }) => {
        if (data.status) {
          this.listaContatos(contato.idPessoa);
          this.cleanFormContato();
          Notification.success(data.mensagem);           
        } else {
          Notification.danger(data.mensagem);
        }
      });
    }
  }

  updateContato(contato) {
    this.seFormContato(contato);
    let validate = this.validaFormContato(contato);
    if (validate) {
      Http.put("/contato", contato).then(({ data }) => {      
        if (data.status) { 
          this.listaContatos(contato.idPessoa);
          this.cleanFormContato();            
          Notification.success(data.mensagem);
        } else {
          Notification.danger(data.mensagem);
        }      
      });
    }
  }

  validaFormContato(contato) {
    var status = true;
    if(!Mask.validaCpfCnpj(contato.cpfCnpj)){
      Notification.danger("CPF ou CNPJ inválido!");
      status = false;
    }

    if (contato.email) {
      if(!Mask.validarEmail(contato.email)){
        Notification.danger("E-mail Principal inválido!");
        status = false;
      }
    } 

    return status;
  }

  seFormContato(contato) {
    contato.tipoCadastro = this.state.tipoCadastroContato;
    contato.nome = this.state.nomeContato;
    contato.rg = this.state.rgContato;
    contato.cpfCnpj = this.state.cpfCnpjContato;
    contato.endereco = this.state.enderecoContato;
    contato.endereco = this.state.enderecoContato;
    contato.numero = this.state.numeroContato;
    contato.complemento = this.state.complementoContato;
    contato.bairro = this.state.bairroContato;
    contato.cep = this.state.cepContato;

    contato.dataNascimento = DateUtils.getDateFormat(this.state.dataNascimentoAux, Pattern.DATE.YYYY_MM_DD_HH_MM);

    return contato;
  }

  cleanFormContato() {          
    this.setState({
      nameButtonContato: "Adicionar",
      idContato: undefined,
      tipoCadastroContato: "",              
      nomeContato: "",
      cargo: "",          
      cpfCnpjContato: "",     
      rgContato: "",
      telefone: "",
      celular: "",        
      outroTelefone: "",
      email: "", 
      dtNacimento: "",
      enderecoContato: "",
      numeroContato: "",
      complementoContato: "",
      bairroContato: "",                  
      cepContato: "",
      idestadoibge: 0,
      idcidadeibge: 0
    });      
  }

  editarContato(contato) {       
    this.setState({
      nameButtonContato: "Alterar", 
      idContato: contato.idContato,
      tipoCadastroContato: contato.tipoCadastro,              
      nomeContato: contato.nome,
      cargo: contato.cargo,          
      cpfCnpjContato: Mask.getValueMaskCpfCnpj(contato.cpfCnpj),     
      rgContato: contato.rg,
      telefone: Mask.getValueMaskPhone(contato.telefone),
      celular: Mask.getValueMaskPhone(contato.celular),        
      outroTelefone: Mask.getValueMaskPhone(contato.outroTelefone),
      email: contato.email,       
      dataNascimento: DateUtils.getDateFormat(contato.dataNascimento, Pattern.DATE.DD_MM_YYYY),      
      enderecoContato: contato.endereco,
      numeroContato: contato.numero,
      complementoContato: contato.complemento,
      bairroContato: contato.bairro, 
      cepContato: Mask.getValueMaskCep(contato.cep),
      idestadoibge: contato.idestadoibge,
      idcidadeibge: contato.idcidadeibge});
    this.getCidadeSelect(contato.idestadoibge);         
  }

  deletarContato(contato) {
    ModalNotification.confirm(
      "Deseja desativar este Contato? " + contato.nome,
      "Este contato será desativada no sistema de compras!",
      () => this.deleteContato(contato),
      () => this.cancelar()
    );
  }

  async deleteContato(contato) {    
    contato.idUsuarioInativacao = this.state.user.idUsuario
    await Http.post(`/contato/deletar`, contato)
    .then(({ status, data }) => {
      if (data.status) {
        Notification.success(data.mensagem);
        this.listaContatos(contato.idPessoa);         
      } else {
        Notification.danger(data.mensagem);
      }
    })
    .catch((error) => console.log(error));
  }

  mudarDataNascimento(data) {
    this.setState({ dataNascimentoAux: data});
  }
  
  renderContato() {
    return <Div>
        <Form id="formCadastroContato" handlerSubmit={this.submitContato}>
          <SectionForm borderLeft="primary">
            <Row col="12">
              <Select required
                  responsive="4"
                  label="Tipo Cadastro"
                  valueSelected={this.state.tipoCadastroContato}
                  handlerChange={(e) => {
                    this.setState({tipoCadastroContato: e.target.value });                                        
                  }}
                  values={this.getTipoCadastroContatoSelect()}
                /> 
            </Row>
            <Row col="12">
              <InputDefault  required
                label="Nome"
                name="nomeContato"
                responsive="8"
                maxLength="200"
                value={this.state.nomeContato}
                handlerChange={(e) => {
                  this.setState({nomeContato: e.target.value });                                        
                }}
              />

              <InputDefault  required
                label="Cargo"
                name="cargo"
                responsive="4"
                maxLength="200"
                value={this.state.cargo}
                handlerChange={(e) => {
                  this.setState({cargo: e.target.value });                                        
                }}
              />
            </Row>
            <Row col="12">
              <InputCpfCnpj  required
                  label="CPF / CNPJ"                  
                  name="cpfCnpjContato"
                  responsive="4"
                  value={this.state.cpfCnpjContato}
                  handlerChange={(e) => {
                    this.setState({cpfCnpjContato: e.target.value });                                        
                  }}
                />
               
                <InputDefault 
                  label="RG"
                  name="rgContato"
                  responsive="4"
                  maxLength="20"
                  value={this.state.rgContato}
                  handlerChange={(e) => {
                    this.setState({rgContato: e.target.value });                                        
                  }}
                />

                <InputDate required
                  label="Data Nascimento"
                  direction="up"
                  colorIcon="white"
                  bgIcon="secondary"
                  bg="secondary"
                  responsive="4"                  
                  handlerChangeDay={(data) => this.mudarDataNascimento(data)}
                  value={this.state.dataNascimentoAux}
                />
            </Row>
            <Row col="12">
                <InputDefault 
                  label="E-mail"
                  name="email"
                  responsive="6"
                  maxLength="200"
                  value={this.state.email}
                  handlerChange={(e) => {
                    this.setState({email: e.target.value });                                        
                  }}
                />
                <InputPhone 
                  label="Telefone"
                  name="telefone"
                  responsive="2"
                  value={this.state.telefone}
                  handlerChange={(e) => {
                    this.setState({telefone: e.target.value });                                        
                  }}
                />
                <InputPhone 
                  label="Telefone 2"
                  name="outroTelefone"
                  responsive="2"
                  value={this.state.outroTelefone}
                  handlerChange={(e) => {
                    this.setState({outroTelefone: e.target.value });                                        
                  }}
                />
                <InputPhone 
                  label="Celular"
                  name="celular"
                  responsive="2"
                  value={this.state.celular}
                  handlerChange={(e) => {
                    this.setState({celular: e.target.value });                                        
                  }}
                />
            </Row>
            <Row col="12">
              <Select required
                  responsive="3"
                  label="Estado"
                  valueSelected={this.state.idestadoibge}
                  handlerChange={(e) => {
                    this.setState({idestadoibge: e.target.value });                                        
                    this.getCidadeSelect(e.target.value);
                  }}
                  values={this.state.estados}
                />
                <Select required
                  responsive="3"
                  label="Cidade"
                  valueSelected={this.state.idcidadeibge}
                  handlerChange={(e) => {
                    this.setState({idcidadeibge: e.target.value });                                        
                  }}
                  values={this.state.cidades}                
                />
                <InputCep 
                  label="Cep"
                  name="cepContato"
                  responsive="3"
                  value={this.state.cepContato}
                  handlerChange={(e) => {
                    this.setState({cepContato: e.target.value });                                        
                  }}
                />
                <InputDefault 
                  label="Bairro"
                  name="bairroContato"
                  responsive="3"
                  maxLength="100"
                  value={this.state.bairroContato}
                  handlerChange={(e) => {
                    this.setState({bairroContato: e.target.value });                                        
                  }}
                />
                
            </Row>
            <Row col="12">
                <InputDefault 
                  label="Endereço"
                  name="enderecoContato"
                  responsive="4"
                  maxLength="200"
                  value={this.state.enderecoContato}
                  handlerChange={(e) => {
                    this.setState({enderecoContato: e.target.value });                                        
                  }}
                />
                <InputDefault 
                  label="Complemento"
                  name="complementoContato"
                  responsive="4"
                  maxLength="200"
                  value={this.state.complementoContato}
                  handlerChange={(e) => {
                    this.setState({complementoContato: e.target.value });                                        
                  }}
                />
                <InputDefault 
                  label="Numéro"
                  name="numeroContato"
                  responsive="4"
                  maxLength="20"
                  value={this.state.numeroContato}
                  handlerChange={(e) => {
                    this.setState({numeroContato: e.target.value });                                        
                  }}
                />
            </Row>
            <SectionButtonForm>
                <Button
                  btn={Color.NODE.PRIMARY}
                  value={this.state.nameButtonContato}
                  col="1"
                  margin="1"              
                />

                <Button
                  btn={Color.NODE.SECONDARY}
                  value="Limpar"
                  col="1"
                  handlerClick={(_e) => this.cleanFormContato()}
                  margin="1"
                />

                <Button
                  btn={Color.NODE.DANGER}
                  value="Cancelar"
                  col="1"
                  handlerClick={(_e) => this.listaPessoas()}
                  margin="5"
                />
            </SectionButtonForm> 
          </SectionForm>
        </Form>

        <Div margin="3" shadow bg={Color.NODE.WHITE} borderLeft={Color.NODE.PRIMARY}>
          <Title value="Contatos" type="h4" paddingLeft="4" paddingRight="4" paddingTop="3"/>        
          <Div padding="2">
            <Table
              columns={[              
                { value: "Tipo Cadastro", align: "center" },                      
                { value: "Nome",          align: "center" },
                { value: "Cargo",         align: "center" },             
                { value: "Cidade",        align: "center" }, 
                { value: "Estado",        align: "center" },
                { value: "Cep",           align: "center" }
              ]}
              fields={[
                { value: "tipoCadastro", type: Type.DADO.STRING },
                { value: "nome",         type: Type.DADO.STRING },
                { value: "cargo",        type: Type.DADO.STRING },    
                { value: "nomeCidade",   type: Type.DADO.STRING },    
                { value: "nomeEstado",   type: Type.DADO.STRING }, 
                { value: "cep",          type: Type.DADO.CEP }                         
              ]}
              bordered
              search
              orientation="center"
              pagination            
              edit
              delete
              handlerEdit={(contato) => this.editarContato(contato)}
              handlerDelete={(contato) => this.deletarContato(contato)}
              data={this.state.contatos}
              error="Nenhum contato encontrada"
            ></Table>
          </Div>
        </Div>
      </Div>;
  }

  //Arquivo

  submitArquivo(event) {
    event.preventDefault();
    if (event.target.reportValidity()) {
      if (this.state.idArquivo === undefined) {
        this.cadastrarArquivo(this.state);
      } else {
        this.updateArquivo(this.state);
      }
    }
  }

  listaArquivos(idPessoa) {
    this.setState({arquivos: []});
    Http.get("/arquivo/listar/" + idPessoa).then(({ data }) => {            
      if (data) {                        
        this.setState({ arquivos: data });
      }
    });
  }

  cadastrarArquivo(arquivo) {        
      let arquivoNovo = {
        idPessoa: this.state.idPessoa,
        tipoCadastro: arquivo.tipoCadastroArquivo,
        tipoArquivo: arquivo.tipoArquivo,
        descricao: arquivo.descricao
      }      

      const formData = new FormData();
      formData.append("data", JSON.stringify(arquivoNovo));
      formData.append("files", this.state.imagensNormalizacao[0])
        Http.postFormData("/arquivo/adicionar", formData)
            .then(({status, data}) => {
              if (data.status) {
                this.listaArquivos(arquivoNovo.idPessoa);
                this.cleanFormArquivo();
                Notification.success(data.mensagem);           
              } else {
                Notification.danger(data.mensagem);
              }
            })
            .catch((error) => console.log(error));
 
  }

  cleanFormArquivo() {          
    this.setState({      
      idArquivo: undefined,
      tipoCadastroArquivo: "",       
      tipoCadastro: "",           
      tipoArquivo: "",          
      nomeArquivo: "",      
      descricao: "",
      caminho: ""         
    });      
  }

  deletarArquivo(arquivo) {
    ModalNotification.confirm(
      "Deseja desativar este Arquivo? " + arquivo.nomeArquivo,
      "Este arquivo será desativada no sistema de compras!",
      () => this.deleteArquivo(arquivo),
      () => this.cancelar()
    );
  }

  async deleteArquivo(arquivo) {    
    arquivo.idUsuarioInativacao = this.state.user.idUsuario
    await Http.post(`/arquivo/deletar`, arquivo)
    .then(({ status, data }) => {
      if (data.status) {
        Notification.success(data.mensagem);
        this.listaArquivos(arquivo.idPessoa);         
      } else {
        Notification.danger(data.mensagem);
      }
    })
    .catch((error) => console.log(error));
  }

  dowloadsArquivo(arquivo) {
     var link = document.createElement("a");
     link.setAttribute('download', arquivo.nomeArquivo);
     link.href = arquivo.caminho + '/' + arquivo.nomeArquivo;
     document.body.appendChild(link);
     link.click();
     link.remove(); 
  }

  

  handlerFile(e, tiposFile) {    

    let imgs = Array.from(e.target.files).filter(file => tiposFile.includes(file.name.split(".").pop()));
    
    let sizeImgs = imgs.map(img => img.size).reduce((acc, size) => acc + size, 0);

    const CEM_MEGA_BYTE = 300400000;

    if (Utils.isValueValid(imgs)) {
        let isSizeValid = sizeImgs <= CEM_MEGA_BYTE;
        if (isSizeValid) {          
            this.setState({imagensNormalizacao: imgs});
            } else {
                Notification.danger(
                    "Tamanho do arquivo não suportado, tamanho máximo é 100MB"
                );
            }

    } else {
        Notification.danger("Nenhum arquivo válido foi encontrado, extensoes permitidas ");
    }
}

  renderArquivo() {
    return <Div>
        <Form id="formCadastroArquivo" handlerSubmit={this.submitArquivo}>
          <SectionForm borderLeft="primary">
            <Row col="12">
              <Select required
                  responsive="3"
                  label="Tipo Cadastro"
                  valueSelected={this.state.tipoCadastroArquivo}
                  handlerChange={(e) => {
                    this.setState({tipoCadastroArquivo: e.target.value });                                        
                  }}
                  values={this.getTipoCadastroArquivoSelect()}
                /> 

                <Select required
                  responsive="3"
                  label="Tipo Arquivo"
                  valueSelected={this.state.tipoArquivo}
                  handlerChange={(e) => {
                    this.setState({tipoArquivo: e.target.value });                                        
                  }}
                  values={this.getTipoArquivoSelect()}
                /> 
                <InputDefault  required
                  label="Descrição"
                  name="descricao"
                  responsive="5"
                  maxLength="200"
                  value={this.state.descricao}
                  handlerChange={(e) => {
                    this.setState({descricao: e.target.value });                                        
                  }}
                />
                <InputFile required
                  responsive="1"
                  id="inputFile"
                  inline="center"                  
                  icon={Type.ICON.UPLOAD}
                  label="Arquivo"                               
                  info=""
                  accept=".pdf, .xlsx, .doc, .xdoc, .xls, .cvs"
                  margin="1"
                  handlerChange={(e) => this.handlerFile(e, ['pdf', 'xlsx', 'doc', 'xls', 'cvs', 'xdoc'])}
                  className="btn btn-info btn-file rounded"
              />
            </Row>


            <SectionButtonForm>
                <Button
                  btn={Color.NODE.PRIMARY}
                  value="Adicionar"
                  col="1"
                  margin="1"              
                />

                <Button
                  btn={Color.NODE.DANGER}
                  value="Cancelar"
                  col="1"
                  handlerClick={(_e) => this.listaPessoas()}
                  margin="5"
                />
            </SectionButtonForm> 
      
          </SectionForm>
        </Form>
      
        <Div margin="3" shadow bg={Color.NODE.WHITE} borderLeft={Color.NODE.PRIMARY}>
          <Title value="Arquivos" type="h4" paddingLeft="4" paddingRight="4" paddingTop="3"/>        
          <Div padding="2">
            <Table
              columns={[              
                { value: "Tipo Cadastro", align: "center" },                      
                { value: "Tipo Arquivo",  align: "center" },
                { value: "Descricao",     align: "center" },             
                { value: "Data Criação",  align: "center" }
              ]}
              fields={[
                { value: "tipoCadastro", type: Type.DADO.STRING },
                { value: "tipoArquivo",  type: Type.DADO.STRING },
                { value: "descricao",    type: Type.DADO.STRING },    
                { value: "datacadastro", type: Type.DADO.DATE }                        
              ]}
              bordered
              search
              orientation="center"
              pagination 
              edit       
              iconEdit={Type.ICON.DONWLOAD}                  
              delete              
              handlerEdit={(arquivo) => this.dowloadsArquivo(arquivo)}
              handlerDelete={(arquivo) => this.deletarArquivo(arquivo)}
              data={this.state.arquivos}
              error="Nenhum arquivo encontrada"
            ></Table>
          </Div>
        </Div>          

      </Div>;
  }

  render() {
    let { target } = this.state.itemActive;
    return (
      <Div inline="left" shadow rounded margin="5" padding="5">
        <Div className="w-100">
          <NavbarTab
            itens={this.getItens()}
            handlerClick={(itemActive) => {
              this.setState({ itemActive });
            }}
          />
        </Div>
        <If and value1={target === "PESSOA"}>
          {this.renderPessoa()}
        </If>
        <If and value1={target === "ENDEREÇO"}>
          {this.renderEndereco()}
        </If>
        <If and value1={target === "CONTATO"}>
          {this.renderContato()}
        </If>
        <If and value1={target === "ARQUIVO"}>
          {this.renderArquivo()}
        </If>
      </Div>
    );
  }
}

const mapStateToProps = (store) => ({
  user: store.loginState.user,
});

export default connect(mapStateToProps)(Cadastrar);
