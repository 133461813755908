import React, { Component } from "react";
import { Color, Http} from "../../utilities";

import {
  Row,
  InputDefault,  
  Form,
  Notification,
  SectionForm,
  SectionButtonForm,
  Button,
  Div  
} from "../../components";
import { connect } from "react-redux";


class Cadastrar extends Component {

  constructor(props) {
    super(props);

    this.state = {
      nameButton: "Salvar",
      user: props.user,           
      ...this.props.location.state 
    };

    this.submit = this.submit.bind(this);  

  }

  UNSAFE_componentWillMount() {        
    let marca = this.state.marca;     
    if (this.state.editar) {          
          this.setState({
            nameButton: "Alterar",
            idMarca: marca.idMarca,
            descricao: marca.descricao
          });
    }
  }


  listaMarcas() {
    this.props.history.push({pathname: `/marca`});
  }

 
  async submit(event) {
    event.preventDefault();
    if (event.target.reportValidity()) {
      if (this.state.idMarca === undefined) {
        this.cadastrar(this.state);
      } else {
        this.update(this.state);
      }
    }
  }

  cadastrar(marca) {           
    marca.idGestorCompras = this.state.user.idGestorCompras         
      Http.post("/marca", marca).then(({ data }) => {
        if (data.status) {
          Notification.success(data.mensagem);
          this.listaMarcas();
        } else {
          Notification.danger(data.mensagem);
        }
      });    
  }

 
  update(marca) {        
    marca.idUsuarioAtualizacao = this.state.user.idUsuario;
      Http.put("/marca", marca).then(({ data }) => {      
        if (data.status) {          
          Notification.success(data.mensagem);
          this.listaMarcas();
        } else {
          Notification.danger(data.mensagem);
        }      
      });    
  }

  
   render() {
    
    return (<Div>
          <Form id="formCadastroMarca" handlerSubmit={this.submit}>
          <SectionForm borderLeft="primary">
            <Row col="12">
            <InputDefault required
                label="Descrição"
                name="descricao"
                maxLength="200"
                responsive="4"
                value={this.state.descricao}
                handlerChange={(e) => {
                  this.setState({descricao: e.target.value });                  
                }}
              />
            </Row>

            <SectionButtonForm>
                <Button
                  btn={Color.NODE.PRIMARY}
                  value={this.state.nameButton}
                  col="1"
                  margin="1"
                />

                <Button
                  btn={Color.NODE.DANGER}
                  value="Cancelar"
                  col="1"
                  handlerClick={(_e) => this.listaMarcas()}
                  margin="5"
                />
              </SectionButtonForm>
          </SectionForm> 
    </Form>

    </Div>   
    
    )}   
}

const mapStateToProps = (store) => ({
  user: store.loginState.user,
});

export default connect(mapStateToProps)(Cadastrar);
