import React from "react";

import { Div, Label, Input } from "../../../components";
import { Type, Color } from "../../../utilities";
import InputDefaultValue from "./InputDefaultValue";

import { responsive, className, col, display, invisible, margin } from "../../css";
import TooltipText from "../../tooltip/TooltipText";
import I from "../../i/I";

const getType = (props) => {
  if (props.number) return Type.INPUT.NUMBER;
  else if (props.date) return Type.INPUT.DATE;
  else if (props.password) return Type.INPUT.PASSWORD;
  else if (props.phone) return Type.INPUT.PHONE;
  else if (props.file) return Type.INPUT.FILE;
  else if (props.checkbox) return Type.INPUT.CHECKBOX;
  else if (props.radio) return Type.INPUT.RADIO;
  else if (props.color) return Type.INPUT.COLOR;
  else if (props.range) return Type.INPUT.RANGE;
  else if (props.time) return Type.INPUT.TIME;
  else if (props.datetime) return Type.INPUT.DATE_TIME;
  else return Type.INPUT.TEXT;
};

const getInput = (props) => {
  let input;
  input = props.defaultValue ? (
    <InputDefaultValue {...props} formcontrol type={getType(props)} />
  ) : (
    <Input {...props} formcontrol type={getType(props)} />
  );
  return input;
};

const getClassNameDefault = (props) => {
  let classe = "form-group ";
  classe = classe.concat(col(props));
  classe = classe.concat(responsive(props));
  classe = classe.concat(display(props));
  classe = classe.concat(invisible(props));
  classe = classe.concat(margin(props));
  classe = classe.concat(className(props));

  return classe;
};

const renderInfo = ({ info }) => {
  let tooltipInfo = null;
  if (info) {
    tooltipInfo = (
      <TooltipText direction="top" title={info}>
        <I icon={Type.ICON.INFO} paddingBottom="2" paddingLeft="2" colorText={Color.NODE.SECONDARY} className="pointer-help"/>
      </TooltipText>
    );
  }
  return tooltipInfo;
};

const InputDefault = (props) => {
  return (
    <Div className={getClassNameDefault(props)} none={props.none}>
      <Div inline={props.labelAlign === "center" ? "center" : "start"}>
        <Label value={props.label}></Label>
        {renderInfo(props)}
      </Div>
      {getInput(props)}
    </Div>
  );
};

export default InputDefault;
