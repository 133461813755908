import React, { Component } from "react";
import { Color, Http} from "../../utilities";

import {
  Row,
  InputDefault,  
  Form,
  Notification,
  SectionForm,
  SectionButtonForm,
  Button,
  Div  
} from "../../components";
import { connect } from "react-redux";


class Cadastrar extends Component {

  constructor(props) {
    super(props);

    this.state = {
      nameButton: "Salvar",
      user: props.user,           
      ...this.props.location.state 
    };

    this.submit = this.submit.bind(this);  

  }

  UNSAFE_componentWillMount() {        
    let departamento = this.state.departamento;     
    if (this.state.editar) {          
          this.setState({
            nameButton: "Alterar",
            idDepartamento: departamento.idDepartamento,
            descricao: departamento.descricao
          });
    }
  }


  listaDepartamentos() {
    this.props.history.push({pathname: `/compras/departamento`});
  }

 
  async submit(event) {
    event.preventDefault();
    if (event.target.reportValidity()) {
      if (this.state.idDepartamento === undefined) {
        this.cadastrar(this.state);
      } else {
        this.update(this.state);
      }
    }
  }

  cadastrar(departamento) {           
    departamento.idGestorCompras = this.state.user.idGestorCompras         
      Http.post("/departamento", departamento).then(({ data }) => {
        if (data.status) {
          Notification.success(data.mensagem);
          this.listaDepartamentos();
        } else {
          Notification.danger(data.mensagem);
        }
      });    
  }

 
  update(departamento) {        
    departamento.idUsuarioAtualizacao = this.state.user.idUsuario;
      Http.put("/departamento", departamento).then(({ data }) => {      
        if (data.status) {          
          Notification.success(data.mensagem);
          this.listaDepartamentos();
        } else {
          Notification.danger(data.mensagem);
        }      
      });    
  }

  
   render() {
    
    return (<Div rounded margin="3" padding="4" shadow>
          <Form id="formCadastroDepartamento" handlerSubmit={this.submit}>
          <SectionForm borderLeft="primary">
            <Row col="12">
            <InputDefault  required
                label="Descrição"
                name="descricao"
                maxLength="200"
                responsive="12"
                value={this.state.descricao}
                handlerChange={(e) => {
                  this.setState({descricao: e.target.value });                  
                }}
              />
            </Row>

            <SectionButtonForm>
                <Button
                  btn={Color.NODE.PRIMARY}
                  value={this.state.nameButton}
                  col="1"
                  margin="1"
                />

                <Button
                  btn={Color.NODE.DANGER}
                  value="Cancelar"
                  col="1"
                  handlerClick={(_e) => this.listaDepartamentos()}
                  margin="5"
                />
              </SectionButtonForm>
          </SectionForm> 
    </Form>

    </Div>   
    
    )}   
}

const mapStateToProps = (store) => ({
  user: store.loginState.user,
});

export default connect(mapStateToProps)(Cadastrar);
