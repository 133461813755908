import React from "react";
import { Mask } from "../../../utilities";
import InputDefault from "./InputDefault";

const InputInteger = (props) => {
  return (
    <InputDefault
      {...props}
      formcontrol
      type="text"
      handlerChange={(e) => {
        if (props.handlerChange) {
          Mask.maskNumberInteger(e);
          return props.handlerChange(e);
        }
      }}
      handlerBlur={(e) => {
        if (props.handlerBlur) {
          Mask.maskNumberInteger(e);
          return props.handlerBlur(e);
        }
      }}
    />
  );
};

export default InputInteger;
