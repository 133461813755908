import pdfMaker from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { DateUtils } from '..';

export function relatorioPDF(header, content, styles, orientation, name="file.pdf") {
	pdfMaker.vfs = pdfFonts.pdfMake.vfs;

	let data = DateUtils.getDataHojeBR()

	const reportTitle = header
	const details = content

	function rodape(currentPage, pageCount) {
		return [
			{
				text: 'Data de Emissão: ' + data + ' Pagina: ' + currentPage + ' de ' + pageCount,
				fontSize: 8,
				alignment: 'right',
				margin: [0, 10, 20, 0]
			}
		]
	}

	const docDefinitios = {
		pageSize: 'A4',
		pageOrientation: orientation,   // portrait or landscape
		pageMargins: [15, 50, 15, 40],

		header: [reportTitle],
		content: [details],
		styles: styles,
		footer: rodape
	}

	pdfMaker.createPdf(docDefinitios).download(name);
}