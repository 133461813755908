import React, { useState } from "react";
import { TableColumn } from "../..";
import Div from "../../layout/div/Div";
import InputNumberBR from "../../forms/input/InputNumberBR";
import InputInteger from "../../forms/input/InputInteger";
import If from "../../if/If";
import InputDefault from "../../forms/input/InputDefault";
import Select from "../../forms/select/Select";
import { Utils } from "../../../utilities";
import SimpleMaskMoney from "simple-mask-money";

const TableColumnInput = ({
  width,
  type,
  value,
  name,
  data,
  keyCell,
  state,
  handlerChangeInput,
  handlerConfirm,
  handlerChangeSelect,
  label,
  getItens,
  setDataFilter
}) => {

  const [selectValue, setSelectValue] = useState(value);
  const [selectItens, setSelectItens] = useState(getItens);

  // configuration
  const mask = SimpleMaskMoney

  mask.args = {
    allowNegative: false,
    negativeSignAfter: false,
    prefix: '',
    suffix: '',
    fixed: true,
    fractionDigits: 2,
    decimalSeparator: ',',
    thousandsSeparator: '.'
  };


  return (
    <TableColumn width={width}>
      <Div inline="center" marginTop="2" marginLeft="2" marginRight="2">
        <If and value1={type === "number"}>
          <InputNumberBR
            styleIcon={{ display: "none" }}
            isInputGroup={false}
            value={value}
            handlerChange={(e) => {
              data[name] = Number(e.target.value);
              if (Utils.isNotNull(handlerChangeInput)) {
                handlerChangeInput(data);
              }
            }}
          />
        </If>
        <If and value1={type === "money"}>
          <InputDefault
            value={value}
            style={{ width: width }}
            handlerChange={(e) => {
              data[name] = mask.formatToMask(e.target.value);
              handlerChangeInput(data);
            }}
            handlerConfirm={(e) => {
              if (Utils.isNotNull(handlerConfirm)) {
                data[name] = mask.formatToMask(e.target.value);
                handlerConfirm(data)
              }
            }}
          />
        </If>
        <If and value1={type === "text"}>
          <InputDefault
            value={value}
            style={{ width: width }}
            handlerChange={(e) => {
              data[name] = e.target.value;
              handlerChangeInput(data);
            }}
          />
        </If>
        <If and value1={type === "integer"}>
          <InputInteger
            style={{ width: width }}
            value={value}
            handlerChange={(e) => {
              data[name] = parseInt(e.target.value) ? parseInt(e.target.value) : "0";
              handlerChangeInput(data);
            }}
          />
        </If>
        <If and value1={type === "select"}>
          <Select
            required
            responsive="12"
            label={label}
            valueSelected={selectValue}
            handlerChange={(e) => {
              data[name] = parseInt(e.target.value);
              setDataFilter(e, keyCell, name, state);
              if (handlerChangeSelect) {
                handlerChangeSelect(e, data);
              }
              setSelectValue(e.target.value)
            }}
            values={selectItens}
          />
        </If>
        <If and value1={type === "date" || type === "datetime"}>
          <InputDefault
            date={type === "date"}
            datetime={type === "datetime"}
            className="unstyled"
            max="1979-12-31"
            value={value}
            style={{ width: width }}
            handlerChange={(e) => {
              data[name] = e.target.value;
              handlerChangeInput(data);
            }}
          />
        </If>
      </Div>
    </TableColumn>
  );
};

export default TableColumnInput;
