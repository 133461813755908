export default class Clients {
  static BACKOFFICE = {
    LOCALHOST: "localhost",
    PORT: "8383",
    CONTEXT: "/erp/v1/"
  };

  static SACCARIA = {
    FILIAL_1: "192.168.1.194",
    FILIAL_2: "192.168.2.250",
    FILIAL_4: "192.168.4.200"
  };

  static PRIME = {
    FILIAL_1: "192.168.7.243"
  }

  static getUrlClientLocalHost = () => {
    return `http://${Clients.BACKOFFICE.LOCALHOST}:${Clients.BACKOFFICE.PORT}${Clients.BACKOFFICE.CONTEXT}`;
  };

  static getUrlClient = (client, filial) => {
    return `http://${Clients[client][filial]}:${Clients.BACKOFFICE.PORT}${Clients.BACKOFFICE.CONTEXT}`;
  };

}
