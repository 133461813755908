import React from "react";
import PropTypes from "prop-types";

import { Label, Div } from "../../../components";

import { responsive, float, padding, margin } from "../../css";

const Select = (props) => {
  return (
    <Div className={getClassSelect(props)} col={props.col}>
      {props.label && (
        <Div inline="start">
          <Label value={props.label}></Label>
        </Div>
      )}
      <select
        value={props.valueSelected}
        className="form-control"
        onChange={props.handlerChange}
        name={props.name}
        required={props.required}
        disabled={props.disabled}
        multiple={props.multiple}
      >
        {Array.from(props.values).map((item, index) => {
          return (
            <option key={index} value={item.id}>
              {item.value}
            </option>
          );
        })}
      </select>
    </Div>
  );
};

const getClassSelect = (props) => {
  let classe = "form-group ";
  classe = classe.concat(float(props));
  classe = classe.concat(responsive(props));
  classe = classe.concat(margin(props));
  classe = classe.concat(padding(props));
  return classe;
};

Select.propTypes = {
  col: PropTypes.string,
  values: PropTypes.array.isRequired,
  multiple: PropTypes.bool,
};

Select.defaultProps = {
  col: "3",
};

export default Select;
