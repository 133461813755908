import React, { Component } from "react";
import { Color, Http, PathRoute, Type, Pattern } from "../../utilities";
import moment from "moment";

import {
  Row,
  InputDefault,
  Form,
  SelectValue,
  Select,
  Notification,
  SectionButtonForm,
  NavbarTab,
  Button,
  Div,
  If,
  InputDate,
  SectionForm,
  ButtonIconOutLine,
  ModalNotification,
} from "../../components";
import { connect } from "react-redux";
import InserirEditarConcorrentes from "./InserirEditarConcorrentes";
import InserirEditarProdutos from "./InserirEditarProdutos";
import ListaPrecos from "./ListaPrecos";
import InserirEditarEmpresas from "./InserirEditarEmpresas";

class CadastrarEditar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      nameButton: "Salvar",
      nameButtonPreco: "Alterar",
      tipo: "",
      selectStatus: null,
      status: [
        new SelectValue(null, "SELECIONE", true),
        new SelectValue("aberta", "ABERTA", true),
        new SelectValue("em_digitacao", "EM DIGITAÇÃO", true),
        new SelectValue("concluido", "CONCLUIDO", true),
        new SelectValue("cancelado", "CANCELADO", true),
        new SelectValue("aguardando_apuracao", "AGUARDANDO APURAÇÃO", true),
      ],
      datavalidade: "",
      descricao: "",
      user: props.user,
      idPesquisa: "",
      idUsuario: "",
      concorrentes: [],
      produtosSelecionados: [],
      concorrentesSelecionados: [],
      departamentosSelecionados: [],
      concorrentesInseridos: [],
      departamentos: [],
      selecionarTodos: false,
      filtros: {
        idProduto: props.idProduto,
        idDepartamento: props.idDepartamento,
        idGrupo: props.idGrupo,
        idMarca: props.idMarca,
        limite: 200,
        descricaoProduto: null,
        departamentos: null,
        inserido: false,
      },
      itemActive: {
        target: "DADOS",
        value: "DADOS",
        active: true,
      },
      marcas: [],

      departs: new Map(),
      departsComboBox: [],
      departsComboBoxSelecionados: [],

      ...this.props.location.state,
    };

    this.submit = this.submit.bind(this);
  }

  UNSAFE_componentWillMount() {
    let pesquisa = this.state.pesquisa;
    this.getDataPrecificador();
    if (!!pesquisa) {
      this.getDataConcorrentesInseridos(pesquisa.idPesquisa);
    }
    if (this.state.editar) {
      this.setState({
        nameButton: "Alterar",
        nameButtonPreco: "Alterar",
        idPesquisa: pesquisa.idPesquisa,
        tipo: pesquisa.tipo,
        datavalidade: pesquisa.datavalidade,
        selectStatus: pesquisa.status,
        idUsuarioPrecificador: pesquisa.idUsuarioPrecificador,
        precificador: pesquisa.precificador,
        dataValidadePesquisa: pesquisa.datavalidade,
        descricao: pesquisa.descricao ? pesquisa.descricao : "",
      });
    }
    this.setState({
      idUsuario: this.state.user.idUsuario,
    });
  }

  getDataConcorrentesInseridos(idPesquisa) {
    if (idPesquisa) {
      Http.get("/pesquisa/" + idPesquisa + "/concorrente/")
        .then(({ status, data }) => {
          if (data) {
            this.setState({ concorrentesInseridos: data });
          }
        })
        .catch((error) => console.log(error));
    }
  }

  enumStatusPesquisa(status) {
    switch (status) {
      case "ABERTA":
        return "aberta";

      case "EM DIGITAÇÃO":
        return "em_digitacao";

      case "CONCLUIDO":
        return "concluido";

      case "CANCELADO":
        return "cancelado";

      case "AGUARDANDO APURAÇÃO":
        return "aguardando_apuracao";

      default:
        return null;
    }
  }

  alterarSituacaoPesquisa(pesquisa) {
    if (this.state.selectStatus !== null) {
      Http.put(`/pesquisa/situacao/${this.state.selectStatus}`, pesquisa).then(
        ({ data }) => {
          console.log(data);
        }
      );
    }
  }

  getDataPrecificador() {
    this.setState({ precificadores: [] });
    Http.get(
      `/usuario/listarPrecificador/` + this.state.user.idGestorCompras
    ).then(({ data }) => {
      if (data) {
        let preComDados = [];
        preComDados.push(new SelectValue(0, "SELECIONE", true));
        Array.from(data).map((pre) => {
          preComDados.push(new SelectValue(pre.idUsuario, pre.nome, false));
        });
        this.setState({ precificadores: preComDados });
      }
    });
  }

  listaPesquisa() {
    this.props.history.push({ pathname: `/compras/pesquisa` });
  }

  getTipoPesquisaSelect() {
    return [
      new SelectValue("", "SELECIONE", true),
      new SelectValue("ALEATÓRIA", "ALEATÓRIA", false),
      new SelectValue("DIRECIONADA", "DIRECIONADA", false),
      new SelectValue("OFERTA", "OFERTA", false),
      new SelectValue("INSTAGRAM", "INSTAGRAM", false)
    ];
  }

  async submit(event) {
    event.preventDefault();
    var valida = this.validaFormProd();
    if (valida) {
      if (event.target.reportValidity()) {
        if (!this.state.idPesquisa) {
          this.cadastrar(this.state);
        } else {
          this.update(this.state);
        }
      }
    }
  }

  voltarPesquisa() {
    this.props.history.push({
      pathname: PathRoute.PATH.PESQUISA,
    });
  }

  cadastrar() {
    let novaPesquisa = {
      idGestorCompras: this.state.user.idGestorCompras,
      status: "ABERTA",
      tipo: this.state.tipo,
      descricao: this.state.descricao,
      datavalidadestr: this.state.datavalidade,
    };

    Http.post("/pesquisa", novaPesquisa).then(({ data }) => {
      if (data) {
        console.log(data);
        Notification.success(data.mensagem);
        this.setState({
          idPesquisa: data.idpesquisa,
        });
      } else {
        Notification.danger(data.mensagem);
      }
    });
  }

  dataValidadePesquisa(data) {
    this.setState({ datavalidade: moment(data).format(Pattern.DATE.YYYY_MM_DD_HH_MM)});
  }

  validaFormProd() {
    var valida = true;

    if (!this.state.tipo) {
      Notification.danger("Selecione um tipo de Pesquisa");
      return false;
    }

    if (this.state.descricao === "") {
      Notification.danger("Escreve uma descrição para pesquisa");
      return false;
    }

    // if (!this.state.datavalidade) {
    //   Notification.danger("Selecione uma data de validade");
    //   return false;
    // }

    return valida;
  }

  update(pesquisa) {
    pesquisa.idUsuarioAtualizacao = this.state.user.idUsuario;

    console.log(pesquisa);
    let putPesquisa = {
      idPesquisa: pesquisa.idPesquisa,
      idUsuarioAtualizacao: pesquisa.idUsuarioAtualizacao,
      idGestorCompras: pesquisa.pesquisa.idGestorCompras,
      descricao: pesquisa.descricao,
      datavalidade: pesquisa.datavalidade,
      tipo: this.state.tipo,
    };
    Http.put("/pesquisa", putPesquisa).then(({ data }) => {
      if (data) {
        console.log(data);
        Notification.success(data.mensagem);
        this.alterarSituacaoPesquisa({ idPesquisa: pesquisa.idPesquisa });
      } else {
        Notification.danger(data.mensagem);
      }
    });
  }

  onConfirmDeletarPesquisa(pesquisa) {
    ModalNotification.confirm(
      "Deseja Excluir esta Pesquisa? ",
      "Esta pesquisa será Excluída no sistema de compras!",
      () => this.delete(pesquisa),
      () => {}
    );
  }

  async delete(pesquisa) {
    let novaPesquisa = {
      idPesquisa: this.state.idPesquisa,
      idGestorCompras: this.state.user.idGestorCompras,
      idUsuarioInativacao: this.state.idUsuario,
    };
    pesquisa
      ? (pesquisa.idUsuarioInativacao = this.state.idUsuario
          ? this.state.idUsuario
          : "")
      : (pesquisa = false);

    await Http.delete(`/pesquisa`, pesquisa ? pesquisa : novaPesquisa)
      .then(({ status, data }) => {
        if (data.status) {
          Notification.success(data.mensagem);
          this.voltarPesquisa();
        } else {
          Notification.danger(data.mensagem);
        }
      })
      .catch((error) => console.log(error));
  }

  getItens = () => {
    let item = this.state.itemActive;

    return [
      {
        target: "DADOS",
        value: "DADOS DA PESQUISA",
        active: item.value === "DADOS",
      },
      
      {
        target: "INSERIR CONCORRENTES",
        value: "INSERIR CONCORRENTES",
        active: item.value === "INSERIR CONCORRENTES",
      },
      {
        target: "PRODUTOS",
        value: "PRODUTOS DA PESQUISA",
        active: item.value === "PRODUTOS",
      },
      {
        target: "EMPRESAS",
        value: "EMPRESAS",
        active: item.value === "EMPRESAS",
      },
      {
        target: "LISTA DE PREÇOS",
        value: "LISTA DE PREÇOS",
        active: item.value === "LISTA DE PREÇOS",
      },
    ];
  };

  validNavAbas(itemActive) {
    // itemActive.stopPropagation();
    // itemActive.preventDefault();
    let item = this.state.itemActive;
    let formValidar = this.validaFormProd();

    if (formValidar) {
      this.setState({ itemActive });
    }

    if (!formValidar) {
      item.value = "DADOS";
      item.target = "DADOS DA PESQUISA";
    }
  }

  renderPesquisa() {
    return (
      <Div>
        {this.state.idPesquisa && (
          <Form id="formCadastroPesquisa" handlerSubmit={this.submit}>
            <SectionForm>
              <Row col="12">
                <InputDefault
                  disabled
                  label="Número da Pesquisa"
                  name="idpesquisa"
                  responsive="2"
                  value={this.state.idPesquisa}
                />
                <InputDefault
                  responsive="4"
                  label="Descrição da Pesquisa"
                  name="descricao"
                  handlerChange={(e) => {
                    this.setState({ descricao: e.target.value });
                  }}
                  value={this.state.descricao}
                />
                <Select
                  required
                  responsive="3"
                  label="Tipo de Pesquisa"
                  valueSelected={this.state.tipo}
                  handlerChange={(e) => {
                    this.setState({ tipo: e.target.value });
                  }}
                  values={this.getTipoPesquisaSelect()}
                />
                <Select
                  required
                  responsive="3"
                  label="Status Pesquisa"
                  valueSelected={this.enumStatusPesquisa(
                    this.state.selectStatus
                  )}
                  handlerChange={(e) => {
                    this.setState({ selectStatus: e.target.value });
                  }}
                  values={this.state.status}
                />
                <InputDate
                  required
                  label="Validade da Pesquisa"
                  direction="down"
                  colorIcon="white"
                  bgIcon="secondary"
                  bg="secondary"
                  responsive="3"
                  handlerChangeDay={(data) => this.dataValidadePesquisa(data)}
                  value={this.state.datavalidade}
                />
              </Row>
              <SectionButtonForm>
                <Row col="12" style={{ justifyContent: "flex-end" }}>
                  <Div margin="1">
                    <ButtonIconOutLine
                      icon={Type.ICON.ARROW_ANGLE_LEFT}
                      value="Voltar"
                      btn={Color.NODE.SECONDARY}
                      handlerClick={(e) => {
                        e.preventDefault();
                        this.voltarPesquisa();
                      }}
                    />
                  </Div>

                  <Div margin="1">
                    <ButtonIconOutLine
                      icon={Type.ICON.DELETE}
                      value="Excluir"
                      btn={Color.NODE.DANGER}
                      handlerClick={(e) => {
                        e.preventDefault();
                        this.onConfirmDeletarPesquisa(this.state.pesquisa);
                      }}
                    />
                  </Div>

                  <Button
                    btn={Color.NODE.PRIMARY}
                    value="Salvar"
                    col="1"
                    margin="1"
                  />
                </Row>
              </SectionButtonForm>
            </SectionForm>
          </Form>
        )}
      </Div>
    );
  }

  renderCadastrar() {
    return (
      <Div>
        {!this.state.idPesquisa && (
          <Form id="formCadastroPesquisa" handlerSubmit={this.submit}>
            <Row col="12" style={{ paddingTop: "15px" }}>
              <InputDefault
                required
                responsive="6"
                label="Descrição da Pesquisa"
                valueSelected={this.state.descricao}
                handlerChange={(e) => {
                  this.setState({ descricao: e.target.value });
                }}
                values={this.state.pesquisa}
              />
              <Select
                required
                responsive="3"
                label="Tipo de Pesquisa"
                valueSelected={this.state.tipo}
                handlerChange={(e) => {
                  this.setState({ tipo: e.target.value });
                }}
                values={this.getTipoPesquisaSelect()}
              />
              <InputDate
                required
                label="Validade da Pesquisa"
                direction="down"
                colorIcon="white"
                bgIcon="secondary"
                bg="secondary"
                responsive="3"
                handlerChangeDay={(data) => this.dataValidadePesquisa(data)}
                value={this.state.datavalidade}
              />
            </Row>
            <SectionButtonForm>
              <Row col="12" style={{ justifyContent: "flex-end" }}>
                <Div margin="1">
                  <ButtonIconOutLine
                    icon={Type.ICON.ARROW_ANGLE_LEFT}
                    value="Voltar"
                    btn={Color.NODE.SECONDARY}
                    handlerClick={(e) => {
                      e.preventDefault();
                      this.voltarPesquisa();
                    }}
                  />
                </Div>
                <Button
                  btn={Color.NODE.PRIMARY}
                  value="Próximo"
                  col="1"
                  margin="1"
                />
              </Row>
            </SectionButtonForm>
          </Form>
        )}
      </Div>
    );
  }

  render() {
    let { target } = this.state.itemActive;

    return (
      <Div
        margin="6"
        shadow
        bg={Color.NODE.WHITE}
        borderLeft={Color.NODE.PRIMARY}
      >
        <If and value1={this.state.idPesquisa}>
          <Div className="w-100">
            <NavbarTab
              itens={this.getItens()}
              handlerClick={(itemActive) => {
                this.validNavAbas(itemActive);
              }}
            />
          </Div>
        </If>
        <If and value1={!this.state.idPesquisa}>
          {this.renderCadastrar()}
        </If>
        <If and value1={target === "DADOS"}>
          {this.renderPesquisa()}
        </If>
        <If and value1={target === "INSERIR CONCORRENTES"}>
          <InserirEditarConcorrentes
            idGestorCompras={this.state.user.idGestorCompras}
            idPesquisa={this.state.idPesquisa}
          />
        </If>
        <If and value1={target === "PRODUTOS"}>
          <InserirEditarProdutos
            idGestorCompras={this.state.user.idGestorCompras}
            idUsuario={this.state.user.idUsuario}
            idPesquisa={this.state.idPesquisa}
          />
        </If>
        <If and value1={target === "EMPRESAS"}>
          <InserirEditarEmpresas
            idGestorCompras={this.state.user.idGestorCompras}
            idUsuario={this.state.user.idUsuario}
            idPesquisa={this.state.idPesquisa}
          />
        </If>
        <If and value1={target === "LISTA DE PREÇOS"}>
          <ListaPrecos
            idGestorCompras={this.state.user.idGestorCompras}
            idPesquisa={this.state.idPesquisa}
            concorrentesInseridos={this.state.concorrentesInseridos}
          />
        </If>
      </Div>
    );
  }
}

const mapStateToProps = (store) => ({
  idGestorCompras: store.loginState.user.idGestorCompras,
  user: store.loginState.user,
});

export default connect(mapStateToProps)(CadastrarEditar);
