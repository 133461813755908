import React from 'react'
import { col, className, inline } from '../css';

const getClassDefault = (props) => {
    let classe = "";
    classe = classe.concat(col(props));
    classe = classe.concat(inline(props));
    classe = classe.concat(className(props));
    return classe;
  };
  

const TableColumn = (props) => {
    return (
        <td onClick={props.handlerClick} className={getClassDefault(props)} align={props.align} width={props.width}>
            {props.value || props.children}
        </td>
    )
}

export default TableColumn