import React from "react";
import PropTypes from "prop-types";
import {
  className,
  underline,
  colorText,
  float,
  pointer,
  margin,
  padding,
  display,
  offset,
  backGround,
  inline,
  col,
  shadow,
  rounded
} from "../../css";

const getClassDefault = props => {
  let classe = "";
  classe = classe.concat(colorText(props));
  classe = classe.concat(underline(props));
  classe = classe.concat(float(props));
  classe = classe.concat(pointer(props));
  classe = classe.concat(margin(props));
  classe = classe.concat(padding(props));
  classe = classe.concat(display(props));
  classe = classe.concat(offset(props));
  classe = classe.concat(backGround(props));
  classe = classe.concat(inline(props));
  classe = classe.concat(col(props));
  classe = classe.concat(shadow(props));
  classe = classe.concat(rounded(props));
  classe = classe.concat(className(props));

  return classe;
};

const Titulo = props => {
  return generateTitle(
    props.type,
    getClassDefault(props),
    props.value,
    props.children
  );
};

const generateTitle = (type, classe, message, children) => {
  let title;
  switch (type) {
    case "h2":
      title = <h2 className={classe}>{message || children}</h2>;
      break;
    case "h3":
      title = <h3 className={classe}>{message || children}</h3>;
      break;
    case "h4":
      title = <h4 className={classe}>{message || children}</h4>;
      break;
    case "h5":
      title = <h5 className={classe}>{message || children}</h5>;
      break;
    case "h6":
      title = <h6 className={classe}>{message || children}</h6>;
      break;
    default:
      title = <h1 className={classe}>{message || children}</h1>;
  }
  return title;
};

Titulo.propTypes = {
  type: PropTypes.string.isRequired,
  color: PropTypes.string,
  value: PropTypes.any
};

Titulo.defaultProps = {
  type: "h1"
};

export default Titulo;
