import React, { Component } from "react";
import {
  Color,
  Http,
  Mask,
  Type,
} from "../../utilities";
import CryptoJS from "crypto-js";

import {
  Row,
  InputDefault,
  InputPhone,
  Form,
  SelectValue,
  Select,
  Notification,
  SectionForm,
  SectionButtonForm,
  Button,
  ModalCustom,
  Div,
  Table,
  ButtonIconOutLine,
} from "../../components";
import { connect } from "react-redux";

class Cadastrar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      nameButton: "Salvar",
      idPessoa: "",
      labelModal: "Erro ao Fazer a busca!",
      labelOption: "EMPRESA",
      user: props.user,
      pessoas: [],
      fornecedores: [],
      empresas: [],
      optionSelected: [],
      nomeOption: "",

      closeModal: false,

      ...this.props.location.state,
    };

    this.submit = this.submit.bind(this);
    this.listaUsuarios = this.listaUsuarios.bind(this);
    this.configuraUsuario = this.configuraUsuario.bind(this);
    this.validaForm = this.validaForm.bind(this);
  }

  UNSAFE_componentWillMount() {
    let usuario = this.state.usuario;
    if (this.state.editar) {
      this.configuraUsuario(usuario);
    }
  }

  configuraUsuario(usuario) {
    this.setState({
      nameButton: usuario.ativo ? "Alterar" : "Ativar Usúario",
      idUsuario: usuario.idUsuario,
      idGestorCompras: usuario.idGestorCompras,
      nome: usuario.nome,
      email: usuario.email,
      login: usuario.login,
      tipo: usuario.tipo,
      cargo: usuario.cargo,
      ativo: usuario.ativo,
      telefone: Mask.maskValuePhone(usuario.telefone),
      idUsuarioAtualizacao: this.state.user.idUsuario,
    });
  }

  listaUsuarios() {
    this.props.history.push({ pathname: `/compras/usuario` });
  }

  getTipoSelect() {
    return [
      new SelectValue("", "SELECIONE", true),
      new SelectValue("GESTOR", "GESTOR", false),
      new SelectValue("PRECIFICADOR", "PRECIFICADOR", false),
      new SelectValue("EMPRESA", "EMPRESA", false),
      new SelectValue("FORNECEDOR", "FORNECEDOR", false),
    ];
  }

  async submit(event) {
    event.preventDefault();
    if (event.target.reportValidity()) {
      if (this.state.idUsuario === undefined) {
        this.cadastrar(this.state);
      } else {
        this.update(this.state);
      }
    }
  }

  setCloseModal = (boolean) => {
    this.setState({
      closeModal: boolean,
    });
  };

  cadastrar(usuario) {
    let senha = CryptoJS.MD5(usuario.senha).toString();
    usuario.senha = senha;
    usuario.idGestorCompras = this.state.user.idGestorCompras;
    let validate = this.validaForm(usuario);
    if (validate) {
      Http.post("/usuario", usuario).then(({ data }) => {
        if (data.status) {
          Notification.success(data.mensagem);
          this.listaUsuarios();
        } else {
          Notification.danger(data.mensagem);
        }
      });
    }
  }

  update(usuario) {
    let senha = CryptoJS.MD5(usuario.senha).toString();
    usuario.senha = senha;
    usuario.ativo = true;
    usuario.idUsuarioAtualizacao = this.state.user.idUsuario;
    let validate = this.validaForm(usuario);
    if (validate) {
      Http.put("/usuario", usuario).then(({ data }) => {
        if (data.status) {
          Notification.success(data.mensagem);
          this.listaUsuarios();
        } else {
          Notification.danger(data.mensagem);
        }
      });
    }
  }

  validaForm(usuario) {
    var status = true;

    if (usuario.email) {
      if (!Mask.validarEmail(usuario.email)) {
        Notification.danger("E-mail inválido!");
        status = false;
      }
    }

    return status;
  }

  getDataEmpresas() {
    Http.get(`/pessoa/listarEmpresa/` + this.state.user.idGestorCompras)
      .then(({ status, data }) => {
        if (data) {
          this.setState({
            optionSelected: data,
          });
        }
      })
      .catch((error) => console.log(error));
  }

  getDataFornecedores() {
    Http.get(`/pessoa/listarFornecedor/` + this.state.user.idGestorCompras)
      .then(({ status, data }) => {
        if (data) {
          if (data) {
            this.setState({
              optionSelected: data,
            });
          }
        }
      })
      .catch((error) => console.log(error));
  }

  render() {
    return (
      <>
        <ModalCustom
          title="Selecione uma das Opções"
          showModal={this.state.closeModal}
          setShowModal={this.setCloseModal}
          confirm={() => {}}
        >
          <Div col="12" style={{ overflow: "auto", maxHeight: "75vh" }}>
            <Table
              columns={[
                { value: "Nome", align: "left" },
                { value: "Razão", align: "left" },
                { value: "Tipo Cadastro", align: "center" },
                { value: "CPF/CNPJ", align: "center" },
              ]}
              fields={[
                { value: "nome", align: "left", type: Type.DADO.STRING },
                { value: "razao", align: "left", type: Type.DADO.STRING },
                { value: "tipoCadastro", type: Type.DADO.STRING },
                { value: "cpfCnpj", type: Type.DADO.CPF_CNPJ },
              ]}
              bordered
              orientation="center"
              search
              data={this.state.optionSelected}
              error={this.state.labelModal}
              checkbox={{ nameChecked: "selected", valueChecked: true }}
              handlerCheckbox={(item) => {
                let optionSelectedNew = this.state.optionSelected;

                optionSelectedNew.forEach((c) => {
                  c.selected = false;
                });

                item.selected = !item.selected;

                this.setState({
                  idPessoa: item.idPessoa,
                  nomeOption: item.nome,
                  optionSelected: optionSelectedNew,
                });
              }}
            ></Table>
          </Div>
        </ModalCustom>
        <Form id="formCadastroUsuario" handlerSubmit={this.submit}>
          <SectionForm borderLeft="primary">
            <Row col="12">
              <InputDefault
                required
                label="Nome"
                name="nome"
                responsive="6"
                value={this.state.nome}
                handlerChange={(e) => {
                  this.setState({ nome: e.target.value });
                }}
              />
              <InputDefault
                required
                disabled={this.state.idUsuario !== undefined}
                label="Login"
                name="login"
                responsive="3"
                value={this.state.login}
                handlerChange={(e) => {
                  this.setState({ login: e.target.value });
                }}
              />
              <InputDefault
                required
                label="E-mail"
                name="email"
                responsive="3"
                value={this.state.email}
                handlerChange={(e) => {
                  this.setState({ email: e.target.value });
                }}
              />
            </Row>
            <Row col="12">
              <InputDefault
                required
                label="Cargo"
                name="cargo"
                responsive="3"
                value={this.state.cargo}
                handlerChange={(e) => {
                  this.setState({ cargo: e.target.value });
                }}
              />
              <InputDefault
                required
                label="Senha"
                name="senha"
                password
                responsive="3"
                value={this.state.senha}
                handlerChange={(e) => {
                  this.setState({ senha: e.target.value });
                }}
              />
              <InputPhone
                required
                label="Telefone"
                name="telefone"
                responsive="3"
                value={this.state.telefone}
                handlerChange={(e) => {
                  this.setState({ telefone: e.target.value });
                }}
              />

              <InputDefault
                disabled
                label={"Status"}
                responsive="3"
                value={this.state.ativo ? "Usúario Ativo" : "Usúario Desativado"}
              />
            </Row>
            <Row col="12">
              <Select
                required
                responsive="3"
                label="Tipo"
                valueSelected={this.state.tipo}
                handlerChange={(e) => {
                  if (e.target.value === "EMPRESA") {
                    this.setState(
                      {
                        closeModal: true,
                        labelModal: "Nenhuma Empresa Encontrada!",
                        optionSelected: [],
                        labelOption: "Empresa Selecionada",
                        tipo: e.target.value,
                      },
                      this.getDataEmpresas()
                    );
                  }
                  if (e.target.value === "FORNECEDOR") {
                    this.setState(
                      {
                        optionSelected: [],
                        closeModal: true,
                        labelOption: "Fornecedor Selecionado",
                        labelModal: "Nenhum Fornecedor Encontrado!",
                        tipo: e.target.value,
                      },
                      this.getDataFornecedores()
                    );
                  }
                  if (
                    e.target.value !== "FORNECEDOR" ||
                    e.target.value !== "EMPRESA"
                  ) {
                    this.setState({
                      optionSelected: [],
                      idPessoa: "",
                      nomeOption: "",
                      labelModal: "",
                      tipo: e.target.value,
                    });
                  }
                }}
                values={this.getTipoSelect()}
              />
              {this.state.nomeOption !== "" && (
                <>
                  <InputDefault
                    disabled
                    label={this.state.labelOption}
                    responsive="3"
                    value={this.state.nomeOption}
                  />

                  <ButtonIconOutLine
                    type="button"
                    className="btn-sm"
                    style={{ maxHeight: "38px", marginTop: "29px" }}
                    btnOutLine={Color.NODE.PRIMARY}
                    icon={Type.ICON.EDIT}
                    value="EDITAR"
                    handlerClick={(_e) => this.setCloseModal(true)}
                  />
                </>
              )}
            </Row>

            <SectionButtonForm col="12">
              <Row col="12" style={{ justifyContent: "flex-end" }}>
                <Button
                  btn={Color.NODE.DANGER}
                  value="Cancelar"
                  col="1"
                  handlerClick={(_e) => this.listaUsuarios()}
                  marginTop="5"
                  marginRight="3"
                />
                <Button
                  btn={Color.NODE.PRIMARY}
                  value={this.state.nameButton}
                  style={{ width: "134px" }}
                  
                  marginTop="5"
                />
              </Row>
            </SectionButtonForm>
          </SectionForm>
        </Form>
      </>
    );
  }
}

const mapStateToProps = (store) => ({
  user: store.loginState.user,
});

export default connect(mapStateToProps)(Cadastrar);
