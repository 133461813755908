import React from "react";
import { active } from "../../css";

const getClassLink = props => {
  let classe = "nav-link navtab-color-item float-right ";
  classe = classe.concat(active(props));
  return classe;
};

const NavbarTabItem = props => {
  return (
    <li className="nav-item " onClick={props.handlerClick}>
      <a className={getClassLink(props)} data-toggle="tab" href={props.target}>
        {props.value || props.children}
      </a>
    </li>
  );
};

export default NavbarTabItem;
