import React, { Component } from "react";
import {
  Div,
  Table,
  InputSearch,
  Title,
  Notification,
  ModalNotification,
  SelectValue,
} from "../../components";
import { connect } from "react-redux";
import { Color, PathRoute, Http, Type } from "../../utilities";
import TemplateFilterPesquisa from "./template/TemplateFilterPesquisa";

class Listar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: props.user,
      selectStatus: null,
      selectTipo: null,
      inicioData: null,
      fimData: null,
      status: [new SelectValue(null, "SELECIONE", true)],
      tipo: [new SelectValue(null, "SELECIONE", true)],
      dialogRede: false,
      pesquisas: [],
      pesquisa: {},
      selectedPesquisas: {},
      globalFilter: null,
      ...this.props.location.state,
    };
  }

  UNSAFE_componentWillMount() {
    this.getDataCotacao();
    this.mapFilterSelect();
  }

  async getDataCotacao() {
    await Http.get(`/cotacao/gestor/` + this.state.user.idGestorCompras)
      .then(({ status, data }) => {
        if (data) {
          Array.from(data).map((pre) => {
            pre.nameStatus = this.enumStatusPesquisa(pre.status);
          });
          this.setState({ pesquisas: data });
        }
      })
      .catch((error) => console.log(error));
  }

  leftToolbarTemplate = () => {
    return (
      <InputSearch
        label="Procurar na Grid"
        name="globalFilter"
        value={this.state.globalFilter}
        handlerChange={(e) => this.setState({ globalFilter: e.target.value })}
        placeholder="Procurar..."
      />
    );
  };

  rightToolbarTemplate = () => {
    return <span className="p-input-icon-right"></span>;
  };

  exportCSV() {}

  editarPesquisa(cotacao) {
    this.props.history.push({
      pathname: PathRoute.PATH.COTACAO_EDITAR,
      state: { cotacao: cotacao, editar: true },
    });
  }

  precificarPesquisa(pesquisa) {
    const { user } = this.state;
    this.props.history.push({
      pathname: PathRoute.PATH.PRECIFICAR_EDITAR,
      state: { pesquisa: pesquisa, editar: true, user: user },
    });
  }

  apuracaoPesquisa(cotacao) {
    const { user } = this.state;
    this.props.history.push({
      pathname: PathRoute.PATH.COTACAO_APURACAO,
      state: { cotacao: cotacao, editar: true, user: user },
    });
  }

  //  detalharProduto(produto) {
  //    alert(produto.descricao);
  //  }

  deletarPesquisa(pesquisa) {
    ModalNotification.confirm(
      "Deseja desativar esta Pesquisa? ",
      "Esta pesquisa será desativada no sistema de compras!",
      () => this.delete(pesquisa),
      () => this.cancelar()
    );
  }

  async delete(pesquisa) {
    pesquisa.idUsuarioInativacao = this.state.user.idUsuario;
    await Http.delete(`/pesquisa`, pesquisa)
      .then(({ status, data }) => {
        if (data.status) {
          Notification.success(data.mensagem);
          this.getDataCotacao();
        } else {
          Notification.danger(data.mensagem);
        }
      })
      .catch((error) => console.log(error));
  }

  cancelar() {}
  clonarConcorrentes(idPesquisaOrigem, idPesquisa) {
    let clonar = { idPesquisaOrigem, idPesquisa };
    Http.post(`/pesquisa/concorrente/clonar`, clonar)
      .then(({ status, data }) => {
        if (data.status) {
          //Notification.success(data.mensagem);
          console.log(data.mensagem);
        } else {
          //Notification.danger(data.mensagem);
          console.log(data.mensagem);
        }
      })
      .catch((error) => console.log(error));
  }

  clonarProdutos(idPesquisaOrigem, idPesquisa) {
    let clonar = { idPesquisaOrigem, idPesquisa };
    Http.post(`/pesquisa/produto/clonar`, clonar)
      .then(({ status, data }) => {
        if (data.status) {
          //Notification.success(data.mensagem);
          console.log(data.mensagem);
          this.getDataCotacao();
        } else {
          //Notification.danger(data.mensagem);
          console.log(data.mensagem);
        }
      })
      .catch((error) => console.log(error));
  }

  clonarPesquisa(idPesquisaOrigem, pesquisa) {
    let clonar = { idPesquisaOrigem };
    Http.post(`/pesquisa/clonar`, clonar)
      .then(({ status, data }) => {
        if (data.status) {
          Notification.success(data.mensagem);
          this.clonarConcorrentes(clonar.idPesquisaOrigem, data.idpesquisa);
          this.clonarProdutos(clonar.idPesquisaOrigem, data.idpesquisa);

          let newPesquisa = {
            ...pesquisa,
            idPesquisa: data.idpesquisa,
          };

          this.editarPesquisa(newPesquisa);
        } else {
          Notification.danger(data.mensagem);
        }
      })
      .catch((error) => console.log(error));
  }

  onConfirmeClone(data) {
    let idPesquisaOrigem = data.idPesquisa;

    ModalNotification.confirm(
      "Deseja Clonar essa Cotação? ",
      "Essa sera criada uma nova cotação com essas informações",
      () => this.clonarPesquisa(idPesquisaOrigem, data)
    );
  }

  mudarFiltros(value, type) {
    const { selectStatus, selectTipo, inicioData, fimData } = this.state;
    if (type === "status") {
      this.setState(
        {
          selectStatus: value === "SELECIONE" ? null : value,
          pesquisas: [],
        },
        () =>
          value != null
            ? this.getPesquisaFiltros(
                value === "SELECIONE" ? null : value,
                selectTipo,
                this.state.user.idGestorCompras,
                inicioData,
                fimData
              )
            : this.getDataCotacao()
      );
    }
    if (type === "tipo") {
      this.setState(
        {
          selectTipo: value === "SELECIONE" ? null : value,
          pesquisas: [],
        },
        () =>
          this.getPesquisaFiltros(
            selectStatus,
            value === "SELECIONE" ? null : value,
            this.state.user.idGestorCompras,
            inicioData,
            fimData
          )
      );
    }

    if (type === "incio") {
      console.log(value);
      this.setState(
        {
          inicioData: value === "SELECIONE" || value === '' ? null : value,
          pesquisas: [],
        },
        () =>
          this.getPesquisaFiltros(
            selectStatus,
            selectTipo,
            this.state.user.idGestorCompras,
            value === "SELECIONE" || value === '' ? null : value,
            fimData
          )
      );
    }

    if (type === "fim") {
      console.log(value);
      this.setState(
        {
          fimData: value === "SELECIONE" || value === '' ? null : value,
          pesquisas: [],
        },
        () =>
          this.getPesquisaFiltros(
            selectStatus,
            selectTipo,
            this.state.user.idGestorCompras,
            inicioData,
            value === "SELECIONE" || value === '' ? null : value,
          )
      );
    }
  }

  mapFilterSelect() {
    let newStatus = [];
    let newTipo = [];

    Http.get(`/pesquisa/gestor/` + this.state.user.idGestorCompras + "/status")
      .then(({ status, data }) => {
        if (data) {
          data.forEach((item) => {
            newStatus.push(new SelectValue(item.status, item.status, false));
          });
          this.setState({
            status: [...this.state.status, ...newStatus],
          });
        }
      })
      .catch((error) => console.log(error));

    Http.get(`/pesquisa/gestor/` + this.state.user.idGestorCompras + "/tipo")
      .then(({ status, data }) => {
        if (data) {
          data.forEach((item) => {
            newTipo.push(new SelectValue(item.tipo, item.tipo, false));
          });
          this.setState({
            tipo: [...this.state.tipo, ...newTipo],
          });
        }
      })
      .catch((error) => console.log(error));
  }

  renderFiltros() {
    let { status, tipo, inicioData, fimData } = this.state;

    return (
      <TemplateFilterPesquisa
        status={status}
        tipo={tipo}
        inicioData={inicioData}
        fimData={fimData}
        handler={(value, type) => this.mudarFiltros(value, type)}
        produtosSelecionados={this.state.produtosSelecionados}
      />
    );
  }

  getPesquisaFiltros(status, tipo, idGestorCompras, inicioData, fimData) {
    let search = {
      idGestorCompras,
      tipo,
      status,
      filtrodatestart: inicioData,
      filtrodateend: fimData,
    };
    Http.post(`/pesquisa/filtro/resumido/`, search)
      .then(({ status, data }) => {
        if (data) {
          this.setState({
            pesquisas: data,
          });
        }
      })
      .catch((error) => console.log(error));
  }

  enumStatusPesquisa(status) {
    switch (status) {
      case "ABERTA":
        return "ABERTA";

      case "CONCLUIDO":
        return "CONCLUÍDO";

      case "CANCELADO":
        return "CANCELADO";
        
      case "EMVIADO_FORNECEDOR" || "ENVIADO_FORNECEDOR":
        return "ENVIADO P/ FORNECEDOR";

      case "AGUARDANDO_APURACAO":
        return "AGUARDANDO APURAÇÃO";

      default:
        return null;
    }
  }

  render() {
    
    return (
      <Div
        margin="3"
        shadow
        bg={Color.NODE.WHITE}
        borderLeft={Color.NODE.PRIMARY}
      >
        <Title
          value="Cotações"
          type="h4"
          paddingLeft="4"
          paddingRight="4"
          paddingTop="3"
        />

        <Div padding="2">
          <Table
            columns={[
              { value: "Código", align: "right" },
              { value: "Descrição", align: "left" },
              { value: "Tipo", align: "left" },
              { value: "Status", align: "left" },
              // { value: "Qtd de Produtos", align: "center" },
              // { value: "Qtd de Produtos Precificado", align: "center" },
              // { value: "Qtd de Concorrentes", align: "center" },
              { value: "Inicio da Vigência", align: "right" },
              { value: "Fim da Vigência", align: "right" },
              { value: "Última Atualização", align: "right" },
            ]}
            fields={[
              { value: "idCotacao", align: "right", type: Type.DADO.STRING },
              { value: "descricao", align: "left", type: Type.DADO.STRING },
              { value: "tipo", align: "left", type: Type.DADO.STRING },
              { value: "nameStatus", align: "left", type: Type.DADO.STRING },
              // { value: "qtd_produtos", type: Type.DADO.STRING },
              // { value: "qtd_produtos_precificados", type: Type.DADO.STRING },
              // { value: "qtd_concorrentes", type: Type.DADO.STRING },
              { value: "iniciovigencia", align: "right", type: Type.DADO.DATE },
              { value: "fimvigencia", align: "right", type: Type.DADO.DATE },
              {
                value: "dataatualizacao",
                align: "right",
                type: Type.DADO.DATE,
              },
            ]}
            bordered
            search
            orientation="center"            
            rota={PathRoute.PATH.COTACAO_CADASTRAR}
            edit
            duplicate
            //delete
            //detail
            apuracao
            iconApuracao={Type.ICON.CHART_LINE}
            iconDeDetail={Type.ICON.SALE_CART}
            iconDuplicate={Type.ICON.CLONE}
            sizeDetail="2"
            sizeEdit="2"
            //sizeDelete="2"
            sizeDuplicate="2"
            detailTitle="Precificar"
            filter={this.renderFiltros()}
            //handlerDetail={(rede) => this.precificarPesquisa(rede)}
            handlerEdit={(rede) => this.editarPesquisa(rede)}
            //handlerDelete={(rede) => this.deletarPesquisa(rede)}
            handlerApuracao={(rede) => this.apuracaoPesquisa(rede)}
            handlerDuplicate={(rede) => this.onConfirmeClone(rede)}
            data={this.state.pesquisas}
            error="Nenhuma Cotação encontrada"
          ></Table>
        </Div>
      </Div>
    );
  }
}

const mapStateToProps = (store) => ({
  idGestorCompras: store.loginState.user.idGestorCompras,
  idUsuario: store.loginState.user.idUsuario,
});

export default connect(mapStateToProps)(Listar);
