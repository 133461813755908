import React, { Component } from "react";
import { Div } from "..";
import { Color } from "../../utilities";
import CollapseCardapioIfood from "./CollapseCardapioIfood";

class CollapseGroup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      idsCollapse: []
    };
  }

  UNSAFE_componentWillReceiveProps(props) {

    this.setState({
      idsCollapse: Array.from(props.collapses).map(
        collapse => collapse.idCollapse
      )
    }, () => {
        Array.from(this.state.idsCollapse).forEach(id => {
            let classList = document.getElementById(id).classList;
            if(classList.contains('show')) {
               classList.remove('show')
            }
        })
    });
  }

  render() {
    const { idParent, collapses } = this.props;
    return (
      <Div id={idParent}>
        {Array.from(collapses).map(collapse => {
          return (
            <CollapseCardapioIfood
              idParent={idParent}
              borderLeft={Color.NODE.DEFAULT}
              bg={Color.NODE.WHITE}
              id={collapse.idCollapse}
              arrow
              title={collapse.title}
              body={collapse.body}
            ></CollapseCardapioIfood>
          );
        })}
      </Div>
    );
  }
}

export default CollapseGroup;
