import Http from "./Http";
import CryptoJS from "crypto-js";

class Auth {

  static async login(user) {
    let loggedUser = {};
    let senha = CryptoJS.MD5(user.senha).toString();
    let newUser = {
      ...user,
      senha
    }
    await Http.post("/usuario/login", newUser).then(user => {
      loggedUser = user;
    });
    return loggedUser;
  }
}

export default Auth;
