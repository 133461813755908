import React, { Component } from "react";
import { Switch, Route, withRouter, Redirect } from "react-router-dom";

import {
    Dashboard,
    Login,
    Pessoas,
    PessoasCadastrar,
    Fornecedor,
    FornecedorCadastrar,
    Empresa,
    EmpresaCadastrar,
    Filial,
    FilialCadastrar,
    Gestor,
    GestorCadastrar,
    GestorBloqueiar,
    Usuarios,
    UsuariosCadastrar,
    MeusDadosInfo,
    RedeEmpresa,
    RedeEmpresaCadastrar,
    Produto,
    ProdutoCadastrar,
    ProdutoEditar,
    Marca,
    MarcaCadastrar,
    Concorrente,
    ConcorrenteCadastrar,
    Grupo,
    Pesquisa,
    PesquisaCadastrar,
    PesquisaEditar,
    PrecificacaoEditar,
    ApuracaoRelatorio,
    Cotacao,
    CotacaoCadastrar,
    CotacaoEditar,
	GrupoProdutos,
    GrupoProdutosCadastrar,
    EnvioCotacao,
    ResponderCotacao,
    ApuracaoCotacao,
    CotacaoRelatorio
} from "../views";


import { connect } from "react-redux";
import App from "../App";
import { bindActionCreators } from "redux";
import { logar } from "../redux/actions";

class Routes extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            this.props.handlerRoute(this.props.location);
        }
    }

    getRoutePrivate(path, component, title) {
        if(sessionStorage.getItem("auth")){
            return (
                <Route
                    path={path}
                    render={(props) => {
                        return (
                            <App {...this.props} title={title}>
                                {component}
                            </App>
                        );
                    }}
                /> 
            )
        }
        return (<Redirect to="/login" />);
    }

    render() {

         return (
            <Switch>
                <Route path="/login" component={Login} />  

                <Redirect from="/compras" exact to="/compras/dashboard" />    

                {this.getRoutePrivate("/compras/dashboard", <Dashboard {...this.props} />)}
                {this.getRoutePrivate("/compras/meusdados", <MeusDadosInfo {...this.props} />)}               

                
                {this.getRoutePrivate("/compras/pessoa/cadastrar", <PessoasCadastrar {...this.props} />)}     
                {this.getRoutePrivate("/compras/pessoa/editar", <PessoasCadastrar {...this.props} />)} 
                {this.getRoutePrivate("/compras/pessoa", <Pessoas {...this.props} />)}                       

                
                {this.getRoutePrivate("/compras/fornecedor/cadastrar", <FornecedorCadastrar {...this.props} />)}     
                {this.getRoutePrivate("/compras/fornecedor/editar", <FornecedorCadastrar {...this.props} />)} 
                {this.getRoutePrivate("/compras/fornecedor", <Fornecedor {...this.props} />)}       

                
                {this.getRoutePrivate("/compras/empresa/cadastrar", <EmpresaCadastrar {...this.props} />)}     
                {this.getRoutePrivate("/compras/empresa/editar", <EmpresaCadastrar {...this.props} />)} 
                {this.getRoutePrivate("/compras/empresa", <Empresa {...this.props} />)}       

               
                {this.getRoutePrivate("/compras/filial/cadastrar", <FilialCadastrar {...this.props} />)}     
                {this.getRoutePrivate("/compras/filial/editar", <FilialCadastrar {...this.props} />)} 
                {this.getRoutePrivate("/compras/filial", <Filial {...this.props} />)}       

                
                {this.getRoutePrivate("/compras/gestor/cadastrar", <GestorCadastrar {...this.props} />)}     
                {this.getRoutePrivate("/compras/gestor/editar", <GestorCadastrar {...this.props} />)}    
                {this.getRoutePrivate("/compras/gestor/bloqueiar", <GestorBloqueiar {...this.props} />)}  
                {this.getRoutePrivate("/compras/gestor", <Gestor {...this.props} />)}       

                
                {this.getRoutePrivate("/compras/usuario/cadastrar", <UsuariosCadastrar {...this.props} />)}     
                {this.getRoutePrivate("/compras/usuario/editar", <UsuariosCadastrar {...this.props} />)}   
                {this.getRoutePrivate("/compras/usuario", <Usuarios {...this.props} />)}       

                
                {this.getRoutePrivate("/compras/redeempresa/cadastrar", <RedeEmpresaCadastrar {...this.props} />)}     
                {this.getRoutePrivate("/compras/redeempresa/editar", <RedeEmpresaCadastrar {...this.props} />)} 
                {this.getRoutePrivate("/compras/redeempresa", <RedeEmpresa {...this.props} />)}       

                {this.getRoutePrivate("/compras/produto/cadastrar", <ProdutoCadastrar {...this.props} />)}     
                {this.getRoutePrivate("/compras/produto/editar", <ProdutoEditar {...this.props} />)} 
                {this.getRoutePrivate("/compras/produto", <Produto {...this.props} />)}       

                
                {this.getRoutePrivate("/compras/marca/cadastrar", <MarcaCadastrar {...this.props} />)}     
                {this.getRoutePrivate("/compras/marca/editar", <MarcaCadastrar {...this.props} />)} 
                {this.getRoutePrivate("/compras/marca", <Marca {...this.props} />)}       

                
                {this.getRoutePrivate("/compras/concorrente/cadastrar", <ConcorrenteCadastrar {...this.props} />)}     
                {this.getRoutePrivate("/compras/concorrente/editar", <ConcorrenteCadastrar {...this.props} />)} 
                {this.getRoutePrivate("/compras/concorrente", <Concorrente {...this.props} />)}       

                
                {/* {this.getRoutePrivate("/compras/departamento/cadastrar", <DepartamentoCadastrar {...this.props} />)}     
                {this.getRoutePrivate("/compras/departamento/editar", <DepartamentoCadastrar {...this.props} />)} 
                {this.getRoutePrivate("/compras/departamento", <Departamento {...this.props} />)}        */}

            
                {this.getRoutePrivate("/compras/departamentos", <Grupo {...this.props} />)}  

                {this.getRoutePrivate("/compras/pesquisa/cadastrar", <PesquisaCadastrar {...this.props} />)}     
                {this.getRoutePrivate("/compras/pesquisa/editar", <PesquisaEditar {...this.props} />)} 
                {this.getRoutePrivate("/compras/pesquisa/precificar", <PrecificacaoEditar {...this.props} />)}
                {this.getRoutePrivate("/compras/pesquisa/apuracao", <ApuracaoRelatorio {...this.props} />)}
                {this.getRoutePrivate("/compras/pesquisa", <Pesquisa {...this.props} />)}

                
                {this.getRoutePrivate("/compras/cotacao/apuracao", <CotacaoRelatorio {...this.props} />)}
                {this.getRoutePrivate("/compras/cotacao/cadastrar", <CotacaoCadastrar {...this.props} />)}
                {this.getRoutePrivate("/compras/cotacao/editar", <CotacaoEditar {...this.props} />)}
                {this.getRoutePrivate("/compras/cotacao", <Cotacao {...this.props} />)}
				
				{this.getRoutePrivate("/compras/grupoprodutos/cadastrar", <GrupoProdutosCadastrar {...this.props} />)}                
                {this.getRoutePrivate("/compras/grupoprodutos", <GrupoProdutos {...this.props} />)}

                {this.getRoutePrivate("/compras/enviocotacao", <EnvioCotacao {...this.props} />)}
                {this.getRoutePrivate("/compras/respondercotacao", <ResponderCotacao {...this.props} />)}
                {this.getRoutePrivate("/compras/apuracaocotacao", <ApuracaoCotacao {...this.props} />)}

                <Route path="/" component={Login} />
                <Route path="/*" component={Login} />

                
            </Switch>
        );
    }
}

const mapStateToProps = (store) => ({
    loggedIn: store.loginState.loggedIn,
    user: store.loginState.user,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators({ logar }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Routes));
