import React, { Component } from "react";
import { connect } from "react-redux";
import Routes from "./routes/Routes";
import { BrowserRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { logar } from "./redux/actions";

class Init extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeMenu: false,
      itensBrand: [],
      title: "",
      path: ""
    };

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.loggedUser = this.loggedUser.bind(this);
  }

  toggleNavbar() {
    this.setState({
      activeMenu: !this.state.activeMenu
    });
  }

  loggedUser() {
    if (!this.props.loggedIn) {
      this.props.logar(true, JSON.parse(sessionStorage.getItem("auth")));
    }
  }

  render() {
    return (
      <BrowserRouter>
        <Routes handlerRoute={({ pathname }) => this.loggedUser()} />
      </BrowserRouter>
    );
  }
}

const mapStateToProps = store => ({
  loggedIn: store.loginState.loggedIn
});

const mapDispatchToProps = dispatch => bindActionCreators({ logar }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Init);
