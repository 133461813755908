import React from "react";
import { Mask, Type } from "../../../utilities";
import InputDefault from "./InputDefault";
import Pattern from "../../../utilities/enuns/Pattern";

const InputCpfCnpj = props => {
  return (
    <InputDefault
      {...props}
      formcontrol
      pattern={Pattern.INPUT.CPF_CNPJ.pattern}
      title={Pattern.INPUT.CPF_CNPJ.message}
      placeholder={Pattern.INPUT.CPF_CNPJ.example}
      type={Type.INPUT.CPF_CNPJ}
      handlerChange={e => {
        Mask.maskCpfCnpj(e);
        props.handlerChange && props.handlerChange(e);
      }}
    />
  );
};

export default InputCpfCnpj;
