import React, { Component } from "react";
import {
  Div,
  Table,
  InputSearch,
  ComboBox,
  Title,
  Select,
  SelectValue,
  ModalNotification,
} from "../../components";
import { connect } from "react-redux";
import { Color, PathRoute, Http, Type } from "../../utilities";

const ENTER = 13;

class Listar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: props.user,
      dialogPessoa: false,
      pessoas: [],
      pessoa: {},
      selectedPessoa: {},
      globalFilter: null,
      ...this.props.location.state,
    };

    this.deletarPessoa = this.deletarPessoa.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.getDataPessoas();
  }

  async getDataPessoas() {
    await Http.get(`/pessoa/listarEmpresa/` + this.state.user.idGestorCompras)
      .then(({ status, data }) => {
        if (data) {
          this.setState({ pessoas: data });
        }
      })
      .catch((error) => console.log(error));
  }

  leftToolbarTemplate = () => {
    return (
      <InputSearch
        label="Procurar na Grid"
        name="globalFilter"
        value={this.state.globalFilter}
        handlerChange={(e) => this.setState({ globalFilter: e.target.value })}
        placeholder="Procurar..."
      />
    );
  };

  rightToolbarTemplate = () => {
    return <span className="p-input-icon-right"></span>;
  };

  exportCSV() {}

  editarPessoa(pessoa) {
    this.props.history.push({
      pathname: PathRoute.PATH.EMPRESA_EDITAR,
      state: { pessoa: pessoa, editar: true },
    });
  }

  deletarPessoa(pessoa) {
    ModalNotification.confirm(
      "Deseja desativar esta Empresa? " + pessoa.nome,
      "Esta Empresa será desativada no sistema de compras!",
      () => this.delete(pessoa),
      () => this.cancelar()
    );
  }

  async delete(pessoa) {
    pessoa.idUsuarioInativacao = this.state.user.idUsuario;
    await Http.post(`/pessoa/deletar`, pessoa)
      .then(({ status, data }) => {
        if (data.status) {
          Notification.success(data.mensagem);
          this.getDataPessoas();
        } else {
          Notification.danger(data.mensagem);
        }
      })
      .catch((error) => console.log(error));
  }

  cancelar() {}

  render() {
    return (
      <Div
        margin="3"
        shadow
        bg={Color.NODE.WHITE}
        borderLeft={Color.NODE.PRIMARY}
      >
        <Title
          value="Empresa"
          type="h4"
          paddingLeft="4"
          paddingRight="4"
          paddingTop="3"
        />

        <Div padding="2">
          <Table
            columns={[
              { value: "Nome", align: "left" },
              { value: "Razão", align: "left" },
              { value: "CPF/CNPJ", align: "center" },
              { value: "E-mail", align: "center" },
              { value: "Contato", align: "center" },
              { value: "Telefone Principal", align: "center" },
              { value: "Celular", align: "center" },
            ]}
            fields={[
              { value: "nome", align: "left", type: Type.DADO.STRING },
              { value: "razao", align: "left", type: Type.DADO.STRING },
              { value: "cpfCnpj", type: Type.DADO.CPF_CNPJ },
              { value: "emailPrincipal", type: Type.DADO.STRING },
              { value: "contatoPrincipal", type: Type.DADO.STRING },
              { value: "telefonePrincipal", type: Type.DADO.PHONE },
              { value: "telefoneFinanceiro", type: Type.DADO.PHONE },
            ]}
            bordered
            search
            orientation="center"
            pagination
            rota={PathRoute.PATH.EMPRESA_CADASTRAR}
            edit
            delete
            handlerEdit={(pessoa) => this.editarPessoa(pessoa)}
            handlerDelete={(pedido) => this.deletarPessoa(pedido)}
            data={this.state.pessoas}
            error="Nenhuma Empresa encontrado"
          ></Table>
        </Div>
      </Div>
    );
  }
}

const mapStateToProps = (store) => ({
  idEmpresa: store.loginState.user.idEmpresa,
  idFilial: store.loginState.user.idFilial,
});

export default connect(mapStateToProps)(Listar);
