import React from "react";
import { Color, Mask, Type, Utils } from "../../../utilities";
import { Component } from "react";
import InputGroup from "./InputGroup";
import Div from "../../layout/div/Div";
import Input from "./Input";
import InputDefault from "./InputDefault";
import { I, If } from "../..";

const STRING_VAZIA = "";
const PONTO = ".";
const VIRGULA = ",";

class InputNumberBR extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.value || STRING_VAZIA,
      valueMask: props.value || STRING_VAZIA,
    };
  }

  UNSAFE_componentWillMount() {
    //this.forceUpdate();
    this.setState({
      value: this.props.value || STRING_VAZIA,
      valueMask: this.getValueMaskDefault(this.props.value),
    });
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({
      value: props.value || STRING_VAZIA,
      valueMask: this.getValueMaskDefault(props.value),
    });
  }

  getValueMaskDefault(value) {
    let valueDefault = Utils.replaceAll(
      String(Number(value).toFixed(2)),
      PONTO,
      STRING_VAZIA
    );
    return valueDefault > 0 ? valueDefault : STRING_VAZIA;
  }

  render() {
    let value = Utils.replaceAll(String(this.state.value), PONTO, STRING_VAZIA);
    let valueMask = String(this.state.valueMask);

    const {
      marginBottom,
      label,
      handlerChange,
      placeholder,
      responsive,
      disabled,
      className,
      col,
      isInputGroup,
      labelAlign,
      styleIcon
    } = this.props;
    return (
      <Div marginBottom={marginBottom} responsive={responsive}>
        {isInputGroup ? (
          <InputGroup
            col={col}
            labelAlign={labelAlign}
            label={label}
            className={className}
            placeholder={placeholder}
            valueGroupLeft={"R$"}
            disabled={disabled}
            value={Mask.formatBR(valueMask)}
            handlerChange={(e) => e}
            formcontrol
            handlerKeyUp={(e) => {
              if (e.keyCode === 8) {
                value = value.slice(0, value.length - 1);
              } else if (!isNaN(e.key)) {
                value = value.concat(e.key);
              }
              this.setState({ value, valueMask: value });
              e.target.value = Utils.replaceAll(
                String(Mask.formatBR(value)),
                PONTO,
                STRING_VAZIA
              ).replace(VIRGULA, PONTO);
              handlerChange(e);
            }}
          />
        ) : (
          <InputDefault
            col={col}
            label={label}
            labelAlign={labelAlign}
            className={className}
            placeholder={placeholder}
            valueGroupLeft={"R$"}
            disabled={disabled}
            value={Mask.formatBR(valueMask)}
            handlerChange={(e) => e}
            formcontrol
            handlerKeyUp={(e) => {
              if (e.keyCode === 8) {
                value = value.slice(0, value.length - 1);
              } else if (!isNaN(e.key)) {
                value = value.concat(e.key);
              }
              this.setState({ value, valueMask: value });
              e.target.value = Utils.replaceAll(
                String(Mask.formatBR(value)),
                PONTO,
                STRING_VAZIA
              ).replace(VIRGULA, PONTO);
              handlerChange(e);
            }}
          />
        )}

        <If and value1={!disabled} value2={Utils.isValueValid(value)}>
          <I
            icon={Type.ICON.CLOSEX}
            style={{ position: "absolute", top: 40, right: 20, ...styleIcon}}
            colorText={Color.NODE.DANGER}
            pointer
            handlerClick={() => {
              handlerChange({
                target: {
                  value: "",
                },
              });
            }}
          />
        </If>
      </Div>
    );
  }
}

InputNumberBR.defaultProps = {
  isInputGroup: true,
};

export default InputNumberBR;
