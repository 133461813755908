import React, { PureComponent } from "react";

import { Div } from "../../components";
import Chart from "chart.js";
import { Utils, Color } from "../../utilities";

const DD = "2d";

export default class ChartOneBar extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      dataSets: props.dataSets || [],
      labels: []
    };
  }

  loadChart() {
    const { id } = this.props;

    let config = this.getConfigCharLine();

    Array.from(Object.values(Chart.instances)).forEach(instance => {
      if (instance.canvas.id === id) {
        instance.destroy();
      }
    });

    let chartCanvas = document.getElementById(id);
    if (chartCanvas) {
      chartCanvas = chartCanvas.getContext(DD);
      new Chart(chartCanvas, config);
    }
  }

  componentWillReceiveProps(props) {
    let datas = [
      {
        backgroundColor: [],
        borderColor: [],
        hoverBackgroundColor: [],
        hoverBorderColor: [],
        borderWidth: 1,
        data: []
      },
      {
        backgroundColor: [],
        borderColor: [],
        hoverBackgroundColor: [],
        hoverBorderColor: [],
        borderWidth: 1,
        data: [],
        type: "line"
      }
    ];
    let newDataSets = props.dataSets;

    if (Utils.isValueValid(newDataSets)) {
      Array.from(newDataSets).forEach(dataSet => {
        datas[0].backgroundColor.push(dataSet.backgroundColor);
        datas[0].hoverBackgroundColor.push(dataSet.backgroundColor);
        datas[0].borderColor.push(dataSet.borderColor);
        datas[0].hoverBorderColor.push(dataSet.borderColor);

        if (props.media) {
          datas[1].backgroundColor.push(Color.RGBA.WHITE);
          datas[1].hoverBackgroundColor.push(dataSet.backgroundColor);
          datas[1].borderColor.push(dataSet.borderColor);
          datas[1].hoverBorderColor.push(dataSet.borderColor);
        }

        let data = Array.from(dataSet.data);
        if (Utils.isValueValid(data)) {
          data.forEach(dt => {
            let newData = {
              ...dt,
              x: dt[dataSet.keyValueX],
              y: dt[dataSet.keyValueY]
            };

            datas[0].data.push(newData);

            if (props.media) {
              let newDataMedia = {
                ...dt,
                x: dt[dataSet.keyValueX],
                y: props.media
              };

              datas[1].data.push(newDataMedia);
            }
          });
        }
      });
    }

    this.setState(
      {
        dataSets: datas
      },
      () => {
        this.loadChart();
      }
    );
  }

  getLabels() {
    let labels = [];
    let dataSets = this.state.dataSets;

    if (Utils.isValueValid(dataSets)) {
      Array.from(dataSets).forEach(dataSet => {
        labels = labels.concat(dataSet.data.map(dt => dt.x));
      });
    }

    return [...new Set(labels)];
  }

  getTitleChart(title) {
    return title
      ? {
          text: title,
          display: true
        }
      : null;
  }

  getConfigCharLine() {
    const { title, type } = this.props;
    return {
      type: type,
      data: {
        labels: this.getLabels(),
        datasets: this.state.dataSets
      },
      options: {
        legend: {
          display: false
        },
        tooltips: {
          callbacks: {
            label: (tooltipItems, data) => {
              let label = this.props.handlerTooltip(tooltipItems, data);
              return label;
            },
            title: function(tooltipItems, data) {
              let dataChart =
                data.datasets[tooltipItems[0].datasetIndex].data[
                  tooltipItems[0].index
                ];
              return dataChart.x;
            }
          }
        },
        title: this.getTitleChart(title),
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                stepSize: this.props.stepSize
              }
            }
          ]
        },
        plugins: {
          labels: false
        }
      }
    };
  }

  render() {
    const { styles, id } = this.props;
    return (
      <Div {...styles}>
        <canvas id={id}></canvas>
      </Div>
    );
  }
}

ChartOneBar.defaultProps = {
  type: "bar"
};
