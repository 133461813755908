import React, { Component } from "react";
import { Div, Table, InputSearch, Title  , Notification, ModalNotification} from "../../components";
import { connect } from "react-redux";
import { Color, PathRoute, Http, Type } from "../../utilities";

const ENTER = 13;

class Listar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: props.user,
      dialogRede: false,
      redeEmpresas: [],
      redeEmpresa: {},
      selectedRede: {},
      globalFilter: null,
      ...this.props.location.state,
    };    
    
  }

  UNSAFE_componentWillMount() {
    this.getDataRedeEmpresas();
  }

  async getDataRedeEmpresas() {        
    await Http.get(`/rede/listar/` + this.state.user.idGestorCompras)
      .then(({ status, data }) => {
        if (data) {
          this.setState({ redeEmpresas: data });
        }
      })
      .catch((error) => console.log(error));
  }

  leftToolbarTemplate = () => {
    return (
      <InputSearch                           
      label="Procurar na Grid"
      name="globalFilter"              
      value={this.state.globalFilter}
      handlerChange={(e) => this.setState({ globalFilter: e.target.value })}
      placeholder="Procurar..."
    />
    )
  }

  rightToolbarTemplate = () => {
    return (      
        <span className="p-input-icon-right">           

        </span>      
    )
  }

  exportCSV () {

  }

  editarRedeEmpresa(rede) {
    this.props.history.push({
      pathname: PathRoute.PATH.REDEEMPRESA_EDITAR,
      state: { redeEmpresa: rede, editar: true },
    });
  }


  deletarRedeEmpresa(rede) {    
    ModalNotification.confirm(
      "Deseja desativar esta RedePessoa? " + rede.descricao,
      "Esta rede será desativada no sistema de compras!",
      () => this.delete(rede),
      () => this.cancelar()
    );
  }

  async delete(rede) {    
    rede.idUsuarioInativacao = this.state.user.idUsuario
    await Http.post(`/rede/deletar`, rede)
    .then(({ status, data }) => {
      if (data.status) {
        Notification.success(data.mensagem);
        this.getDataRedeEmpresas();        
      } else {
        Notification.danger(data.mensagem);
      }
    })
    .catch((error) => console.log(error));
  }

  cancelar() {
    
  }

  render() {
    
    return (
      <Div margin="3" shadow bg={Color.NODE.WHITE} borderLeft={Color.NODE.PRIMARY}>

        <Title value="Rede de Empresas" type="h4" paddingLeft="4" paddingRight="4" paddingTop="3"/>
        
        <Div padding="2">
          <Table
            columns={[              
              { value: "Código da Rede", align: "center" },
              { value: "Descrição", align: "left" }
              
            ]}
            fields={[              
              { value: "codigoRede",   type: Type.DADO.STRING },
              { value: "descricao",  align: "left",  type: Type.DADO.STRING }
            ]}
            bordered
            search
            orientation="center"
            pagination
            rota={PathRoute.PATH.REDEEMPRESA_CADASTRAR}
            edit
            delete            
            handlerEdit={(rede) => this.editarRedeEmpresa(rede)}
            handlerDelete={(rede) => this.deletarRedeEmpresa(rede)}            
            data={this.state.redeEmpresas}
            error="Nenhuma rede encontrada"
          ></Table>
        </Div>
      </Div>
    );
  }
}

const mapStateToProps = (store) => ({
  idGestorCompras: store.loginState.user.idGestorCompras,
  idUsuario: store.loginState.user.idUsuario,
});

export default connect(mapStateToProps)(Listar);
