import React from "react";

import BreadParam from "../../components/breadcrumb/BreadParam";
import { I } from "../../components";
import { Color } from "..";

export default class PathRoute {
  static PATH_DEFAULT = "/compras/";

  static PATH = {
    ERP_CADASTROS: PathRoute.getPath("cadastros"),
    EMPRESAS_ADMIN_CADASTRAR: PathRoute.getPath("admin/empresas/cadastrar"),
    EMPRESAS_ADMIN: PathRoute.getPath("admin/empresas"),
    

    PESSOAS: PathRoute.getPath("pessoa"),
    PESSOAS_CADASTRAR: PathRoute.getPath("pessoa/cadastrar"),
    PESSOAS_EDITAR: PathRoute.getPath("pessoa/editar"),

    FORNECEDOR: PathRoute.getPath("fornecedor"),
    FORNECEDOR_CADASTRAR: PathRoute.getPath("fornecedor/cadastrar"),
    FORNECEDOR_EDITAR: PathRoute.getPath("fornecedor/editar"),

    EMPRESA: PathRoute.getPath("empresa"),
    EMPRESA_CADASTRAR: PathRoute.getPath("empresa/cadastrar"),
    EMPRESA_EDITAR: PathRoute.getPath("empresa/editar"),

    FILIAL: PathRoute.getPath("filial"),
    FILIAL_CADASTRAR: PathRoute.getPath("filial/cadastrar"),
    FILIAL_EDITAR: PathRoute.getPath("filial/editar"),

    TRANSPORTADORA: PathRoute.getPath("transportadora"),
    TRANSPORTADORA_CADASTRAR: PathRoute.getPath("transportadora/cadastrar"),
    TRANSPORTADORA_EDITAR: PathRoute.getPath("transportadora/editar"),

    REPRESENTANTE: PathRoute.getPath("representante"),
    REPRESENTANTE_CADASTRAR: PathRoute.getPath("representante/cadastrar"),
    REPRESENTANTE_EDITAR: PathRoute.getPath("representante/editar"),

    GESTOR: PathRoute.getPath("gestor"),
    GESTOR_CADASTRAR: PathRoute.getPath("gestor/cadastrar"),
    GESTOR_EDITAR: PathRoute.getPath("gestor/editar"),
    GESTOR_BLOQUEIAR: PathRoute.getPath("gestor/bloqueiar"),

    USUARIOS: PathRoute.getPath("usuario"),
    USUARIOS_CADASTRAR: PathRoute.getPath("usuario/cadastrar"),
    USUARIOS_EDITAR: PathRoute.getPath("usuario/editar"),

    REDEEMPRESA: PathRoute.getPath("redeempresa"),
    REDEEMPRESA_CADASTRAR: PathRoute.getPath("redeempresa/cadastrar"),
    REDEEMPRESA_EDITAR: PathRoute.getPath("redeempresa/editar"),

    PRODUTO: PathRoute.getPath("produto"),
    PRODUTO_CADASTRAR: PathRoute.getPath("produto/cadastrar"),
    PRODUTO_EDITAR: PathRoute.getPath("produto/editar"),

    MARCA: PathRoute.getPath("marca"),
    MARCA_CADASTRAR: PathRoute.getPath("marca/cadastrar"),
    MARCA_EDITAR: PathRoute.getPath("marca/editar"),

    CLASSIFICACAO: PathRoute.getPath("classificacao"),
    CLASSIFICACAO_CADASTRAR: PathRoute.getPath("classificacao/cadastrar"),
    CLASSIFICACAO_EDITAR: PathRoute.getPath("classificacao/editar"),

    CONCORRENTE: PathRoute.getPath("concorrente"),
    CONCORRENTE_CADASTRAR: PathRoute.getPath("concorrente/cadastrar"),
    CONCORRENTE_EDITAR: PathRoute.getPath("concorrente/editar"),

    DEPARTAMENTO: PathRoute.getPath("departamento"),
    DEPARTAMENTO_CADASTRAR: PathRoute.getPath("departamento/cadastrar"),
    DEPARTAMENTO_EDITAR: PathRoute.getPath("departamento/editar"),

    GRUPO: PathRoute.getPath("grupo"),
    GRUPO_CADASTRAR: PathRoute.getPath("grupo/cadastrar"),
    GRUPO_EDITAR: PathRoute.getPath("grupo/editar"),

    PESQUISA: PathRoute.getPath("pesquisa"),
    PESQUISA_CADASTRAR: PathRoute.getPath("pesquisa/cadastrar"),
    PESQUISA_EDITAR: PathRoute.getPath("pesquisa/editar"),

    COTACAO: PathRoute.getPath("cotacao"),
    COTACAO_CADASTRAR: PathRoute.getPath("cotacao/cadastrar"),
    COTACAO_EDITAR: PathRoute.getPath("cotacao/editar"),
    
    PRECIFICAR_EDITAR: PathRoute.getPath("pesquisa/precificar"),
    APURACAO: PathRoute.getPath("pesquisa/apuracao"),

    COTACAO_APURACAO: PathRoute.getPath("cotacao/apuracao"),
    COTACAO_PRECIFICAR_EDITAR: PathRoute.getPath("cotacao/precificar"),
    
    GRUPOPRODUTO: PathRoute.getPath("grupoprodutos"),
    GRUPOPRODUTO_CADASTRAR: PathRoute.getPath("grupoprodutos/cadastrar"),

    LOGIN: "/login",
    ERP: "/erp",
  };

  static getPath(path) {
    return PathRoute.PATH_DEFAULT.concat(path);
  }

  static getBreads(route) {
    let paths = String(route)
      .split("/")
      .slice(1);
    let breads = [];
    let pathConcat = "";
    paths.forEach((path, index) => {
      pathConcat = pathConcat.concat("/" + path);

      let pathName =
        index === 0 ? (
          "Início"
        ) : (
          path
        );
      breads.push(new BreadParam(pathConcat, pathName, false));
    });
    return breads;
  }
}
