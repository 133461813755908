import React, { Component } from "react";
import { Http, Type } from "../../utilities";
import {
  Row,
  Table,
  InputSearch,
  InputNumberBR,
  Title,
  ModalNotification,
  InputDefault,
  Form,
  Notification,
  SectionForm,
  Div,
  ModalCustom,
} from "../../components";
import { connect } from "react-redux";

class Cadastrar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      nameButton: "Adicionar",
      labelModal: "Adicionar Novo Departamento",
      user: props.user,
      dialogdepartamento: false,
      closeModal: false,
      departamentos: [],
      departamento: {},
      selecteddepartamento: {},
      globalFilter: null,
      ...this.props.location.state,
    };

    this.submit = this.submit.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.getDataDepartamentos();
  }

  async getDataDepartamentos() {
    await Http.get(`/departamento/` + this.state.user.idGestorCompras)
      .then(({ status, data }) => {
        if (data) {
          this.setState({ departamentos: data });
        }
      })
      .catch((error) => console.log(error));
  }

  leftToolbarTemplate = () => {
    return (
      <InputSearch
        label="Procurar na Grid"
        name="globalFilter"
        value={this.state.globalFilter}
        handlerChange={(e) => this.setState({ globalFilter: e.target.value })}
        placeholder="Procurar..."
      />
    );
  };

  rightToolbarTemplate = () => {
    return <span className="p-input-icon-right"></span>;
  };

  exportCSV() {}

  editarDepartamento(departamento) {
    this.setState({
      nameButton: "Alterar",
      idDepartamento: departamento.idDepartamento,
      descricao: departamento.descricao,
      margemPrevista: departamento.margemPrevista,
      closeModal: true,
      labelModal: "Editar Departamento " + departamento.descricao,
    });
  }

  deletarDepartamento(departamento) {
    if (departamento.sequencia === "1") {
      Notification.danger("Essa Seção não Pode Ser Inativado!");  
      return 
    }
    ModalNotification.confirm(
      "Deseja desativar este Departamento? " + departamento.descricao,
      "Este Departamento será desativada no sistema de compras!",
      () => this.delete(departamento),
      () => this.cancelar()
    );
  }

  async delete(departamento) {
    departamento.idUsuarioInativacao = this.state.user.idUsuario;
    await Http.delete(`/departamento`, departamento)
      .then(({ status, data }) => {
        if (data.status) {
          Notification.success(data.mensagem);
          this.getDataDepartamentos();
        } else {
          Notification.danger(data.mensagem);
        }
      })
      .catch((error) => console.log(error));
  }

  cancelar() {
    this.setState({
      nameButton: "Salvar",
      idDepartamento: undefined,
      descricao: "",
      margemPrevista: "",
    });
  }

  async submit(event) {
    event.preventDefault();
    if (event.target.reportValidity()) {
      if (this.state.idDepartamento === undefined) {
        this.cadastrar(this.state);
      } else {
        this.update(this.state);
      }
    }
  }

  cadastrar(departamento) {
    departamento.idGestorCompras = this.state.user.idGestorCompras;
    Http.post("/departamento", departamento).then(({ data }) => {
      if (data.status) {
        this.cancelar();
        Notification.success(data.mensagem);
        this.getDataDepartamentos();
      } else {
        Notification.danger(data.mensagem);
      }
    });
  }

  update(departamento) {
    departamento.idUsuarioAtualizacao = this.state.user.idUsuario;
    Http.put("/departamento", departamento).then(({ data }) => {
      if (data.status) {
        this.cancelar();
        Notification.success(data.mensagem);
        this.getDataDepartamentos();
      } else {
        Notification.danger(data.mensagem);
      }
    });
  }

  setCloseModal = (boolean, nome = "Adicionar Novo Departamento") => {
    this.setState({
      closeModal: boolean,
      labelModal: nome,
    });
  };

  render() {
    return (
      <>
        <ModalCustom
          title={this.state.labelModal}
          showModal={this.state.closeModal}
          setShowModal={this.setCloseModal}
          confirm={(e) => this.submit(e)}
        >
          <Div
            col="12"
            style={{ overflow: "auto", maxHeight: "75vh", padding: 0 }}
          >
            <Form
              id="formCadastroDepartamento"
              handlerSubmit={this.submit}
              padding="0"
            >
              <SectionForm>
                <Row col="12" style={{ padding: 0 }}>
                  <InputDefault
                    required
                    label="Descrição"
                    name="descricao"
                    maxLength="200"
                    responsive="8"
                    value={this.state.descricao}
                    handlerChange={(e) => {
                      this.setState({ descricao: e.target.value });
                    }}
                  />

                  <InputNumberBR
                    required
                    label="Margem Prevista"
                    name="margemPrevista"
                    maxLength="200"
                    responsive="2"
                    value={this.state.margemPrevista}
                    handlerChange={(e) => {
                      this.setState({ margemPrevista: e.target.value });
                    }}
                  />
                </Row>
              </SectionForm>
            </Form>
          </Div>
        </ModalCustom>
        <Div rounded margin="3" padding="4" shadow>
          <Div>
            <Title
              value="Departamentos"
              type="h4"
              paddingLeft="4"
              paddingRight="4"
              paddingTop="3"
            />

            <Div padding="2" style={{ overflow: "auto", maxHeight: "70vh" }}>
              <Table
                columns={[
                  { value: "Código", width: "1", align: "left" },
                  { value: "Descrição", width: "250", align: "left" },
                  { value: "Margem Prevista", width: "1", align: "center" },
                  { value: "Status", align: "center" },
                ]}
                fields={[
                  {
                    value: "sequencia",
                    width: "1",
                    align: "left",
                    type: Type.DADO.STRING,
                  },
                  {
                    value: "descricao",
                    width: "250",
                    align: "left",
                    type: Type.DADO.STRING,
                  },
                  {
                    value: "margemPrevista",
                    width: "1",
                    align: "center",
                    type: Type.DADO.MARGEM,
                  },
                  {
                    value: "ativo",
                    type: Type.DADO.LABEL,
                    valueTrue: "Ativo",
                    valueFalse: "Desativado",
                    align: "center",
                    color: "#FFF",
                  },
                ]}
                bordered
                search
                add={(e) => {
                  e.preventDefault();
                  this.cancelar();
                  this.setCloseModal(true);
                }}
                edit
                delete
                handlerEdit={(departamento) =>
                  this.editarDepartamento(departamento)
                }
                handlerDelete={(departamento) =>
                  this.deletarDepartamento(departamento)
                }
                data={this.state.departamentos}
                error="Nenhuma Departamento encontrada"
              ></Table>
            </Div>
          </Div>
        </Div>
      </>
    );
  }
}

const mapStateToProps = (store) => ({
  user: store.loginState.user,
});

export default connect(mapStateToProps)(Cadastrar);
