import React from "react";
import {Div, Input, Label} from "../..";
import {className, col, display, inline, invisible, margin, padding, responsive} from "../../css";
import TooltipText from "../../tooltip/TooltipText";
import I from "../../i/I";
import {Color, Type, Utils} from "../../../utilities";
import If from "../../if/If";

const getClassNameDefault = (props) => {
  let classe = "form-group ";
  classe = classe.concat(col(props));
  classe = classe.concat(responsive(props));
  classe = classe.concat(display(props));
  classe = classe.concat(invisible(props));
  classe = classe.concat(margin(props));
  classe = classe.concat(padding(props));
  classe = classe.concat(inline(props));
  classe = classe.concat(className(props));

  return classe;
};

const renderInfo = ({ info }) => {
  let tooltipInfo = null;
  if (info) {
    tooltipInfo = (
      <TooltipText direction="top" title={info}>
        <I
          icon={Type.ICON.INFO}
          paddingBottom="2"
          paddingLeft="2"
          colorText={Color.NODE.SECONDARY}
          className="pointer-help"
        />
      </TooltipText>
    );
  }
  return tooltipInfo;
};

const InputFile = (props) => {
  return (
    <Div className={getClassNameDefault(props)}>
      <Div inline="start">
        <If and value1={Utils.isValueValid(props.icon)}>
          <I icon={props.icon}/>
        </If>
        {props.label && <Label value={props.label}></Label>}
        {renderInfo(props)}
      </Div>
      <Input
        id={props.id}
        type="file"
        multiple={props.multiple}
        handlerChange={props.handlerChange}
        info={props.info}
        formcontrol
        accept={props.accept}
        value={props.value}
        name={props.name}
        className={props.className}
      />
    </Div>
  );
};

export default InputFile;
