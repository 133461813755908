import React, { Component } from "react";
import "./dragdrop.css";
import { Div, Label, I } from "..";
import { Type, Color, Utils } from "../../utilities";
import PropTypes from "prop-types";

import { rounded, backGround, className } from "../css";

class DragDrop extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dragSrcEl: null,
      close: true
    };
  }

  setStyle(e, style, add) {
    e.target.classList.toggle(style, add);
  }

  handleDragStart(e, data) {
    this.setStyle(e, "opacity-4", true);

    this.setState({
      itemStart: data,
      dragSrcEl: e.target.innerHTML
    });

    e.dataTransfer.effectAllowed = "move";
    e.dataTransfer.setData("text/html", e.target.innerHTML);
  }

  handleDragOver(e) {
    if (e.preventDefault) {
      e.preventDefault(); // Necessary. Allows us to drop.
    }

    return false;
  }

  handleDragEnter(e) {
    this.setStyle(e, "over", true);
  }

  handleDragLeave(e) {
    this.setStyle(e, "over", false);
  }

  handleDrop(e, data) {
    if (e.stopPropagation) {
      e.stopPropagation();
    }

    this.setStyle(e, "over", false);

    this.state.dragSrcEl = e.target.innerHTML;
    e.target.innerHTML = e.dataTransfer.getData("text/html");

    this.setState({
      itemTarget: data
    });

    return false;
  }

  handleDragEnd(e) {
    let target = e.target;

    if (target.innerHTML !== this.state.dragSrcEl) {
      target.innerHTML = this.state.dragSrcEl;
    }
    this.setStyle(e, "opacity-4", false);

    let { itemStart, itemTarget } = this.state;
    let orderStart = itemStart.prioridade;

    try {
      itemStart.prioridade = itemTarget.prioridade;
      itemTarget.prioridade = orderStart;
      let imagens = Array.from(this.props.data).sort(
        (a, b) => a.prioridade - b.prioridade
      );
      this.props.handlerChangeOrderDragDrop(imagens);
    } catch (error) {
      console.log(error);
    }
  }

  getClassDragDrop(props) {
    let classe = "";
    classe = classe.concat(className(props));
    return classe;
  }

  getClassColumns(props) {
    let classe = "card-body cursor-move ";
    classe = classe.concat(props.spacing ? `m-${props.spacing} ` : "");
    classe = classe.concat(backGround(props));
    classe = classe.concat(
      props.borderColor ? `border-${props.borderColor} ` : ""
    );
    classe = classe.concat(props.opacity ? `opacity-4 ` : "");
    classe = classe.concat(rounded(props));
    classe = classe.concat(props.color ? `${props.color} ` : "");
    classe = classe.concat(props.styleName ? `${props.styleName} ` : "");
    return classe;
  }

  getClassIconClose() {
    return this.state.close ? "dragdropleave" : "dragdropenter";
  }

  getTemplateDragDrop() {
    return (
      <Div inline={this.props.inline} col="12">
        {Array.from(this.props.children).map((child, key) => {
          return (
            <Div
              inline="center"
              key={key}
              col={this.props.colDrag}
              handleMouseEnter={e => this.setState({ close: false })}
              handleMouseLeave={e => this.setState({ close: true })}
            >
              <Div
                inline="between"
                className={this.getClassColumns(this.props)}
                draggable={true}
                onDragLeave={e => this.handleDragLeave(e)}
                onDragStart={e => this.handleDragStart(e, child.props.data)}
                onDragEnter={e => this.handleDragEnter(e)}
                onDragOver={e => this.handleDragOver(e)}
                onDragEnd={e => this.handleDragEnd(e)}
                onDrop={e => this.handleDrop(e, child.props.data)}
              >
                <Div inline="between">
                  <Div className="pointer-event-none ">{child}</Div>
                </Div>

                {child.props.label && (
                  <Div inline="center" bg="white">
                    <Label value={child.props.label}></Label>
                  </Div>
                )}
              </Div>
              {this.props.close && (
                <I
                  icon={Type.ICON.CLOSEX}
                  colorText={Color.NODE.DANGER}
                  pointer
                  className={`align-self-start ${this.getClassIconClose()}`}
                  handlerClick={e => {
                    let data = Array.from(this.props.data);
                    let imagens = data.filter(
                      imagem => imagem.prioridade > child.props.data.prioridade
                    );

                    if (Utils.isValueValid(imagens)) {
                      Array.from(imagens).forEach(imagem => {
                        imagem.prioridade--;
                      });
                    }

                    const indexChild = data.indexOf(child.props.data);
                    data.splice(indexChild, 1);

                    this.props.handlerRemoveDragDrop(data);
                  }}
                ></I>
              )}
            </Div>
          );
        })}
      </Div>
    );
  }

  render() {
    return (
      <Div
        className={this.getClassDragDrop(this.props)}
        col={this.props.col}
        inline={this.props.inline}
      >
        {this.getTemplateDragDrop()}
      </Div>
    );
  }
}

DragDrop.propTypes = {
  handlerChangeOrderDragDrop: PropTypes.func.isRequired,
  handlerRemoveDragDrop: PropTypes.func.isRequired,
  children: PropTypes.any.isRequired
};

export default DragDrop;
