import React from "react";
import { Button, Span, I } from "..";
import If from "../if/If";
import { Utils } from "../../utilities";

const ButtonIconOutLine = ({
  btnOutLine,
  handlerClick,
  icon,
  value,
  btn,
  float,
  marginTop,
  marginRight,
  marginBottom,
  margin,
  className,
  toggle,
  target,
  style,
  disabled,
  responsive,
  type,
  id
}) => {
  return (
    <Button
      className={className}
      type={type}
      btnOutLine={btnOutLine}
      btn={btn}
      handlerClick={handlerClick}
      responsive={responsive}
      margin={margin}
      marginTop={marginTop}
      marginRight={marginRight}
      marginBottom={marginBottom}
      toggle={toggle}
      target={target}
      disabled={disabled}
      marginLeft="2"
      float={float}
      style={style}
      id={id}
    >
      <If and value1={Utils.isValueValid(icon)}>
        <I icon={icon}>
          <Span padding="1" value={value}></Span>
        </I>
      </If>
      <If and value1={!Utils.isValueValid(icon)}>
        <Span padding="1" value={value}></Span>
      </If>
    </Button>
  );
};

export default ButtonIconOutLine;
