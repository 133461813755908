import React, { Component } from "react";
import {
  ButtonIconOutLine,
  Checkbox,
  Div,
  I,
  Label,
  ModalCustom,
  ModalNotification,
  Notification,
  Row,
  SelectValue,
  Table,
  Title,
} from "../../components";
import { Color, DateUtils, Http, Type, Utils } from "../../utilities";
import TemplateFilterProdutos from "./template/TemplateFilterProdutos";
import ProdutosInseridosEditar from "./ProdutosInseridosEditar";

class InserirEditarProdutos extends Component {
  constructor(props) {
    super(props);

    this.state = {
      produtos: [],
      produtosSlice: [],
      filtros: {
        departamentos: [new SelectValue(0, "SELECIONE", true)],
        grupos: [new SelectValue(0, "SELECIONE", true)],
      },
      produtosSelecionados: [],
      produtosInseridosSelecionados: [],
      produtosInseridos: [],
      produtosTotalInserir: [],
      departamentos: [],
      departamentosSelecionados: [],
      grupos: [],
      idDepartamento: "",
      idGrupo: "",
      closeModal: false,
      idGestorCompras: "",
      tipoPesquisas: [],
      tipoPesquisa: "DESCRICAO",
      selecionarTodos: false,
      selecionarTodosInseridos: false,
      user: {},
      paginacao: 0,
      stopPag: true,
      buscarPag: true,
      loading: false,
      cancelarBusca: false,
      isSearch: false
    };

    this.setEditarProdutosInseridos = this.setEditarProdutosInseridos.bind(
      this
    );
  }

  UNSAFE_componentWillMount() {
    //this.getDataProdutosInseridos();
    //this.getProdutos(0);
    this.getDataDepartamentos();
    this.getTipoPesquisaSelect();
    this.setState({
      idGestorCompras: this.props.idGestorCompras,
    });
  }

  getTipoPesquisaSelect() {
    var tp = [
      new SelectValue("DESCRICAO", "DESCRIÇÃO", true),
      new SelectValue("EAN", "CÓDIGO DE BARRAS", false),
      new SelectValue("PLU", "PLU", false),
    ];

    this.setState({ tipoPesquisas: tp });
  }

  setEditarProdutosInseridos(obj) {
    this.setState(obj);
  }

  getDataDepartamentos() {
    Http.get(`/departamento/` + this.props.idGestorCompras).then(({ data }) => {
      if (data) {
        let depComDados = [];
        depComDados.push(new SelectValue(0, "SELECIONE", true));

        Array.from(data).map((dep) => {
          depComDados.push(
            new SelectValue(
              dep.idDepartamento,
              dep.sequencia + " - " + dep.descricao,
              false
            )
          );
        });
        this.setState({
          filtros: {
            ...this.state.filtros,
            departamentos: depComDados,
          },
          isSearch: false
        });
      }
    });
  }

  getDataGrupos(idDepartamento) {
    Http.get(`/grupo/listargrupopordepartamento/` + idDepartamento).then(
      ({ data }) => {
        if (data) {
          let grupoComDados = [];
          grupoComDados.push(new SelectValue(0, "SELECIONE", true));

          Array.from(data).map((dep) => {
            grupoComDados.push(
              new SelectValue(
                dep.idGrupo,
                dep.sequenciaDepartamentoGrupo + " - " + dep.descricao,
                false
              )
            );
          });
          this.setState({
            filtros: {
              ...this.state.filtros,
              grupos: grupoComDados,
            },
            isSearch: false
          });
        }
      }
    );
  }
  getDataProdutosInseridos() {
    let idPesquisa = this.props.idPesquisa;
    Http.get("/pesquisa/" + idPesquisa + "/produto/")
      .then(({ status, data }) => {
        if (data) {
          this.setState({ produtosInseridos: data });
        }
      })
      .catch((error) => console.log(error));
  }

  async getProdutos(paginacao) {
    await Http.get(
      `/produto/listar/${this.props.idGestorCompras}/paginacao/${paginacao}`
    )
      .then(({ status, data }) => {
        if (data) {
          this.setState({ produtos: data });
        }
      })
      .catch((error) => console.log(error));
  }

  async getProdutosFiltros(idDepartamento, idGrupo, paginacao) {
    if (paginacao === 0) {
      this.setState({ produtos: [], produtosSlice: [] });
    }
    var idgestor = this.state.idGestorCompras;

    //SEM PAGINACAO
    // idGrupo
    //     ? `/produto/filtro/${idDepartamento}/departamento/${idGrupo}/grupo`
    //     : `/produto/filtro/${idDepartamento}/departamento`

    await Http.get(
      idGrupo
        ? `/produto/filtro/${idDepartamento}/departamento/${idGrupo}/grupo/${idgestor}/paginacao/${paginacao *
            100}`
        : `/produto/filtro/${idDepartamento}/departamento/${idgestor}/paginacao/${paginacao *
            100}`
    )
      .then(({ status, data }) => {
        if (data) {
          if (paginacao === 0) {
            this.setState({
              produtos: data,
              produtosSlice: data.length > 100 ? data.slice(0, 100) : data,
              paginacao: 0,
              stopPag: data.length < 100,
              buscarPag: data.length < 100,
            });
          } else {
            this.setState({
              produtos: [...this.state.produtos, ...data],
              produtosSlice: [...this.state.produtosSlice, ...data],
              paginacao: paginacao,
              stopPag: data.length < 100,
              buscarPag: data.length < 100,
            });
          }
        }
      })
      .catch((error) => console.log(error));
  }

  async consultarProdutos() {
    let filtros = { ...this.state.filtros };

    let idDepartamento = this.state.idDepartamento;
    let departamentos = this.state.departamentos;
    let idGrupo = this.state.idGrupo;
    var prodDepart = idDepartamento;
    var grupDepart = idGrupo;

    departamentos.map((depto) => {
      depto.idDepartamento = filtros.idDepartamento;
    });

    let url = Utils.isValueValid(departamentos)
      ? "/produto/filtro/" +
        prodDepart +
        "/departamento/" +
        grupDepart +
        "/grupo/"
      : "/produto/listar/" + this.props.idGestorCompras;

    await Http.get(
      url,
      Utils.isValueValid(departamentos) ? departamentos : filtros
    )
      .then(({ status, data }) => {
        if (status) {
          Array.from(data).map((produto) => {
            produto.isProdutoDisponivel = produto.situacao === "LIBERADO-VENDA";
          });
        }
        this.setState({ produtos: status ? data : [] });
      })
      .catch((error) => console.log(error));
  }

  inserirProdutosPesquisa() {
    if (!this.props.idPesquisa) {
      Notification.danger("Confirme Dados da pesquisa antes!");
      return;
    }

    let idPesquisa = this.props.idPesquisa;
    let newProdutos = [];

    if (this.state.selecionarTodos) {
      this.state.produtosTotalInserir.forEach((item, index) => {
        let isProdutoInserido = false;
        let obj = {
          idPesquisa,
          idGestorCompras: item.idGestorCompras,
          idProduto: item.idProduto,
          dataCadastro: DateUtils.getDataHojeBR(),
        };
        this.state.produtosInseridos.forEach((itemInserido) => {
          if (item.idProduto === itemInserido.idProduto) {
            isProdutoInserido = true;
          }
        });
        if (!isProdutoInserido) {
          newProdutos.push(obj);
        }
      });
    } else {
      this.state.produtosSelecionados.forEach((item, index) => {
        let isProdutoInserido = false;
        let obj = {
          idPesquisa,
          idGestorCompras: item.idGestorCompras,
          idProduto: item.idProduto,
          dataCadastro: DateUtils.getDataHojeBR(),
        };
        this.state.produtosInseridos.forEach((itemInserido) => {
          if (item.idProduto === itemInserido.idProduto) {
            isProdutoInserido = true;
          }
        });
        if (!isProdutoInserido) {
          newProdutos.push(obj);
        }
      });
    }

    Http.post("/pesquisa/produto/", newProdutos)
      .then(({ status, mensagem }) => {
        if (status) {
          Notification.success();
          this.getDataProdutosInseridos(mensagem);
          this.setState({
            produtosSelecionados: [],
            produtos: [],
            produtosSlice: [],
            selecionarTodos: false,
            loading: false,
            cancelarBusca: true,
            produtosTotalInserir: [],
          });
        } else {
          Notification.danger("Falha ao inserir Produto");
        }
      })
      .catch((error) => console.log(error));

    this.setState({
      selecionarTodos: false,
    });
  }

  setCloseModal = (boolean) => {
    this.setState({
      closeModal: boolean,
      produtos: [],
      produtosSlice: [],
      produtosSelecionados: [],
      produtosTotalInserir: [],
      paginacao: 0,
      selecionarTodos: false,
      cancelarBusca: !boolean,
    });
  };

  listarPedidos() {
    let { idDepartamento, idGrupo } = this.state;

    if (idDepartamento !== "" && idGrupo !== "") {
      this.getProdutosFiltros(idDepartamento, idGrupo, 0);
    }
  }

  mudarFiltros(value, type) {
    if (type === "departamento") {
      if (value != 0) {
        this.getDataGrupos(value);
      }
      this.setState(
        {
          idDepartamento: value,
          idGrupo: "",
          produtos: [],
          produtosSlice: [],
          produtosSelecionados: [],
          produtosTotalInserir: [],
          selecionarTodos: false,
          isSearch: false
        },
        () => (value != 0 ? this.getProdutosFiltros(value, null, 0) : {})
      );
    }

    if (type === "grupo") {
      this.setState(
        {
          idGrupo: value,
          paginacao: 0,
          produtos: [],
          produtosSlice: [],
          produtosSelecionados: [],
          produtosTotalInserir: [],
          selecionarTodos: false,
          isSearch: false
        },
        () => this.listarPedidos()
      );
    }

    if (type === "tpPesquisa") {
      this.setState({ tipoPesquisa: value, isSearch: false });
    }
  }

  async deletarProduto(produtoPesquisa) {
    produtoPesquisa.idUsuarioInativacao = this.props.idUsuario;
    await Http.delete(`/pesquisa/produto`, produtoPesquisa)
      .then(({ status, data }) => {
        if (data.status) {
          Notification.success(data.mensagem);
          this.getDataProdutosInseridos();
        } else {
          Notification.danger(data.mensagem);
        }
      })
      .catch((error) => console.log(error));
  }

  deletarProdutoPesquisa(produtoPesquisa) {
    let deleteProduto = [];

    if (!!produtoPesquisa.length) {
      deleteProduto = [...produtoPesquisa];
    } else {
      deleteProduto = [produtoPesquisa];
    }

    ModalNotification.confirm(
      "Deseja remover este Produto? ",
      "Este Produto será removido da pesquisa!",
      () => this.deletarProduto(deleteProduto)
    );
  }

  deletarTodosProdutosPesquisa(produtosSelecionados) {
    ModalNotification.confirm(
      "Deseja remover todos os Produtos selecionados? ",
      "Estes Produtos serão removidos da pesquisa!",
      () => {
        this.deletarProduto(produtosSelecionados);
      }
    );

    this.setState({
      selecionarTodos: false,
      produtosInseridosSelecionados: [],
    });
  }

  handlerChangeProduto(e) {
    if (e.target.value === "") {
      this.getProdutos(0);
    }
  }

  buscarTodosProdutosInserir = async () => {
    let {
      idDepartamento,
      idGrupo,
      paginacao,
      produtosTotalInserir,
      produtosSelecionados,
    } = this.state;

    var idgestor = this.state.idGestorCompras;

    if (produtosSelecionados < 100) {
      this.setState({
        loading: false,
        selecionarTodos: false,
        cancelarBusca: false,
        produtosTotalInserir: [...produtosSelecionados],
      });
      return;
    }

    const res = await Http.get(
      idGrupo
        ? `/produto/filtro/${idDepartamento}/departamento/${idGrupo}/grupo/${idgestor}/paginacao/${paginacao *
            100}`
        : `/produto/filtro/${idDepartamento}/departamento/${idgestor}/paginacao/${paginacao *
            100}`
    );

    this.setState({
      produtosTotalInserir: [...produtosTotalInserir, ...res.data],
      paginacao: paginacao + 1,
      loading: true,
    });

    if (this.state.cancelarBusca) {
      this.setState({
        loading: false,
        selecionarTodos: false,
        cancelarBusca: false,
        produtosTotalInserir: [],
        produtosSelecionados: [],
      });
      return;
    }

    if (res.data.length === 100) {
      this.buscarTodosProdutosInserir();
    }

    if (res.data.length < 100) {
      this.setState({
        loading: false,
        paginacao: 0,
      });
    }
  };

  setBuscarPag(value) {
    this.setState({
      buscarPag: value,
      stopPag: value,
    });
  }

  renderFiltros() {
    let { departamentos, grupos } = this.state.filtros;
    let { tipoPesquisas, selecionarTodos, produtosTotalInserir } = this.state;
    return (
      <TemplateFilterProdutos
        departamentos={departamentos}
        grupos={grupos}
        tipoPesquisas={tipoPesquisas}
        selecionarTodos={selecionarTodos}
        handler={(value, type) => this.mudarFiltros(value, type)}
        handlerSearch={(e) => this.getProdutosSearch(e)}
        handlerChange={(e) => this.handlerChangeProduto(e)}
        produtosSelecionados={this.state.produtosSelecionados}
        produtos={produtosTotalInserir}
        buscarTodosProdutosInserir={this.buscarTodosProdutosInserir}
        loading={this.state.loading}
      />
    );
  }

  getProdutosSearch(search) {
    this.setState({ produtos: [], produtosSlice: [], isSearch: true });
    let busca = {
      descricao: search,
      idPesquisa: this.props.idPesquisa,
      tipoPesquisa: this.state.tipoPesquisa,
    };

    if (search.trim() !== "") {
      Http.post(`/pesquisa/produto/listar/resumido`, busca)
        .then(({ data }) => {
          if (data) {
            this.setState({
              produtos: data,
              produtosSlice: data.length > 100 ? data.slice(0, 100) : data,
            });
          }
        })
        .catch((error) => console.log(error));
    }
  }

  handleScroll = (e) => {
    const { produtosSlice, produtos } = this.state;
    let element = e.target;

    if (
      Math.round(element.scrollHeight - element.scrollTop) ===
      element.clientHeight
    ) {
      let { idDepartamento, idGrupo, paginacao, isSearch } = this.state;

      if(isSearch) {
        let newProdutos = [];
        let start = produtosSlice.length;
        let end =
          produtos.length > 100
            ? produtosSlice.length + 100
            : produtosSlice.length - produtos.length;

        if (produtos.length >= produtosSlice.length) {
          newProdutos = [...produtosSlice, ...produtos.slice(start, end)];

          this.setState({
            produtosSlice: newProdutos,
          });
        }

        return
      }

      this.getProdutosFiltros(idDepartamento, idGrupo, paginacao + 1);

      
    }
  };

  render() {
    const {
      produtos,
      produtosSelecionados,
      selecionarTodos,
      produtosInseridosSelecionados,
      produtosSlice,
    } = this.state;

    return (
      <>
        <ModalCustom
          title="Produtos Disponíveis"
          showModal={this.state.closeModal}
          setShowModal={this.setCloseModal}
          confirm={() => this.inserirProdutosPesquisa()}
        >
          <Div
            col="12"
            style={{ overflow: "scroll", maxHeight: "75vh" }}
            handlerScroll={(e) => this.handleScroll(e)}
          >
            <Div>
              <Table
                columns={[
                  { value: "PLU", align: "right" },
                  { value: "Descricao", align: "left" },
                  { value: "Código de Barras", align: "left" },
                  { value: "Departamento", align: "left" },
                  { value: "Grupo", align: "left" },
                  { value: "Marca", align: "left" },
                  { value: "Embalagem", align: "left" },
                  { value: "Fornecedor Principal", align: "left" },
                ]}
                fields={[
                  {
                    value: "codigoProduto",
                    align: "right",
                    type: Type.DADO.STRING,
                  },
                  {
                    value: "descricao",
                    align: "left",
                    type: Type.DADO.STRING,
                  },
                  { value: "ean", align: "left", type: Type.DADO.STRING },
                  {
                    value: "nomeDepartamento",
                    align: "left",
                    type: Type.DADO.STRING,
                  },
                  {
                    value: "nomeGrupo",
                    align: "left",
                    type: Type.DADO.STRING,
                  },
                  {
                    value: "nomeMarca",
                    align: "left",
                    type: Type.DADO.STRING,
                  },
                  {
                    value: "embalagem",
                    align: "left",
                    type: Type.DADO.STRING,
                  },
                  {
                    value: "nomeFornecedorPrincipal",
                    align: "left",
                    type: Type.DADO.STRING,
                  },
                ]}
                bordered
                orientation="center"
                //pagination
                filter={this.renderFiltros()}
                filterShow={true}
                data={produtosSlice}
                error="Nenhum produto encontrado"
                checkbox={{ nameChecked: "selected", valueChecked: true }}
                handlerCheckbox={(produto) => {
                  produto.selected = !produto.selected;

                  produto.idUsuarioInativacao = this.props.idUsuario;
                  produto.idUsuarioAtualizacao = this.props.idUsuario;

                  let newProdutosSelecionados = produtosSelecionados;

                  if (produto.selected) {
                    newProdutosSelecionados.push(produto);
                  } else {
                    this.setState({
                      selecionarTodos: false,
                    });
                    newProdutosSelecionados = produtosSelecionados.filter(
                      (item) => {
                        return item.codigoProduto === produto.codigoProduto
                          ? false
                          : true;
                      }
                    );
                  }

                  this.setState({
                    produtosSelecionados: newProdutosSelecionados,
                    produtosTotalInserir: [],
                    paginacao: 0,
                  });
                }}
                TableColumnCheckBox={
                  <Checkbox
                    width="50px"
                    label="Selecionar Todos os Produtos"
                  ></Checkbox>
                }
                labelCheckBox={
                  <Checkbox
                    width="50px"
                    label="Selecionar Todos"
                    checked={selecionarTodos}
                    handlerChange={({ checked }) => {
                      let produtosList = produtos;

                      if (Utils.isArrayNotEmpty(produtosList)) {
                        produtosList.forEach((c) => {
                          c.selected = checked;
                          this.setState({
                            produtosSelecionados: checked ? produtosList : [],
                            selecionarTodos: checked,
                          });
                        });
                      } else {
                        Notification.info(
                          "Nenhum Produto selecionado, verifique se os produtos estão selecionado para serem inseridos na pesquisa."
                        );
                      }
                    }}
                  ></Checkbox>
                }
              ></Table>
            </Div>
          </Div>
        </ModalCustom>
        <Div>
          <Div col="12" padding="2">
            <Row col="12" padding="3">
              <Title
                value="Produtos inseridos na pesquisa"
                type="h4"
                paddingLeft="4"
                paddingRight="4"
                paddingTop="3"
                col="10"
              />
              <Div
                col="12"
                padding="0 1rem"
                style={{
                  textAlign: "right",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "flex-end ",
                  marginBottom: "-20px",
                }}
              >
                {produtosInseridosSelecionados.length >= 1 && (
                  <>
                    <Label
                      value={
                        produtosInseridosSelecionados.length +
                        " Produtos Selecionados"
                      }
                      style={{ marginLeft: "10px", fontWeight: "bold" }}
                      className="text-bege"
                    />

                    <Div margin="1">
                      <ButtonIconOutLine
                        icon={Type.ICON.DELETE}
                        value="Excluir"
                        btn={Color.NODE.DANGER}
                        handlerClick={() =>
                          this.deletarTodosProdutosPesquisa(
                            this.state.produtosInseridosSelecionados
                          )
                        }
                      />
                    </Div>
                  </>
                )}
                <ButtonIconOutLine
                  value="Adicionar Produtos"
                  icon={Type.ICON.PLUS}
                  btn={Color.NODE.PRIMARY}
                  handlerClick={() => this.setCloseModal(true)}
                />
              </Div>
            </Row>
            {
              <ProdutosInseridosEditar
                idPesquisa={this.props.idPesquisa}
                setState={this.setEditarProdutosInseridos}
                produtosInseridosSelecionados={
                  this.state.produtosInseridosSelecionados
                }
                deletarProdutoPesquisa={this.deletarProdutoPesquisa}
                idUsuario={this.props.idUsuario}
              />
            }
            {/* <Div style={{ overflow: "scroll", maxHeight: "460px", marginTop: "20px" }}>
              <Table
                columns={[
                  { value: "PLU", align: "center" },
                  { value: "Descricao", align: "left" },
                  { value: "Código de Barras", align: "center" },
                ]}
                fields={[
                  {
                    value: "codigoProduto",
                    align: "center",
                    type: Type.DADO.STRING,
                    width: "100px",
                  },
                  {
                    value: "descricao",
                    align: "left",
                    width: "50%",
                    type: Type.DADO.STRING,
                  },
                  {
                    value: "ean",
                    align: "left",
                    type: Type.DADO.STRING,
                    width: "20%",
                  },
                ]}
                bordered
                orientation="center"
                pagination
                search
                data={produtosInseridos}
                error="Nenhum produto encontrado"
                checkbox={{ nameChecked: "selected", valueChecked: true }}
                handlerCheckbox={(produto) => {
                  produto.selected = !produto.selected;
                  produto.idUsuarioInativacao = this.props.idUsuario;
                  produto.idUsuarioAtualizacao = this.props.idUsuario;

                  if (!produto.selected) {
                    let index = produtosInseridosSelecionados.indexOf(produto);
                    produtosInseridosSelecionados.splice(index, 1);
                  } else {
                    produtosInseridosSelecionados.push(produto);
                  }

                  // this.setState({
                  //   produtosInseridosSelecionados: [...produtosInseridosSelecionados],
                  //   selecionarTodos: false,
                  // });
                }}
                TableColumnCheckBox={
                  <Checkbox
                    width="40px"
                    label="Selecionar Todos os Produtos"
                  ></Checkbox>
                }
                labelCheckBox={
                  <Checkbox
                    width="40px"
                    //  label="Selecionar Todos"
                    checked={selecionarTodosInseridos}
                    handlerChange={({ checked }) => {
                      let produtosList = [...produtosInseridos];

                      if (Utils.isArrayNotEmpty(produtosList)) {
                        produtosList.forEach((c) => {
                          c.selected = checked;
                          this.setState({
                            produtosInseridosSelecionados: checked
                              ? [...produtosList]
                              : [],
                            selecionarTodosInseridos: checked,
                          });
                        });
                      } else {
                        Notification.info(
                          "Nenhum Produto selecionado, verifique se os produtos estão selecionado para serem inseridos na pesquisa."
                        );
                      }
                    }}
                  ></Checkbox>
                }
                stylesCheckbox={{ width: "150px" }}
                delete
                //filter={this.renderFiltros()}
                handlerDelete={(rede) => this.deletarProdutoPesquisa(rede)}
              ></Table>
            </Div> */}
          </Div>
        </Div>
      </>
    );
  }
}

export default InserirEditarProdutos;
