import React, { Component } from "react";
import { Color, Http, Type } from "../../utilities";

import {
  Row, Table, InputSearch, Title  ,
  InputDefault,  
  Form,
  Notification, ModalNotification,
  SectionForm,
  SectionButtonForm,
  Button,
  Div,  
  ButtonIconOutLine
} from "../../components";
import { connect } from "react-redux";


class Cadastrar extends Component {

  constructor(props) {
    super(props);

    this.state = {
      nameButton: "Salvar",
      user: props.user,  
      dialogMarca: false,
      marcas: [],
      marca: {},
      selectedMarca: {},
      globalFilter: null,         
      ...this.props.location.state 
    };

    this.submit = this.submit.bind(this);  

  }

  UNSAFE_componentWillMount() {    
    this.getDataMarcas();        
  }

  async getDataMarcas() { 
    this.setState({marcas: []});      
    await Http.get(`/marca/listar/` + this.state.user.idGestorCompras)
      .then(({ status, data }) => {        
        if (data) {
          this.setState({ marcas: data });
        }
      })
      .catch((error) => console.log(error));
  }

  leftToolbarTemplate = () => {
    return (
      <InputSearch                           
      label="Procurar na Grid"
      name="globalFilter"              
      value={this.state.globalFilter}
      handlerChange={(e) => this.setState({ globalFilter: e.target.value })}
      placeholder="Procurar..."
    />
    )
  }

  rightToolbarTemplate = () => {
    return (      
        <span className="p-input-icon-right">           

        </span>      
    )
  }

  exportCSV () {

  }

  editarMarca(marca) {
    this.setState({
            nameButton: "Alterar",
            idMarca: marca.idMarca,
            descricao: marca.descricao
          });
  }


  deletarMarca(marca) {    
    ModalNotification.confirm(
      "Deseja desativar esta marca? " + marca.descricao,
      "Esta marca será desativada no sistema de compras!",
      () => this.delete(marca),
      () => this.cancelar()
    );
  }

  async delete(marca) {    
    marca.idUsuarioInativacao = this.state.user.idUsuario
    await Http.post(`/marca/deletar`, marca)
    .then(({ status, data }) => {
      if (data.status) {
        Notification.success(data.mensagem);
        this.getDataMarcas();        
      } else {
        Notification.danger(data.mensagem);
      }
    })
    .catch((error) => console.log(error));
  }

 
  async submit(event) {
    event.preventDefault();
    if (event.target.reportValidity()) {
      if (this.state.idMarca === undefined) {
        this.cadastrar(this.state);
      } else {
        this.update(this.state);
      }
    }
  }

  cadastrar(marca) {           
    marca.idGestorCompras = this.state.user.idGestorCompras         
      Http.post("/marca", marca).then(({ data }) => {
        if (data.status) {
          this.setState({idMarca: undefined, descricao: ""});
          Notification.success(data.mensagem);
          this.getDataMarcas();
        } else {
          Notification.danger(data.mensagem);
        }
      });    
  }

 
  update(marca) {        
    marca.idUsuarioAtualizacao = this.state.user.idUsuario;
      Http.put("/marca", marca).then(({ data }) => {      
        if (data.status) {      
          this.setState({idMarca: undefined, descricao: "", nameButton: "Salvar"});    
          Notification.success(data.mensagem);
          this.getDataMarcas();
        } else {
          Notification.danger(data.mensagem);
        }      
      });    
  }

  cancelar() {
    this.setState({
      nameButton: "Salvar",      
      idMarca: undefined,
      descricao: ''
    });
  }
  
   render() {
    
    return (<Div>
          <Form id="formCadastroMarca" handlerSubmit={this.submit}>
          <SectionForm borderLeft="primary">
            <Row col="12">
            <InputDefault required
                label="Descrição"
                name="descricao"
                maxLength="200"
                responsive="6"
                value={this.state.descricao}
                handlerChange={(e) => {
                  this.setState({descricao: e.target.value });                  
                }}
              />
            
            {/* <SectionButtonForm>
                <Button
                  btn={Color.NODE.PRIMARY}
                  value={this.state.nameButton}
                  col="1"
                  margin="1"
                />
                <Button
                  btn={Color.NODE.DANGER}
                  value="Cancelar"                  
                  handlerClick={(_e) => this.cancelar()}
                  col="1"
                  margin="1"
                />
              </SectionButtonForm> */}


              <SectionButtonForm
                col="6"
                style={{
                  display: "flex",
                  alignItems: "center",
                  paddingTop: "10px",
                }}
              >
                <Row col="12" style={{}}>
                  <Div margin="1">
                    <ButtonIconOutLine
                     // icon={Type.ICON.ARROW_ANGLE_LEFT}
                      value="Limpar"
                      btn={Color.NODE.SECONDARY}
                      handlerClick={(_e) => this.cancelar()}
                    />
                  </Div>

                  <Button btn={Color.NODE.PRIMARY} value="Adicionar" margin="1" />
                </Row>
              </SectionButtonForm>

              </Row>
          </SectionForm> 
    </Form>

    <Div>

        <Title value="Marcas" type="h4" paddingLeft="4" paddingRight="4" paddingTop="3"/>

        <Div padding="2">
          <Table
            columns={[              
              { value: "Descrição", align: "left" }
            ]}
            fields={[
              { value: "descricao", align: "left",    type: Type.DADO.STRING }
            ]}
            bordered
            search
            orientation="center"
            pagination            
            edit
            delete            
            handlerEdit={(marca) => this.editarMarca(marca)}
            handlerDelete={(marca) => this.deletarMarca(marca)}            
            data={this.state.marcas}
            error="Nenhuma marca encontrada"
          ></Table>
        </Div>
        </Div>
    </Div>   
    
    )}   
}

const mapStateToProps = (store) => ({
  user: store.loginState.user,
});

export default connect(mapStateToProps)(Cadastrar);
