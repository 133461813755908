import React, { Component } from "react";
import { Div, Table, InputSearch, Title } from "../../components";
import { connect } from "react-redux";
import { Color, PathRoute, Http, Type } from "../../utilities";


class Precificacao extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: props.user,
      dialogRede: false,
      pesquisas: [],
      pesquisa: {},
      selectedPesquisas: {},
      globalFilter: null,
      ...this.props.location.state,
    };    
    
  }

  UNSAFE_componentWillMount() {
    this.getDataPesquisa();
  }

  async getDataPesquisa() {        
    await Http.get(`/pesquisa/gestor/` + this.state.user.idGestorCompras)   
      .then(({ data }) => {
        if (data) { 
          this.setState({ pesquisas: data });
        }
      })
      .catch((error) => console.log(error));
  }

  leftToolbarTemplate = () => {
    return (
      <InputSearch                           
      label="Procurar na Grid"
      name="globalFilter"              
      value={this.state.globalFilter}
      handlerChange={(e) => this.setState({ globalFilter: e.target.value })}
      placeholder="Procurar..."
    />
    )
  }

  editarPesquisa(pesquisa) {
    const { user } = this.state;
    this.props.history.push({
      pathname: PathRoute.PATH.PRECIFICAR_EDITAR,
      state: { pesquisa: pesquisa, editar: true, user: user },
    });
  }


  render() {
    
    return (
      <Div margin="3" shadow bg={Color.NODE.WHITE} borderLeft={Color.NODE.PRIMARY}>

        <Title value="Precificação de Pesquisa" type="h4" paddingLeft="4" paddingRight="4" paddingTop="3"/>
        
        <Div padding="2">     
          <Table
            columns={[              
              { value: "Código", align: "right" },
              { value: "Descrição", align: "left" },   
              { value: "Tipo", align: "left" },
              { value: "Precificador", align: "left" },             
              { value: "Status", align: "left" },
              { value: "Data do Cadastro", align: "right" },
              { value: "Data da Validade", align: "right" },
              { value: "Última Atualização", align: "right" }
              
            ]}
            fields={[
              { value: "idPesquisa",  align: "right",   type: Type.DADO.STRING},
              { value: "descricao",   align: "left",  type: Type.DADO.STRING },                 
              { value: "tipo",  align: "left",  type: Type.DADO.STRING }, 
              { value: "precificador",  align: "left",  type: Type.DADO.STRING },             
              { value: "status",  align: "left",  type: Type.DADO.STRING },
              { value: "datacadastro",  align: "right",  type: Type.DADO.DATE },
              { value: "datavalidade",  align: "right",  type: Type.DADO.DATE },
              { value: "dataatualizacao",  align: "right",  type: Type.DADO.DATE }
                                       

            ]}
            search
            orientation="center"
            pagination            
            edit                      
            handlerEdit={(rede) => this.editarPesquisa(rede)}           
            data={this.state.pesquisas}
            error="Nenhuma pesquisa encontrada"
          ></Table>
        </Div>
      </Div>
    );
  }
}

const mapStateToProps = (store) => ({
  idGestorCompras: store.loginState.user.idGestorCompras,
  idUsuario: store.loginState.user.idUsuario,
});

export default connect(mapStateToProps)(Precificacao);
