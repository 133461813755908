import React, { Component } from "react";
import {
  ButtonIconOutLine,
  Checkbox,
  Div,
  ModalCustom,
  ModalNotification,
  Notification,
  Row,
  SelectValue,
  Table,
  Title,
} from "../../components";
import { Color, DateUtils, Http, Type, Utils } from "../../utilities";

class InserirEditarFornecedores extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fornecedores: [],
      fornecedoresSelecionados: [],
      fornecedoresInseridos: [],
      fornecedorSlice: [],
      closeModal: false,
      idGestorCompras: "",
      tipoPagamento: "SELECIONE",
      planoPagamento: "SELECIONE",
      selecionarTodos: false,
      user: {},
    };
  }

  UNSAFE_componentWillMount() {
    this.getDataFornecedoresInseridos();
    this.setState({
      idGestorCompras: this.props.idGestorCompras,
    });
  }

  getDataFornecedores(fornecedoresInseridos) {
    if (this.props.idGestorCompras) {
      Http.get(
        `/pessoa/cotacao/${this.props.idCotacao}/listarFornecedor/${this.props.idGestorCompras}`
      )
        .then(({ status, data }) => {
          let newFornecedores = []

          data.forEach(fornecedor => {
            let valido = true;

            fornecedoresInseridos.forEach(inseridos => {
              if (fornecedor.nome === inseridos.fornecedor) {
                valido = false;
              }
            })

            if (valido) {
              newFornecedores.push(fornecedor)
            }
          })
          if (newFornecedores) {
            this.setState({ fornecedores: [...newFornecedores],
              fornecedorSlice: newFornecedores.length > 100 ? newFornecedores.slice(0, 100) : newFornecedores,
            });
          }
        })
        .catch((error) => console.log(error));
    }
  }

  getDataFornecedoresInseridos() {
    let idCotacao = this.props.idCotacao;
    if (idCotacao) {
      Http.get("/cotacao/" + idCotacao + "/fornecedores")
        .then(({ status, data }) => {
          if (data) {
            this.setState({ fornecedoresInseridos: data });
            this.getDataFornecedores(data)
          }
        })
        .catch((error) => console.log(error));
    }
  }

  async confirmeDeleteFornecedorInserido(fornecedorInserido) {
    fornecedorInserido.idUsuarioInativacao = this.state.user.idUsuario;

    console.log(fornecedorInserido.datainativacao)

    await Http.delete(`/cotacao/fornecedores/`, fornecedorInserido)
      .then(({ status, data }) => {
        console.log(status, data)
        if (data.status) {
          Notification.success(data.mensagem);
          this.getDataFornecedores();
          this.getDataFornecedoresInseridos();
        } else {
          Notification.danger(data.mensagem);
        }
      })
      .catch((error) => console.log(error));
  }

  deletarFornecedorInserido(fornecedorInserido) {
    ModalNotification.confirm(
      "Deseja deletar este Fornecedor? ",
      "Este fornecedor será removido da Cotação!",
      () => this.confirmeDeleteFornecedorInserido(fornecedorInserido)
    );
  }

  inserirFornecedoresCotacao() {
    if (!this.props.idCotacao) {
      Notification.danger("Confirme Dados da cotação antes!");
      return;
    }
    let idCotacao = this.props.idCotacao;
    let idGestorCompras = this.props.idGestorCompras;

    this.state.fornecedoresSelecionados.forEach((item, index) => {
     
      let obj = {
        idCotacao: idCotacao,
        idGestorCompras: idGestorCompras,
        idPessoa: item.idPessoa,
        tipopagamento: item.tipoPagamento,
        tipoplanopagamento: item.planoPagamento
      };

      Http.post("/cotacao/fornecedores/", obj)
        .then(({ status, data }) => {
          if (data.status) {
            Notification.success(data.mensagem);
            this.getDataFornecedoresInseridos();
            this.setState({ fornecedoresSelecionados: [], selecionarTodos: false });
          } else {
            Notification.danger(data.mensagem);
          }
        })
        .catch((error) => console.log(error));
    });
  }

  setCloseModal = (boolean) => {
    this.setState({
      closeModal: boolean,
      fornecedoresSelecionados: [],
      selecionarTodos: false
    });
  };

  getTipoPagamento() {
    return [
      new SelectValue("", "SELECIONE", true),
      new SelectValue("DINHEIRO", "DINHEIRO", false),
      new SelectValue("BOLETO", "BOLETO", false),
    ];
  }

  getPlanoPagamento() {
    return [
      new SelectValue("", "SELECIONE", true),
      new SelectValue("A VISTA", "A VISTA", false),
      new SelectValue("15 DIAS", "15 DIAS", false),
      new SelectValue("30 DIAS", "30 DIAS", false),
    ];
  }


  setTipoPagmento(e) {
    this.setState({
      tipoPagamento: e.target.value
    })
  }

  handleScroll = (e) => {
    const { fornecedorSlice, fornecedores } = this.state;
    let element = e.target;
    if (element.scrollHeight - element.scrollTop === element.clientHeight) {
      let newFornecedor = [];
      let start = fornecedorSlice.length;
      let end =
      fornecedores.length > 100
          ? fornecedorSlice.length + 100
          : fornecedorSlice.length - fornecedores.length;

      if (fornecedores.length >= fornecedorSlice.length) {
        newFornecedor = [...fornecedorSlice, ...fornecedores.slice(start, end)];

        console.log(newFornecedor);
        this.setState({
          fornecedorSlice: newFornecedor,
        });
      }
    }
  };


  render() {
    const {
      fornecedores,
      selecionarTodos,
      fornecedoresSelecionados,
      fornecedoresInseridos,
      fornecedorSlice,
    } = this.state;

    return (
      <>
        <ModalCustom
          title="Fornecedores Disponíveis "
          showModal={this.state.closeModal}
          setShowModal={this.setCloseModal}
          confirm={() => this.inserirFornecedoresCotacao()}
        >
          <Div col="12" style={{ overflow: "auto", maxHeight: "75vh" }}>
            <Table
              columns={[
                { value: "Nome", align: "center" },
                { value: "Razão", align: "center" },
                { value: "Email", align: "center" },
                { value: "Tipo Pagamento", align: "center" },
                { value: "Plano Pagamento", align: "center" }
              ]}
              fields={[
                { value: "nome", type: Type.DADO.STRING },
                { value: "razao", type: Type.DADO.STRING },
                { value: "emailPrincipal", type: Type.DADO.STRING },
                {
                  input: true,
                  typeInput: "select",
                  value: "tipoPagamento",
                  getItens: this.getTipoPagamento,          
                  align: "right",
                  width: "250px",
                },
                {
                  input: true,
                  typeInput: "select",
                  value: "planoPagamento",
                  getItens: this.getPlanoPagamento,          
                  align: "right",
                  width: "250px",
                },
              ]}
              bordered
              orientation="center"
              search  
              data={fornecedorSlice}
              error="Nenhum fornecedor encontrado"
              checkbox={{ nameChecked: "selected", valueChecked: true }}              
              handlerCheckbox={(fornecedor) => {  

                let newFornecedoresSelecionados = fornecedoresSelecionados;

                if (!fornecedor.selected) {
                    fornecedor.selected = true;

                    if (fornecedor.selected) {
                      newFornecedoresSelecionados.push(fornecedor);
                    } else {
                      newFornecedoresSelecionados = fornecedoresSelecionados.filter(
                        (item) => {
                            return item.idCotacaoFornecedor === fornecedor.idCotacaoFornecedor ? false : true
                        }
                      );
                    }
                }

                this.setState({
                  fornecedoresSelecionados: [...newFornecedoresSelecionados]
                });
              }}
              labelCheckBox={
                <Checkbox
                  width="50px"
                  label="Selecionar Todos"
                  checked={selecionarTodos}
                  handlerChange={({ checked }) => {
                    let fornecedoresList = fornecedores;

                    if (Utils.isArrayNotEmpty(fornecedoresList)) {
                      fornecedoresList.forEach((c) => {
                        c.selected = checked;
                        this.setState({
                          fornecedoresSelecionados: checked
                            ? fornecedoresList
                            : [],
                          selecionarTodos: checked,
                        });
                      });
                    } else {
                      Notification.info(
                        "Nenhum Fornecedor selecionado, verifique se os fornecedores estão selecionado para serem inseridos na cotação."
                      );
                    }
                  }}
                ></Checkbox>
              }
            ></Table>
          </Div>
        </ModalCustom>
        <Div>
          <Div col="12" padding="2">
            <Row col="12" padding="3">
              <Title
                value="Fornecedores inseridos na cotação"
                type="h4"
                paddingLeft="4"
                paddingRight="4"
                paddingTop="3"
                col="10"
              />
              <Div
                col="2"
                padding="0 1rem"
                style={{
                  textAlign: "right",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "flex-end ",
                  marginBottom: "-20px",
                }}
              >
                <ButtonIconOutLine
                  value="Adicionar Fornecedores"
                  icon={Type.ICON.PLUS}
                  btn={Color.NODE.PRIMARY}
                  handlerClick={() => this.setCloseModal(true)}
                />
              </Div>
            </Row>
            <Table
              columns={[
                { value: "Fornecedor", align: "center" },
                { value: "Tipo de Pagamento", align: "center" },
                { value: "Plano de Pagamento", align: "center" },
              ]}
              fields={[
                { value: "fornecedor", type: Type.DADO.STRING },
                { value: "tipopagamento", type: Type.DADO.STRING },
                { value: "tipoplanopagamento", type: Type.DADO.STRING },
              ]}
              bordered
              orientation="center"
              pagination
              data={fornecedoresInseridos}
              error="Nenhum fornecedor encontrado"
              delete
              handlerDelete={(rede) => this.deletarFornecedorInserido(rede)}
            ></Table>
          </Div>
        </Div>
      </>
    );
  }
}

export default InserirEditarFornecedores;
