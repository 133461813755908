import React from "react";
import ReactTooltip from "react-tooltip";
import Div from "../layout/div/Div";
import { className, padding, margin } from "../css";
import { Utils } from "../../utilities";

const getClassName = props => {
  let classe = "";
  classe = classe.concat(props.icon ? `${props.icon} ` : "");
  classe = classe.concat(margin(props));
  classe = classe.concat(padding(props));
  classe = classe.concat(className(props));
  return classe;
};

const TooltipText = props => {
  return (
    <Div>
      <i
        className={getClassName(props)}
        data-tip={props.title}
        data-place={props.direction}
        data-for={props.id}
        id={Utils.uuidString()}
      >
        {props.children}
      </i>
      <ReactTooltip id={props.id} />
    </Div>
  );
};

export default TooltipText;
