import React from "react";
import {
  ButtonIconOutLine,
  Div,
  I,
  If,
  InputSearch,
  Select,
  Title,
} from "../../../components";
import { Color, Type, Utils } from "../../../utilities";

const TemplateFilterProdutos = ({
  departamentos,
  grupos,
  tipoPesquisas,
  handler,
  handlerSearch,
  handlerChange,
  produtosSelecionados
}) => {
  return (
    <Div rounded col="12" bg={Color.NODE.LIGHT} textAlign="left">
      <Div col="12" padding="2" inline="start">
        <Select
          required
          className="col-4"
          label="Tipo Pesquisa"                    
          handlerChange={(e) => handler(e.target.value, "tpPesquisa")}
          values={tipoPesquisas}
        />

        <InputSearch
          handlerChange={(e) => handlerChange(e)}
          handlerBlur={(e) => handlerSearch(e.target.value)}
          hnadlerIcon={(e) => handlerSearch(e.target.value)}
          className="col-8"
          label="Informe um produto por nome, codigo de barras, codigo produto ou ncm"
        />
      </Div>
      <Div col="12" padding="2" inline="start">
        <Select
          required
          responsive="6"
          label="Departamentos"
          // valueSelected={this.state.idDepartamento}
          multi={true}
          handlerChange={(e) => handler(e.target.value, "departamento")}
          values={departamentos}
        />

        <Select
          required
          responsive="6"
          label="Grupos"
          //  valueSelected={this.state.idGrupo}
          multi={true}
          handlerChange={(e) => handler(e.target.value, "grupo")}
          values={grupos}
        />
      </Div>
      <If and value1={produtosSelecionados.length > 0}>
        <Div col="12" inline="center">
          <Div
            bg={Color.NODE.BEGE}
            colorText={Color.NODE.WHITE}
            className="opacity-7"
            padding="4"
            col="6"
            rounded
            column
          >
            <Div inline="center">
              <Title
                type="h4"
                value={`${produtosSelecionados.length} produtos selecionados`}
                bold
              />
              <I
                marginLeft="2"
                sizeIcon="2"
                pointer
                className="pulse"
                icon={Type.ICON.INFO}
                //handlerClick={() => this.renderProdutosSelecionados()}
              />
            </Div>
          </Div>
        </Div>
      </If>
    </Div>
  );
};

export default TemplateFilterProdutos;
