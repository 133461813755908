import React, { Component } from "react";
import { Div, Table, InputSearch, ComboBox, Title  , Select, SelectValue, ModalNotification} from "../../components";
import { connect } from "react-redux";
import { Color, PathRoute, Http, Type } from "../../utilities";

const ENTER = 13;

class Listar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: props.user,
      dialogGestor: false,
      gestores: [],
      gestor: {},
      selectedGestor: {},
      globalFilter: null,
      ...this.props.location.state,
    };    
    
  }

  UNSAFE_componentWillMount() {
    this.getDataGestores();
  }

  async getDataGestores() {        
    await Http.get(`/gestor/listar/` + this.state.user.idUsuario)
      .then(({ status, data }) => {
        if (data) {
          this.setState({ gestores: data });
        }
      })
      .catch((error) => console.log(error));
  }

  leftToolbarTemplate = () => {
    return (
      <InputSearch                           
      label="Procurar na Grid"
      name="globalFilter"              
      value={this.state.globalFilter}
      handlerChange={(e) => this.setState({ globalFilter: e.target.value })}
      placeholder="Procurar..."
    />
    )
  }

  rightToolbarTemplate = () => {
    return (      
        <span className="p-input-icon-right">           

        </span>      
    )
  }

  exportCSV () {

  }

  editarGestor(gestor) {
    this.props.history.push({
      pathname: PathRoute.PATH.GESTOR_EDITAR,
      state: { gestor: gestor, editar: true },
    });
  }

  bloqueiarGestor(gestor) {
    this.props.history.push({
      pathname: PathRoute.PATH.GESTOR_BLOQUEIAR,
      state: { gestor: gestor, editar: true },
    });
  }
  

  async delete(gestor) {        
    await Http.post(`/gestor/deletar`, gestor)
    .then(({ status, data }) => {
      if (data.status) {
        Notification.success(data.mensagem);
        this.getDataGestores();        
      } else {
        Notification.danger(data.mensagem);
      }
    })
    .catch((error) => console.log(error));
  }

  cancelar() {
    
  }

  render() {
    
    return (
      <Div margin="3" shadow bg={Color.NODE.WHITE} borderLeft={Color.NODE.PRIMARY}>

        <Title value="Gestores" type="h4" paddingLeft="4" paddingRight="4" paddingTop="3"/>
        
        <Div padding="2">
          <Table
            columns={[              
              { value: "Nome Pessoa", align: "center" },
              { value: "Código", align: "center" }, 
              { value: "Descrição", align: "center" },
              { value: "Motivo Bloqueio", align: "center" },
              { value: "Data Bloqueio", align: "center" },
              { value: "Ativo", align: "center" }
            ]}
            fields={[
              { value: "nomePessoa",    type: Type.DADO.STRING },
              { value: "codigoGestorCompras",   type: Type.DADO.STRING },
              { value: "descricao",       type: Type.DADO.STRING },
              { value: "motivoBloqueio",       type: Type.DADO.STRING },     
              { value: "dataBloqueio",       type: Type.DADO.DATE },    
              { value: "ativo",          type: Type.DADO.BOOLEAN}
            ]}
            bordered
            search
            orientation="center"
            pagination
            rota={PathRoute.PATH.GESTOR_CADASTRAR}
            edit
            delete
            handlerEdit={(gestor) => this.editarGestor(gestor)}
            handlerDelete={(gestor) => this.bloqueiarGestor(gestor)}
            data={this.state.gestores}
            error="Nenhuma pessoa encontrada"
          ></Table>
        </Div>
      </Div>
    );
  }
}

const mapStateToProps = (store) => ({
  idGestorCompras: store.loginState.user.idGestorCompras,
  idUsuario: store.loginState.user.idUsuario,
});

export default connect(mapStateToProps)(Listar);
