import React, { Component } from "react";
import { Color, Http, Mask, Type, Pattern, DateUtils, Utils } from "../../utilities";

import {
  Row,
  InputDefault,
  InputInteger,
  Form,
  SelectValue,
  Select,
  Notification,
  SectionForm,
  SectionButtonForm,
  Button
} from "../../components";
import { connect } from "react-redux";


class Cadastrar extends Component {

  constructor(props) {
    super(props);

    this.state = {
      nameButton: "Salvar",
      user: props.user,   
      pessoas: [],         
      ...this.props.location.state 
    };

    this.submit = this.submit.bind(this);
    this.listaGestores = this.listaGestores.bind(this);    
    this.configuraGestor = this.configuraGestor.bind(this);    
    this.getDataPessoasSelect = this.getDataPessoasSelect.bind(this);    

  }

  UNSAFE_componentWillMount() {    
    this.getDataPessoasSelect();   
    let gestor = this.state.gestor;    
    if (this.state.editar) {
      this.configuraGestor(gestor);     

    }
  }

  configuraGestor(gestor) {
    this.setState({
        nameButton: "Alterar",        
        idPessoa: gestor.idPessoa,
        idUsuario: gestor.idUsuario,
        idGestorCompras: gestor.idGestorCompras,
        idUsuarioAtualizacao: this.state.user.idUsuario,
        codigoGestorCompras: gestor.codigoGestorCompras,	
        descricao: gestor.descricao,
        motivoBloqueio : gestor.motivoBloqueio
    })    
  }


  listaGestores() {
    this.props.history.push({pathname: `/compras/gestor`});
  }

  getTipoAtivoSelect() {
    return [
      new SelectValue("", "SELECIONE", true),
      new SelectValue("true", "ATIVO", false),
      new SelectValue("false", "INATIVO", false)      
    ];
  }  

  async getDataPessoasSelect() {           
    await Http.get(`/pessoa/listarEmpresa/` + this.state.user.idGestorCompras)
      .then(({ status, data }) => {        
        if (data) {
          let pessoaComDados = [];
          pessoaComDados.push(new SelectValue("", "SELECIONE A PESSOA", true));
          Array.from(data).map((pessoa) => {
            pessoaComDados.push(
              new SelectValue(pessoa.idPessoa, pessoa.nome, false)
            );
          });
          this.setState({ pessoas: pessoaComDados });
        }
      })
      .catch((error) => console.log(error));
  }
  
  async submit(event) {
    event.preventDefault();
    if (event.target.reportValidity()) {
      if (this.state.idGestorCompras === undefined) {
        this.cadastrar(this.state);
      } else {
        this.update(this.state);
      }
    }
  }

  cadastrar(gestor) {           
    gestor.idUsuario = this.state.user.idUsuario         
      Http.post("/gestor", gestor).then(({ data }) => {
        if (data.status) {
          Notification.success(data.mensagem);
          this.listaGestores();
        } else {
          Notification.danger(data.mensagem);
        }
      });    
  }

  
  update(gestor) {        
    gestor.idUsuarioAtualizacao = this.state.user.idUsuario;
      Http.put("/gestor", gestor).then(({ data }) => {      
        if (data.status) {          
          Notification.success(data.mensagem);
          this.listaGestores();
        } else {
          Notification.danger(data.mensagem);
        }      
      });    
  }
  
  
   render() {
    return (
      <Form id="formCadastroGestor" handlerSubmit={this.submit}>
          <SectionForm borderLeft="primary">
            <Row col="12">
            <Select required
                responsive="4"
                label="Pessoa"
                valueSelected={this.state.idPessoa}
                handlerChange={(e) => {
                  this.setState({idPessoa: e.target.value });                                                          
                }}
                values={this.state.pessoas}
              />
              <InputInteger
                label="Código Gestor Compras"
                name="codigoGestorCompras"
                responsive="2"
                value={this.state.codigoGestorCompras}
                handlerChange={(e) => {
                  this.setState({codigoGestorCompras: e.target.value });                  
                }}
              />
              <InputDefault  required
                label="Descrição"
                name="descricao"
                responsive="4"
                value={this.state.descricao}
                handlerChange={(e) => {
                  this.setState({descricao: e.target.value });                  
                }}
              />
            </Row>


            <SectionButtonForm>
                <Button
                  btn={Color.NODE.PRIMARY}
                  value={this.state.nameButton}
                  col="1"
                  margin="1"
                />

                <Button
                  btn={Color.NODE.DANGER}
                  value="Cancelar"
                  col="1"
                  handlerClick={(_e) => this.listaGestores()}
                  margin="5"
                />
              </SectionButtonForm>
          </SectionForm> 
    </Form>)
  }
   
}

const mapStateToProps = (store) => ({
  user: store.loginState.user,
});

export default connect(mapStateToProps)(Cadastrar);
