import React, { Component } from "react";
import {
  ButtonIconOutLine,
  Checkbox,
  Div,
  ModalCustom,
  ModalNotification,
  Notification,
  Row,
  Table,
  Title,
} from "../../components";
import { Color, Http, Type, Utils } from "../../utilities";

class InserirEditarEmpresas extends Component {
  constructor(props) {
    super(props);

    this.state = {
      empresas: [],
      empresasSelecionadas: [],
      empresasInseridas: [],
      empresaSlice: [],
      closeModal: false,
      idGestorCompras: "",
      selecionarTodos: false,
      user: {},
    };
  }

  UNSAFE_componentWillMount() {
    this.getDataEmpresasInseridos();
    this.setState({
      idGestorCompras: this.props.idGestorCompras,
    });
  }

  async getDataEmpresas(empresasInseridas) {  
    if (this.props.idCotacao) {
      await Http.get(`/pessoa/listarEmpresaassinatura/` + this.props.idGestorCompras)
      .then(({ status, data }) => {

        let newEmpresas = []
       
        if(!Utils.isArrayNotEmpty(data)) {
          Notification.danger("Empresa com assinatura não encontrado!");
          return;
        }


        data.forEach(emp => {
          let valido = true;

          empresasInseridas.forEach(inseridos => {
            if (emp.idPessoa === inseridos.idEmpresa) {              
              valido = false;
            }
          })

          if (valido) {
            newEmpresas.push(emp)
          }
        })

        if (newEmpresas) {
          this.setState({ empresas: [...newEmpresas],
            empresaSlice: newEmpresas.length > 100 ? newEmpresas.slice(0, 100) : newEmpresas,
          });
        }
      })
      .catch((error) => console.log(error)); 
    }
  }

  async getDataEmpresasInseridos() {    
    if (this.props.idCotacao) {
      await Http.get(`/cotacao/empresa/` + this.props.idCotacao).then(({ status, data }) => {        
        if (data) {
          this.setState({ empresasInseridas: data });
          this.getDataEmpresas(data)
        }
      })
      .catch((error) => console.log(error)); 
    }
  }

  async deleteEmPesquisa(empresa) {
    empresa.idUsuarioInativacao = this.state.user.idUsuario;
    empresa.ativo = false;
    await Http.delete(`/cotacao/empresa`, empresa)
      .then(({ status, data }) => {
        if (data.status) {
          Notification.success(data.mensagem);
          this.getDataEmpresas();
          this.getDataEmpresasInseridos();
        } else {
          Notification.danger(data.mensagem);
        }
      })
      .catch((error) => console.log(error));
  }

  deletarEmpresaPesquisa(empresa) {
    ModalNotification.confirm(
      "Deseja remover essa Empresa da pesquisa? ",
      "Esta empresa será removida da pesquisa!",
      () => this.deleteEmPesquisa(empresa)
    );
  }

  inserirEmpresaPesquisa() {
    if (!this.props.idCotacao) {
      Notification.danger("É necessário uma cotação!");
      return;
    }    

    var arrayEmpresaSelecionadas = this.state.empresasSelecionadas;

    arrayEmpresaSelecionadas.forEach((item, index) => {
      let obj = {
        idCotacao: this.props.idCotacao,
        idGestorCompras: item.idGestorCompras,
        idEmpresa: item.idPessoa,
        ativo: true
      };     

      Http.post("/cotacao/empresa/", obj).then(({ status, data }) => {          
          if (data.status) {
            Notification.success(data.mensagem); 
            this.getDataEmpresasInseridos();
            this.setState({ empresasSelecionadas: [], selecionarTodos: false });
          } else {
            Notification.danger("Falha ao inserir a Empresas");
          }
        }).catch((error) => console.log(error));
    });
  }

  setCloseModal = (boolean) => {
    this.setState({
      closeModal: boolean,
      empresasSelecionadas: [],
      selecionarTodos: false
    });
  };

  handleScroll = (e) => {
    const { empresaSlice, empresas } = this.state;
    let element = e.target;
    if (element.scrollHeight - element.scrollTop === element.clientHeight) {
      let newEmpresa = [];
      let start = empresaSlice.length;
      let end =
        empresas.length > 100
          ? empresaSlice.length + 100
          : empresaSlice.length - empresas.length;

      if (empresas.length >= empresaSlice.length) {
        newEmpresa = [...empresaSlice, ...empresas.slice(start, end)];

        console.log(newEmpresa);
        this.setState({
          empresaSlice: newEmpresa,
        });
      }
    }
  };

  render() {

    const {
      empresas,
      selecionarTodos,
      empresasSelecionadas,
      empresasInseridas,
      empresaSlice,
    } = this.state;

    return (
      <>
        <ModalCustom
          title="Empresas Disponíveis "
          showModal={this.state.closeModal}
          setShowModal={this.setCloseModal}
          confirm={() => this.inserirEmpresaPesquisa()}          
        >
          <Div col="12" style={{ overflow: "auto", maxHeight: "75vh" }}
             handlerScroll={(e) => this.handleScroll(e)}
          >
            <Table
              columns={[
                { value: "Razão Social", align: "center" },
                { value: "Cnpj", align: "center" },

              ]}
              fields={[
                { value: "razao", type: Type.DADO.STRING },
                { value: "cpfCnpj", type: Type.DADO.STRING },
              ]}
              bordered
              orientation="center"
              search
              data={empresaSlice}
              error="Nenhuma empresa encontrada"
              checkbox={{ nameChecked: "selected", valueChecked: true }}
              handlerCheckbox={(empresa) => {
                empresa.selected = !empresa.selected;
                let empresaSelecionada = empresasSelecionadas.filter(
                  (p) => p.idEmpresa === empresa.idEmpresa
                )[0];

                if (Utils.isValueValid(empresaSelecionada)) {
                  const index = empresasSelecionadas.indexOf(empresa);
                  empresasSelecionadas.splice(index);
                } else {
                  empresasSelecionadas.push(empresa);
                }

                this.setState({
                  empresasSelecionadas,
                });
              }}
              labelCheckBox={
                <Checkbox
                  width="50px"
                  label="Selecionar Todos"
                  checked={selecionarTodos}
                  handlerChange={({ checked }) => {
                    let empresasList = empresas;

                    if (Utils.isArrayNotEmpty(empresasList)) {
                      empresasList.forEach((c) => {
                        c.selected = checked;
                        this.setState({
                          empresasSelecionadas: checked
                            ? empresasList
                            : [],
                          selecionarTodos: checked,
                        });
                      });
                    } else {
                      Notification.info(
                        "Nenhuma Empresa selecionada, verifique se as empresas estão selecionadas para serem inseridas na pesquisa."
                      );
                    }
                  }}
                ></Checkbox>
              }
            ></Table>
          </Div>
        </ModalCustom>
        <Div>
          <Div col="12" padding="2">
            <Row col="12" padding="3">
              <Title
                value="Empresas"
                type="h4"
                paddingLeft="4"
                paddingRight="4"
                paddingTop="3"
                col="10"
              />
              <Div
                col="2"
                padding="0 1rem"
                style={{
                  textAlign: "right",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "flex-end ",
                  marginBottom: "-20px",
                }}
              >
                <ButtonIconOutLine
                  value="Adicionar Empresas"
                  icon={Type.ICON.PLUS}
                  btn={Color.NODE.PRIMARY}
                  handlerClick={() => this.setCloseModal(true)}
                />
              </Div>
            </Row>
            <Table
              columns={[
                { value: "Razão Social", align: "center" },
                { value: "Cnpj", align: "center" },
                { value: "Email", align: "center" },
              ]}
              fields={[
                { value: "nomeEmpresa", type: Type.DADO.STRING },
                { value: "cpfCnpjEmpresa", type: Type.DADO.CPF_CNPJ },
                { value: "emailEmpresa", type: Type.DADO.STRING },
              ]}
              bordered
              orientation="center"
              pagination
              data={empresasInseridas}
              error="Nenhuma empresa Inserida ou Encontrada na Pesquisa"
              delete
              handlerDelete={(rede) => this.deletarEmpresaPesquisa(rede)}
            ></Table>
          </Div>
        </Div>
      </>
    );
  }
}

export default InserirEditarEmpresas;
