import React from "react";
import { TableColumn, Checkbox } from "../..";

const TableColumnCheckBox = props => {

  return (
    <TableColumn>
    {!props.isHiden && 
        <Checkbox
          className="pt-3"
          checked={props.checked}
          handlerChange={props.handlerCheckBox}
        ></Checkbox>
      
      }
    </TableColumn>
  );
};

export default TableColumnCheckBox;
