import React from "react";
import { Http, Type, Utils } from "../../utilities";
import {
  Checkbox,
  Div,
  InputSearchTable,
  Notification,
  Table,
} from "../../components";

export default function ProdutosInseridosEditar({
  idPesquisa,
  idUsuario,
  produtosInseridosSelecionados,
  setState,
  deletarProdutoPesquisa,
}) {
  const [produtosInseridos, setProdutosInseridos] = React.useState({
    original: [],
    slice: [],
  });
  const [selecionarTodos, setSelecionarTodos] = React.useState(false);

  const [originalProdutosLista, setOriginalProdutosLista] = React.useState([]);

  React.useEffect(() => {
    getDataProdutosInseridos();
  }, []);

  function getDataProdutosInseridos() {
    if (!idPesquisa) return;

    //`/pesquisa/${idPesquisa}/preco`
    Http.get("/pesquisa/" + idPesquisa + "/produto")
      .then(({ status, data }) => {
        if (Utils.isNotNull(data)) {
          setProdutosInseridos({
            original: [...data],
            slice: data.length > 50 ? data.slice(0, 50) : data,
          });
          setOriginalProdutosLista([...data]);
        }
      })
      .catch((error) => console.log(error));
  }

  const handleScroll = (e) => {
    const { slice, original } = produtosInseridos;

    let element = e.target;

    if (
      Math.round(element.scrollHeight - element.scrollTop) ===
      element.clientHeight
    ) {
      let newProdutos = [];
      let start = slice.length;
      let end =
        original.length > 50
          ? slice.length + 50
          : slice.length - original.length;

      if (original.length >= slice.length) {
        newProdutos = [...slice, ...original.slice(start, end)];

        setProdutosInseridos((state) => {
          return {
            slice: newProdutos,
            original: state.original,
          };
        });
      }
    }
  };

  function buscarItem(searchText) {
    let buscarLista = [...produtosInseridos.original];
    let newList = buscarLista.filter(
      (item) =>
        (item.descricao !== null &&
          item.descricao.toLowerCase().indexOf(searchText.toLowerCase()) >
            -1) ||
        (item.codigoProduto !== null &&
          item.codigoProduto.toLowerCase().indexOf(searchText.toLowerCase()) >
            -1) ||
        (item.ean !== null &&
          item.ean.toLowerCase().indexOf(searchText.toLowerCase()) > -1)
    );

    return [...newList];
  }

  function handlerChange(e) {
    let newArray = buscarItem(e.target.value);

    setProdutosInseridos((state) => {
      return {
        slice: newArray.length > 50 ? newArray.slice(0, 50) : newArray,
        original: state.original,
      };
    });
  }

  return (
    <Div
      style={{ overflow: "scroll", maxHeight: "460px", marginTop: "20px" }}
      handlerScroll={(e) => handleScroll(e)}
    >
      <Div responsive="12">
        <InputSearchTable
          handlerChange={(e) => handlerChange(e)}
        />
      </Div>
      <Table
        columns={[
          { value: "PLU", align: "center" },
          { value: "Descricao", align: "left" },
          { value: "Código de Barras", align: "center" },
        ]}
        fields={[
          {
            value: "codigoProduto",
            align: "center",
            type: Type.DADO.STRING,
            width: "100px",
          },
          {
            value: "descricao",
            align: "left",
            width: "50%",
            type: Type.DADO.STRING,
          },
          {
            value: "ean",
            align: "left",
            type: Type.DADO.STRING,
            width: "20%",
          },
        ]}
        bordered
        orientation="center"
        // pagination
        data={produtosInseridos.slice}
        error="Nenhum produto encontrado"
        checkbox={{ nameChecked: "selected", valueChecked: true }}
        handlerCheckbox={(produto) => {
          produto.selected = !produto.selected;
          produto.idUsuarioInativacao = idUsuario;
          produto.idUsuarioAtualizacao = idUsuario;

          if (!produto.selected) {
            let index = produtosInseridosSelecionados.indexOf(produto);
            produtosInseridosSelecionados.splice(index, 1);
          } else {
            produtosInseridosSelecionados.push(produto);
          }

          setState({
            produtosInseridosSelecionados: [...produtosInseridosSelecionados],
          });

          setSelecionarTodos(false);
        }}
        TableColumnCheckBox={
          <Checkbox
            width="40px"
            label="Selecionar Todos os Produtos"
          ></Checkbox>
        }
        labelCheckBox={
          <Checkbox
            width="40px"
            //  label="Selecionar Todos"
            checked={selecionarTodos}
            handlerChange={({ checked }) => {
              let produtosList = [...produtosInseridos.original];

              if (Utils.isArrayNotEmpty(produtosList)) {
                produtosList.forEach((c) => {
                  c.selected = checked;

                  setState({
                    produtosInseridosSelecionados: checked
                      ? [...produtosList]
                      : [],
                  });

                  setSelecionarTodos(checked);
                });
              } else {
                Notification.info(
                  "Nenhum Produto selecionado, verifique se os produtos estão selecionado para serem inseridos na pesquisa."
                );
              }
            }}
          ></Checkbox>
        }
        stylesCheckbox={{ width: "150px" }}
        delete
        //filter={this.renderFiltros()}
        handlerDelete={(rede) => deletarProdutoPesquisa(rede)}
      ></Table>
    </Div>
  );
}
