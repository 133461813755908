import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import Init from "./Init";
import { Provider } from "react-redux";
import { Store } from "./redux/store/Store";

import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";

import "bootstrap/dist/js/bootstrap.min.js";

import "./index.css";
import "./assets/css/card.css";
import "simple-mask-money"
import ThemeContextProvider from "./context/ThemeContext";

ReactDOM.render(
  <Provider store={Store}>
    <ThemeContextProvider>
      <Init />
    </ThemeContextProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
