import React, { Component } from "react";
import { Color, Http, Type, Utils, DateUtils } from "../../utilities";

import {
  Row,
  InputDefault,
  Checkbox,
  ButtonIconOutLine,
  I,
  Form,
  ModalCustom,
  SelectValue,
  Notification,
  SectionForm,
  SectionButtonForm,
  Button,
  Div,
  Table,
  Title,
  ModalNotification,
} from "../../components";
import { connect } from "react-redux";
import TemplateFilterProdutos from "./template/TemplateFilterProdutosSelect";

class Cadastrar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      closeModal: false,
      nameButton: "Salvar",
      user: props.user,
      idGrupoProdutos: undefined,
      idGestorCompras: "",
      descricao: "",
      produtos: [],
      grupoProdutos: {},
      produtosSelecionados: [],
      produtosInseridosSelecionados: [],
      produtosInseridos: [],
      tipoPesquisas: [],
      selecionarTodos: false,
      addButtonGrupoProdutos: false,
      ...this.props.location.state,
      produtosSlice: [],
      filtros: {
        departamentos: [new SelectValue(0, "SELECIONE", true)],
        grupos: [new SelectValue(0, "SELECIONE", true)],
      },
      produtosSlice: [],
      departamentos: [],
      departamentosSelecionados: [],
      grupos: [],
      idDepartamento: "",
      idGrupo: "",
      idGestorCompras: "",
      tipoPesquisa: "DESCRICAO",
      paginacao: 0,
    };

    this.submit = this.submit.bind(this);
    this.getDataGrupoProdutosProduto = this.getDataGrupoProdutosProduto.bind(
      this
    );
  }

  UNSAFE_componentWillMount() {
    let grupoProdutos = this.state.grupoProduto;
    this.getDataProdutos(0);
    this.getDataDepartamentos();
    this.getTipoPesquisaSelect();
    if (this.state.editar) {
      this.getDataGrupoProdutosProduto(grupoProdutos.idGrupoProdutos);
      this.setState({
        nameButton: "Alterar",
        idGrupoProdutos: grupoProdutos.idGrupoProdutos,
        idGestorCompras: grupoProdutos.idGestorCompras,
        descricao: grupoProdutos.descricao,
      });
    }
  }

  getTipoPesquisaSelect() {
    var tp = [
      new SelectValue("DESCRICAO", "DESCRIÇÃO", true),
      new SelectValue("EAN", "CÓDIGO DE BARRAS", false),
      new SelectValue("PLU", "PLU", false),
    ];

    this.setState({ tipoPesquisas: tp });
  }

  listaGrupoPrpdutos() {
    this.props.history.push({ pathname: `/compras/grupoprodutos` });
  }

  async submit(event) {
    event.preventDefault();
    if (event.target.reportValidity()) {
      if (this.state.idGrupoProdutos === undefined) {
        this.cadastrar(this.state);
      } else {
        this.update(this.state);
      }
    }
  }

  cadastrar(grupoprodutos) {
    grupoprodutos.idGestorCompras = this.state.user.idGestorCompras;
    Http.post("/grupoprodutos", grupoprodutos).then(({ data }) => {
      if (data.status) {
        this.setState({ idGrupoProdutos: data.id, nameButton: "Alterar" });
        Notification.success(data.mensagem);
      } else {
        Notification.danger(data.mensagem);
      }
    });
  }

  update(grupoprodutos) {
    grupoprodutos.idUsuarioAtualizacao = this.state.user.idUsuario;
    Http.put("/grupoprodutos", grupoprodutos).then(({ data }) => {
      if (data.status) {
        Notification.success(data.mensagem);
      } else {
        Notification.danger(data.mensagem);
      }
    });
  }

  addProdutos() {
    if (this.state.addButtonGrupoProdutos) {
      this.setState({ addButtonGrupoProdutos: false });
    } else {
      this.setState({ addButtonGrupoProdutos: true });
    }
  }

  setCloseModal = (boolean) => {
    this.setState({
      closeModal: boolean,
      produtos: [],
      produtosSlice: [],
      produtosSelecionados: [],
      selecionarTodos: false,
    });
  };

  inserirProdutosPesquisa() {
    if (this.state.idGrupoProdutos === undefined) {
      Notification.danger("Obrigatório a criação do Grupo!");
      return false;
    }

    this.state.produtosSelecionados.forEach((item, index) => {
      let isProdutoInserido = false;
      let obj = {
        idGrupoProdutos: this.state.idGrupoProdutos,
        idGestorCompras: item.idGestorCompras,
        idProduto: item.idProduto,
      };
      this.state.produtosInseridos.forEach((itemInserido) => {
        if (item.idProduto === itemInserido.idProduto) {
          isProdutoInserido = true;
        }
      });
      this.adicionarProduto(obj);
    });

    this.setState({
      selecionarTodos: false,
    });
  }

  async getDataProdutos() {
    this.setState({ produtos: [] });
    await Http.get(
      `/grupoprodutos/listarProdutos/` + this.state.user.idGestorCompras
    )
      .then(({ status, data }) => {
        if (data) {
          this.setState({ produtos: data });
        }
      })
      .catch((error) => console.log(error));
  }

  async delete(produtos) {
    produtos.forEach((produto) => {
      produto.ativo = false;
      produto.idUsuarioInativacao = this.state.user.idUsuario;
    });

    await Http.delete(`/grupoprodutos/produto`, produtos)
      .then(({ status, data }) => {
        if (data.status) {
          Notification.success(data.mensagem);
          this.getDataGrupoProdutosProduto(this.state.idGrupoProdutos);
        } else {
          Notification.danger(data.mensagem);
        }
      })
      .catch((error) => console.log(error));
  }

  getDataGrupoProdutosProduto(idGrupoProdutos) {
    this.setState({ produtosInseridos: [] });
    Http.get(`/grupoprodutos/listarGrupoProdutosProduto/` + idGrupoProdutos)
      .then(({ status, data }) => {
        if (data) {
          this.setState({ produtosInseridos: data });
        }
      })
      .catch((error) => console.log(error));
  }

  async getProdutosFiltros(idDepartamento, idGrupo) {
    this.setState({ produtos: [], produtosSlice: [] });

    var idgestor = this.state.user.idGestorCompras;
    var paginacao = 0;

    await Http.get(
      idGrupo
        ? `/produto/filtro/${idDepartamento}/departamento/${idGrupo}/grupo/${idgestor}/paginacao/${paginacao}`
        : `/produto/filtro/${idDepartamento}/departamento/${idgestor}/paginacao/${paginacao}`
    )
      .then(({ status, data }) => {
        if (data) {
          this.setState({
            produtos: data,
            produtosSlice: data.length > 100 ? data.slice(0, 100) : data,
          });
        }
      })
      .catch((error) => console.log(error));
  }

  mudarFiltros(value, type) {
    if (type === "departamento") {
      if (value != 0) {
        this.getDataGrupos(value);
      }
      this.setState(
        {
          idDepartamento: value,
          idGrupo: "",
          produtos: [],
          produtosSlice: [],
        },
        () => (value != 0 ? this.getProdutosFiltros(value, null) : {})
      );
    }

    if (type === "grupo") {
      this.setState(
        {
          idGrupo: value,
          paginacao: 0,
          produtos: [],
          produtosSlice: [],
        },
        () => this.listarPedidos()
      );
    }

    if (type === "tpPesquisa") {
      this.setState({ tipoPesquisa: value });
    }
  }

  listarPedidos() {
    let { idDepartamento, idGrupo } = this.state;

    if (idDepartamento !== "" && idGrupo !== "") {
      this.getProdutosFiltros(idDepartamento, idGrupo);
    }
  }

  handlerChangeProduto(e) {
    if (e.target.value === "") {
      this.getDataProdutos(0);
    }
  }

  getProdutosSearch(search) {
    this.setState({ produtos: [], produtosSlice: [] });
    let busca = {
      descricao: search,
      idPesquisa: this.props.idPesquisa,
      tipoPesquisa: this.state.tipoPesquisa,
    };

    if (search.trim() !== "") {
      Http.post(`/pesquisa/produto/listar/resumido`, busca)
        .then(({ data }) => {
          if (data) {
            this.setState({
              produtos: data,
              produtosSlice: data.length > 100 ? data.slice(0, 100) : data,
            });
          }
        })
        .catch((error) => console.log(error));
    }
  }

  getDataDepartamentos() {
    Http.get(`/departamento/` + this.state.user.idGestorCompras).then(
      ({ data }) => {
        if (data) {
          let depComDados = [];
          depComDados.push(new SelectValue(0, "SELECIONE", true));
          depComDados.push(
            new SelectValue(
              "6597f716-7beb-4166-a41a-66ee5fe1b9ea",
              "TODOS",
              false
            )
          );
          Array.from(data).map((dep) => {
            depComDados.push(
              new SelectValue(
                dep.idDepartamento,
                dep.sequencia + " - " + dep.descricao,
                false
              )
            );
          });
          this.setState({
            filtros: {
              ...this.state.filtros,
              departamentos: depComDados,
            },
          });
        }
      }
    );
  }

  getDataGrupos(idDepartamento) {
    Http.get(`/grupo/listargrupopordepartamento/` + idDepartamento).then(
      ({ data }) => {
        if (data) {
          let grupoComDados = [];
          grupoComDados.push(new SelectValue(0, "SELECIONE", true));
          grupoComDados.push(
            new SelectValue(
              "6597f716-7beb-4166-a41a-66ee5fe1b9ea",
              "TODOS",
              false
            )
          );
          Array.from(data).map((dep) => {
            grupoComDados.push(
              new SelectValue(
                dep.idGrupo,
                dep.sequenciaDepartamentoGrupo + " - " + dep.descricao,
                false
              )
            );
          });
          this.setState({
            filtros: {
              ...this.state.filtros,
              grupos: grupoComDados,
            },
          });
        }
      }
    );
  }

  async getDataProdutos(paginacao) {
    await Http.get(
      `/produto/listar/` +
        this.state.user.idGestorCompras +
        "/paginacao/" +
        paginacao
    )
      .then(({ status, data }) => {
        if (data) {
          this.setState({ produtos: data });
        }
      })
      .catch((error) => console.log(error));
  }

  async adicionarProduto(produto) {
    await Http.post(`/grupoprodutos/adicionarproduto`, produto)
      .then(({ status, data }) => {
        if (data.status) {
          this.getDataGrupoProdutosProduto(this.state.idGrupoProdutos);
          Notification.success(data.mensagem);
        } else {
          Notification.danger(data.mensagem);
        }
      })
      .catch((error) => console.log(error));
  }

  handleScroll = (e) => {
    const { produtosSlice, produtos } = this.state;
    let element = e.target;
    if (element.scrollHeight - element.scrollTop === element.clientHeight) {
      let newProdutos = [];
      let start = produtosSlice.length;
      let end =
        produtos.length > 100
          ? produtosSlice.length + 100
          : produtosSlice.length - produtos.length;

      if (produtos.length >= produtosSlice.length) {
        newProdutos = [...produtosSlice, ...produtos.slice(start, end)];

        this.setState({
          produtosSlice: newProdutos,
        });
      }
    }
  };

  renderFiltros() {
    let { departamentos, grupos } = this.state.filtros;
    let { tipoPesquisas } = this.state;
    return (
      <TemplateFilterProdutos
        departamentos={departamentos}
        grupos={grupos}
        tipoPesquisas={tipoPesquisas}
        handler={(value, type) => this.mudarFiltros(value, type)}
        handlerSearch={(e) => this.getProdutosSearch(e)}
        handlerChange={(e) => this.handlerChangeProduto(e)}
        produtosSelecionados={this.state.produtosSelecionados}
      />
    );
  }

  deletarTodosProdutosPesquisa(produtosSelecionados) {
    ModalNotification.confirm(
      "Deseja remover todos os Produtos selecionados? ",
      "Estes Produtos serão removidos do grupo!",
      () => {
        this.delete(produtosSelecionados);
      }
    );

    this.setState({
      selecionarTodos: false,
    });
  }

  render() {
    const {
      produtosInseridos,
      produtos,
      produtosSelecionados,
      selecionarTodos,
      produtosInseridosSelecionados,
      produtosSlice,
    } = this.state;

    return (
      <Div>
        <Form id="formCadastroGrupo" handlerSubmit={this.submit}>
          <SectionForm borderLeft="primary">
            <Row col="12">
              <InputDefault
                required
                label="Descrição"
                name="descricao"
                responsive="6"
                value={this.state.descricao}
                handlerChange={(e) => {
                  this.setState({ descricao: e.target.value });
                }}
              />

              {/* <SectionButtonForm>
                <Button
                  btn={Color.NODE.PRIMARY}
                  value={this.state.nameButton}
                  col="1"
                  margin="1"
                />

                <Button
                  btn={Color.NODE.DANGER}
                  value="Cancelar"
                  col="1"
                  handlerClick={(_e) => this.listaGrupoPrpdutos()}
                  margin="5"
                />
              </SectionButtonForm> */}

              <SectionButtonForm
                col="6"
                style={{
                  display: "flex",
                  alignItems: "center",
                  paddingTop: "10px",
                }}
              >
                <Row col="12" style={{}}>
                  <Div margin="1">
                    <ButtonIconOutLine
                      icon={Type.ICON.ARROW_ANGLE_LEFT}
                      value="Voltar"
                      btn={Color.NODE.SECONDARY}
                      handlerClick={(_e) => this.listaGrupoPrpdutos()}
                    />
                  </Div>

                  <Button btn={Color.NODE.PRIMARY} value="Salvar" margin="1" />
                </Row>
              </SectionButtonForm>
            </Row>
          </SectionForm>
        </Form>

        <Div>
          <Div>
            <>
              <ModalCustom
                title="Produtos Disponíveis "
                showModal={this.state.closeModal}
                setShowModal={this.setCloseModal}
                confirm={() => this.inserirProdutosPesquisa()}
              >
                <Div
                  col="12"
                  style={{ overflow: "scroll", maxHeight: "75vh" }}
                  handlerScroll={(e) => this.handleScroll(e)}
                >
                  <Div>
                    <Table
                      columns={[
                        { value: "PLU", align: "right" },
                        { value: "Descricao", align: "left" },
                        { value: "Código de Barras", align: "left" },
                        { value: "Departamento", align: "left" },
                        { value: "Grupo", align: "left" },
                        { value: "Marca", align: "left" },
                        { value: "Embalagem", align: "left" },
                        { value: "Fornecedor Principal", align: "left" },
                      ]}
                      fields={[
                        {
                          value: "codigoProduto",
                          align: "right",
                          type: Type.DADO.STRING,
                        },
                        {
                          value: "descricao",
                          align: "left",
                          type: Type.DADO.STRING,
                        },
                        { value: "ean", align: "left", type: Type.DADO.STRING },
                        {
                          value: "nomeDepartamento",
                          align: "left",
                          type: Type.DADO.STRING,
                        },
                        {
                          value: "nomeGrupo",
                          align: "left",
                          type: Type.DADO.STRING,
                        },
                        {
                          value: "nomeMarca",
                          align: "left",
                          type: Type.DADO.STRING,
                        },
                        {
                          value: "embalagem",
                          align: "left",
                          type: Type.DADO.STRING,
                        },
                        {
                          value: "nomeFornecedorPrincipal",
                          align: "left",
                          type: Type.DADO.STRING,
                        },
                      ]}
                      bordered
                      orientation="center"
                      //pagination
                      filter={this.renderFiltros()}
                      filterShow={true}
                      data={produtosSlice}
                      error="Nenhum produto encontrado"
                      checkbox={{ nameChecked: "selected", valueChecked: true }}
                      handlerCheckbox={(produto) => {
                        produto.selected = !produto.selected;

                        produto.idUsuarioInativacao = this.props.idUsuario;
                        produto.idUsuarioAtualizacao = this.props.idUsuario;

                        let newProdutosSelecionados = produtosSelecionados;

                        if (produto.selected) {
                          newProdutosSelecionados.push(produto);
                        } else {
                          newProdutosSelecionados = produtosSelecionados.filter(
                            (item) => {
                              return item.codigoProduto ===
                                produto.codigoProduto
                                ? false
                                : true;
                            }
                          );
                        }

                        this.setState({
                          produtosSelecionados: newProdutosSelecionados,
                        });
                      }}
                      TableColumnCheckBox={
                        <Checkbox
                          width="50px"
                          label="Selecionar Todos os Produtos"
                        ></Checkbox>
                      }
                      labelCheckBox={
                        <Checkbox
                          width="50px"
                          label="Selecionar Todos"
                          checked={selecionarTodos}
                          handlerChange={({ checked }) => {
                            let produtosList = produtos;

                            if (Utils.isArrayNotEmpty(produtosList)) {
                              produtosList.forEach((c) => {
                                c.selected = checked;
                                this.setState({
                                  produtosSelecionados: checked
                                    ? produtosList
                                    : [],
                                  selecionarTodos: checked,
                                });
                              });
                            } else {
                              Notification.info(
                                "Nenhum Produto selecionado, verifique se os produtos estão selecionado para serem inseridos na pesquisa."
                              );
                            }
                          }}
                        ></Checkbox>
                      }
                    ></Table>
                  </Div>
                </Div>
              </ModalCustom>
              <Div>
                <Div col="12" padding="2">
                  <Row col="12" padding="3">
                    <Title
                      value="Produtos inseridos no grupo"
                      type="h4"
                      paddingLeft="4"
                      paddingRight="4"
                      paddingTop="3"
                      col="10"
                    />
                    <Div
                      col="2"
                      padding="0 1rem"
                      style={{
                        textAlign: "right",
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "flex-end ",
                        marginBottom: "-20px",
                      }}
                    >
                      <ButtonIconOutLine
                        value="Adicionar Produtos"
                        icon={Type.ICON.PLUS}
                        btn={Color.NODE.PRIMARY}
                        handlerClick={() => this.setCloseModal(true)}
                      />
                    </Div>
                  </Row>
                </Div>
              </Div>
            </>
          </Div>

          <Div padding="2">
            <Table
              columns={[
                { value: "PLU", align: "center" },
                { value: "Produtos", align: "center" },
                { value: "Código de Barras", align: "center" },
                { value: "Departamento", align: "center" },
                { value: "Grupo", align: "center" },
              ]}
              fields={[
                { value: "codigoproduto", type: Type.DADO.STRING },
                { value: "descricao", type: Type.DADO.STRING },
                { value: "ean", type: Type.DADO.STRING },
                { value: "nomedepartamento", type: Type.DADO.STRING },
                { value: "nomegrupo", type: Type.DADO.STRING },
              ]}
              bordered
              search
              orientation="center"
              pagination
              data={this.state.produtosInseridos}
              error="Nenhum produto encontrado"
              checkbox={{ nameChecked: "selected", valueChecked: true }}
              handlerCheckbox={(produto) => {
                produto.selected = !produto.selected;
                produto.idUsuarioInativacao = this.props.idUsuario;
                produto.idUsuarioAtualizacao = this.props.idUsuario;
                let produtoEncontrado = produtosInseridosSelecionados.filter(
                  (p) =>
                    !p.produto &&
                    Utils.isValueValid(p.situacao) &&
                    String(p.ativo) === "true"
                )[0];

                if (Utils.isValueValid(produtoEncontrado)) {
                  produtoEncontrado = produto;
                } else {
                  produtosInseridosSelecionados.push(produto);
                }

                this.setState({
                  produtosInseridosSelecionados,
                });
              }}
              TableColumnCheckBox={
                <Checkbox
                  width="40px"
                  label="Selecionar Todos os Produtos"
                ></Checkbox>
              }
              labelCheckBox={
                <Div
                  col="12"
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <Div
                    col="6"
                    style={{
                      display: "flex",
                      marginTop: "12px",
                    }}
                  >
                    <Checkbox
                      width="40px"
                      label="Selecionar Todos"
                      checked={selecionarTodos}
                      handlerChange={({ checked }) => {
                        let produtosList = produtosInseridos;

                        if (Utils.isArrayNotEmpty(produtosList)) {
                          produtosList.forEach((c) => {
                            c.selected = checked;
                            this.setState({
                              produtosInseridosSelecionados: checked
                                ? produtosList
                                : [],
                              selecionarTodos: checked,
                            });
                          });
                        } else {
                          Notification.info(
                            "Nenhum Produto selecionado, verifique se os produtos estão selecionado para serem inseridos na pesquisa."
                          );
                        }
                      }}
                    ></Checkbox>
                  </Div>

                  <I
                    icon={Type.ICON.DELETE}
                    sizeIcon="2"
                    colorText={Color.NODE.DANGER}
                    style={{ cursor: "pointer" }}
                    handlerClick={() =>
                      this.deletarTodosProdutosPesquisa(
                        this.state.produtosInseridosSelecionados
                      )
                    }
                  ></I>
                </Div>
              }
            ></Table>
          </Div>
        </Div>
      </Div>
    );
  }
}

const mapStateToProps = (store) => ({
  user: store.loginState.user,
});

export default connect(mapStateToProps)(Cadastrar);
