import React, { Component } from "react";
import { Div, Table, InputSearch, Title, Select, SelectValue, Notification, ModalNotification} from "../../components";
import { connect } from "react-redux";
import { Color, PathRoute, Http, Type } from "../../utilities";

const ENTER = 13;

class Listar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: props.user,
      dialogRede: false,
      empresas: [],
      concorrentes: [],
      concorrente: {},
      selectedConcorrente: {},
      globalFilter: null,
      ...this.props.location.state,
    };    
    
  }

  UNSAFE_componentWillMount() {    
    this.getDataConcorrentes();
  }

  getDataConcorrentes() {        
    Http.get(`/concorrente/listar/` + this.state.user.idGestorCompras)
      .then(({ status, data }) => {
        if (data) {
          this.setState({ concorrentes: data });
        }
      })
      .catch((error) => console.log(error));
  }

  leftToolbarTemplate = () => {
    return (
      <InputSearch                           
      label="Procurar na Grid"
      name="globalFilter"              
      value={this.state.globalFilter}
      handlerChange={(e) => this.setState({ globalFilter: e.target.value })}
      placeholder="Procurar..."
    />
    )
  }

  rightToolbarTemplate = () => {
    return (      
        <span className="p-input-icon-right">           

        </span>      
    )
  }

  exportCSV () {

  }

  editarConcorrente(concorrente) {
    this.props.history.push({
      pathname: PathRoute.PATH.CONCORRENTE_CADASTRAR,
      state: { concorrente: concorrente, editar: true },
    });
  }


  deletarConcorrente(concorrente) {    
    ModalNotification.confirm(
      "Deseja desativar este Concorrente? " + concorrente.descricao,
      "Esta concorrente será desativada no sistema de compras!",
      () => this.delete(concorrente),
      () => this.cancelar()
    );
  }

  async delete(concorrente) {    
    concorrente.idUsuarioInativacao = this.state.user.idUsuario
    await Http.post(`/concorrente/deletar`, concorrente)
    .then(({ status, data }) => {
      if (data.status) {
        Notification.success(data.mensagem);
        this.getDataConcorrentes(concorrente.idEmpresa);        
      } else {
        Notification.danger(data.mensagem);
      }
    })
    .catch((error) => console.log(error));
  }

  cancelar() {
    
  }

  render() {
    
    return (
      <Div margin="3" shadow bg={Color.NODE.WHITE} borderLeft={Color.NODE.PRIMARY}>

        <Title value="Concorrentes" type="h4" paddingLeft="4" paddingRight="4" paddingTop="3"/>
       
        <Div padding="2">
          <Table
            columns={[              
              { value: "Descrição", align: "left" },
              { value: "Localização", align: "center" },              
              { value: "Website", align: "center" }
            ]}
            fields={[
              { value: "descricao", align: "left",    type: Type.DADO.STRING },
              { value: "localizacao",   type: Type.DADO.STRING },              
              { value: "website",   type: Type.DADO.STRING }
            ]}
            bordered
            search
            orientation="center"
            pagination
            rota={PathRoute.PATH.CONCORRENTE_CADASTRAR}
            edit
            delete            
            handlerEdit={(concorrente) => this.editarConcorrente(concorrente)}
            handlerDelete={(concorrente) => this.deletarConcorrente(concorrente)}            
            data={this.state.concorrentes}
            error="Nenhum concorrente encontrado"
          ></Table>
        </Div>
      </Div>
    );
  }
}

const mapStateToProps = (store) => ({
  idGestorCompras: store.loginState.user.idGestorCompras,
  idUsuario: store.loginState.user.idUsuario,
});

export default connect(mapStateToProps)(Listar);
