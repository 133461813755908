import React from "react";
import PropTypes from "prop-types";

import {
  margin,
  padding,
  className,
  rounded,
  pointer,
  col,
  float,
  responsive,
  invisible,
  textAlign,
  colorText,
  backGround,
  font,
  underline,
  inline,
  shadow
} from "../../css";

const Label = props => {
  return (
    <label
      className={getClassLabel(props)}
      id={props.id}
      data-target={props.target}
      data-toggle={props.toggle}
      htmlFor={props.for}
      onClick={props.handlerClick}
      style={props.style}
      title={props.title}
    >
      {getValue(props.value, props.uppercase, props.lowercase) ||
        props.children}
    </label>
  );
};

const getValue = (value, uppercase, lowercase) => {
  if (uppercase) {
    value = value.toUpperCase();
  } else if (lowercase) {
    value = value.toLowerCase();
  }
  return value;
};

const getClassLabel = props => {
  let classe = "";
  classe = classe.concat(textAlign(props));
  classe = classe.concat(colorText(props));
  classe = classe.concat(backGround(props));
  classe = classe.concat(margin(props));
  classe = classe.concat(padding(props));
  classe = classe.concat(margin(props));
  classe = classe.concat(pointer(props));
  classe = classe.concat(rounded(props));
  classe = classe.concat(col(props));
  classe = classe.concat(underline(props));
  classe = classe.concat(float(props));
  classe = classe.concat(inline(props));
  classe = classe.concat(responsive(props));
  classe = classe.concat(props.bold ? `font-weight-bold ` : "");
  classe = classe.concat(invisible(props));
  classe = classe.concat(font(props));
  classe = classe.concat(shadow(props));
  classe = classe.concat(className(props));

  return classe;
};

Label.propTypes = {
  color: PropTypes.string,
  uppercase: PropTypes.bool,
  lowercase: PropTypes.bool
};

export default Label;
