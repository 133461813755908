import React from "react";
import { active, className, inline } from "../css";

const getClassDefault = (props) => {
  let classe = ''
  classe = classe.concat(props.primary ? `${getColor("primary")} ` : '')
  classe = classe.concat(props.secondary ? `${getColor("secondary")} ` : '')
  classe = classe.concat(props.danger ? `${getColor("danger")} ` : '')
  classe = classe.concat(props.warning ? `${getColor("warning")} ` : '')
  classe = classe.concat(props.info ? `${getColor("info")} ` : '')
  classe = classe.concat(props.light ? `${getColor("light")} ` : '')
  classe = classe.concat(props.dark ? `${getColor("dark")} ` : '')
  classe = classe.concat(props.success ? `${getColor("success")} ` : '')
  classe = classe.concat(active(props))
  classe = classe.concat(inline(props))
  classe = classe.concat(props.bege ? `bg-bege ` : '')
  classe = classe.concat(className(props))
  return classe
}

const getColor = (type) => {
  return "list-group-item-".concat(type)
}

const Li = props => {
  return <li style={props.style} className={getClassDefault(props)} onClick={props.handlerClick}>{props.value || props.children}</li>;
};

export default Li;
